import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { SaveButton } from "@refinedev/antd";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  Col,
  Form,
  FormProps,
  Image,
  Input,
  Row,
  Select,
  Space,
  Upload,
  UploadFile,
  message,
} from "antd";
import { getBase64 } from "../../utils/helper";

import { RcFile } from "antd/es/upload";
import React, { useEffect, useState } from "react";
import { countryOptions, languageOptions } from "../../utils/common";
import { industryOptions } from "../../utils/selectionList";
import UploadCrop from "./components/UploadCrop";

const { TextArea } = Input;

type ProfileFormProps = {
  formProps: FormProps;
  showSaveButton?: boolean;
  onAvatarPreview?: (file: UploadFile) => void;
  hidePageCover?: boolean;
};
type FileListType = {
  uid: string;
  name: string;
  status: string;
  url: string;
}[];
const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const TOKEN_KEY = import.meta.env.VITE_AUTH_TOKEN_KEY;

export const ProfileForm: React.FC<ProfileFormProps> = ({
  formProps,
  onAvatarPreview,
  showSaveButton = false,
  hidePageCover = false,
}) => {
  const [avatarFileList, setAvatarFileList] = useState<FileListType>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const initialProfileData = formProps?.initialValues?.profile;
  const apiUrl = useApiUrl();
  useEffect(() => {
    if (initialProfileData?.avatar_url) {
      console.log("avatar_url", initialProfileData?.avatar_url);
      setAvatarFileList([
        {
          uid: "-1",
          name: "avatar.png",
          status: "done",
          url: IMG_PREFIX + initialProfileData?.avatar_url,
        },
      ]);
    }
  }, [initialProfileData]);

  const onRemove = (index: number) => {
    if (index === 0) setAvatarFileList([]);
  };
  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const beforeUpload = (file: RcFile) => {
    const isOver10M = file.size / 1024 / 1024 > 10;
    if (isOver10M) {
      message.warning("File must smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  // biome-ignore lint:
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    // biome-ignore lint:
    return e && e.fileList;
  };
  // @ts-ignore
  const handleUploadChange = ({ fileList }) => {
    // @ts-ignore
    const newFileList = fileList.map((file) => {
      return {
        uid: file.uid,
        name: file.name,
        status: file.status,
        url: IMG_PREFIX + file.response?.id,
      };
    });
    setAvatarFileList(newFileList);
  };

  return (
    <Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Full Name"
            name="user_name"
            rules={[
              {
                required: true,
                message: "Full Name is required",
              },
              {
                validator: (_, value) => {
                  if (value?.length > 0 && value?.trim() === "")
                    return Promise.reject(
                      new Error("Full Name cannot be only spaces"),
                    );
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Please Enter" maxLength={100} />
          </Form.Item>
          <Form.Item label="Introduction" name="introduction">
            <TextArea
              autoSize={{ minRows: 3, maxRows: 20 }}
              placeholder="Please Enter"
              maxLength={300}
              showCount
            />
          </Form.Item>
          <Form.Item
            label="Industry"
            name="industry"
            rules={[{ required: true }]}
          >
            <Select
              // options={industryOptions}
              optionFilterProp="children"
              showSearch
              placeholder="Please Select"
            >
              {industryOptions.map((item) => (
                <Select.OptGroup key={item.key} value={item.label}>
                  {item.options.map((opt) => (
                    <Select.Option key={opt.key} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Country" name="country">
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Please Select"
              filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countryOptions.map((country) => (
                <Select.Option key={country.value} value={country.value}>
                  {country.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* // TODO: get from location api */}
          <Form.Item label="City" name="city">
            <Input placeholder="Please Enter" maxLength={100} />
          </Form.Item>
          <Form.Item label="Languages Proficient In" name="languages">
            <Select
              mode="multiple"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Please Select"
            >
              {languageOptions.map((language) => (
                <Select.Option key={language.value} value={language.value}>
                  {language.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Summary" name="summary">
            <TextArea
              autoSize={{ minRows: 4, maxRows: 20 }}
              maxLength={1000}
              placeholder="Share your portfolio"
              showCount
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Avatar"
            name="avatar_url"
            extra="Upload an avatar image"
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <Upload
              name="file"
              className="upload-picture-object-fit-cover"
              action={`${apiUrl}/users/upload`}
              headers={{
                authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              }}
              maxCount={1}
              listType="picture-circle"
              accept="image/*"
              multiple={false}
              onPreview={handlePreview}
              onChange={handleUploadChange}
              beforeUpload={beforeUpload}
              // onRemove={() => onRemove(0)}
              // @ts-ignore
              // fileList={avatarFileList}
              // valuePropName="fileList"
              // getValueFromEvent={(e) => e.fileList}
            >
              {avatarFileList.length === 0 && (
                <button style={{ border: 0, background: "none" }} type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              )}
            </Upload>
          </Form.Item>
          {/* <Form.Item
            label="Page Cover"
            name="page_cover_url"
            extra="Upload a Page Cover Image"
            hidden={hidePageCover}
          >
            <UploadCrop
              uploadProps={{
                action: `${apiUrl}/users/upload`,
                headers: {
                  authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                },
                onPreview: onAvatarPreview,
              }}
            />
          </Form.Item> */}
        </Col>
        {showSaveButton && (
          <Col span={12}>
            <Space>
              {/* <Button
              onClick={() => {
                autoFill(formProps, 0, 0);
              }}
            >
              Autofill the form
            </Button> */}
              <SaveButton
                style={{ color: "white" }}
                type="primary"
                htmlType="submit"
              >
                Save
              </SaveButton>
            </Space>
          </Col>
        )}
      </Row>
      {previewImage && (
        <Image
          style={{ display: "none" }}
          src={previewImage}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
          }}
        />
      )}
    </Form.Item>
  );
};

export default ProfileForm;
