import { getStaticImgUrl } from "@/utils/common";
import React, { useRef, useEffect } from "react";

export const ProducerMask: React.FC = () => {
  const container = useRef(null);
  const stickyMask = useRef(null);

  const initialMaskSize = 0.3;
  const targetMaskSize = 50;
  const easing = 0.15;
  let easedScrollProgress = 0;

  useEffect(() => {
    requestAnimationFrame(animate);
  }, []);

  const animate = () => {
    const maskSizeProgress = targetMaskSize * getScrollProgress();
    //@ts-ignore
    stickyMask.current.style.webkitMaskSize = `${
      (initialMaskSize + maskSizeProgress) * 100
    }%`;
    requestAnimationFrame(animate);
  };

  const getScrollProgress = () => {
    const scrollProgress =
      //@ts-ignore
      stickyMask.current.offsetTop /
      //@ts-ignore
      (container.current.getBoundingClientRect().height - window.innerHeight);
    const delta = scrollProgress - easedScrollProgress;
    easedScrollProgress += delta * easing;
    return easedScrollProgress;
  };

  return (
    <div ref={container} className="relative h-[200vh] bg-black">
      <div
        ref={stickyMask}
        className="flex overflow-hidden sticky top-0 h-screen items-center justify-center sticky-mask"
      >
        <video autoPlay muted loop className="w-full h-full object-cover">
          <source src={getStaticImgUrl("nature.mp4") || ""} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};
