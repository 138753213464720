import { padImageUrl } from "@/utils/upload";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Avatar, Col, Collapse, Row, Typography } from "antd";
import { useState } from "react";
import { useSettlementContext } from "../../../contexts/settlement";

const { Panel } = Collapse;
const { Text, Title } = Typography;

interface UserProfileProps {
  status: string;
}

export const UserProfile: React.FC<UserProfileProps> = () => {
  const context = useSettlementContext();

  if (!context) {
    throw new Error("SettlementContext not available");
  }

  const { settlementDetails } = context;
  // console.log("settlementDetails of task details: ", settlementDetails);

  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const departmentLevel =
    settlementDetails?.department_levels
      ?.map((level) => `${level.department}-${level.level}`)
      .join("; ") || "N/A";

  const defaultAvatar = "https://joesch.moe/api/v1/random";

  const avatarUrl =
    settlementDetails?.avatar_url && settlementDetails.avatar_url !== ""
      ? padImageUrl(settlementDetails.avatar_url)
      : defaultAvatar;

  return (
    <div>
      <Collapse
        ghost
        bordered={false}
        defaultActiveKey={collapsed ? [] : ["1"]}
        expandIcon={({ isActive }) =>
          isActive ? <UpOutlined /> : <DownOutlined />
        }
        className="w-full"
        onChange={toggleCollapse}
        expandIconPosition="end"
      >
        <Panel
          header={
            <>
              <div className="flex items-center">
                <Avatar size="large" src={avatarUrl} />
                <div className="ml-3">
                  <Title level={5} className="mb-0">
                    {settlementDetails?.name}
                  </Title>
                </div>
              </div>
              <Row gutter={24} className="mt-4">
                <Col span={12}>
                  <Text type="secondary">Remaining days: </Text>
                  <Text className="font-semibold text-orange-500">
                    {" "}
                    {settlementDetails?.remain_days} days
                  </Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary">HeadCount: </Text>
                  <Text> {settlementDetails?.headcount}</Text>
                </Col>
              </Row>
            </>
          }
          key="1"
          className="bg-transparent"
        >
          {/* Remaining content */}
          <Row gutter={16} align="top">
            <Col flex="auto">
              <Row className="mb-2">
                <Col span={24}>
                  <Text type="secondary">Asset/Shot Department-Level: </Text>
                  <Text>{departmentLevel}</Text>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={12}>
                  <Text type="secondary">Group: </Text>
                  <Text>{settlementDetails?.group_name}</Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary">Title: </Text>
                  <Text>{settlementDetails?.job_title}</Text>
                </Col>
              </Row>
              {/* <Row
                // Temporarily remove this part
                className="mb-2"
              >
                <Col span={12}>
                  <Text type="secondary">Personnel efficiency: </Text>
                  <Text className="font-semibold text-orange-500">
                    {profileData.efficiency}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text type="secondary">Group ranking: </Text>
                  <Text className="font-semibold text-orange-500">
                    {profileData.ranking}
                  </Text>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};
