import { UserType } from "@/__generated__/graphql";
import SEO from "@/components/seo";
import ArtistPortfolioCore from "@/pages/discover/ArtistPortfolioCore";
import { SEOProps } from "@/utils/type";
import { useParsed } from "@refinedev/core";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const getUserRoleAndType = (
  searchParams: URLSearchParams,
  pathname?: string,
) => {
  let role: string;
  let type: string;
  if (pathname === "/users/profile") {
    role = UserType.Individual;
    type = "myself";
  } else if (pathname === "/companies" || pathname === "/companies/homepage") {
    role = UserType.Company;
    type = "myself";
  } else {
    role = searchParams.get("role") || UserType.Individual;
    type = "artist";
  }
  return [role, type];
};

export const ArtistPortfolio = () => {
  const { id } = useParams();
  const location = useLocation();
  const { pathname } = useParsed();
  const searchParams = new URLSearchParams(location.search);
  const [userRole, userType] = getUserRoleAndType(searchParams, pathname);

  const [seoInfo, setSeoInfo] = useState<SEOProps>({
    title: "",
    description: "",
    keywords: "",
    image: "",
    ogTitle: "",
    ogDescription: "",
  });

  return (
    <>
      {/* SEO */}
      <SEO
        title={seoInfo.title}
        description={seoInfo.description}
        keywords={seoInfo.keywords}
        ogTitle={seoInfo.ogTitle}
        ogDescription={seoInfo.ogDescription}
        image={seoInfo.image}
      />

      {/* Main page content */}
      <ArtistPortfolioCore
        artistId={id}
        userRole={userRole}
        userType={userType}
        onSeoDataLoad={(data) => setSeoInfo(data)}
      />
    </>
  );
};
