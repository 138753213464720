import { getStaticImgUrl } from "@/utils/common";
import { Col, Row } from "antd";
import React from "react";

export const AIVoice: React.FC = () => {
  return (
    <div className="relative bg-black">
      <Row className="pb-10 sm:pb-20 mb:pt-40 lg:pb-72">
        <Col span={12}>
          <img
            src={getStaticImgUrl("banner_voice.png") || ""}
            width="100%"
            alt="ai voice"
          />
        </Col>
        <Col span={12}>
          <div className="max-w-lg flex flex-col h-full justify-center gap-4 sm:gap-6 md:gap-8 lg:gap-10">
            <span className="text-2xl sm:text-3xl md:text-5xl lg:text-7xl font-extrabold text-white">
              AI voice
            </span>
            <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-white">
              Makes scratch tracks a breeze
            </span>
            <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/60">
              Makes scratch tracks a breeze
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
