import { axiosInstance } from "../rest-data-provider/utils";
// import type { HttpError } from "@refinedev/core";
// import axios, { type AxiosError } from "axios";

// const TOKEN_KEY = "leyline-auth";

// const BASE_URL = import.meta.env.VITE_MARKETPLACE_API_BASE_URL;

// export const axiosInstance = axios.create({
//   baseURL: BASE_URL,
//   timeout: 15 * 1000,
// });

// // @ts-ignore
// axiosInstance.interceptors.request.use((config) => {
//   const token = localStorage.getItem(TOKEN_KEY);

//   return {
//     ...config,
//     paramsSerializer: {
//       indexes: null,
//     },
//     headers: {
//       Authorization: `Bearer ${token}`,
//       ...config.headers,
//     },
//   };
// });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error: AxiosError) => {
//     if (error.response) {
//       const { status, statusText, data } = error.response;
//       const httpError: HttpError = {
//         statusCode: status,
//         message: statusText,
//         // @ts-ignore
//         errors: data,
//       };
//       throw httpError;
//       // return Promise.reject(new Error(msg, { cause: type }));
//     }
//     return Promise.reject(error);
//   },
// );

export { axiosInstance };
export const httpGet = axiosInstance.get;
export const httpPost = axiosInstance.post;
export const httpPut = axiosInstance.put;
