import { ErrorBoundary } from "@sentry/react";
import { Alert, Button, Result } from "antd";
import React from "react";

interface ErrorBoundaryWrapperProps {
  fallback?: (errorProps: {
    error: Error;
    componentStack: string;
    resetError: () => void;
  }) => React.ReactElement;
  children: React.ReactNode;
}

const isNotPROD = import.meta.env.MODE === "staging" || import.meta.env.DEV;

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({
  fallback,
  children,
}) => {
  return (
    <ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
          {isNotPROD ? (
            <Alert
              message={error.toString()}
              showIcon
              description={React.createElement(
                "pre",
                {
                  style: {
                    fontSize: "0.9em",
                    overflowX: "auto",
                  },
                },
                componentStack,
              )}
              type="error"
              action={
                <Button
                  size="large"
                  danger
                  onClick={() => {
                    resetError();
                  }}
                >
                  Click to reset the app!
                </Button>
              }
            />
          ) : (
            <Result
              status="500"
              subTitle="Sorry, something went wrong."
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    resetError();
                  }}
                >
                  Click to refresh the page!
                </Button>
              }
            />
          )}
        </React.Fragment>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
