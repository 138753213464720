import { CustomInput, CustomModalV2 } from "@/components/customComponents";
import { useEffect, useState } from "react";

type ModalProps = {
  value?: string | null;
  open: boolean;
  refreshList: () => void;
  setOpen: (x: boolean) => void;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  updateMemoRemark: (newRemark: string) => Promise<any>;
};

const RemarkModal = ({
  value,
  open,
  refreshList,
  setOpen,
  updateMemoRemark,
}: ModalProps) => {
  const [remark, setRemark] = useState("");

  useEffect(() => {
    setRemark(open ? value ?? "" : "");
  }, [open, value]);

  return (
    <CustomModalV2
      title="Remark"
      width={800}
      classNames={{
        body: "!px-10",
      }}
      open={open}
      onOk={async () => {
        await updateMemoRemark(remark);
        setOpen(false);
        refreshList();
      }}
      onCancel={() => {
        setOpen(false);
      }}
    >
      {open && (
        <CustomInput.TextArea
          value={remark}
          onChange={(e) => {
            setRemark(e.target.value);
          }}
          className="mb-14"
          maxLength={2000}
          placeholder="Please enter remark..."
        />
      )}
    </CustomModalV2>
  );
};

export default RemarkModal;
