import { getStaticImgUrl } from "@/utils/common";
import React from "react";

export const LeylineArtists: React.FC = () => {
  const artistList = [
    {
      name: "Alaric",
      role: "Modeler",
      description:
        "3D reconstruction is my modeling magic wand! With Al, it effortlessly transforms 2D into stunning 3D models, saving me time and effort. It's like having a digital sculpting assistant, making my job smoother and more enjoyable!",
      avatar: getStaticImgUrl("artist_avatar1.png") || "",
      bg: getStaticImgUrl("artist1.png") || "",
    },
    {
      name: "Elara",
      role: "Animator",
      description:
        "The auto-smoothing feature of Kframe effortlessly eliminates motion noise….",
      avatar: getStaticImgUrl("artist_avatar2.png") || "",
      bg: getStaticImgUrl("artist2.png") || "",
    },
    {
      name: "Lucian",
      role: "Artists",
      description:
        "Artists can train Personalized Model packs on the platform, facilitating ….",
      avatar: getStaticImgUrl("artist_avatar3.png") || "",
      bg: getStaticImgUrl("artist3.png") || "",
    },
  ];

  return (
    <div className="relative pt-10 sm:pt-20 md:pt-40 lg:pt-72 bg-white pb-10 sm:pb-20 md:pb-28 lg:pb-40">
      <div className="max-w-7xl px-6 mx-auto text-center">
        <span className="text-2xl sm:text-3xl md:text-5xl lg:text-7xl font-extrabold text-black">
          Reviews by Artists on LeyLine
        </span>
        <div className="artists-list flex gap-5 mt-8 sm:mt-14 md:mt-20 lg:mt-28">
          {artistList.map((artist) => (
            <div
              key={artist.name}
              className="relative h-auto flex-1 overflow-hidden transition-all duration-500 hover:flex-[4] hover:px-20 px-6 pb-16 pt-56 rounded-[1.25rem] bg-cover bg-no-repeat bg-center"
              style={{ backgroundImage: `url(${artist.bg})` }}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-linearGradient1" />
              <div className="relative flex flex-col gap-2.5 items-center">
                <div className="flex items-center justify-center w-20 h-20 rounded-full bg-yellow1 overflow-hidden">
                  <img
                    src={artist.avatar}
                    width="100%"
                    height="100%"
                    alt="avatar"
                  />
                </div>
                <span className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-semibold text-white">
                  {artist.name}
                </span>
                <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/60">
                  {artist.role}
                </span>
                <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/80 leading-relaxed block mt-5 line-clamp-5">
                  {artist.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
