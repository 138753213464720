import { JobPaymentMethod } from "@/components/businessComponents";
// import { getUserTimezone } from "@/utils/date";
import { removePrefix } from "@/utils/upload";
import { UploadFile } from "antd";

const getFilesUrl = (files: UploadFile[]) => {
  return files?.length > 0
    ? files.filter((x) => !!x).map((x) => removePrefix(x.url))
    : null;
};

export const paymentMethodOptions = [
  {
    label: "By Unit",
    value: "Piece-rate",
  },
  {
    label: "Wage Rate",
    value: "Time-based",
  },
  {
    label: "Entire Project",
    value: "Negotiable",
  },
];

export const currencyOptions = [
  { label: "USD", value: "USD" },
  // { label: "CNY", value: "CNY" },
];

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const handleSubmitData = (formData: any) => {
  // const userTimezone = getUserTimezone();
  const {
    party_a_name,
    party_a_email,
    party_b_name,
    party_b_email,
    timezone,
    headcount,
    payment_method,
    budget,
    totalAmount,
    workDateRange,
    totalAmountEx,
    is_deposit_required,
    depositAmount,
    deposit_due_date,
    project_file_urls,
    delivery_and_payment_schedule_file_urls,
    contract_file_urls,
    currency,
  } = formData;
  const { quantity, duration_per_ppl, unit, unit_price } = budget || {};
  const requestBody = {
    party_a_name,
    party_a_email,
    party_b_name,
    party_b_email,
    start_date: workDateRange[0].format("YYYY-MM-DD HH:mm:ss"),
    end_date: workDateRange[1].format("YYYY-MM-DD HH:mm:ss"),
    headcount: headcount,
    timezone,
    payment_method,
    count:
      payment_method !== JobPaymentMethod.Negotiable &&
      payment_method !== JobPaymentMethod.Undefined
        ? payment_method === JobPaymentMethod.Paperwork
          ? parseInt(quantity)
          : parseFloat(duration_per_ppl)
        : null,
    count_unit:
      payment_method !== JobPaymentMethod.Negotiable &&
      payment_method !== JobPaymentMethod.Undefined
        ? unit
        : null,
    rate:
      payment_method !== JobPaymentMethod.Negotiable &&
      payment_method !== JobPaymentMethod.Undefined
        ? parseFloat(unit_price)
        : null,
    // rate_unit:
    // payment_method === JobPaymentMethod.Time ? calculateRateUnit : "",
    total_cost:
      payment_method === JobPaymentMethod.Negotiable
        ? parseFloat(totalAmount)
        : parseFloat(totalAmountEx),
    is_deposit_required,
    ...(is_deposit_required
      ? {
          deposit: parseFloat(depositAmount),
          deposit_due_date: deposit_due_date.format("YYYY-MM-DD HH:mm:ss"),
        }
      : {}),
    project_file_urls: getFilesUrl(project_file_urls),
    delivery_and_payment_schedule_file_urls: getFilesUrl(
      delivery_and_payment_schedule_file_urls,
    ),
    contract_file_urls: getFilesUrl(contract_file_urls),
    currency,
  };
  return requestBody;
};

export const contractStepOptions = [
  {
    label: "Basic information",
    value: 1,
  },
  {
    label: "Quotation Sheet",
    value: 2,
  },
  {
    label: "Additional Documents",
    value: 3,
  },
];
