import {
  ApplicationStatEnum,
  ApplicationStatType,
} from "@/pages/Applications/enum";
import { ApplicantCardProps } from "@/pages/jobs/type";

interface TagConfType {
  text: string;
  textColor: string;
  bgColor: string;
}

export interface AppliedDataType {
  total_pending: number;
  total_waiting_for_actions: number;
  total_signed: number;
  total_closed: number;
  total_sub_status_0: number | null;
  total_sub_status_1: number | null;
  total_sub_status_2: number | null;
  selected_applications: ApplicantCardProps[];
}

export interface JobItem {
  project_id: string;
  project_name: string;
  job_id: string;
  job_title: string;
  applications: ApplicantCardProps[];
  total_pending: number | null;
  total_interviewing: number | null;
  total_saved_for_later: number | null;
  total_to_be_signed: number | null;
  total_signed: number | null;
  total_closed: number | null;
  total_sub_status_0: number | null;
  total_sub_status_1: number | null;
  total_sub_status_2: number | null;
}

export enum ApplGroupEnum {
  PENDING = "PENDING",
  WAITING = "WAITING_FOR_ACTIONS",
  SIGNED = "SIGNED",
  CLOSED = "CLOSED",
}

export enum ReceGroupEnum {
  PENDING = "PENDING",
  INTERVIEWING = "INTERVIEWING",
  LATER = "SAVED_FOR_LATER",
  TO_SIGN = "TO_BE_SIGNED",
  SIGNED = "SIGNED",
  CLOSED = "CLOSED",
}

export const applicationStatusTagMap: {
  [key in ApplicationStatType]: TagConfType;
} = {
  [ApplicationStatEnum.PENDING]: {
    text: "Pending",
    textColor: "#2F68FF",
    bgColor: "rgba(47, 104, 255, 0.10)",
  },
  [ApplicationStatEnum.A_DENIED]: {
    text: "Application rejected",
    textColor: "#FF4A4A",
    bgColor: "rgba(255, 74, 74, 0.1)",
  },
  [ApplicationStatEnum.B_DENIED]: {
    text: "Contract Declined",
    textColor: "#FF4A4A",
    bgColor: "rgba(255, 74, 74, 0.1)",
  },
  [ApplicationStatEnum.A_CANCELLED]: {
    text: "Contract Declined",
    textColor: "#FF4A4A",
    bgColor: "rgba(255, 74, 74, 0.1)",
  },
  [ApplicationStatEnum.B_CANCELLED]: {
    text: "Contract Declined",
    textColor: "#FF4A4A",
    bgColor: "rgba(255, 74, 74, 0.1)",
  },
  [ApplicationStatEnum.WITHDRAWN]: {
    text: "Application withdraw ",
    textColor: "#999999",
    bgColor: "rgba(153, 153, 153, 0.1)",
  },
  [ApplicationStatEnum.A_TIMEOUT]: {
    text: "Application timeout ",
    textColor: "#999999",
    bgColor: "rgba(153, 153, 153, 0.1)",
  },
  [ApplicationStatEnum.B_TIMEOUT]: {
    text: "Application timeout ",
    textColor: "#999999",
    bgColor: "rgba(153, 153, 153, 0.1)",
  },
  [ApplicationStatEnum.CONTRACTING]: {
    text: "Pending signing",
    textColor: "#FF9900",
    bgColor: "rgba(255, 153, 0, 0.1)",
  },
  [ApplicationStatEnum.A_SIGNED]: {
    text: "Pending signing",
    textColor: "#FF9900",
    bgColor: "rgba(255, 153, 0, 0.1)",
  },
  [ApplicationStatEnum.B_SIGNED]: {
    text: "Pending signing",
    textColor: "#FF9900",
    bgColor: "rgba(255, 153, 0, 0.1)",
  },
  [ApplicationStatEnum.COMPLETED]: {
    text: "Signed",
    textColor: "#0CBFA1",
    bgColor: "rgba(12, 191, 161, 0.1)",
  },
};

export const renderAppliedStageOptions = (item: AppliedDataType) => {
  return [
    {
      label: `Pending (${item?.total_pending ?? 0})`,
      value: ApplGroupEnum.PENDING,
    },
    {
      label: `Waiting for Actions (${item?.total_waiting_for_actions ?? 0})`,
      value: ApplGroupEnum.WAITING,
    },
    {
      label: `Signed (${item?.total_signed ?? 0})`,
      value: ApplGroupEnum.SIGNED,
    },
    {
      label: `Closed (${item?.total_closed ?? 0})`,
      value: ApplGroupEnum.CLOSED,
    },
  ];
};

export const renderApplicaStageOptions = (item: JobItem) => {
  return [
    {
      label: `Needs Review (${item.total_pending ?? 0})`,
      value: ReceGroupEnum.PENDING,
    },
    {
      label: `Interviewing (${item.total_interviewing ?? 0})`,
      value: ReceGroupEnum.INTERVIEWING,
    },
    {
      label: `Saved for later (${item.total_saved_for_later ?? 0})`,
      value: ReceGroupEnum.LATER,
    },
    {
      label: `To be signed (${item.total_to_be_signed ?? 0})`,
      value: ReceGroupEnum.TO_SIGN,
    },
    {
      label: `Signed (${item.total_signed ?? 0})`,
      value: ReceGroupEnum.SIGNED,
    },
    {
      label: `Closed (${item.total_closed ?? 0})`,
      value: ReceGroupEnum.CLOSED,
    },
  ];
};

export const renderWaitingFilters = (
  stage: string,
  totals: {
    total_sub_status_0: number | null;
    total_sub_status_1: number | null;
    total_sub_status_2: number | null;
  },
) => {
  return stage === ApplGroupEnum.WAITING
    ? [
        {
          label: `All (${totals.total_sub_status_0 ?? 0})`,
          key: "W0",
        },
        {
          label: `Pending your signature (${totals.total_sub_status_1 ?? 0})`,
          key: "W1",
        },
        {
          label: `Pending signature from the other party (${
            totals.total_sub_status_2 ?? 0
          })`,
          key: "W2",
        },
      ]
    : [
        {
          label: `All (${totals.total_sub_status_0 ?? 0})`,
          key: "S0",
        },
        {
          label: `Deposit pending (${totals.total_sub_status_1 ?? 0})`,
          key: "S1",
        },
        {
          label: `Deposit paid (${totals.total_sub_status_2 ?? 0})`,
          key: "S2",
        },
      ];
};

export const renderToSignFilters = (
  stage: string,
  totals: {
    total_sub_status_0: number | null;
    total_sub_status_1: number | null;
    total_sub_status_2: number | null;
  },
) => {
  return stage === ReceGroupEnum.TO_SIGN
    ? [
        {
          label: `All (${totals.total_sub_status_0 ?? 0})`,
          key: "T0",
        },
        {
          label: `Pending your signature (${totals.total_sub_status_1 ?? 0})`,
          key: "T1",
        },
        {
          label: `Pending signature from the other party (${
            totals.total_sub_status_2 ?? 0
          })`,
          key: "T2",
        },
      ]
    : [
        {
          label: `All (${totals.total_sub_status_0 ?? 0})`,
          key: "S0",
        },
        {
          label: `Deposit pending (${totals.total_sub_status_1 ?? 0})`,
          key: "S1",
        },
        {
          label: `Deposit paid (${totals.total_sub_status_2 ?? 0})`,
          key: "S2",
        },
      ];
};

export const applicationSorts = [
  {
    label: "Latest application",
    value: "LATEST_APPLICATION",
  },
  {
    label: "Lowest price",
    value: "LOWEST_COST",
  },
];

export const mockDataFilterOptions = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hanzhou",
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
      },
    ],
  },
];
