import { Button, ButtonProps, ConfigProvider } from "antd";
import { useContext } from "react";
import styles from "./styles/button.module.css";

export const CustomButton = ({ ...props }: ButtonProps) => {
  const themeConfig = useContext(ConfigProvider.ConfigContext);

  return (
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: "#00ADAE",
        },
      }}
    >
      <Button
        rootClassName={
          // @ts-ignore
          themeConfig?.theme?.mode === "dark" ? styles.btnWrapper : ""
        }
        {...props}
      />
    </ConfigProvider>
  );
};
