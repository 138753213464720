import { UserType } from "@/__generated__/graphql";
import { Col, Row, Space, Typography } from "antd";
import React from "react";

interface IArtistMenuProps {
  setAuthorTab: (tab: string) => void;
  authorTab: string;
  user_role: string;
}

export const ArtistMenu: React.FC<IArtistMenuProps> = ({
  setAuthorTab,
  authorTab,
  user_role,
}) => {
  const menu_individual_info = [
    {
      name: "Portfolio",
      state: "portfolio",
    },
    {
      name: "Profile",
      state: "resume",
    },
    {
      name: "Likes",
      state: "likes",
    },
    {
      name: "Collections",
      state: "collections",
    },
    {
      name: "Follow",
      state: "follow",
    },
  ];

  const menu_company_info = [
    {
      name: "Portfolio",
      state: "portfolio",
    },
    {
      name: "Introduction",
      state: "introduction",
    },
    {
      name: "Jobs",
      state: "jobs",
    },
    {
      name: "Likes",
      state: "likes",
    },
    {
      name: "Collections",
      state: "collections",
    },
    {
      name: "Follow",
      state: "follow",
    },
  ];

  const onTabClick = (tab: string) => {
    setAuthorTab(tab);
  };

  return (
    <Row className="flex flex-row justify-center rounded-sm mt-[30px] mb-9">
      <Col className="flex flex-row justify-center rounded-sm overflow-hidden">
        {(user_role === UserType.Company
          ? menu_company_info
          : menu_individual_info
        ).map((item) => (
          <Space
            key={item.name}
            className={`cursor-pointer text-base py-2.5 px-6 ${
              item.state === authorTab ? "bg-[#181818]" : "bg-[#2A2D30]"
            } `}
            onClick={() => onTabClick(item.state)}
          >
            <Typography.Text
              className={`text-opacity-85 ${
                item.state === authorTab ? "text-[#00ADAE]" : "text-white"
              } `}
            >
              {item.name}
            </Typography.Text>
          </Space>
        ))}
      </Col>
    </Row>
  );
};
