import { httpGet, httpPost, httpPut } from "@/utils/http";

const ptApiUrl = import.meta.env.VITE_PRODUCER_TOOL_API_BASE_URL;

export const updateMemoRemarkApi = (application_id: string, remark: string) =>
  httpPut(`/applications/company/${application_id}/memo-remark`, {
    memo_remark: remark,
  });

export const createIndividualSpriteAccount = (params: {
  refresh_url?: string;
  return_url?: string;
}) => httpPost("/payment/connect", {}, { baseURL: ptApiUrl, params });

export const createCompanySpriteAccount = (params: {
  refresh_url?: string;
  return_url?: string;
}) => httpPost("/payment/connect/company", {}, { baseURL: ptApiUrl, params });

export const getIndividualSpriteAccount = () =>
  httpGet("/payment/connect", {
    baseURL: ptApiUrl,
  });

export const getCompanySpriteAccount = () =>
  httpGet("/payment/connect/company", {
    baseURL: ptApiUrl,
  });

export const paymentDeposit = (params: {
  contract_id: string;
  success_url: string;
}) =>
  httpPost(
    "/payment/deposit",
    {},
    {
      baseURL: ptApiUrl,
      params,
    },
  );
