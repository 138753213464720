import { formatToMiddleStyle } from "@/utils/date";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Image, Space, Typography, Upload } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IWorkExperience } from "../../../interfaces";
const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const { Text } = Typography;

interface WorkPreviewCardProps {
  work: IWorkExperience;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

export const WorkExpPreviewCard: React.FC<WorkPreviewCardProps> = ({
  work,
  onDelete,
  onEdit,
}) => {
  return (
    <Card
      className="mb-3"
      bodyStyle={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "8px",
        borderRadius: "8px",
        backgroundColor: "#0000ff30",
      }}
    >
      <div className="flex flex-col items-start flex-1 pl-4">
        <div className="flex justify-between items-start w-full">
          <Text strong className="mb-2">
            {work.title}{" "}
          </Text>
          <Text type="secondary" strong={false}>
            {[work.department_name, work.city, work.country]
              .filter((i) => !!i)
              .join(" | ")}
          </Text>
          <Space size={12}>
            {onDelete && <DeleteOutlined onClick={() => onDelete(work.id)} />}
            {onEdit && <EditOutlined onClick={() => onEdit(work.id)} />}
          </Space>
        </div>
        <Text className="mb-2">{work.company_name}</Text>
        <Text type="secondary">
          {formatToMiddleStyle(work.start_date)} -{" "}
          {work.end_date ? formatToMiddleStyle(work.end_date) : "Present"}
        </Text>

        <Text type="secondary">{work.description}</Text>
      </div>
    </Card>
  );
};
