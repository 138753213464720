import { AIAnimation } from "@/components/ai/AIAnimation";
import { AIBanner } from "@/components/ai/AIBanner";
import { AIModeling } from "@/components/ai/AIModeling";
import { AIScriptBreakdown } from "@/components/ai/AIScriptBreakdown";
import { AIVideos } from "@/components/ai/AIVideos";
import { AIVoice } from "@/components/ai/AIVoice";
import { LeylineArtists } from "@/components/ai/LeylineArtists";
import { Subscribe } from "@/components/ai/Subscribe";
import React from "react";

export const AIPage: React.FC = () => {
  return (
    <div>
      <AIBanner />
      <AIScriptBreakdown />
      <AIModeling />
      <AIAnimation />
      <AIVoice />
      <AIVideos />
      <LeylineArtists />
      <Subscribe />
    </div>
  );
};
