const isDEV = import.meta.env.DEV;

export const settleDateOptions = [
  { label: "1st", value: 1 },
  { label: "5th", value: 5 },
  { label: "10th", value: 10 },
  { label: "15th", value: 15 },
  { label: "20th", value: 20 },
  { label: "25th", value: 25 },
];

export const settleDateOptionsForDev = [
  { label: "1st", value: 1 },
  { label: "2nd", value: 2 },
  { label: "3rd", value: 3 },
  { label: "4th", value: 4 },
  { label: "5th", value: 5 },
  { label: "6th", value: 6 },
  { label: "7th", value: 7 },
  { label: "8th", value: 8 },
  { label: "9th", value: 9 },
  { label: "10th", value: 10 },
  { label: "11th", value: 11 },
  { label: "12th", value: 12 },
  { label: "13th", value: 13 },
  { label: "14th", value: 14 },
  { label: "15th", value: 15 },
  { label: "16th", value: 16 },
  { label: "17th", value: 17 },
  { label: "18th", value: 18 },
  { label: "19th", value: 19 },
  { label: "20th", value: 20 },
  { label: "21th", value: 21 },
  { label: "22th", value: 22 },
  { label: "23th", value: 23 },
  { label: "24th", value: 24 },
  { label: "25th", value: 25 },
  { label: "26th", value: 26 },
  { label: "27th", value: 27 },
  { label: "28th", value: 28 },
  { label: "29th", value: 29 },
  { label: "30th", value: 30 },
];

export const showSettleDate = (val: number | null | undefined) => {
  if (!val) return null;
  const res = (isDEV ? settleDateOptionsForDev : settleDateOptions).find(
    (item) => item.value === val,
  );
  return res ? `${res.label}/Monthly` : null;
};

export const groupListOptions = [
  { label: "Script", value: "Script" },
  { label: "Storyboarding", value: "Storyboarding" },
  { label: "Editing", value: "Editing" },
  { label: "Concept Design", value: "Concept Design" },
  { label: "Modelling", value: "Modelling" },
  { label: "Location Scouting", value: "Location Scouting" },
  { label: "Surfacing", value: "Surfacing" },
  { label: "Rigging", value: "Rigging" },
  { label: "Grooming", value: "Grooming" },
  { label: "Layout", value: "Layout" },
  { label: "Animation", value: "Animation" },
  { label: "Effect", value: "Effect" },
  { label: "Lighting", value: "Lighting" },
  { label: "Compositing", value: "Compositing" },
  { label: "Mattepainting", value: "Mattepainting" },
  { label: "TD", value: "TD" },
  { label: "Production", value: "Production" },
  { label: "Other", value: "Other" },
];

export const handleSumaryTotal = (
  existingJobs: { total: number; currency: string }[],
) => {
  const obj = {};
  let str = "";
  for (let i = 0; i < existingJobs.length; i++) {
    const item = existingJobs[i];
    if (!item.currency) break;
    // @ts-ignore
    if (!obj[item.currency]) {
      // @ts-ignore
      obj[item.currency] = parseFloat(item.total.toFixed(2));
    } else {
      // @ts-ignore
      obj[item.currency] += item.total;
      // @ts-ignore
      obj[item.currency] = parseFloat(obj[item.currency].toFixed(2));
    }
  }

  // biome-ignore lint/complexity/noForEach: <explanation>
  Object.keys(obj).forEach((key) => {
    // @ts-ignore
    str += ` ${key} ${obj[key]}`;
  });
  return str || "-";
};
