import { WithdrawIcon } from "@/components/customComponents";
import LeyIcon from "@/components/leylineIcons";
import { JobInfoContextType, JobInfoProvider } from "@/contexts/jobInfo";
import { useModalConfirm } from "@/hooks";
import { JobDetailData } from "@/interfaces";
import { ApplicationStatEnum } from "@/pages/Applications/enum";
import { useCheckJob } from "@/pages/enterprises/hooks/useCheckJob";
import { ApplicantCardProps, PaymentStatusType } from "@/pages/jobs/type";
import { useGlobalStore } from "@/stores";
import { setConversationCustomData } from "@/utils/imUtils";
import {
  AuditOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  FileProtectOutlined,
  FrownOutlined,
  MessageOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import { Button, Modal, Typography, message } from "antd";
import { BaseType } from "antd/es/typography/Base";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  UserType,
  useSetRemoteCustomDataConversationMutation,
} from "../../../__generated__/graphql";
import { ApplyInfoType } from "../../../pages/enterprises/utils";
import { JobContractApplicantModal } from "../../../pages/jobs/modalContractApplicant";
import { JobContractDetailModal } from "../../../pages/jobs/modalContractDetailPublic";
import { JobUnmatchModal as RefuseModal } from "../../../pages/jobs/modalUnmatch";
import {
  formatRemainingTime,
  formatToLongStyle,
  formatToShortStyleUTC,
} from "../../../utils/date";
// import { ApplicantInfo } from "../ArtistApplicantCard";
import ApplyJobDrawer from "../components/ApplyJobDrawer";

interface ConfigOpt {
  hideTip: boolean;
  hideTalkBtn: boolean;
  jobId?: string;
}
interface DrawerRefType {
  open: (info?: ApplyInfoType) => void;
}
const { Paragraph } = Typography;

const TipParagraph = ({
  type,
  text,
  className,
}: { type: BaseType; text: string | ReactNode; className?: string }) => {
  return (
    <Paragraph
      type={type}
      className={`leading-5 !mb-0 text-center absolute top-1/2 -translate-y-1/2 w-full ${className}`}
      ellipsis={{
        rows: 2,
        tooltip: text,
      }}
    >
      {text}
    </Paragraph>
  );
};

export const useAppliedButtons = (
  applicant: ApplicantCardProps | undefined,
  refreshList: () => void,
  configOption?: ConfigOpt,
  isShow?: boolean,
  jobData?: JobDetailData,
) => {
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isContractDetailModalOpen, setIsContractDetailModalOpen] =
    useState(false);
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false);
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const [modalConfirm, modalContextHolder] = useModalConfirm();
  const { chatClient } = useGlobalStore((state) => state);
  const [setRemoteCustomDataMutation] =
    useSetRemoteCustomDataConversationMutation();
  const finallyJobId = configOption?.jobId || applicant?.job_id;
  const isHide = isShow === false;
  const {
    contextHolder,
    checkLoading,
    applyDrawerRef: editApplyInfoRef,
    checkApplyApi,
  } = useCheckJob(finallyJobId);

  const handleConfirmation = () => {
    (async () => {
      const loadingMessage = message.loading(
        "Cancelling the application, please wait...",
        0,
      );
      try {
        const response1 = await fetch(
          `${apiUrl}/applications/applicant/${applicant?.id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
            },
            body: JSON.stringify({
              is_enabled: 0,
            }),
          },
        );
        const data1 = await response1.json();
        if (response1.status !== 200 && response1.status !== 204) {
          console.error("Error cancelling", data1);
          message.error("Failed to cancel the application - try again later");
        } else {
          message.success("Withdrew the application - refreshing");
          refreshList();
        }
      } catch (error) {
        console.error("Error cancelling", error);
        message.error("Failed to withdraw the application - try again later");
      } finally {
        loadingMessage();
      }
    })();
  };

  const updateApplication = async (
    action: string,
    reason?: string,
    remark?: string,
  ) => {
    const loadingMessage = message.loading(
      "Unmatching the application, please wait...",
      0,
    );
    try {
      const requestBody =
        action === "refuse"
          ? {
              is_denied: 1,
              rejection_reason: reason,
              remark,
              // role: "Individual",
            }
          : {};
      const response1 = await fetch(
        `${apiUrl}/applications/applicant/${applicant?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
          },
          body: JSON.stringify(requestBody),
        },
      );
      const data1 = await response1.json();
      if (response1.status !== 200 && response1.status !== 204) {
        console.error(`Error ${action}`, data1);
        message.error("Failed to handle the application - try again later");
      } else {
        action === "refuse" && setIsRefuseModalOpen(false);
        refreshList();
      }
    } catch (error) {
      console.error(`Error ${action}`, error);
      message.error("Failed to handle the application - try again later");
    } finally {
      loadingMessage();
    }
  };

  const buttonConfigs = useMemo(
    () =>
      isHide
        ? null
        : {
            continueCommunication: {
              title: "Send Message",
              icon: <MessageOutlined />,
              onClick: async () => {
                const opponentImId = `${applicant?.user_id}_company`;
                await setConversationCustomData(
                  chatClient,
                  setRemoteCustomDataMutation,
                  opponentImId,
                  {
                    jInfo: {
                      id: applicant?.job_id,
                      titl: applicant?.job_title,
                      pName: applicant?.project_name,
                      role: 1,
                    },
                  },
                );
                navigate("/enterprises/chat", {
                  state: {
                    opponentImId: `${applicant?.user_id}_company`,
                    activeTab: 1,
                  },
                });
              },
            },
            revokeApplication: {
              title: "Withdraw",
              icon: <LeyIcon icon="Withdraw" />,
              onClick: () => {
                // @ts-ignore
                modalConfirm({
                  title: "Are you sure you want to withdraw the application?",
                  content:
                    "If you need to adjust your application content, we suggest you edit Application. Once you withdraw, you will not be able to apply for this job again.",
                  okText: "Confirm",
                  onOk() {
                    handleConfirmation();
                  },
                });
              },
            },
            modifyApplicationInfo: {
              title: "Edit Application",
              icon: <EditOutlined />,
              loading: checkLoading,
              onClick: () => {
                // checkApplyApi();
                sessionStorage.setItem(
                  "currentJobIdToApply",
                  finallyJobId || "",
                );
                editApplyInfoRef.current?.open(applicant);
              },
            },
            applyNow: {
              title: "Apply Now",
              loading: checkLoading,
              icon: <SmileOutlined />,
              onClick: () => {
                if (applicant?.is_job_available === 0) {
                  message.error(
                    "Application failed. The current job has been closed.",
                  );
                  return;
                }
                checkApplyApi();
              },
            },
            refuse: {
              title: "Reject",
              icon: <LeyIcon icon="Reject" />,
              onClick: () => {
                setIsRefuseModalOpen(true);
              },
            },
            goToSign: {
              title: "Sign Contract",
              icon: <AuditOutlined />,
              onClick: () => {
                setIsContractModalOpen(true);
              },
            },
            reviewTheContractContent: {
              title: "View Contract",
              icon: <LeyIcon icon="ViewContract" />,
              onClick: () => {
                setIsContractDetailModalOpen(true);
              },
            },
          },
    [
      finallyJobId,
      applicant,
      navigate,
      handleConfirmation,
      modalConfirm,
      chatClient,
      setRemoteCustomDataMutation,
      isHide,
      checkApplyApi,
      checkLoading,
      editApplyInfoRef,
    ],
  );

  const simpleJobData = useMemo(
    () =>
      jobData
        ? {
            project_name: jobData.project_name,
            project_id: jobData.project_id,
            company_id: jobData.company_id,
            job_title: jobData.title,
            job_id: jobData.id,
          }
        : null,
    [jobData],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const showTipsAndActions = useMemo(() => {
    let tipsContent: null | React.JSX.Element = null;
    let btns: string[] = [];
    if (isHide) return null;
    if (applicant?.status) {
      switch (applicant.status) {
        case ApplicationStatEnum.PENDING:
          tipsContent = (
            <TipParagraph
              type="warning"
              text={`You have submitted your application. The other party will process it within ${formatRemainingTime(
                applicant.created_at,
                90,
              )}.`}
            />
          );
          btns = [
            // "continueCommunication",
            "revokeApplication",
            "modifyApplicationInfo",
          ];
          break;
        case ApplicationStatEnum.A_DENIED:
          tipsContent = (
            <TipParagraph
              type="warning"
              text={`Unsuitable: ${
                applicant.remark || applicant.rejection_reason || "(no reason)"
              }`}
            />
          );
          btns = [];
          break;
        case ApplicationStatEnum.B_DENIED:
          tipsContent = (
            <TipParagraph
              type="danger"
              text={`Reason: ${
                applicant.remark || applicant.rejection_reason || "(no reason)"
              }`}
            />
          );
          btns = ["reviewTheContractContent"];
          break;
        case ApplicationStatEnum.CONTRACTING:
          tipsContent = (
            <TipParagraph
              type="warning"
              text="A copy of the contract has been sent to your registered email. Please check at your earliest convenience."
            />
          );
          btns = ["refuse", "reviewTheContractContent"];
          break;
        case ApplicationStatEnum.A_SIGNED:
          tipsContent = (
            <TipParagraph
              type="warning"
              text="The other party has finished signing the contract. Please check and sign at your earliest convenience."
            />
          );
          btns = ["refuse", "reviewTheContractContent"];
          break;
        case ApplicationStatEnum.B_SIGNED:
          tipsContent = (
            <TipParagraph
              type="warning"
              text="You have completed signing the contract. Waiting for the other party's signature."
            />
          );
          btns = ["reviewTheContractContent"];
          break;
        case ApplicationStatEnum.COMPLETED:
          tipsContent = (
            <div className="h-full py-1 flex flex-col justify-between text-[#0CBFA1]">
              <div>
                Signing Time:{" "}
                {formatToLongStyle(applicant.contract?.signed_at as string)}
              </div>
              {applicant.contract?.is_deposit_required ? (
                applicant.contract?.payment_status ===
                PaymentStatusType.PAID ? (
                  <>
                    <div>
                      Deposit: {applicant.contract?.currency}{" "}
                      {applicant.contract?.deposit?.toFixed(2)}
                    </div>
                    <div>
                      Pay Deposit Time:{" "}
                      {formatToLongStyle(
                        applicant.contract?.deposit_pay_time as string,
                      )}
                    </div>
                  </>
                ) : (
                  <TipParagraph
                    className="!static translate-y-0 !text-left"
                    type="warning"
                    text={`The negotiated deposit amount of ${
                      applicant.contract?.currency
                    } ${applicant.contract?.deposit?.toFixed(
                      2,
                    )} is due from the other party by  ${formatToShortStyleUTC(
                      applicant.contract?.deposit_due_date,
                    )}`}
                  />
                )
              ) : (
                <div>No deposit required</div>
              )}
            </div>
          );
          btns = ["reviewTheContractContent"];
          break;
        case ApplicationStatEnum.A_CANCELLED:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="Counterparty have withdrawn the signing."
            />
          );
          btns = ["reviewTheContractContent"];
          break;
        case ApplicationStatEnum.B_CANCELLED:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="You have withdrawn the signing."
            />
          );
          btns = [
            // "continueCommunication",
            // "applyNow",
            "reviewTheContractContent",
          ];
          break;
        case ApplicationStatEnum.A_TIMEOUT:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="The project party did not handle it in a timely manner. We suggest
              you contact the project party for communication and then reapply."
            />
          );
          btns = [];
          break;
        case ApplicationStatEnum.B_TIMEOUT:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="You did not sign in a timely manner. We suggest you contact the
              project party for communication and then reapply."
            />
          );
          btns = [];
          break;
        case ApplicationStatEnum.WITHDRAWN:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="You have withdrawn the application."
            />
          );
          btns = [];
          break;
        default:
          break;
      }
    } else {
      btns = [];
    }
    if (configOption?.hideTalkBtn) {
      const index = btns.findIndex(
        (btnName) => btnName === "continueCommunication",
      );
      index > -1 && btns.splice(index, 1);
    }
    return [
      !!tipsContent && !configOption?.hideTip && (
        <p
          className={`mr-4 mb-0 ${
            applicant?.status === ApplicationStatEnum.COMPLETED
              ? "h-24 !leading-[18px]"
              : "h-10 !leading-3"
          } relative`}
        >
          {tipsContent}
        </p>
      ),
      // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
      <div
        className={`flex justify-end ${!btns.length && "h-8"}`}
        onClick={(e) => e.stopPropagation()}
      >
        {btns.map((item, index) => {
          // @ts-ignore
          const config = buttonConfigs[item] || {};
          return (
            <Button
              key={config.title}
              type={index < btns.length - 1 ? "default" : "primary"}
              loading={config.loading}
              className="mr-2"
              onClick={config.onClick}
              icon={config.icon}
            >
              {config.title}
            </Button>
          );
        })}
      </div>,
      <>
        {contextHolder}
        {modalContextHolder}
        {!!applicant?.status && (
          <>
            <JobContractApplicantModal
              open={isContractModalOpen}
              setOpen={setIsContractModalOpen}
              applied={applicant}
              refreshList={refreshList}
            />
            <JobContractDetailModal
              curRole="partyB"
              open={isContractDetailModalOpen}
              setOpen={setIsContractDetailModalOpen}
              refreshList={refreshList}
              application={applicant}
              tipsContent={tipsContent}
            />
            <RefuseModal // @ts-ignore
              type="refuse"
              open={isRefuseModalOpen} // @ts-ignore
              onOk={(reason, remark) => {
                updateApplication("refuse", reason, remark);
              }}
              onCancel={() => {
                setIsRefuseModalOpen(false);
              }}
            />
          </>
        )}
        {(!!applicant || simpleJobData) && (
          <JobInfoProvider
            jobData={applicant || (simpleJobData as JobInfoContextType)}
          >
            <ApplyJobDrawer ref={editApplyInfoRef} refresh={refreshList} />
          </JobInfoProvider>
        )}
      </>,
    ];
  }, [applicant, buttonConfigs, isHide]);

  return showTipsAndActions;
};
