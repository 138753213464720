import { useUserData } from "@/hooks";
import { JobDetailData } from "@/interfaces";
import { getGuestJobDetailAPI, getJobDetailAPI } from "@/pages/discover/api";
import { getImgUrl } from "@/utils/common";
import { Col, Image, Row, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface ISelectJobProps {
  jobId: string;
}

export const SelectJob: React.FC<ISelectJobProps> = ({ jobId }) => {
  const profile = useUserData();
  const [selectJob, setSelectJob] = useState<JobDetailData>();
  const getJobDetail = useCallback(async () => {
    const response = await getJobDetailAPI(jobId);
    setSelectJob(response);
  }, [jobId]);

  const getGuestJobDetail = useCallback(async () => {
    const response = await getGuestJobDetailAPI(jobId);
    setSelectJob(response);
  }, [jobId]);

  useEffect(() => {
    if (!profile) {
      getGuestJobDetail();
    } else {
      getJobDetail();
    }
  }, [getJobDetail, getGuestJobDetail, profile]);
  return (
    <Row className="rounded-sm bg-[#343638] p-3 relative">
      <Link
        to={`/enterprises/public-job-details/${selectJob?.id}`}
        className="flex flex-row gap-2"
      >
        <Image
          preview={false}
          width="80px"
          height="60px"
          className="rounded-sm"
          src={
            getImgUrl(selectJob?.project_thumbnail_url) ||
            "https://placehold.co/64x64"
          }
        />
        <Col className="flex-1 flex flex-col gap-2.5 justify-center">
          <Typography.Text className="text-white text-opacity-85 font-bold text-base">
            {selectJob?.project_name}
          </Typography.Text>
          <Typography.Text className="text-[#606264] text-sm">
            {selectJob?.location
              ? `${selectJob.location} / ${selectJob?.remote_option} / ${selectJob?.employment_type}`
              : `${selectJob?.remote_option} / ${selectJob?.employment_type}`}
          </Typography.Text>
        </Col>
      </Link>
    </Row>
  );
};
