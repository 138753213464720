import { TaskDetail } from "@/interfaces";
import { taskStatusOptions } from "@/utils/common";
import { statusDictMap } from "@/utils/dict";
import { StatusColorEnum } from "@/utils/enum";
import { TaskStatusType } from "@/utils/type";
import { Table, Tag, Typography, message } from "antd";
import React from "react";
// import EditableCell from "@/components/editableCell";
import { useSettlementContext } from "../../../contexts/settlement";

const { Title, Text, Link } = Typography;

interface TaskDetailsProps {
  status: string;
  role: string;
}

export const TaskDetails: React.FC<TaskDetailsProps> = ({ status, role }) => {
  const { settlementDetails, updateTaskDetails } = useSettlementContext() ?? {};

  const handleTaskChange = (
    newValue: number,
    key: keyof TaskDetail,
    record: TaskDetail,
  ) => {
    if (!settlementDetails) {
      console.error("Settlement details are not available.");
      return;
    }

    const updatedTaskDetails = settlementDetails.task_details.map((task) => {
      if (task.id === record.id) {
        const updates = { [key]: newValue };

        if (key === "pending_hour") {
          const hourDifference = newValue - task.pending_hour;
          updates.act_workhour = parseFloat(
            (task.act_workhour + hourDifference).toFixed(2),
          );

          const newPendingCost = newValue * (settlementDetails.unit_price ?? 0);
          updates.pending_cost = Math.round(newPendingCost * 100) / 100;
        }

        return { ...task, ...updates };
      }
      return task;
    });

    if (updateTaskDetails) {
      updateTaskDetails(updatedTaskDetails);
    } else {
      message.error("Update failed. Please try again.");
    }

    console.log("Updated Task Details", updatedTaskDetails);
  };

  const columns = [
    {
      title: "Task",
      dataIndex: "full_name",
      key: "full_name",
      render: (text: string) => <Link>{text}</Link>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (task_status: TaskStatusType) => (
        <Tag color={StatusColorEnum[task_status]}>
          {statusDictMap[task_status]}
        </Tag>
      ),
    },
    {
      title: "Act. Hours",
      dataIndex: "act_workhour",
      key: "act_workhour",
    },
    {
      title:
        status === "Settled" ? "Settlement Hours" : "Pending Settlement Hours",
      dataIndex: "pending_hour",
      key: "pending_hour",
      render: (text: string, record: TaskDetail) => <Text>{text}</Text>,
    },
    {
      title: status === "Settled" ? "Payment" : "Pending Settlement Cost",
      dataIndex: "pending_cost",
      key: "pending_cost",
      render: (text: string, record: TaskDetail) => <Text>{text}</Text>,
    },
  ];

  const editableStatuses = [
    "Pending settlement",
    "Pending confirmation",
    "Pending processing",
  ];
  const isEditable = role === "producer" && editableStatuses.includes(status);

  const filteredColumns = columns.filter(
    (column) =>
      column.key !== "pending_cost" ||
      (settlementDetails?.unit &&
        !["day", "week", "month"].includes(settlementDetails.unit)),
  );

  return (
    <div className="ml-5">
      <Title level={4}>Task Details</Title>
      <Table
        columns={filteredColumns}
        dataSource={settlementDetails?.task_details}
        pagination={false}
      />
    </div>
  );
};
