import { useLink, useRouterContext, useRouterType } from "@refinedev/core";
import { Image, Space, Typography, theme } from "antd";
import React from "react";

type CustomizedThemedTitleProps = {
  icon?: React.ReactNode;
  text?: React.ReactNode;
  wrapperStyles?: React.CSSProperties;
};
const VITE_LANDING_WEB_BASE = import.meta.env.VITE_LANDING_WEB_BASE;
export const CustomizedTitleV2: React.FC<CustomizedThemedTitleProps> = ({
  icon,
  wrapperStyles,
}) => {
  const { token } = theme.useToken();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  return (
    <ActiveLink
      to={VITE_LANDING_WEB_BASE}
      className="inline-block no-underline"
    >
      <div
        className="flex items-center"
        style={{ fontSize: "inherit", ...wrapperStyles }}
      >
        <div className="h-12 w-12" style={{ color: token.colorPrimary }}>
          {icon}
        </div>
      </div>
    </ActiveLink>
  );
};
