import { getStaticImgUrl } from "@/utils/common";
import React, { useState, useEffect, useRef } from "react";

export const AIAnimation: React.FC = () => {
  const [activeImageGallery, setActiveImageGallery] = useState<boolean>(false);
  const imageGalleryRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setActiveImageGallery(entry.isIntersecting);
      },
      { threshold: 0.1 }, // Adjust this threshold as needed
    );

    if (imageGalleryRef.current) {
      observer.observe(imageGalleryRef.current);
    }

    return () => {
      if (imageGalleryRef.current) {
        observer.unobserve(imageGalleryRef.current);
      }
    };
  }, []);

  return (
    <div className="relative bg-black">
      <div className="max-w-7xl px-6 mx-auto pt-10 sm:pt-20 md:pt-40 lg:pt-72">
        <div className="max-w-2xl flex flex-col gap-4 sm:gap-6 md:gap-8 lg:gap-10 mb-20">
          <span className="text-2xl sm:text-3xl md:text-5xl lg:text-7xl font-extrabold text-white">
            AI in animation
          </span>
          <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-white">
            Optimize motion capture curves with 1-click to smooth out motion
            noise
          </span>
          <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/60">
            Saves hours for animators so they can focus on the quality of
            animation and performance. Apply motion and expressions easily for
            peripheral characters with text descriptions.
          </span>
        </div>
      </div>
      <div
        ref={imageGalleryRef}
        className={`image-gallery ${activeImageGallery ? "active" : ""}`}
      >
        <picture className="absolute hardware">
          <img src={getStaticImgUrl("gallery1.png") || ""} alt="" />
        </picture>
        <picture className="absolute block screen screen-1">
          <img src={getStaticImgUrl("gallery2.png") || ""} alt="" />
        </picture>
        <picture className="absolute block screen screen-2">
          <img src={getStaticImgUrl("gallery3.png") || ""} alt="" />
        </picture>
        <picture className="absolute block screen screen-3">
          <img src={getStaticImgUrl("gallery4.png") || ""} alt="" />
        </picture>
        <picture className="absolute block screen screen-4">
          <img src={getStaticImgUrl("gallery6.png") || ""} alt="" />
        </picture>
        <picture className="absolute block screen screen-5">
          <img src={getStaticImgUrl("gallery8.png") || ""} alt="" />
        </picture>
        <picture className="absolute block screen screen-6">
          <img src={getStaticImgUrl("gallery5.png") || ""} alt="" />
        </picture>
        <picture className="absolute block screen screen-7">
          <img src={getStaticImgUrl("gallery7.png") || ""} alt="" />
        </picture>
      </div>
    </div>
  );
};
