import { create } from "zustand";

interface ArtworkState {
  artworkSearch: string;
  artworkDetailModalVisible: boolean;
  artworkDetailArtworkId: string;
  artworkDetailEditMode: boolean;
  artworkDetailEditArtworkId: string;
  updateArtworkSearch: (search: string) => void;
  updateArtworkDetailModalVisible: (visible: boolean) => void;
  updateArtworkDetailArtworkId: (id: string) => void;
  updateArtworkDetailEditMode: (editMode: boolean) => void;
  updateArtworkDetailEditArtworkId: (id: string) => void;
}

export const useArtworkStore = create<ArtworkState>((set) => ({
  artworkSearch: "",
  artworkDetailModalVisible: false,
  artworkDetailArtworkId: "",
  artworkDetailEditMode: false,
  artworkDetailEditArtworkId: "",
  updateArtworkSearch: (search: string) =>
    set(() => ({ artworkSearch: search })),
  updateArtworkDetailModalVisible: (visible: boolean) =>
    set(() => ({ artworkDetailModalVisible: visible })),
  updateArtworkDetailArtworkId: (id: string) =>
    set(() => ({ artworkDetailArtworkId: id })),
  updateArtworkDetailEditMode: (editMode: boolean) =>
    set(() => ({ artworkDetailEditMode: editMode })),
  updateArtworkDetailEditArtworkId: (id: string) =>
    set(() => ({ artworkDetailEditArtworkId: id })),
}));

export default useArtworkStore;
