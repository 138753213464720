import ProjectStatusTag from "@/pages/enterprises/components/ProjectStatusTag";
import ShowFileOrLink from "@/pages/enterprises/components/ShowFileOrLink";
import CompanyInfo from "@/pages/jobs/components/CompanyInfo";
import JobProperties from "@/pages/jobs/components/JobProperties";
import { padLeftImgUrl, showAbsoluteUrl } from "@/utils/common";
import { formatToMiddleStyle } from "@/utils/date";
import { DownloadOutlined } from "@ant-design/icons";
import { IResourceComponentsProps } from "@refinedev/core";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Grid,
  Result,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";

const { Paragraph, Title, Text, Link } = Typography;
const { Meta } = Card;
import moment from "moment";

import { useNavigate } from "react-router-dom";

interface ICreateJobIn {
  department_id: string;
  title: string;
  description: string;
  software: string[];
  location: string;
  remote_option: string;
  question: {
    link: string;
    description: string;
    pic_url: string;
    video_url: string;
  };
  is_enabled: number;
  headcount: number;
  payment_method: number;
  count: number;
  count_unit: string;
  rate: number;
  rate_unit: string;
}

export const JobInfoSummary: React.FC<IResourceComponentsProps> = ({
  // @ts-ignore
  goBack,
  // @ts-ignore
  jobInfo,
  // @ts-ignore
  projectInfo,
  // @ts-ignore
  departmentList,
}) => {
  const navigate = useNavigate();
  const breakpoint = Grid.useBreakpoint();

  const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const projectDesp =
    "Elemental is a 2023 American animated romantic comedy-drama film.";

  // @ts-ignore
  const previewFile = (src, isImage: boolean) => {
    if (isImage) {
      const imgWindow = window.open(src);
      if (imgWindow) {
        imgWindow.document.write(`<img src="${src}" style="width: 100%;">`);
      }
    } else {
      const videoWindow = window.open(src);
      if (videoWindow) {
        videoWindow.document.write(
          `<video src="${src}" controls style="width: 100%;"></video>`,
        );
      }
    }
  };

  const documentURLSplitted = (
    jobInfo.question?.pic_url ||
    jobInfo.question?.video_url ||
    ""
  ).split("/");
  // console.log(9999, jobInfo);
  const documentName = documentURLSplitted[documentURLSplitted.length - 1];

  const departments = (departmentList || []).filter((x: { id: string }) => {
    return x.id === jobInfo.department_id;
  });
  const currentDepartment = departments.length > 0 ? departments[0] : null;

  return (
    <>
      {/* <Result
        status="success"
        title="Successfully Created Job!"
        subTitle="Job number: 20240101AK0001"
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => {
              navigate("/jobs");
            }}
          >
            Go to Summary
          </Button>,
          <Button
            key="new"
            onClick={() => {
              navigate("/jobs/create");
            }}
          >
            Create Again
          </Button>,
        ]}
      /> */}

      <Space
        direction="vertical"
        style={{ width: "100%", marginBottom: "20px" }}
      >
        <Alert
          message="Job Details Preview"
          type="warning"
          style={{ textAlign: "center" }}
        />
      </Space>

      {breakpoint.xl ? (
        <Row>
          <Col span={17}>
            <Space direction="vertical" className="w-full">
              <Title level={3}>{jobInfo.title}</Title>

              {/* <Title level={5}>Group Description</Title>
              <Text>{currentDepartment?.description}</Text> */}

              <Title level={5} className="mt-4">
                Skills and Requirement
              </Title>
              <Text className="whitespace-pre-wrap">{jobInfo.description}</Text>
              <JobProperties job={jobInfo} />
              <Title level={5} className="mt-4">
                Software
              </Title>
              <p>
                {
                  // @ts-ignore
                  jobInfo.software?.map((val) => (
                    <Tag color="#00ced1" className="mr-1 mb-1">
                      {val}
                    </Tag>
                  ))
                }
              </p>

              <Title level={5} className="mt-4">
                Benchmark
              </Title>
              <ShowFileOrLink {...jobInfo?.benchmark} />

              <Title level={5} className="mt-4">
                Test
              </Title>
              <ShowFileOrLink {...jobInfo?.test} />
            </Space>
          </Col>
          <Col span={7}>
            <Card
              hoverable
              className=""
              bodyStyle={{ width: "100%" }}
              cover={
                <img
                  alt="job cover"
                  // style={{ width: "100%" }}
                  className="w-full"
                  src={IMG_PREFIX + projectInfo.thumbnail_url}
                />
              }
            >
              <Row gutter={20}>
                <Col span={20}>
                  <Title level={3}>{projectInfo.name}</Title>
                  <p className="mt-4">
                    <Text type="secondary">
                      {formatToMiddleStyle(projectInfo.start_date)}
                      &nbsp;to&nbsp;
                      {formatToMiddleStyle(projectInfo.end_date)}
                    </Text>
                  </p>
                  <p className="mt-4">
                    <ProjectStatusTag status={jobInfo.progress_status} />
                  </p>
                  <Text type="secondary">{projectInfo.description}</Text>
                </Col>
                <Divider type="horizontal" />
                <CompanyInfo job={jobInfo} />
              </Row>
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Space direction="vertical" className="w-full">
            <Title level={3}>{jobInfo.title}</Title>

            {/* <Title level={5}>Group Description</Title>
            <Text>{currentDepartment?.description}</Text> */}

            <Title level={5} className="mt-4">
              Skills and Requirement
            </Title>
            <Text className="whitespace-pre-wrap">{jobInfo.description}</Text>
            <JobProperties job={jobInfo} />
            <Title level={5} className="mt-4">
              Software
            </Title>
            <p>
              {
                // @ts-ignore
                jobInfo.software?.map(
                  (
                    // @ts-ignore
                    val, // allow software to be null
                  ) => (
                    <Tag color="#00ced1" className="mr-1 mb-1">
                      {val}
                    </Tag>
                  ),
                )
              }
            </p>

            <Title level={5} className="mt-4">
              Benchmark
            </Title>
            <ShowFileOrLink {...jobInfo?.benchmark} />

            <Title level={5} className="mt-4">
              Test
            </Title>
            <ShowFileOrLink {...jobInfo?.test} />
          </Space>

          <Card
            hoverable
            className="mt-8"
            bodyStyle={{ width: "100%" }}
            cover={
              <img
                alt="job cover"
                // style={{ width: "100%" }}
                className="w-full"
                src={IMG_PREFIX + projectInfo.thumbnail_url}
              />
            }
          >
            <Row gutter={20}>
              <Col span={20}>
                <Title level={3}>{projectInfo.name}</Title>
                <p className="mt-4">
                  <Text type="secondary">
                    {formatToMiddleStyle(projectInfo.start_date)}
                    &nbsp;to&nbsp;
                    {formatToMiddleStyle(projectInfo.end_date)}
                  </Text>
                </p>
                <p className="mt-4">
                  <ProjectStatusTag status={jobInfo.progress_status} />
                </p>
                <Text type="secondary">{projectInfo.description}</Text>
              </Col>
              <Divider type="horizontal" />
              <CompanyInfo job={jobInfo} />
            </Row>
          </Card>
        </>
      )}
    </>
  );
};
