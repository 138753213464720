import { ICompanyInfo, IExtendedInfo, IPublicCompanyInfo } from "./type";

export const isEmptyExtendedInfo = (extendedInfo: IExtendedInfo) => {
  return Object.values(extendedInfo).every(
    (info) =>
      info === null ||
      info === "" ||
      (Array.isArray(info) && info.length === 0),
  );
};

export const transformArtistInfo = (
  info: ICompanyInfo | undefined,
): IPublicCompanyInfo | null => {
  if (!info) return null;
  return {
    id: info.id,
    is_verified: info.is_verified,
    name: info.extended_info?.name as string,
    type: info.extended_info?.type as string,
    established_at: info.extended_info?.established_at as string,
    country: info.extended_info?.country as string,
    logo_url: info.extended_info?.logo_url as string,
    shortname: info.extended_info?.shortname as string,
    industry: info.extended_info?.industry as string,
    scale: info.extended_info?.scale as string,
    description: info.extended_info?.description as string,
    business_fields: info.extended_info?.business_fields as string[],
    pic_urls: info.extended_info?.pic_urls as string[],
    addresses: info.extended_info?.addresses as string[],
    cover_url: info.extended_info?.cover_url as string,
  };
};
