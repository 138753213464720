import {
  ForgotPasswordFormTypes,
  ForgotPasswordPageProps,
  UpdatePasswordFormTypes,
  useActiveAuthProvider,
  useLink,
  useRouterType,
  useUpdatePassword,
} from "@refinedev/core";
import {
  useForgotPassword,
  useRouterContext,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Card,
  CardProps,
  Col,
  Form,
  FormProps,
  Input,
  Layout,
  LayoutProps,
  Row,
  Typography,
  message,
  notification,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";

import { CustomButton, CustomInput } from "@/components/customComponents";
import ContainerContent from "@/pages/authCore/components/containerContent";
import AuthLayout from "@/pages/authCore/layout";
import { passwordValidator } from "@/pages/authCore/utils";
import { httpPost } from "@/utils/http";
import { KeyOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { ThemedTitleV2 } from "@refinedev/antd";
import styles from "../register/index.module.css";

type ResetPassworProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;
type stepType = "opt" | "reset";
interface IUpdatePasswordTypes extends UpdatePasswordFormTypes {
  token: string;
}

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const ForgotPasswordPage: React.FC<ResetPassworProps> = ({
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
  title,
}) => {
  const [cooldown, setCooldown] = useState(0);
  const [current, setCurrent] = useState<stepType>("opt");
  const [newEmail, setNewEmail] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false); // Added state for loading effect
  const { token } = theme.useToken();
  const [form1] = Form.useForm<ForgotPasswordFormTypes>();
  const [form2] = Form.useForm<IUpdatePasswordTypes>();
  const translate = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const authProvider = useActiveAuthProvider();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const { mutate: forgotPassword, isLoading } =
    useForgotPassword<ForgotPasswordFormTypes>();
  const { mutate: updatePassword, isLoading: isLoading2 } =
    useUpdatePassword<IUpdatePasswordTypes>({
      v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
    });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldown > 0) {
      timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [cooldown]);

  const sendVerificationCode = async () => {
    const value = await form1.validateFields(["email"]);
    try {
      if (cooldown === 0) {
        setIsSendingCode(true);
        await httpPost("/auth/forgot-password/code", {
          email: value.email,
        });
        notification.success({
          message: "Verification Code Sent",
          description: "Please check your email for the code",
        });
        setCooldown(60);
        setIsSendingCode(false);
      }
    } catch (error) {
      setIsSendingCode(false);
      message.error(
        // @ts-ignore
        error?.errors?.detail || "The verification code failed to be sent.",
      );
    }
  };

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title ?? <ThemedTitleV2 collapsed={false} />}
      </div>
    );

  const CardContent1 = (
    <ContainerContent title="LeyLine" description="Forgot your password?">
      <Form<ForgotPasswordFormTypes>
        layout="vertical"
        form={form1}
        onFinish={(values) => {
          forgotPassword(values, {
            onSuccess: (data, variables, context) => {
              // @ts-ignore
              if (data?.success === true) {
                setNewEmail(values.email as string);
                setAccessToken(data.access_token as string);
                setCurrent("reset");
              }
            },
          });
        }}
        requiredMark={false}
        {...formProps}
      >
        <Form.Item
          name="email"
          // label={translate("pages.forgotPassword.fields.email", "Email")}
          rules={[
            { required: true },
            {
              type: "email",
              message: translate(
                "pages.forgotPassword.errors.validEmail",
                "Invalid email address",
              ),
            },
          ]}
        >
          <CustomInput
            roundRadius
            type="email"
            size="large"
            placeholder={translate(
              "pages.forgotPassword.fields.email",
              "Email",
            )}
            prefix={<MailOutlined style={{ color: "#999" }} />}
          />
        </Form.Item>
        <Form.Item
          name="otp"
          rules={[
            {
              required: true,
              len: 6,
            },
          ]}
        >
          <CustomInput
            roundRadius
            placeholder="Verification Code"
            size="large"
            maxLength={6}
            allowClear
            prefix={<KeyOutlined className="!text-[#999]" />}
            suffix={
              <CustomButton
                size="small"
                loading={isSendingCode}
                onClick={sendVerificationCode}
              >
                {cooldown > 0 ? `${cooldown}s` : "Send the code"}
              </CustomButton>
            }
          />
        </Form.Item>
        <Form.Item className="mt-10 mb-5 text-center">
          <CustomButton
            className="w-[208px]"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Continue
          </CustomButton>
        </Form.Item>
        <div
          className="text-center"
          // style={{
          //   display: "flex",
          //   justifyContent: "space-between",
          // }}
        >
          {loginLink ?? (
            <Typography.Text
              style={{
                fontSize: 12,
                marginLeft: "auto",
              }}
            >
              {translate(
                "pages.register.buttons.haveAccount",
                "Have an account? ",
              )}{" "}
              <ActiveLink
                style={{
                  fontWeight: "bold",
                  color: token.colorPrimaryTextHover,
                }}
                to={`/login${location.search}`}
              >
                {translate("pages.login.signin", "Sign in")}
              </ActiveLink>
            </Typography.Text>
          )}
        </div>
      </Form>
    </ContainerContent>
  );

  const CardContent2 = (
    <ContainerContent title="LeyLine" description="Forgot your password?">
      <Form<IUpdatePasswordTypes>
        layout="vertical"
        form={form2}
        className={styles.register}
        onFinish={(values) => updatePassword({ ...values, token: accessToken })}
        requiredMark={false}
        {...formProps}
      >
        <div className="pl-4 mb-7">Email: {newEmail}</div>
        <Form.Item
          name="password"
          extra={
            <span className="leading-4">
              At least 8+ characters with at least one digit, one uppercase, one
              lowercase, and one special character.
            </span>
          }
          rules={[
            {
              required: true,
              validator: passwordValidator,
            },
          ]}
        >
          <CustomInput.Password
            roundRadius
            placeholder="New Password"
            size="large"
            allowClear
            autoComplete={"off"}
            prefix={<LockOutlined style={{ color: "#999" }} />}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          hasFeedback
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: translate(
                "pages.register.errors.confirmPassword",
                "Please confirm your password!",
              ),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    translate(
                      "pages.register.errors.passwordMatch",
                      "The two passwords do not match",
                    ),
                  ),
                );
              },
            }),
          ]}
        >
          <CustomInput.Password
            roundRadius
            placeholder="Confirm Password"
            size="large"
            autoComplete="off"
            prefix={<LockOutlined style={{ color: "#999" }} />}
          />
        </Form.Item>
        <Form.Item className="text-center mt-10">
          <Button
            className="w-[208px]"
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading2}
          >
            Confirm
          </Button>
        </Form.Item>
      </Form>
    </ContainerContent>
  );

  return (
    <AuthLayout>
      {renderContent?.(
        current === "opt" ? CardContent1 : CardContent2,
        PageTitle,
      )}
    </AuthLayout>
  );
};
