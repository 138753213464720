import { ConfigProvider, Empty, EmptyProps } from "antd";
import React from "react";
import NoDataImg from "/images/noData.png";
import styles from "./styles/empty.module.css";

export const CustomEmpty: React.FC<EmptyProps> = ({
  image = NoDataImg,
  description = "No Data",
  children,
  ...restProps
}) => (
  <ConfigProvider
    theme={{
      token: {},
      components: {},
    }}
  >
    <Empty
      rootClassName={styles.emptyWrap}
      image={image}
      imageStyle={{ height: 120 }}
      description={description}
      {...restProps}
    >
      {children}
    </Empty>
  </ConfigProvider>
);
