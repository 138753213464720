import { IResourceComponentsProps } from "@refinedev/core";
import { Modal, Tag } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useConfigProvider } from "../../contexts";

export const JobSetOpenModal: React.FC<IResourceComponentsProps> = ({
  // @ts-ignore
  open, // @ts-ignore
  onOk, // @ts-ignore
  onCancel,
}) => {
  const { mode, setMode } = useConfigProvider();

  const tagClasses = "hover: cursor-pointer mt-1 mb-1";

  const warn = (text: string) => {
    if (mode === "light") {
      toast(text, {
        icon: "⚠️",
      });
    } else {
      toast(text, {
        icon: "⚠️",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <Modal title="Confirm" open={open} onOk={onOk} onCancel={onCancel}>
        <p>Open the job?</p>
      </Modal>
    </>
  );
};
