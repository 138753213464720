import { LeyLineIcon } from "@/components";
import { getStaticImgUrl } from "@/utils/common";
import React, { useState, useEffect, useRef } from "react";

export const AIBanner: React.FC = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [inViewIndex, setInViewIndex] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const winTop = window.scrollY;
      const winMid = window.innerHeight / 2 + winTop;
      const winBot = window.innerHeight + winTop;

      const listItems = document.querySelectorAll(".scroll-wrap li");

      listItems.forEach((item, idx) => {
        //@ts-ignore
        const liTop = item.offsetTop;
        //@ts-ignore
        const liBot = liTop + item.offsetHeight;

        if (liBot <= winMid || liTop >= winBot) {
          item.classList.remove("inView");
        } else if (liTop <= winMid) {
          item.classList.add("inView");
        }
      });

      const inViewItems = document.querySelectorAll(".inView");
      //@ts-ignore
      setInViewIndex(inViewItems.length > 0 ? inViewItems[0].dataset.index : 0);

      if (winTop === 0) {
        setInViewIndex(1);
      }

      setLastScrollTop(winTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`ai-banner fixed top-0 left-0 w-full h-full bg-black step-${inViewIndex}`}
      >
        <div
          className="ai-banner-img relative flex items-center justify-center w-screen h-screen bg"
          style={{ backgroundImage: `url(${getStaticImgUrl("banner1.png")}` }}
        >
          <div className="absolute top-0 left-0 w-full h-full bg-black/80" />
          <div className="relative w-full flex flex-col items-center justify-center gap-4 sm:gap-6 md:gap-8 lg:gap-10 text-center">
            <LeyLineIcon width={168} height={98} />
            <span className="text-center text-2xl sm:text-3xl md:text-5xl lg:text-7xl font-extrabold text-white">
              Compose your <br />
              visions, faster, easier, cheaper
            </span>
          </div>
        </div>
        <div className="story absolute top-0 left-0 w-full h-full bg-black/90 text-center transition-all duration-300 opacity-0">
          <div className="absolute top-1/2 left-1/2 w-full -translate-x-1/2 -translate-y-1/2 inline-block transition-all max-w-4xl px-6 text-center">
            <span className="text-blue1 text-xl sm:text-2xl md:text-3xl lg:text-4xl font-medium leading-normal">
              Cybernetic tools that leverage your abilities, tailored to your
              workflow. Maximize efficiency without compromising creative
              integrity
            </span>
          </div>
        </div>
      </div>

      <ul className="scroll-wrap relative block">
        <li className="h-screen" data-index="1" />
        <li className="h-screen" data-index="2" />
      </ul>
    </div>
  );
};
