import { UploadFile, message } from "antd";

/**
 * Autofill the resume form with dummy data, just for development purpose
 * @param formProps
 * @param values
 * @param step
 * @param sectionIndex
 */
export const autoFill = (
  // biome-ignore lint:
  formProps: any,
  // biome-ignore lint:
  values: any,
  step: number,
  sectionIndex = 0,
) => {
  switch (step) {
    case 0:
      //personal-info
      // @ts-ignore
      formProps?.form.setFieldsValue({
        user_name: "Joe",
        introduction: "Hello",
        industry: "Entertainment",
        country: "Japan",
        city: "Tokyo",
        language: "English",
        summary: "Hello",
      });
      // message.success("Auto fill personal info successfully!")

      break;
    case 1:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formProps.form.setFieldsValue({
        remote_option: "Remote",
        employment_type: "Full-time",
      });
      break;
    case 2:
      console.log("step3", formProps.form?.getFieldsValue());
      // @ts-ignore
      formProps.form.setFieldsValue({
        name: "Project 1",
        // start_date: { date: moment("2023-01-01") },
        // end_date: { date: moment("2024-01-01") },
        role: "Developer",
        description: "Hello",
        // cover_url: "users/abc.png",
      });
      break;
    case 3:
      // @ts-ignore
      formProps.form.setFieldsValue({
        title: "titleA",
        company_name: "companyABC",
        department_name: "departmentABC",
        country: "Japan",
        city: "Tokyo",
        is_current: true,
        // start_date: { value: moment("2023-01-01") },
        // end_date: { value: moment("2024-01-01") },
        description: "Hello",
      });
      break;
    default:
      break;
  }
};

/**
 * This function is used to preview the avatar image or cover image
 * @param file
 */
export const onAvatarPreview = async (file: UploadFile) => {
  let src = file.url as string;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      // @ts-ignore
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result as string);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};
