import { StatusValueEnum, priorityValueEnum } from "@/utils/enum";
import { OptionItemType } from "@/utils/type";

export const statusDictOptions: OptionItemType[] = [
  { label: "Not Ready", value: StatusValueEnum.NotReady },
  { label: "Assign", value: StatusValueEnum.Assign },
  { label: "In Progress", value: StatusValueEnum.InProgress },
  { label: "Dailies", value: StatusValueEnum.Dailies },
  { label: "Feedback", value: StatusValueEnum.Feedback },
  { label: "Publish", value: StatusValueEnum.Publish },
  { label: "Approved", value: StatusValueEnum.Approved },
  { label: "Finished", value: StatusValueEnum.Finished },
  { label: "Hold", value: StatusValueEnum.Hold },
  { label: "Cut", value: StatusValueEnum.Cut },
  { label: "Fix", value: StatusValueEnum.Fix },
  { label: "Update", value: StatusValueEnum.Update },
];

export const statusDictMap = {
  [StatusValueEnum.NotReady]: "Not Ready",
  [StatusValueEnum.Assign]: "Assign",
  [StatusValueEnum.InProgress]: "In Progress",
  [StatusValueEnum.Dailies]: "Dailies",
  [StatusValueEnum.Feedback]: "Feedback",
  [StatusValueEnum.Publish]: "Publish",
  [StatusValueEnum.Approved]: "Approved",
  [StatusValueEnum.Finished]: "Finished",
  [StatusValueEnum.Hold]: "Hold",
  [StatusValueEnum.Cut]: "Cut",
  [StatusValueEnum.Fix]: "Fix",
  [StatusValueEnum.Update]: "Update",
};

export const priorityDictOptions = [
  { label: "None", value: priorityValueEnum.None },
  { label: "Low", value: priorityValueEnum.Low },
  { label: "Normal", value: priorityValueEnum.Normal },
  { label: "High", value: priorityValueEnum.High },
  { label: "Urgent", value: priorityValueEnum.Urgent },
  { label: "Immediate", value: priorityValueEnum.Immediate },
];

export const priorityDictMap = {
  [priorityValueEnum.None]: "None",
  [priorityValueEnum.Low]: "Low",
  [priorityValueEnum.Normal]: "Normal",
  [priorityValueEnum.High]: "High",
  [priorityValueEnum.Urgent]: "Urgent",
  [priorityValueEnum.Immediate]: "Immediate",
};

export const levelDictOptions = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
];

export const keyShotOptions = [
  { label: "Yes", value: "Key" },
  { label: "No", value: "" },
];

export const keyShotOptionsV2 = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];
