import { RuleObject } from "antd/es/form";

export const passwordValidator = (_: RuleObject, value: string) => {
  const errors = [];
  if (value && value.length < 8) errors.push("Min 8 chars");
  if (!/\d/.test(value)) errors.push("1 digit");
  if (!/[A-Z]/.test(value)) errors.push("1 uppercase letter");
  if (!/[a-z]/.test(value)) errors.push("1 lowercase letter");
  if (!/[ !@#$%&'()*+,-.\/[\\\]^_`{|}~]/.test(value))
    errors.push("1 special char");

  if (errors.length > 0) {
    return Promise.reject(new Error(errors.join(", ")));
  }
  return Promise.resolve();
};
