export enum StatusValueEnum {
  NotReady = "1",
  Assign = "2",
  InProgress = "3",
  Dailies = "4",
  Feedback = "5",
  Publish = "6",
  Approved = "7",
  Finished = "8",
  Hold = "9",
  Cut = "10",
  Fix = "11",
  Update = "12",
}

export enum priorityValueEnum {
  None = "1",
  Low = "2",
  Normal = "3",
  High = "4",
  Urgent = "5",
  Immediate = "6",
}

export const StatusColorEnum = {
  [StatusValueEnum.NotReady]: "gray",
  [StatusValueEnum.Assign]: "blue",
  [StatusValueEnum.InProgress]: "orange",
  [StatusValueEnum.Dailies]: "magenta",
  [StatusValueEnum.Feedback]: "volcano",
  [StatusValueEnum.Publish]: "cyan",
  [StatusValueEnum.Approved]: "gold",
  [StatusValueEnum.Finished]: "lime",
  [StatusValueEnum.Hold]: "red",
  [StatusValueEnum.Cut]: "purple",
  [StatusValueEnum.Fix]: "geekblue",
  [StatusValueEnum.Update]: "orange",
};

export const PriorityColorEnum = {
  [priorityValueEnum.None]: "gray",
  [priorityValueEnum.Low]: "blue",
  [priorityValueEnum.Normal]: "green",
  [priorityValueEnum.High]: "magenta",
  [priorityValueEnum.Urgent]: "red",
  [priorityValueEnum.Immediate]: "purple",
};
