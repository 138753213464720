import { IProjectExperience } from "@/interfaces";
import { formatToMiddleStyle } from "@/utils/date";
import { padImageUrl } from "@/utils/upload";
import { Col, Image, Row, Typography } from "antd";

interface IArtistResumeProps {
  projectExperience: IProjectExperience;
}
const RESOURCES_BASE_URL = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
export const ResumeProjectExperienceItem: React.FC<IArtistResumeProps> = ({
  projectExperience,
}) => {
  return (
    <Row className="flex flex-row gap-4">
      <Col>
        <Image
          width="150px"
          height="156px"
          src={padImageUrl(projectExperience.cover_url || "")}
        />
      </Col>
      <Row className="flex flex-col gap-3">
        <Col>
          <Typography.Text className="text-white">
            {projectExperience.name}
          </Typography.Text>
        </Col>
        <Col className="flex flex-col">
          <Typography.Text className="text-[#999999]">
            {formatToMiddleStyle(projectExperience.start_date)} ~{" "}
            {formatToMiddleStyle(projectExperience.end_date)}
          </Typography.Text>
          <Typography.Text className="text-white">
            BY:{projectExperience.role}
          </Typography.Text>
        </Col>
        <Col>
          <Typography.Paragraph className="text-white" ellipsis={{ rows: 2 }}>
            {projectExperience.description}
          </Typography.Paragraph>
        </Col>
      </Row>
    </Row>
  );
};
