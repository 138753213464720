import { getStaticImgUrl } from "@/utils/common";
import React from "react";

export const AIModeling: React.FC = () => {
  return (
    <div className="relative">
      <img
        src={getStaticImgUrl("banner_modeling.png") || ""}
        className="w-full aspect-[1.78/1]"
        alt="test"
      />
      <div className="absolute bottom-0 left-0 w-full h-1/2 bg-black/50" />
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-4 max-w-full md:max-w-2xl flex flex-col gap-4 sm:gap-6 md:gap-8 lg:gap-10 items-center text-center">
        <span className="text-2xl sm:text-3xl md:text-5xl lg:text-7xl text-white font-extrabold">
          AI 3D modeling
        </span>
        <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-white font-medium">
          From Sketch to Sculpt
        </span>
        <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/60 inline-block mt-10">
          Generate professional grade 3D assets from simple design files and
          text prompts. We apply streamlined elegant topology for professionals
          to get to finished models faster. You can also apply texture from text
          & 3D mesh inputs
        </span>
      </div>
    </div>
  );
};
