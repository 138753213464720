import { CustomInput } from "@/components/customComponents";
import { useGlobalStore } from "@/stores";
import { getStaticImgUrl } from "@/utils/common";
import { axiosInstance } from "@/utils/http";
import { ConfigProvider, Image, Input, message } from "antd";
import React from "react";

export const Subscribe: React.FC = () => {
  const { updateLoading } = useGlobalStore((state) => state);
  const [email, setEmail] = React.useState<string>("");

  // Function to validate email format
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const subscribe = async (email: string) => {
    if (!email) {
      message.error("Please enter your email address.");
      return;
    }

    if (!validateEmail(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    updateLoading(true);
    try {
      const response = await axiosInstance.post(
        `/users/guest-email-subscription?email=${email}`,
      );

      message.success("Subscription successful!");
      setEmail("");
      // biome-ignore lint:
    } catch (error: any) {
      message.error(
        error?.response?.data?.detail || "An error occurred. Please try again.",
      );
      throw error;
    } finally {
      updateLoading(false);
    }
  };

  return (
    <div className="relative bg-grey">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-6 max-w-7xl px-6 mx-auto py-11">
        <span className="text-base sm:text-lg md:text-xl lg:text-2xl text-white/80 leading-normal">
          Subscribe to our news <br /> and get the latest updates and trending
          topics
        </span>
        <ConfigProvider
          theme={{
            token: {
              colorTextPlaceholder: "white/80",
            },
          }}
        >
          <CustomInput
            placeholder="Enter your Email!"
            className="!rounded-full bg-grey4 border-0 hover:bg-grey4 focus:bg-grey4 focus-within:bg-grey4 text-white/80 !shadow-none max-w-[25rem] pl-[1.875rem] pr-2.5 py-2.5"
            suffix={
              <Image
                preview={false}
                src={getStaticImgUrl("arrow-right-circle.svg") || ""}
                className="w-full h-full cursor-pointer"
                width={46}
                height={46}
                onClick={() => subscribe(email)}
              />
            }
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};
