import { datePickerFormat } from "@/utils/date";
import {
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Result,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import type { FormProps, UploadFile, UploadProps } from "antd";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React, { useEffect, useState } from "react";
import { countryOptions } from "../../utils/common";

dayjs.extend(isSameOrBefore);

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Dragger } = Upload;

type WorkExperienceFormProps = {
  formProps: FormProps;
  onAvatarPreview: (file: UploadFile) => void;
};

export const WorkExperienceForm: React.FC<WorkExperienceFormProps> = ({
  formProps,
}) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name={["company_name"]}
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Company Name is required",
              },
              {
                validator: (_, value) => {
                  if (value?.length > 0 && value?.trim() === "")
                    return Promise.reject(
                      new Error("Company Name cannot be only spaces"),
                    );
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Form.Item name={["department_name"]} label="Department Name">
            <Input maxLength={60} />
          </Form.Item>
          <Form.Item
            name={["title"]}
            label="Title"
            rules={[
              {
                required: true,
                message: "Title is required",
              },
              {
                validator: (_, value) => {
                  if (value?.length > 0 && value?.trim() === "")
                    return Promise.reject(
                      new Error("Title cannot be only spaces"),
                    );
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input maxLength={60} />
          </Form.Item>
          <Form.Item name={["city"]} label="City">
            <Input maxLength={100} />
          </Form.Item>
          <Form.Item name={["country"]} label="Country">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                // @ts-ignore
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countryOptions.map((country) => (
                <Select.Option key={country.value} value={country.value}>
                  {country.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={["is_current"]}
            valuePropName="checked"
            label="Is Current Job"
          >
            <Checkbox>Yes</Checkbox>
          </Form.Item>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.is_current !== currentValues.is_current
            }
          >
            {({ getFieldValue }) => {
              const isCurrent = getFieldValue("is_current");
              if (isCurrent) {
                return (
                  <Form.Item
                    name={["start_date"]}
                    label="Start Date"
                    rules={[
                      {
                        required: true,
                        message: "Start date is required.",
                      },
                    ]}
                  >
                    <DatePicker format={datePickerFormat} />
                  </Form.Item>
                );
              }
              return (
                <Form.Item
                  name="work_duration"
                  label="Work Duration"
                  rules={[
                    {
                      required: true,
                      message: "Work duration is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || value[0].isSameOrBefore(value[1])) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The start date should precede the end date.",
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <RangePicker format={datePickerFormat} />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item name={["description"]} label="Description">
            <TextArea rows={4} maxLength={1000} showCount />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
