import { Popconfirm, PopconfirmProps } from "antd";
import "./styles/popconfirm.css";

export const CustomPopconfirm = ({ ...props }: PopconfirmProps) => {
  return (
    <Popconfirm
      arrow={false}
      okText="Yes"
      cancelText="No"
      overlayClassName="custom-popconfirm"
      {...props}
    />
  );
};
