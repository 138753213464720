import { MotionValue, motion, useTransform } from "framer-motion";
import React from "react";

interface IProducerCardTitle {
  title: string;
  description: string;
  range: [number, number];
  progress: MotionValue<number>;
}

export const ProducerCardTitle: React.FC<IProducerCardTitle> = ({
  title,
  description,
  range,
  progress,
}) => {
  const opacity = useTransform(
    progress,
    [range[0] - 0.25, range[0], range[1], range[1] + 0.25],
    [0.2, 1, 1, 0.1],
  );

  return (
    <motion.div style={{ opacity }} className="flex flex-col gap-6">
      <span className="text-xl md:text-2xl lg:text-3xl font-extrabold text-white">
        {title}
      </span>
      <span className="text-base md:text-lg lg:text-xl text-white/80">
        {description}
      </span>
    </motion.div>
  );
};
