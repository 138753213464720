import { CommentPanel } from "@/components/discover/workDetail/CommentPanel";
import SEO from "@/components/seo";
import { useUserData } from "@/hooks";
import {
  getArtworkDetailAPI,
  getArtworkGuestDetailAPI,
} from "@/pages/discover/api";
import { IArtworkItem, ICompany, IUser } from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import { getLogoUrl } from "@/utils/helper";
import { videoExtensions } from "@/utils/identifyFiles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IResourceComponentsProps } from "@refinedev/core";
import { Col, Image, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

const videoExtensionsRegex = videoExtensions.join("|");

export const PortfolioDetailCopyLinkPage: React.FC<IResourceComponentsProps> =
  () => {
    const { currentRole, updateLoading } = useGlobalStore((state) => state);
    const { id } = useParams();
    const profile = useUserData();
    const [artwork, setArtwork] = useState<IArtworkItem | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [artworkList, setArtworkList] = useState<string[]>([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    const getArtworkDetail = useCallback(async () => {
      if (!currentRole || !id || id === "preview") return;
      const response = await getArtworkDetailAPI(
        updateLoading,
        currentRole,
        id,
      );
      setArtwork(response);
    }, [id, updateLoading, currentRole]);

    const getArtworkGuestDetail = useCallback(async () => {
      if (!id || id === "preview") return;
      const response = await getArtworkGuestDetailAPI(updateLoading, id);
      setArtwork(response);
    }, [id, updateLoading]);

    useEffect(() => {
      if (!profile) {
        getArtworkGuestDetail();
      } else {
        getArtworkDetail();
      }
    }, [getArtworkDetail, getArtworkGuestDetail, profile]);

    useEffect(() => {
      const savedArtworkList = localStorage.getItem("artworkList");
      if (savedArtworkList) {
        const artworkArray = JSON.parse(savedArtworkList);
        setArtworkList(artworkArray);
        const pathParts = location.pathname.split("/");
        const currentArtworkId = pathParts[pathParts.length - 1];
        const index = artworkArray.indexOf(currentArtworkId);
        setCurrentIndex(index);
      }
    }, [location]);

    const navigateToArtwork = (index: number) => {
      if (index >= 0 && index < artworkList.length) {
        const nextArtworkId = artworkList[index];
        navigate(`/discover/portfolio/${nextArtworkId}`);
      }
    };

    const handleLeftClick = () => {
      navigateToArtwork(currentIndex - 1);
    };

    const handleRightClick = () => {
      navigateToArtwork(currentIndex + 1);
    };

    const getAuthorName = (
      role: string | undefined,
      author: IUser | ICompany | undefined,
    ) => {
      if (!author) return "Unknown Author";
      return role === "COMPANY"
        ? (author as ICompany).shortname
        : (author as IUser).user_name;
    };

    return (
      <>
        {/* SEO */}
        {artwork && (
          <SEO
            title={`LeyLine - ${
              artwork.title || artwork.description
            }, posted by ${getAuthorName(artwork.role, artwork.author)}`}
            description={`LeyLine - ${
              artwork.title || artwork.description
            }, posted by ${getAuthorName(artwork.role, artwork.author)}`}
            keywords={`Art,Artwork,Visual art,Digital art,3D art,Artist portfolio,Art community,Art sharing platform,Art marketplace,Online art gallery,Showcase artwork,Share artwork online,Art platform,Digital portfolio,CG platform,CG community,CG marketplace,CG showcase,${
              artwork.title
            },${artwork.tags?.join(",")},${getAuthorName(
              artwork.role,
              artwork.author,
            )}`}
            ogTitle={`LeyLine - ${
              artwork.title || artwork.description
            }, posted by ${getAuthorName(artwork.role, artwork.author)}`}
            ogDescription={`${artwork.title || artwork.description}${
              artwork.tags?.length ? `, ${artwork.tags.join(", ")}` : ""
            }`}
            image={artwork.cover_picture || getLogoUrl()}
          />
        )}

        {/* Main page content */}
        <Row className="gap-4 p-6 flex flex-row flex-1 w-full h-[calc(100vh-80px)]">
          <Col className="bg-black rounded-xl text-white overflow-hidden flex flex-row flex-1 py-[52px] px-2 h-full portfolio-detail-view">
            <Col className="flex items-center">
              {currentIndex <= 0 ? (
                <LeftOutlined className="custom-arrow !text-opacity-50 !text-[#578A8C]" />
              ) : (
                <LeftOutlined
                  onClick={handleLeftClick}
                  className="custom-arrow"
                />
              )}
            </Col>
            <Col className="px-3 flex flex-col gap-9 overflow-y-scroll flex-1">
              {artwork?.medias?.map((media) =>
                media.match(
                  new RegExp(`\\.(?:${videoExtensionsRegex})$`, "i"),
                ) ? (
                  <div
                    className="w-full aspect-[16/9]"
                    key={media}
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <Player
                      fluid={true}
                      width={100}
                      height={100}
                      playsInline
                      src={media}
                    />
                  </div>
                ) : (
                  <Image
                    key={media}
                    className="w-full object-cover"
                    preview={false}
                    src={media}
                  />
                ),
              )}
            </Col>
            <Col className="flex items-center">
              {currentIndex >= artworkList.length - 1 ? (
                <RightOutlined className="custom-arrow !text-opacity-50 !text-[#578A8C]" />
              ) : (
                <RightOutlined
                  onClick={handleRightClick}
                  className="custom-arrow"
                />
              )}
            </Col>
          </Col>
          {artwork && (
            <CommentPanel modal={false} preview={false} artwork={artwork} />
          )}
        </Row>
      </>
    );
  };
