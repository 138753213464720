import { setDefaultTimezone } from "@/utils/date";
import { ThemedTitleV2 } from "@refinedev/antd";
import { LoginPageProps } from "@refinedev/core";
import {
  Card,
  CardProps,
  Col,
  FormProps,
  Layout,
  LayoutProps,
  Row,
  Typography,
  theme,
} from "antd";
import React, { useEffect } from "react";
import {
  bodyStyles,
  containerStyles,
  headStyles,
  subTitleStyles,
  titleStyles,
} from "../../authCore/components/styles";
import { NewUserProfile } from "./NewUserProfile";

type LoginProps = LoginPageProps<LayoutProps, CardProps, FormProps>;
/**
 * **refine** has a default login page form which is served on `/login` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#login} for more details.
 */
export const CompleteUserInfo: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
  hideForm,
}) => {
  const { token } = theme.useToken();

  useEffect(() => {
    setDefaultTimezone();
  }, []);

  const PageTitle =
    title === false ? null : (
      <div className="flex flex-col items-center mb-8">
        {title ?? <ThemedTitleV2 collapsed={false} />}
      </div>
    );

  const NewUserCardTitle = (
    <>
      <Typography.Title
        level={3}
        style={{
          color: token.colorPrimaryTextHover,
          ...titleStyles,
        }}
      >
        Welcome to LeyLine
      </Typography.Title>
      <Typography.Title
        level={5}
        style={{
          color: token.colorPrimaryTextHover,
          ...subTitleStyles,
        }}
      >
        To provide you with better service, please complete your personal
        information
      </Typography.Title>
    </>
  );

  const NewUserCard = (
    <Card
      title={NewUserCardTitle}
      headStyle={headStyles}
      bodyStyle={bodyStyles}
      style={{
        ...containerStyles,
        backgroundColor: token.colorBgElevated,
        maxWidth: "700px",
      }}
      {...(contentProps ?? {})}
    >
      <NewUserProfile />
    </Card>
  );

  return (
    <Layout
      className="flex flex-col items-center justify-center min-h-screen pt-4"
      {...(wrapperProps ?? {})}
    >
      <Row
        justify="center"
        align={hideForm ? "top" : "middle"}
        className={`min-h-screen pt-4 ${hideForm ? "pt-36" : ""}`}
      >
        <Col xs={22}>
          {renderContent ? (
            renderContent(NewUserCard, PageTitle)
          ) : (
            <>
              {PageTitle}
              {NewUserCard}
            </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};
