import { CheveronIcon } from "@/components/customComponents/CustomIcons";
import { getStaticImgUrl } from "@/utils/common";
import { Carousel, Space } from "antd";
import { CarouselRef } from "antd/es/carousel";
import React, { useState, useRef } from "react";

const settings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "150px", // Default centerPadding for larger screens
  responsive: [
    {
      breakpoint: 992,
      settings: {
        centerPadding: "120px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: "80px",
      },
    },
    {
      breakpoint: 554,
      settings: {
        centerPadding: "50px",
      },
    },
  ],
};

export const AIVideos: React.FC = () => {
  const [activeCarousel, setActiveCarousel] = useState<number>(0);
  const sliderRef = useRef<CarouselRef>(null);
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
  const [pausedVideos, setPausedVideos] = useState<boolean[]>([]);

  const videoList = [
    {
      id: 0,
      video: getStaticImgUrl("video/AI_Breakdown.mp4") || "",
    },
    {
      id: 1,
      video: getStaticImgUrl("video/AI_AVScript.mp4") || "",
    },
  ];

  const handleCarousel = (currentSlide: number) => {
    setActiveCarousel(currentSlide);
    videoRefs.current.forEach((videoRef, index) => {
      if (videoRef) {
        if (index === currentSlide) {
          videoRef.play();
        } else {
          videoRef.pause();
          videoRef.currentTime = 0; // Optional: Reset video to the beginning
        }
      }
    });
  };

  const handlePause = (index: number) => {
    const newPausedVideos = [...pausedVideos];
    newPausedVideos[index] = !newPausedVideos[index];
    setPausedVideos(newPausedVideos);

    if (videoRefs.current[index]) {
      if (newPausedVideos[index]) {
        videoRefs.current[index]?.pause();
      } else {
        videoRefs.current[index]?.play();
      }
    }
  };

  const handleRef = (
    el: HTMLVideoElement | null,
    video: { id: number; video?: string },
  ) => {
    videoRefs.current[video.id] = el;
  };

  return (
    <div className="relative pt-10 sm:pt-20 md:pt-40 lg:pt-72 bg-white">
      <Carousel
        className="discover-carousel max-w-7xl mx-auto text-center"
        ref={sliderRef}
        dots={false}
        infinite={settings.infinite}
        slidesToShow={settings.slidesToShow}
        slidesToScroll={settings.slidesToScroll}
        centerMode={settings.centerMode}
        arrows={false}
        centerPadding={settings.centerPadding}
        responsive={settings.responsive}
        afterChange={handleCarousel}
      >
        {videoList.map((video, idx) => (
          <div key={video.id} className="px-5">
            <div
              className={`relative w-full aspect-video h-auto rounded-[1.25rem] overflow-hidden transition-all duration-500 ${
                activeCarousel === video.id ? "scale-100" : "scale-90"
              }`}
            >
              <Space
                className="absolute z-10 top-9 right-9 flex items-center justify-center w-12 h-12 bg-black/70 rounded-full cursor-pointer"
                onClick={() => handlePause(idx)}
              >
                <img
                  src={getStaticImgUrl("pause.svg") || ""}
                  alt="pause"
                  className="w-full h-full cursor-pointer"
                />
              </Space>
              <video
                ref={(el) => handleRef(el, video)}
                width="100%"
                height="100%"
                className="object-cover"
                muted
              >
                <source src={video.video} type="video/mp4" />
              </video>
            </div>
          </div>
        ))}
      </Carousel>
      <div className="flex items-center justify-end max-w-7xl px-6 mx-auto gap-4 sm:gap-6 md:gap-8 lg:gap-10 mt-6 sm:mt-12 md:mt-16 lg:mt-24">
        <Space onClick={() => sliderRef.current?.prev()}>
          <CheveronIcon direction="left" active={!(activeCarousel === 0)} />
        </Space>
        <Space onClick={() => sliderRef.current?.next()}>
          <CheveronIcon active={!(activeCarousel === videoList.length - 1)} />
        </Space>
      </div>
    </div>
  );
};
