import { TreeMenuItem } from "@refinedev/core/dist/hooks/menu/useMenu";
import { Modal } from "antd";
import { HookAPI } from "antd/es/modal/useModal";
import type { ModalFuncProps } from "antd/lib";
import { UploadRequestOption } from "rc-upload/lib/interface";

export const companyTypeOptions = [
  { value: "Sole proprietorship", label: "Sole proprietorship" },
  {
    value: "Limited Liability Company (LLC)",
    label: "Limited Liability Company (LLC)",
  },
  {
    value: "Joint Stock Company",
    label: "Joint Stock Company",
  },
  {
    value: "Public Institution",
    label: "Public Institution",
  },
  { value: "Others", label: "Others" },
];

export const languageOptions = [
  "English",
  "Chinese",
  "Japanese",
  "Korean",
  "Hindi",
  "Indonesian",
  "Thai",
  "Vietnamese",
  "Filipino",
  "Malay",
  "French",
  "German",
  "Spanish",
  "Italian",
  "Russian",
  "Portuguese",
  "Dutch",
  "Greek",
  "Swedish",
  "Norwegian",
  "Danish",
  "Finnish",
  "Polish",
  "Turkish",
  "Arabic",
  "Persian (Farsi)",
  "Hebrew",
  "Afrikaans",
  "Zulu",
  "Xhosa",
  "Maori",
].map((language) => ({
  label: language,
  value: language,
}));

export const countryOptions = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
].map((country) => ({ label: country, value: country }));

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL || "";
const STATIC_IMG_PREFIX = import.meta.env.VITE_STATIC_ASSETS_BASE_URL || "";

export const padLeftImgUrl = (url: string | null | undefined) => {
  if (!url) return undefined;
  return url.startsWith("http") ? url : `${IMG_PREFIX}${url}`;
};

// biome-ignore lint:
export const makeUploadFileObject = (url: any) => {
  if (typeof url === "string") {
    url.replace(IMG_PREFIX, "");
    const file = {
      uid: "-1",
      status: "done",
      response: {
        id: url,
      },
    };
    return {
      file: file,
      fileList: [file],
    };
  }
  return url;
};

export const delFileUrlPrefix = (url: string) => {
  return url ? url.replace(IMG_PREFIX, "") : url;
};

// biome-ignore lint:
export const convertUploadListToId = (cover_url: any) => {
  if (cover_url?.fileList?.length > 0) {
    return cover_url?.fileList[0]?.response?.id;
  }
  return cover_url;
};
export type FileListType = {
  uid: string;
  name: string;
  status: string;
  url: string;
}[];

export function constructFileObj(url: string) {
  if (!url) return null;
  return {
    uid: url.slice(-9),
    name: url.slice(-9),
    status: "done",
    url: IMG_PREFIX + url,
  };
}

export function makeUploadFileObjectList(urls: string[] | string | undefined) {
  if (!urls) return [];
  if (Array.isArray(urls)) {
    console.log("1", urls);
    const fileList = urls
      .filter((url) => !!url)
      .map((url) => constructFileObj(url));
    console.log("2", fileList);
    return fileList;
  }
  if (typeof urls === "string") {
    const fileList = [constructFileObj(urls)];
    return fileList;
  }
}

export const getImgUrl = (url: string | null | undefined) => {
  if (!url) return null;
  return url.startsWith("http") ? url : `${IMG_PREFIX}${url}`;
};

export const getStaticImgUrl = (url: string | null | undefined) => {
  if (!url) return null;
  return url.startsWith("http") ? url : `${STATIC_IMG_PREFIX}${url}`;
};

export const preventAutoUpload = (options: UploadRequestOption) => {
  if (options.onSuccess) {
    options.onSuccess("ok", new XMLHttpRequest());
  }
};

export const downloadFile = (fileUrl: string, fileName?: string) => {
  if (!fileUrl) return;
  const file_name: string = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
  const ext = file_name.split(".").pop()?.toLocaleLowerCase();
  const x = new window.XMLHttpRequest();
  x.open("GET", fileUrl, true);
  x.responseType = "blob";
  x.onload = () => {
    const url = window.URL.createObjectURL(x.response);
    const a = document.createElement("a");
    a.href = url;
    if (fileName)
      a.download = fileName.includes(".") ? fileName : `${fileName}.${ext}`;
    a.click();
  };
  x.send();
};

// /**
//  * This function is used to normalize the file uploaded by the user.
//  * @param e
//  * @returns
//  */
// export const normFile = (e: any) => {
//   console.log("Upload event:", e);
//   if (Array.isArray(e)) {
//     return e;
//   }
//   return e?.fileList;
// };

export const isImage = (url: string): boolean => {
  const extension = url.split(".")?.pop()?.toLowerCase();
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"];
  return imageExtensions.includes(extension as string);
};

export const isVideo = (url: string): boolean => {
  const extension = url.split(".")?.pop()?.toLowerCase();
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "webm", "mkv"];
  return videoExtensions.includes(extension as string);
};

export const taskStatusOptions = {
  "Not Ready": "gray",
  Assign: "blue",
  "In Progress": "orange",
  Dailies: "magenta",
  Feedback: "volcano",
  Publish: "cyan",
  Approved: "gold",
  Finished: "lime",
  Hold: "red",
  Cut: "purple",
  Fix: "geekblue",
  Update: "orange",
};

export const isAllowClose = (
  hasChanges: boolean | null | undefined,
  modalIns?: HookAPI,
) => {
  return new Promise((resolve) => {
    if (!hasChanges) resolve(true);
    else {
      (modalIns ?? Modal).confirm({
        title: "Are you sure you want to leave? You have unsaved changes.",
        onOk: () => {
          resolve(true);
        },
        onCancel: () => {
          resolve(false);
        },
      });
    }
  });
};

export const modalConfirmSync = (props: ModalFuncProps, modalIns?: HookAPI) => {
  return new Promise((resolve) => {
    (modalIns ?? Modal).confirm({
      ...props,
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
};

export const showAbsoluteUrl = (url: string) => {
  if (!url) return "";
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `http://${url}`;
};

export const accountTypeMapping: Record<string, string> = {
  DEEL: "Deel",
  SERVICESHARE: "身边云",
};

export const checkIsInPersonalCenter = (
  pathname: string | undefined,
  menuItems: TreeMenuItem[],
) => menuItems.some((item) => pathname?.indexOf(item.name as string) === 0);
