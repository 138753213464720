import { Link } from "react-router-dom";
import { AuthPage as AntdAuthPage, AuthProps } from "../authCore";

// const authWrapperProps = {
//   style: {
//     background: "url('images/loginBg.png')",
//     backgroundSize: "contain",
//   },
// };

const renderAuthContent = (content: React.ReactNode) => {
  return (
    <div
      style={{
        maxWidth: 550,
        margin: "auto",
      }}
    >
      {/* <Link to="/">
                <img
                    style={{ marginBottom: 26 }}
                    src="images/fine-foods-login.svg"
                    alt="Logo"
                    width="100%"
                />
            </Link> */}
      {content}
    </div>
  );
};

export const AuthPage: React.FC<AuthProps> = ({ type, formProps }) => {
  return (
    <AntdAuthPage
      type={type}
      // wrapperProps={authWrapperProps}
      renderContent={renderAuthContent}
      formProps={formProps}
    />
  );
};
