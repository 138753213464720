import { ConfigProvider, Tabs, TabsProps } from "antd";
import styles from "./styles/tabs.module.css";

export const CustomTabs = ({ ...props }: TabsProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Tabs: {
            cardPadding: "0px 80px",
            inkBarColor: "#fff",
            itemActiveColor: "#fff",
            itemColor: "rgba(255, 255, 255, 0.5)",
            itemHoverColor: "rgba(255, 255, 255, 0.8)",
            itemSelectedColor: "#fff",
            cardBg: "transparent",
          },
        },
        token: {},
      }}
    >
      <Tabs
        indicator={{ size: (origin) => 20, align: "center" }}
        style={{
          background: "transparent",
          border: 0,
          borderRadius: "2px",
        }}
        rootClassName={styles.tabsWrap}
        {...props}
      />
    </ConfigProvider>
  );
};
