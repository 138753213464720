import { CustomInput } from "@/components/customComponents/CustomInput";
import { CustomModal } from "@/components/customComponents/CustomModal";
import { PersonalArtworkManagement } from "@/components/personalCenter/PersonalArtworkManagement";
import { FolderItem } from "@/components/personalCenter/portfolio/FolderItem";
import {
  createFolderAPI,
  deleteFolderAPI,
  editFolderAPI,
  getFoldersAPI,
} from "@/pages/discover/api";
import { IFolder, ISearchMyArtworkList } from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import { FolderOutlined, PlusOutlined } from "@ant-design/icons";
import { IResourceComponentsProps } from "@refinedev/core";
import { Button, Col, Row, Space, Typography, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";

export const PersonalPortfolio: React.FC<IResourceComponentsProps> = () => {
  const { currentRole } = useGlobalStore((state) => state);
  const [createFolderModalOpen, setCreateFolderModalOpen] =
    useState<boolean>(false);
  const [editFolderModalOpen, setEditFolderModalOpen] =
    useState<boolean>(false);
  const [editFolderId, setEditFolderId] = useState<string>("");
  const [editFolderName, setEditFolderName] = useState<string>("");
  const [folderName, setFolderName] = useState<string>("");
  const [folderList, setFolderList] = useState<IFolder[]>([]);
  const [artworkAllCount, setArtworkAllCount] = useState<number>(0);
  const [isDeleteArtworState, setIsDeleteArtworState] = useState<string>("");
  const [searchData, setSearchData] = useState<ISearchMyArtworkList>({
    page: 1,
    size: 8,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  // biome-ignore lint:
  const fetchFolders = useCallback(async () => {
    if (!currentRole) return;
    const data = await getFoldersAPI(currentRole);
    setFolderList(data);
  }, [currentRole, isDeleteArtworState]);

  useEffect(() => {
    fetchFolders();
  }, [fetchFolders]);

  useEffect(() => {
    if (!currentRole) return;
    setArtworkAllCount(0);
    setSearchData(({ folder_id, search, is_published, ...rest }) => rest);
  }, [currentRole]);

  const onEditFolderModal = (key: string) => {
    setEditFolderId(key);
    setEditFolderModalOpen(true);
  };

  const onEditFolder = async () => {
    if (editFolderName === "") {
      message.error("Folder name is required");
      return;
    }
    await editFolderAPI(editFolderId, editFolderName);
    const newFolderList = folderList.map((folder) => {
      if (folder.id === editFolderId) {
        folder.folder_name = editFolderName;
      }
      return folder;
    });

    setFolderList(newFolderList);
    setEditFolderModalOpen(false);
    setEditFolderName("");
  };

  const onDeleteFolder = async (key: string) => {
    await deleteFolderAPI(key);
    setIsDeleteArtworState(`delete_${key}`);
    setSearchData(({ folder_id, ...rest }) => rest);
  };

  const createFolder = async () => {
    if (!currentRole) return;
    if (folderName === "") {
      message.error("Folder name is required");
      return;
    }
    if (folderList.length >= 20) {
      message.error("Cannot exceed 20 folders.");
      setCreateFolderModalOpen(false);
    } else {
      const data = await createFolderAPI(currentRole, folderName);
      setFolderList([data, ...folderList]);
      setCreateFolderModalOpen(false);
      setFolderName("");
    }
  };

  return (
    <Row className="flex flex-row flex-1 h-full">
      <Col className="w-[235px] border-solid border-r-[1px] border-[#FFFFFF1A] h-full flex flex-col">
        <Row
          className="pt-8 pl-4 pb-3 pr-4 border-solid border-b-[1px] border-[#FFFFFF1A] flex flex-row justify-between items-center cursor-pointer"
          onClick={() =>
            setSearchData(
              ({ folder_id, search, is_published, ...rest }) => rest,
            )
          }
        >
          <Col className="flex flex-row gap-1.5">
            <FolderOutlined className="text-base text-white" />
            <Typography.Text className="text-base text-white">
              All
            </Typography.Text>
          </Col>
          <Col>
            <Typography.Text className="text-[#686868] text-sm font-normal">
              {artworkAllCount} artworks
            </Typography.Text>
          </Col>
        </Row>
        <Col className="pb-4 overflow-auto">
          <Col
            className={`${folderList.length > 0 && "pt-[10px]"} flex flex-col`}
          >
            {folderList.map((folder: IFolder) => (
              <FolderItem
                setCurrentPage={setCurrentPage}
                setEditFolderName={setEditFolderName}
                setArtworkFilter={setSearchData}
                searchData={searchData}
                key={folder.id}
                folder={folder}
                onEditFolderModal={onEditFolderModal}
                onDeleteFolder={onDeleteFolder}
              />
            ))}
          </Col>
          <Col className="mt-[30px] text-center">
            <Button
              type="primary"
              // className="bg-[#00ADAE] px-4 py-1 border-none rounded-full text-white text-base"
              onClick={() => setCreateFolderModalOpen(true)}
            >
              <PlusOutlined />
              <span>Create Folder</span>
            </Button>
          </Col>
        </Col>
      </Col>
      <Col className="flex-1">
        <PersonalArtworkManagement
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          folderList={folderList}
          artworkAllCount={artworkAllCount}
          setArtworkAllCount={setArtworkAllCount}
          setArtworkFilter={setSearchData}
          searchData={searchData}
          isDeleteArtworState={isDeleteArtworState}
          setIsDeleteArtworState={setIsDeleteArtworState}
        />
      </Col>

      <CustomModal
        width="200px"
        closable={false}
        footer={null}
        open={editFolderModalOpen}
        onCancel={() => setEditFolderModalOpen(false)}
      >
        <div className="p-[15px] flex flex-col gap-2.5">
          <CustomInput
            maxLength={20}
            value={editFolderName}
            onChange={(e) => setEditFolderName(e.target.value)}
            placeholder="Folder Name..."
            className="!w-full !rounded-full !border-[1px] !border-solid !border-[#515151] py-2.5 px-5"
          />
          <Space className="flex justify-end">
            <Button
              className="bg-[#333538] px-2 py-1 border-none rounded-full text-[#606264] text-sm"
              onClick={() => setEditFolderModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              // className="bg-[#00ADAE] px-2 py-1 border-none rounded-full text-white text-sm"
              onClick={onEditFolder}
            >
              Confirm
            </Button>
          </Space>
        </div>
      </CustomModal>

      <CustomModal
        width="456px"
        title="Create Folder"
        open={createFolderModalOpen}
        //   confirmLoading={confirmLoading}
        onCancel={() => setCreateFolderModalOpen(false)}
        footer={
          <Button
            type="primary"
            // className="bg-[#00ADAE] px-4 py-1 border-none rounded-full text-white text-base"
            onClick={createFolder}
          >
            Confirm
          </Button>
        }
      >
        <div className="px-6 pt-5 pb-16">
          <CustomInput
            size="large"
            roundRadius
            value={folderName}
            maxLength={20}
            onChange={(e) => setFolderName(e.target.value)}
            placeholder="Folder Name..."
            // className="!w-full !rounded-full !border-[1px] !border-solid !border-[#515151] py-2.5 px-5"
          />
        </div>
      </CustomModal>
    </Row>
  );
};
