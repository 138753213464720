// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type Callback = (data?: any) => void;

class EventBus {
  private events: { [key: string]: Callback[] };

  constructor() {
    this.events = {};
  }

  public subscribe(eventName: string, callback: Callback): void {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  }

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  public publish(eventName: string, data?: any): void {
    const eventCallbacks = this.events[eventName];
    if (eventCallbacks) {
      // biome-ignore lint/complexity/noForEach: <explanation>
      eventCallbacks.forEach((callback) => {
        callback(data);
      });
    }
  }

  public unsubscribe(eventName: string, callback: Callback): void {
    const eventCallbacks = this.events[eventName];
    if (eventCallbacks) {
      this.events[eventName] = eventCallbacks.filter((cb) => cb !== callback);
    }
  }
}

export default new EventBus();
