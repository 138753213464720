import { CustomInput } from "@/components/customComponents/CustomInput";
import { CustomSelect } from "@/components/customComponents/CustomSelect";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Tag,
  theme,
} from "antd";
import _ from "lodash";
import React from "react";

const { useToken } = theme;

const PublicJobsSearch: React.FC<{
  searchKeyword: string;
  setSearchKeyword: (keyword: string) => void;
  selectedWorkMode: string[];
  setSelectedWorkMode: (status: string[]) => void;
  selectedEmploymentType: string[];
  setSelectedEmploymentType: (status: string[]) => void;
  isVerified: boolean;
  setIsVerified: (verified: boolean) => void;
}> = ({
  searchKeyword,
  setSearchKeyword,
  selectedWorkMode,
  setSelectedWorkMode,
  selectedEmploymentType,
  setSelectedEmploymentType,
  isVerified,
  setIsVerified,
}) => {
  const { token } = useToken();

  const addWorkMode = (val: string[]) => {
    setSelectedWorkMode(val);
  };

  const removeWorkMode = (option: string) => {
    setSelectedWorkMode(selectedWorkMode.filter((status) => status !== option));
  };

  const addEmploymentType = (val: string[]) => {
    setSelectedEmploymentType(val);
  };

  const removeEmploymentType = (option: string) => {
    setSelectedEmploymentType(
      selectedEmploymentType.filter((status) => status !== option),
    );
  };

  const renderWorkModeTags = () =>
    selectedWorkMode.map((status) => (
      <Tag closable onClose={() => removeWorkMode(status)} key={status}>
        {status}
      </Tag>
    ));

  const renderEmploymentTypeTags = () =>
    selectedEmploymentType.map((status) => (
      <Tag closable onClose={() => removeEmploymentType(status)} key={status}>
        {status}
      </Tag>
    ));

  const workModeOptions = [
    {
      label: "Remote",
      value: "Remote",
    },
    // {
    //   label: "On-site",
    //   value: "On-site",
    // },
    // {
    //   label: "Hybrid",
    //   value: "Hybrid",
    // },
  ];

  const employmentTypeOptions = [
    {
      label: "Full-time",
      value: "Full-time",
    },
    {
      label: "Contract",
      value: "Contract",
    },
  ];

  return (
    <div className="flex justify-between items-center mb-10">
      <CustomInput
        roundRadius
        size="large"
        placeholder="Please enter job title, project name or company name to search"
        prefix={
          <SearchOutlined style={{ color: token.colorTextPlaceholder }} />
        }
        className="flex-1 mr-5"
        // className={
        //   "gap-[10px] pl-[5px] text-base text-[#999] bg-[#292D31] border-0 rounded-full w-[200px] hover:bg-[#292D31] focus:bg-[#292D31] focus-within:bg-[#292D31]"
        // }
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
      />
      {/* <CustomSelect
        roundRadius
        size="large"
        className="shrink-0 w-1/4 mr-5"
        placeholder="Work Model"
        value={selectedWorkMode}
        mode="multiple"
        options={workModeOptions}
        onChange={addWorkMode}
      /> */}
      <CustomSelect
        roundRadius
        size="large"
        className="shrink-0 w-1/4 mr-5"
        placeholder="Job Type"
        value={selectedEmploymentType}
        mode="multiple"
        options={employmentTypeOptions}
        onChange={addEmploymentType}
      />
      <Checkbox
        className="shrink-0 w-[90px]"
        checked={isVerified}
        onChange={(e) => setIsVerified(e.target.checked)}
      >
        Verified
      </Checkbox>
    </div>
  );
};

export default PublicJobsSearch;
