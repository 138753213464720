import { UserType } from "@/__generated__/graphql";
import { ArtistFollow } from "@/components/discover/artistDetail/ArtistFollow";
import { ArtistIntroduction } from "@/components/discover/artistDetail/ArtistIntroduction";
import { ArtistMenu } from "@/components/discover/artistDetail/ArtistMenu";
import { IPublicCompanyInfo } from "@/pages/companies/type";
import { PublicCompanyCollections } from "@/pages/enterprises/components/PublicCompanyCollections";
// import PublicCompanyImgInfo from "@/pages/enterprises/components/PublicCompanyImgInfo";
import PublicCompanyIntroduction from "@/pages/enterprises/components/PublicCompanyIntroduction";
import { PublicCompanyJobs } from "@/pages/enterprises/components/PublicCompanyJobs";
import { PublicCompanyLikes } from "@/pages/enterprises/components/PublicCompanyLikes";
import { PublicCompanyPortfolio } from "@/pages/enterprises/components/PublicCompanyPortfolio";
import { httpGet } from "@/utils/http";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

interface JobItem {
  company_name: string;
  id: string;
  title: string;
  project_thumbnail_url: string;
  project_name: string;
  company_id: string;
  company_shortname: string;
  company_logo_url: string;
  department_name: string;
  location: string;
  remote_option: string;
  employment_type: string;
  created_at: string;
  is_company_verified: boolean;
  is_project_verified: boolean;
  is_enabled: boolean;
  is_recommended: boolean;
}

export const PublicCompanyHomepage: React.FC<IResourceComponentsProps> = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const apiUrl = useApiUrl();
  const [authorTabState, setAuthorTabState] = useState<string>("portfolio");
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedWorkMode, setSelectedWorkMode] = useState<string[]>([]);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState<
    string[]
  >([]);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const navigate = useNavigate();

  const goToDetail = (jobId: string) => {
    navigate(`/enterprises/public-job-details/${jobId}`);
  };

  const [jobs, setJobs] = useState<JobItem[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const loadingMessage = message.loading("Loading...", 0);
      const response = await fetch(`${apiUrl}/jobs?page=1&size=100`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Jobs: ", data);
        // TODO: Check with PM. Filter the jobs where is_enabled is 1
        const enabledJobs = data.items.filter(
          (item: JobItem) => item.is_enabled === true,
        );
        let filteredJobs = enabledJobs;
        const lowerCaseSearchKeyword = searchKeyword.toLowerCase();
        if (searchKeyword) {
          filteredJobs = filteredJobs.filter(
            (job: JobItem) =>
              job.title.toLowerCase().includes(lowerCaseSearchKeyword) ||
              job.project_name.toLowerCase().includes(lowerCaseSearchKeyword) ||
              job.company_shortname
                .toLowerCase()
                .includes(lowerCaseSearchKeyword),
          );
        }
        if (selectedWorkMode.length > 0) {
          filteredJobs = filteredJobs.filter((job: JobItem) =>
            selectedWorkMode.includes(job.remote_option),
          );
        }
        if (selectedEmploymentType.length > 0) {
          filteredJobs = filteredJobs.filter((job: JobItem) =>
            selectedEmploymentType.includes(job.employment_type),
          );
        }
        if (isVerified) {
          filteredJobs = filteredJobs.filter(
            (job: JobItem) => job.is_project_verified === true,
          );
        }
        setJobs(filteredJobs);
      }
      loadingMessage();
    };

    fetchData();
  }, [
    apiUrl,
    token,
    searchKeyword,
    selectedWorkMode,
    selectedEmploymentType,
    isVerified,
  ]);

  const formatEstablishmentDate = (isoDateString: string | number | Date) => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const userId = sessionStorage.getItem("jobtouserid");
  const companyId = sessionStorage.getItem("jobtocompanyid");
  const [companyInfo, setCompanyInfo] = useState<IPublicCompanyInfo | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchCompanyInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await httpGet(`/companies/${companyId}/public`);
      if (response.status === 200) {
        console.log(response.data);
        setCompanyInfo(response.data);

        if (response.data.is_verified !== true) {
          message.error(
            "This company didn't finish the verification process yet. Redirecting to job list...",
          );
          navigate("/enterprises/public-jobs");
        }
      } else {
        console.error("Failed to fetch company information");
      }
    } catch (error) {
      console.error("Error fetching company information:", error);
    } finally {
      setIsLoading(false);
    }
  }, [navigate, companyId]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyInfo();
    }
  }, [companyId, fetchCompanyInfo]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!companyInfo) {
    return <div>Company information is not available.</div>;
  }

  const renderContent = () => {
    switch (authorTabState) {
      case "portfolio":
        return (
          <PublicCompanyPortfolio
            containerRef={containerRef}
            user_id={userId ?? ""}
            user_role={UserType.Company}
          />
        );
      case "introduction":
        return (
          companyInfo && <PublicCompanyIntroduction companyInfo={companyInfo} />
        );
      case "jobs":
        return (
          <PublicCompanyJobs
            containerRef={containerRef}
            userId={userId ?? ""}
          />
        );
      case "likes":
        return (
          <PublicCompanyLikes
            containerRef={containerRef}
            author_id={userId ?? ""}
            user_role={UserType.Company}
          />
        );
      case "collections":
        return (
          <PublicCompanyCollections
            containerRef={containerRef}
            author_id={userId ?? ""}
            user_role={UserType.Company}
          />
        );
      case "follow":
        return (
          <ArtistFollow
            containerRef={containerRef}
            author_id={userId ?? ""}
            user_role={UserType.Company}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div ref={containerRef} className="p-5 h-full overflow-auto">
      <ArtistIntroduction
        artistUserInfo={companyInfo}
        artistId={userId as string}
        user_role={UserType.Company}
        refresh={fetchCompanyInfo}
      />
      <ArtistMenu
        setAuthorTab={setAuthorTabState}
        authorTab={authorTabState}
        user_role={UserType.Company}
      />
      <div className="flex flex-col w-full">{renderContent()}</div>
    </div>
  );
};
