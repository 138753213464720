import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Image,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatToMiddleStyle } from "../../utils/date";
import { formatJobDetails } from "../../utils/helper";
import EventPage from "./EventPage";

const { Title, Text } = Typography;

interface Department {
  id: string;
  name: string;
  description: string;
  question: {
    description: string;
    link: string;
    pic_url: string;
    video_url: string;
  };
  standards: Array<{
    description: string;
    link: string;
    pic_url: string;
    video_url: string;
  }>;
}

interface Verification {
  is_verified: number;
  status: string;
  comments: string;
  reviewed_at: string;
  reviewer: string;
}

interface VerificationMethod {
  contract_url: string;
  license_number: string;
  license_pic_urls: string[];
  license_type: string;
  manager_license_pic_url: string;
  manager_name: string;
}

interface Violation {
  comments: string;
  is_violated: boolean;
  reviewed_at: string;
  reviewer: string;
  status: string;
}

interface Job {
  is_project_verified: boolean;
  is_company_verified: boolean;
  company_shortname: string | undefined;
  company_logo_url: string;
  department_id: string;
  id: string;
  title: string;
  description: string;
  created_at: string;
  location: string;
  employment_type: string;
  headcount: number;
  payment_method: string;
  rate: number;
  rate_unit: string;
  remote_option: string;
  count: number;
  count_unit: string;
  is_enabled: boolean;
  total_applicants: number;
  total_views: number;
  total_interviewed: number;
}

export const PublicProjectDetails: React.FC = () => {
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const [selectedOption, setSelectedOption] = useState("modelling");
  const [hasPostedJobs, setHasPostedJobs] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);

  const [isVerified, setIsVerified] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const [isProjectEnabled, setIsProjectEnabled] = useState<boolean>(true);

  const [departments, setDepartments] = useState<Department[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<string[]>([]);
  const [verification, setVerification] = useState<Verification | null>(null);
  const [verificationMethod, setVerificationMethod] =
    useState<VerificationMethod | null>(null);
  const [violation, setViolation] = useState<Violation | null>(null);
  const [fullThumbnailUrl, setFullThumbnailUrl] = useState("");
  const [showDeniedAlert, setShowDeniedAlert] = useState(false);
  const [showViolatedAlert, setShowViolatedAlert] = useState(false);
  const [showViolatedResubmittedWarning, setShowViolatedResubmittedWarning] =
    useState(false);

  const handleJobClick = (jobId: string) => {
    navigate(`/enterprises/public-job-details/${jobId}`);
  };

  const handleJobKeyPress = (event: React.KeyboardEvent, jobId: string) => {
    return;
  };

  useEffect(() => {
    const projectId = sessionStorage.getItem("jobtoprojectid");
    console.log(projectId);
    if (projectId) {
      fetchProjectDetails(projectId);
      const fetchJobsDetails = async () => {
        try {
          const response = await fetch(`${apiUrl}/jobs/projects/${projectId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const jobsData = await response.json();
          if (response.status === 200 && jobsData.length > 0) {
            setHasPostedJobs(true);
            setJobs(jobsData);
            console.log("Job Data: ", jobsData);
          } else {
            setHasPostedJobs(false);
          }
        } catch (error) {
          console.error("Error fetching jobs details:", error);
          setHasPostedJobs(false);
        }
      };
      fetchJobsDetails();
    }
  }, [apiUrl, token]);

  const fetchProjectDetails = async (projectId: string) => {
    try {
      message.info("Loading...");
      const response = await fetch(`${apiUrl}/projects/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        console.log("Project Details:", data);
        setIsProjectEnabled(data.is_enabled === true);
        setProjectName(data.name);
        setVerification(data.verification);
        setVerificationMethod(data.verification_method);
        setViolation(data.violation);
        if (data.thumbnail_url) {
          const newFullThumbnailUrl = `${marketplaceUploadUrl}${data.thumbnail_url}`;
          setFullThumbnailUrl(newFullThumbnailUrl);
        }
      } else {
        console.error("Cannot fetch project details:", response.status, data);
      }
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  const handleDepartmentClick = (deptName: string) => {
    setSelectedOption(deptName);
  };

  useEffect(() => {
    if (
      verification &&
      (verification.status === "APPROVED" ||
        verification.status === "PENDING" ||
        verification.status === "DENIED")
    ) {
      setIsVerified(true);
    }
  }, [verification]);

  useEffect(() => {
    if (!verification) {
      return;
    }

    if (verification?.status === "DENIED") {
      setShowDeniedAlert(true);
    } else {
      setShowDeniedAlert(false);
    }

    if (violation?.is_violated === true) {
      if (violation.status === "DENIED" || violation.status === "UNSUBMITTED") {
        setShowViolatedResubmittedWarning(false);
        setShowViolatedAlert(true);
      } else if (violation.status === "PENDING") {
        setShowViolatedAlert(false);
        setShowViolatedResubmittedWarning(true);
      }
    } else {
      setShowViolatedAlert(false);
      setShowViolatedResubmittedWarning(false);
    }
  }, [verification, violation]);

  const selectedDepartment = departments.find(
    (dept) => dept.name === selectedOption,
  );

  const filteredJobs = jobs.filter(
    (job) =>
      job.department_id === selectedDepartment?.id && job.is_enabled === true,
  );

  return (
    <div className="min-h-screen">
      <Button className="mb-4" onClick={() => navigate(-1)}>
        Back
      </Button>
      <Row gutter={[16, 16]} className="min-h-screen">
        <Col xl={6} xs={24} className="overflow-auto">
          <EventPage />
        </Col>
        <Col xl={18} xs={24} className="overflow-auto">
          <Card bordered className="rounded-lg">
            <div>
              <Space wrap className=" p-2 mb-4">
                {departmentOptions.map((option, index) => (
                  <Button
                    key={option}
                    type={selectedOption === option ? "primary" : "default"}
                    onClick={() => handleDepartmentClick(option)}
                  >
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </Button>
                ))}
              </Space>

              <Row
                align="middle"
                justify="space-between"
                className="my-4 mt-10"
              >
                <Col>
                  <Title level={3}>Posted Jobs</Title>
                </Col>
              </Row>
              {hasPostedJobs ? (
                <>
                  {filteredJobs.map((job) => (
                    <div
                      key={job.id}
                      onClick={() => handleJobClick(job.id)}
                      onKeyUp={(event) => handleJobKeyPress(event, job.id)}
                      tabIndex={0}
                      role="button"
                      style={{ cursor: "pointer" }}
                    >
                      <Row
                        gutter={16}
                        className="my-4 p-2 shadow rounded bg-white"
                      >
                        {/* Image */}
                        <Col
                          span={8}
                          className="flex justify-center items-center"
                        >
                          <Image
                            src={fullThumbnailUrl}
                            preview={false}
                            alt="Project Cover"
                            className="w-full h-64 object-cover rounded-l-lg"
                          />
                          <Tag
                            color={
                              job.is_project_verified === true ? "green" : "red"
                            }
                            className="absolute bottom-0 -right-2"
                          >
                            {job.is_project_verified === true
                              ? "Verified"
                              : "Unverified"}
                          </Tag>
                        </Col>

                        {/* Contents */}
                        <Col span={16}>
                          <Title level={3}>{job.title}</Title>
                          <Row justify="space-between" className="mb-4 mt-6">
                            <Text type="secondary">{projectName}</Text>
                            <Text>
                              {formatJobDetails(
                                job.location,
                                job.remote_option,
                                job.employment_type,
                              )}
                            </Text>
                          </Row>
                          <Row justify="space-between" className="mb-4">
                            <Row align="middle">
                              <Col>
                                <div style={{ position: "relative" }}>
                                  <Image
                                    src={`${marketplaceUploadUrl}${job.company_logo_url}`}
                                    alt={job.company_shortname}
                                    preview={false}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                    }}
                                  />
                                  <div
                                    style={{
                                      position: "absolute",
                                      bottom: 0,
                                      right: 0,
                                      backgroundColor:
                                        job.is_company_verified === true
                                          ? "green"
                                          : "red",
                                      color: "white",
                                      fontSize: "0.5rem",
                                      padding: "2px 4px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    {job.is_company_verified === true ? (
                                      <CheckOutlined />
                                    ) : (
                                      <CloseOutlined />
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div style={{ marginLeft: "10px" }}>
                                  {job.company_shortname}
                                </div>
                              </Col>
                            </Row>
                            <Row justify="end" className="mt-4">
                              <Col>
                                <Text type="secondary" className="!text-right">
                                  Create Date:{" "}
                                  {formatToMiddleStyle(job.created_at)}
                                </Text>
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              ) : (
                <p>No posted jobs yet.</p>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
