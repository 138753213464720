import {
  UserType,
  useSetRemoteCustomDataConversationMutation,
} from "@/__generated__/graphql";
import { CustomDropDown, DepositIcon } from "@/components/customComponents";
import LeyIcon from "@/components/leylineIcons";
import { useModalConfirm } from "@/hooks";
import { ApplicationStatEnum } from "@/pages/Applications/enum";
import {
  paymentDeposit,
  updateMemoRemarkApi,
} from "@/pages/Applications/services";
import RemarkModal from "@/pages/jobs/components/RemarkModal";
import SignContractModal from "@/pages/jobs/components/signContractModal";
import { ApplicantCardProps, PaymentStatusType } from "@/pages/jobs/type";
import { useGlobalStore } from "@/stores";
import { setConversationCustomData } from "@/utils/imUtils";
import {
  AuditOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  FrownOutlined,
  MessageFilled,
  MessageOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  ConfigProvider,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import { BaseType } from "antd/es/typography/Base";
import dayjs from "dayjs";
import {
  MouseEvent,
  ReactEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { JobContractModal } from "../../../pages/jobs/modalContract";
import { JobContractDetailModal } from "../../../pages/jobs/modalContractDetailPublic";
import { JobUnmatchModal } from "../../../pages/jobs/modalUnmatch";
import {
  formatRemainingTime,
  formatToLongStyle,
  formatToShortStyleUTC,
} from "../../../utils/date";
// import { ApplicantInfo } from "../ArtistApplicantCard";
import CompanyInfoDrawer from "../components/CompanyInfoDrawer";
import ResumeDetailDrawer from "../components/ResumeDetailDrawer";

interface ConfigOpt {
  hideTip: boolean;
  hideTalkBtn: boolean;
  userId?: string;
  companyId?: string;
  role?: "Company" | "Individual";
}
const { Paragraph } = Typography;

const TipParagraph = ({
  type,
  text,
  className,
}: { type: BaseType; text: string; className?: string }) => {
  return (
    <Paragraph
      type={type}
      className={`leading-5 !mb-0 text-center absolute top-1/2 -translate-y-1/2 w-full ${className}`}
      ellipsis={{
        rows: 2,
        tooltip: text,
      }}
    >
      {text}
    </Paragraph>
  );
};

export const useApplicantButtons = (
  applicant: ApplicantCardProps | undefined,
  refreshList: () => void,
  configOption?: ConfigOpt,
  isShow?: boolean,
) => {
  const [isUnmatchModalOpen, setIsUnmatchModalOpen] = useState(false);
  const [isContractModalOpen, setIsContractModalOpen] = useState(false);
  const [isContractDetailModalOpen, setIsContractDetailModalOpen] =
    useState(false);
  const [isResumeDrawerOpen, setIsResumeDrawerOpen] = useState(false);
  const [isRemarkModalOpen, setIsRemarkModalOpen] = useState(false);
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const { chatClient } = useGlobalStore((state) => state);
  const [setRemoteCustomDataMutation] =
    useSetRemoteCustomDataConversationMutation();
  const themeConfig = useContext(ConfigProvider.ConfigContext);
  const [modalConfirm, modalConfirmHolder] = useModalConfirm();
  const isHide = isShow === false;

  const updateMemoMark = (val: string) => {
    return updateMemoRemarkApi(applicant?.id as string, val);
  };
  const updateApplication = async ({
    action,
    reason,
    remark,
  }: {
    action: string;
    reason?: string;
    remark?: string;
  }) => {
    const loadingMessage = message.loading(
      "Unmatching the application, please wait...",
      0,
    );
    try {
      let requestBody = {};
      switch (action) {
        case "unmatch":
          requestBody = {
            is_denied: 1,
            rejection_reason: reason,
            remark,
          };
          break;
        case "revokeSign":
          requestBody = {
            is_revoked: 1,
          };
          break;
        case "later":
          requestBody = {
            is_saved_for_later: true,
          };
          break;
        default:
          break;
      }
      const response1 = await fetch(
        `${apiUrl}/applications/company/${applicant?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
          },
          body: JSON.stringify(requestBody),
        },
      );
      const data1 = await response1.json();
      // if (!data1.ok) {
      if (response1.status !== 200 && response1.status !== 204) {
        console.error(`Error ${action}`, data1);
        message.error(
          data1.detail || "Failed to handle the application - try again later",
        );
      } else {
        action === "unmatch" && setIsUnmatchModalOpen(false);
        refreshList();
      }
    } catch (error) {
      console.error(`Error ${action}`, error);
      message.error("Failed to handle the application - try again later");
    } finally {
      loadingMessage();
    }
  };

  const viewResume = useCallback(() => {
    setIsResumeDrawerOpen(true);
  }, []);

  const showRemark = useCallback(() => {
    setIsRemarkModalOpen(true);
  }, []);

  const buttonConfigs = useMemo(
    () =>
      isHide
        ? null
        : {
            unsuitable: {
              title: "Reject",
              icon: <LeyIcon icon="Reject" />,
              onClick: () => {
                setIsUnmatchModalOpen(true);
              },
            },
            continueCommunication: {
              title: "Send Message",
              icon: <MessageOutlined />,
              onClick: async (e: MouseEvent) => {
                e?.stopPropagation();
                const opponentImId = `${
                  applicant?.user_id
                }_${applicant?.role?.toLowerCase()}`;
                await setConversationCustomData(
                  chatClient,
                  setRemoteCustomDataMutation,
                  opponentImId,
                  {
                    jInfo: {
                      id: applicant?.job_id,
                      titl: applicant?.job_title,
                      pName: applicant?.project_name,
                      role: 2,
                    },
                  },
                );
                navigate("/enterprises/chat", {
                  state: {
                    opponentImId,
                    activeTab: 2,
                  },
                });
              },
            },
            viewResume: {
              title: "View Profile",
              icon: <FileSearchOutlined />,
              onClick: viewResume,
            },
            remark: {
              title: "Remark",
              icon: "",
              onClick: () => {
                setIsRemarkModalOpen(true);
              },
            },
            handleLater: {
              title: "Saved for later",
              icon: "",
              onClick: () => {
                updateApplication({ action: "later" });
              },
            },
            signNow: {
              title: "Sign",
              icon: <LeyIcon icon="Sign" />,
              onClick: () => {
                setIsContractModalOpen(true);
              },
            },
            reviewTheContractContent: {
              title: "View Contract",
              icon: <LeyIcon icon="ViewContract" />,
              onClick: () => {
                setIsContractDetailModalOpen(true);
              },
            },
            revokeSign: {
              title: "Void Contract",
              icon: <FrownOutlined />,
              onClick: () => {
                const isLess90 = dayjs(applicant?.created_at)
                  .add(90, "day")
                  .isAfter(dayjs());
                // @ts-ignore
                modalConfirm({
                  title: "Notice",
                  content: isLess90
                    ? "After voiding the contract, you can resubmit the signing information. Do you want to void the contract?"
                    : "The application has exceeded 90 days. After voiding the contract, you will not be able to resubmit signing information. Do you want to void the contract?",
                  onOk() {
                    updateApplication({ action: "revokeSign" });
                  },
                });
              },
            },
            payDeposit: {
              title: "Pay Deposit",
              icon: <LeyIcon icon="Deposit" />,
              onClick: () => {
                paymentDeposit({
                  contract_id: applicant?.contract?.id as string,
                  success_url: `${location.origin}/mktp/third-party-result?businessType=deposit`,
                })
                  .then((res) => {
                    if (res.status === 200) {
                      localStorage.setItem(
                        "refreshJobId",
                        applicant?.job_id as string,
                      );
                      res.data && window.open(res.data, "_blank");
                    }
                  })
                  .catch((err) => {
                    message.error(
                      err?.errors?.detail ||
                        "An unknown error has been encountered.",
                    );
                  });
              },
            },
          },
    [
      applicant,
      navigate,
      updateApplication,
      chatClient,
      setRemoteCustomDataMutation,
      isHide,
      viewResume,
      modalConfirm,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const showTipsAndActions = useMemo(() => {
    let tipsContent = null;
    let btns: string[][] = [];
    if (isHide) return null;
    if (applicant?.status) {
      switch (applicant.status) {
        case ApplicationStatEnum.PENDING:
          tipsContent = (
            <TipParagraph
              type="warning"
              text={`Please complete the process with ${formatRemainingTime(
                applicant.created_at,
                90,
              )}.`}
            />
          );
          btns = [
            ["unsuitable", "signNow"],
            ["continueCommunication"],
            [
              ...(applicant?.is_saved_for_later ? [] : ["handleLater"]),
              "viewResume",
              "remark",
            ],
          ];
          break;
        case ApplicationStatEnum.A_DENIED:
          tipsContent = (
            <TipParagraph
              type="danger"
              text={`Unsuitable: ${
                applicant.remark || applicant.rejection_reason || "(no reason)"
              }`}
            />
          );
          btns = [[], ["continueCommunication"], ["viewResume", "remark"]];
          break;
        case ApplicationStatEnum.B_DENIED:
          tipsContent = (
            <TipParagraph
              type="danger"
              text={`Rejected: ${
                applicant.remark || applicant.rejection_reason || "(no reason)"
              }`}
            />
          );
          btns = [
            ["reviewTheContractContent"],
            ["continueCommunication"],
            ["viewResume", "remark"],
          ];
          break;
        case ApplicationStatEnum.CONTRACTING:
          tipsContent = (
            <TipParagraph
              type="warning"
              text="A copy of the contract has been sent to your registered email. Please check at your earliest convenience."
            />
          );
          btns = [
            ["reviewTheContractContent"],
            ["continueCommunication"],
            ["revokeSign", "viewResume", "remark"],
          ];
          break;
        case ApplicationStatEnum.A_SIGNED:
          tipsContent = (
            <TipParagraph
              type="warning"
              text={`You have submitted the signing content. The other party will process it within ${formatRemainingTime(
                applicant.contract?.signed_at as string,
                3,
              )}.`}
            />
          );
          btns = [
            ["reviewTheContractContent"],
            ["continueCommunication"],
            ["revokeSign", "viewResume", "remark"],
          ];
          break;
        case ApplicationStatEnum.B_SIGNED:
          tipsContent = (
            <TipParagraph
              type="warning"
              text="The other party has finished signing the contract. Please check and sign at your earliest convenience."
            />
          );
          btns = [
            ["reviewTheContractContent"],
            ["continueCommunication"],
            ["revokeSign", "viewResume", "remark"],
          ];
          break;
        case ApplicationStatEnum.COMPLETED:
          tipsContent = (
            <div className="h-full py-1 flex flex-col justify-between text-[#0CBFA1]">
              <div>
                Signing Time:{" "}
                {formatToLongStyle(applicant.contract?.signed_at as string)}
              </div>
              {applicant.contract?.is_deposit_required ? (
                applicant.contract?.payment_status ===
                PaymentStatusType.PAID ? (
                  <>
                    <div>
                      Deposit: {applicant.contract?.currency}{" "}
                      {applicant.contract?.deposit?.toFixed(2)}
                    </div>
                    <div>
                      Pay Deposit Time:{" "}
                      {formatToLongStyle(
                        applicant.contract?.deposit_pay_time as string,
                      )}
                    </div>
                  </>
                ) : (
                  <TipParagraph
                    className="!static translate-y-0 !text-left"
                    type="warning"
                    text={`Please pay the deposit of ${
                      applicant.contract?.currency
                    } ${applicant.contract?.deposit?.toFixed(
                      2,
                    )} before ${formatToShortStyleUTC(
                      applicant.contract?.deposit_due_date,
                    )}`}
                  />
                )
              ) : (
                <div>No deposit required</div>
              )}
            </div>
          );
          btns = [
            [
              ...(applicant.contract?.payment_status ===
              PaymentStatusType.UNPAID
                ? ["payDeposit"]
                : []),
              "reviewTheContractContent",
            ],
            ["continueCommunication"],
            ["viewResume", "remark"],
          ];
          break;
        // case "REFUSE_SIGN": // ????
        //   tipsContent = (
        //     <TipParagraph
        //       type="danger"
        //       text={`Rejected: ${applicant.rejection_reason || "(no reason)"}`}
        //     />
        //   );
        //   btns = [[], ["continueCommunication"], ["viewResume", "remark"]];
        //   break;
        case ApplicationStatEnum.A_TIMEOUT:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="You did not handle it in a timely manner. The current application has timed out."
            />
          );
          btns = [[], ["continueCommunication"], ["viewResume", "remark"]];
          break;
        case ApplicationStatEnum.B_TIMEOUT:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="Counterparty did not sign in a timely manner."
            />
          );
          btns = [[], ["continueCommunication"], ["viewResume", "remark"]];
          break;
        case ApplicationStatEnum.A_CANCELLED:
          tipsContent = (
            <TipParagraph type="danger" text="You have revoked the signing." />
          );
          btns = [
            ["reviewTheContractContent"],
            ["continueCommunication"],
            ["viewResume", "remark"],
          ];
          break;
        case ApplicationStatEnum.B_CANCELLED:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="Counterparty have revoked the signing."
            />
          );
          btns = [
            ["reviewTheContractContent"],
            ["continueCommunication"],
            ["viewResume", "remark"],
          ];
          break;
        case ApplicationStatEnum.WITHDRAWN:
          tipsContent = (
            <TipParagraph
              type="danger"
              text="The other party has withdrawn the application."
            />
          );
          btns = [[], ["continueCommunication"], ["viewResume", "remark"]];
          break;
        default:
          break;
      }
    } else {
      btns = [[], [], ["viewResume", "remark"]];
    }
    const [bigBtns, iconBtns, hideBtns] = btns;
    if (configOption?.hideTalkBtn) {
      const index = iconBtns.findIndex(
        (btnName) => btnName === "continueCommunication",
      );
      index > -1 && iconBtns.splice(index, 1);
    }
    const items = hideBtns.map((btnName) => {
      // @ts-ignore
      const config = buttonConfigs[btnName] || {};
      return {
        key: config.title,
        label: config.title,
        onClick: config.onClick,
      };
    });
    return [
      !!tipsContent && !configOption?.hideTip && (
        <p
          className={`mr-4 mb-0 ${
            applicant?.status === ApplicationStatEnum.COMPLETED
              ? "h-24 !leading-[18px]"
              : "h-10 !leading-3"
          } relative`}
        >
          {tipsContent}
        </p>
      ),
      <Space
        className="w-full justify-end"
        onClick={(e) => e.stopPropagation()}
      >
        {bigBtns.map((item, index) => {
          // @ts-ignore
          const config = buttonConfigs[item] || {};
          return (
            <Button
              key={config.title}
              type={index === bigBtns.length - 1 ? "primary" : "default"}
              className="mr-2"
              onClick={config.onClick}
              icon={config.icon}
            >
              {config.title}
            </Button>
          );
        })}
        {/* {iconBtns.length > 0 && (
          <MessageFilled
            onClick={buttonConfigs?.continueCommunication.onClick}
            className="text-[#00ADAE] text-3xl"
          />
        )} */}
        {hideBtns.length > 0 && (
          <CustomDropDown menu={{ items }}>
            {/* @ts-ignore */}
            {themeConfig?.theme?.mode === "dark" ? (
              <MoreOutlined className="text-[#00ADAE] text-3xl cursor-pointer" />
            ) : (
              <Button
                type="primary"
                icon={
                  <EllipsisOutlined className="text-white text-3xl cursor-pointer" />
                }
              />
            )}
          </CustomDropDown>
        )}
      </Space>,
      <>
        {!!applicant?.status && (
          <>
            {/* <JobContractModal
              open={isContractModalOpen}
              setOpen={setIsContractModalOpen}
              refreshList={refreshList}
              applicant={applicant}
            /> */}
            <SignContractModal
              open={isContractModalOpen}
              setOpen={setIsContractModalOpen}
              refreshList={refreshList}
              applicant={applicant}
            />
            <JobContractDetailModal
              curRole="partyA"
              open={isContractDetailModalOpen}
              setOpen={setIsContractDetailModalOpen}
              refreshList={refreshList}
              application={applicant}
              tipsContent={tipsContent}
              onRevokeContract={buttonConfigs?.revokeSign?.onClick}
            />
            <JobUnmatchModal // @ts-ignore
              open={isUnmatchModalOpen} // @ts-ignore
              onOk={(reason, remark) => {
                updateApplication({ action: "unmatch", reason, remark });
              }}
              onCancel={() => {
                setIsUnmatchModalOpen(false);
              }}
            />
            <RemarkModal
              open={isRemarkModalOpen}
              setOpen={setIsRemarkModalOpen}
              refreshList={refreshList}
              value={applicant?.memo_remark}
              updateMemoRemark={updateMemoMark}
            />
            {modalConfirmHolder}
          </>
        )}
        {(!!applicant?.role || configOption?.role) && (
          <ResumeDetailDrawer
            open={isResumeDrawerOpen}
            userId={
              (applicant?.user_id as string) || (configOption?.userId as string)
            }
            userRole={(
              (applicant?.role as string) || (configOption?.role as string)
            )?.toUpperCase()}
            onCancel={() => setIsResumeDrawerOpen(false)}
          />
        )}
      </>,
    ];
  }, [applicant, buttonConfigs, isHide]);

  return {
    showTipsAndActions,
    fnCommunicate: buttonConfigs?.continueCommunication?.onClick,
    viewResume,
    showRemark,
  };
};
