import {
  CustomEmpty,
  CustomSegmented,
  CustomSelect,
  CustomTabs,
} from "@/components/customComponents";
import CardItem from "@/pages/Applications/components/CardItem";
import {
  JobItem,
  ReceGroupEnum,
  applicationSorts,
  renderApplicaStageOptions,
  renderToSignFilters,
} from "@/pages/Applications/utils";
import JobDetail from "@/pages/enterprises/components/JobDetail";
import { ApplicantCardProps } from "@/pages/jobs/type";
import { Button, Col, Drawer, Result, Row } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: JobItem;
  fnRefresh: (e?: {
    job_id?: string;
    project_id?: string;
    published_list_status?: ReceGroupEnum;
    sub_status?: number;
    sort?: string;
  }) => void;
}

const JobGroup: FC<Props> = ({ item, fnRefresh }) => {
  const [stageVal, setStageVal] = useState(ReceGroupEnum.PENDING);
  const [filterVal, setFilterVal] = useState("");
  const [sortVal, setSortVal] = useState("LATEST_APPLICATION");
  const [jobDetailVisible, setJobDetailVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (stageVal === ReceGroupEnum.TO_SIGN) setFilterVal("T0");
    else if (stageVal === ReceGroupEnum.SIGNED) setFilterVal("S0");
    else setFilterVal("");
  }, [stageVal]);

  const showSubTabs = useMemo(() => {
    return [ReceGroupEnum.TO_SIGN, ReceGroupEnum.SIGNED].includes(stageVal);
  }, [stageVal]);

  const refreshData = useCallback(
    () =>
      fnRefresh({
        job_id: item.job_id,
        published_list_status: stageVal,
        sub_status: filterVal
          ? parseFloat(filterVal.replace(/[A-Z]/, ""))
          : undefined,
        sort: sortVal,
      }),
    [item.job_id, stageVal, filterVal, sortVal, fnRefresh],
  );

  useEffect(() => {
    const channel = new BroadcastChannel("refreshReceivedList");
    channel.onmessage = () => {
      const KEY = "refreshJobId";
      const localJobId = localStorage.getItem(KEY);
      if (localJobId === item.job_id) {
        refreshData();
        localStorage.removeItem(KEY);
      }
    };
    return () => channel.close();
  }, [item.job_id, refreshData]);

  const onClickJob = () => {
    setJobDetailVisible(true);
  };

  return (
    <div key={item.job_id} className="bg-[#2A2D30] mb-6 p-7 rounded-xl">
      <div className="text-[#00ADAE] text-2xl mb-5">
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
        <span className=" cursor-pointer hover:underline" onClick={onClickJob}>
          {item.project_name} / {item.job_title}
        </span>
      </div>
      <div className={`flex ${showSubTabs ? "mb-4" : "mb-7"}`}>
        <CustomSegmented
          size="large"
          value={stageVal}
          onChange={(val) => {
            setStageVal(val as ReceGroupEnum);
            fnRefresh({
              job_id: item.job_id,
              published_list_status: val as ReceGroupEnum,
              sub_status: undefined,
              sort: sortVal,
            });
          }}
          options={renderApplicaStageOptions(item)}
        />
        <CustomSelect
          className="ml-5"
          roundRadius
          lightBg
          size="large"
          value={sortVal}
          onChange={(e) => {
            setSortVal(e);
            fnRefresh({
              job_id: item.job_id,
              published_list_status: stageVal,
              sub_status: filterVal
                ? parseFloat(filterVal.replace(/[A-Z]/, ""))
                : undefined,
              sort: e,
            });
          }}
          options={applicationSorts}
        />
      </div>
      {showSubTabs && (
        <CustomTabs
          items={renderToSignFilters(stageVal, item)}
          activeKey={filterVal}
          onChange={(e) => {
            setFilterVal(e);
            fnRefresh({
              job_id: item.job_id,
              published_list_status: stageVal,
              sub_status: e ? parseFloat(e.replace(/[A-Z]/, "")) : undefined,
              sort: sortVal,
            });
          }}
        />
      )}
      <Row gutter={[24, 24]}>
        {/* @ts-ignore */}
        {item.applications.length > 0 ? (
          item.applications.map(
            (x: ApplicantCardProps | undefined, i: number) => (
              <Col span={8} key={x?.id}>
                <CardItem
                  type="received"
                  infoData={x}
                  onClickContainer={() => {}}
                  refresh={refreshData}
                />
              </Col>
            ),
          )
        ) : (
          <CustomEmpty className="mx-auto mt-[40px]" />
        )}
      </Row>
      <Drawer
        width={"75%"}
        open={jobDetailVisible}
        onClose={() => setJobDetailVisible(false)}
      >
        <JobDetail
          id={item.job_id as string}
          onClose={() => setJobDetailVisible(false)}
        />
      </Drawer>
    </div>
  );
};

export default JobGroup;
