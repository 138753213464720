import { UserType } from "@/__generated__/graphql";
import {
  CompanyIcon,
  DefaultUserIcon,
  FollowIcon,
  FollowingIcon,
} from "@/components/customComponents/CustomIcons";
import { UserCompanyAvatar } from "@/components/discover/homepage/UserCompanyAvatar";
import { LeyLineIcon } from "@/components/icons";
import { IIdentity } from "@/interfaces";
import {
  createFollowAPI,
  removeFollowAPI,
  useUserProfile,
} from "@/pages/discover/api";
import { IAuthorItem } from "@/pages/discover/types";
import { useArtworkStore, useGlobalStore } from "@/stores";
import { getImgUrl } from "@/utils/common";
import Icon, { CloseOutlined, EnvironmentFilled } from "@ant-design/icons";
import { useGetIdentity, useOne } from "@refinedev/core";
import { Avatar, Image, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface IAuthorItemComProps {
  authorItem: IAuthorItem;
  comState: string;
  stopPropagation?: boolean;
  updateData?: () => void;
}

const AuthorItem: React.FC<IAuthorItemComProps> = ({
  authorItem,
  comState,
  stopPropagation = false,
  updateData,
}) => {
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const { updateArtworkDetailArtworkId, updateArtworkDetailModalVisible } =
    useArtworkStore((state) => state);
  const { data: user } = useGetIdentity<IIdentity>();
  const [isHovered, setIsHovered] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const user_id = user?.id || "";
  const { data } = useUserProfile(user_id);
  const navigate = useNavigate();
  useEffect(() => {
    setIsFollowed(authorItem.is_following);
  }, [authorItem.is_following]);

  const onUnFollowing = async (id: string, user_role: string) => {
    if (!currentRole) return;
    await removeFollowAPI(currentRole, updateLoading, id, user_role);
    setIsFollowed(false);
    updateData?.();
  };

  const onFollowing = async (id: string, user_role: string) => {
    if (!currentRole) return;
    await createFollowAPI(currentRole, updateLoading, id, user_role);
    setIsFollowed(true);
    updateData?.();
  };

  const goToArtworkDetail = (id: string) => {
    updateArtworkDetailArtworkId(id);
    updateArtworkDetailModalVisible(true);
  };

  return (
    <div
      className={`w-full flex flex-row relative gap-2 ${
        comState === "follow"
          ? "bg-[#232529] rounded-sm"
          : "bg-[#141618] rounded-xl"
      } p-6 custom-alibaba-font h-full cursor-pointer`}
      onClick={() => {
        navigate(`/discover/author/${authorItem.id}?role=${authorItem.role}`);
      }}
      onKeyUp={() => {}}
    >
      <div className="relative">
        <div className="relative">
          <UserCompanyAvatar
            user_role={authorItem.role}
            avatar_src={getImgUrl(
              authorItem.role === UserType.Individual
                ? authorItem.avatar_url
                : authorItem?.company?.logo_url,
            )}
            avatar_size={60}
            border_size="2px"
          />
          {authorItem.role === UserType.Company && (
            <Space className="absolute z-10 left-1/2 top-[-15%] transform -translate-x-1/2">
              <CompanyIcon width="24px" height="24px" />
            </Space>
          )}
        </div>
      </div>
      <div className="w-full text-base text-[#fff9] flex flex-col flex-1 overflow-hidden">
        <div className="text-base font-bold flex flex-row">
          <Typography.Paragraph
            className="!mb-0 text-[#999] text-base font-bold"
            ellipsis={{ rows: 1 }}
          >
            {authorItem.role === UserType.Individual
              ? authorItem.user_name
              : authorItem.company?.shortname}
          </Typography.Paragraph>
          <div className="flex w-auto">
            {authorItem.id !== user_id &&
              data &&
              (isFollowed ? (
                <Space
                  className="text-[#00ADAE] text-base ml-4 flex gap-1 items-center cursor-pointer"
                  onClick={(e) => {
                    stopPropagation && e?.stopPropagation();
                    onUnFollowing(authorItem.id, authorItem.role);
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {isHovered ? (
                    <>
                      <CloseOutlined />
                      <span className="text-[#00ADAE] custom-alibaba-font">
                        UnFollow
                      </span>
                    </>
                  ) : (
                    <>
                      <Icon component={FollowingIcon} />
                      <span className="text-[#00ADAE] custom-alibaba-font">
                        Following
                      </span>
                    </>
                  )}
                </Space>
              ) : (
                <Space
                  className="text-[#00ADAE] text-base ml-4 flex gap-1 items-center cursor-pointer"
                  onClick={(e) => {
                    stopPropagation && e?.stopPropagation();
                    onFollowing(authorItem.id, authorItem.role);
                  }}
                >
                  <Icon component={FollowIcon} />
                  <span className="text-[#00ADAE] custom-alibaba-font">
                    Follow
                  </span>
                </Space>
              ))}
          </div>
        </div>
        <div className="text-base overflow-hidden whitespace-nowrap text-ellipsis pr-16">
          {authorItem.role === UserType.Individual
            ? authorItem.introduction
            : authorItem.company?.industry
              ? authorItem.company?.scale
                ? `${authorItem.company?.industry} | ${authorItem.company?.scale}`
                : authorItem.company?.industry
              : authorItem.company?.scale
                ? authorItem.company?.scale
                : ""}
        </div>
        <div className="text-sm flex gap-1 items-center">
          {authorItem.role === UserType.Individual
            ? (authorItem.city || authorItem.country) && (
                <>
                  <EnvironmentFilled />
                  <Typography.Text className="text-[#999] custom-alibaba-font">
                    {authorItem.city
                      ? authorItem.country
                        ? `${authorItem.city}, ${authorItem.country}`
                        : authorItem.city
                      : authorItem.country}
                  </Typography.Text>
                </>
              )
            : authorItem.company?.country && (
                <>
                  <EnvironmentFilled />
                  <Typography.Text className="text-[#999] custom-alibaba-font">
                    {authorItem.company.country}
                  </Typography.Text>
                </>
              )}
        </div>
        {/* <Link to={`/discover/author/${authorItem.id}?role=${authorItem.role}`}> */}
        <div className="w-full mt-4 grid grid-cols-3 gap-4 flex-1">
          {authorItem.artworks?.length >= 3
            ? authorItem.artworks.slice(0, 3).map((artworkImage) => (
                <span
                  key={artworkImage.artwork_id} // Using index as key here, ideally should use unique identifier
                  onClick={(e) => {
                    stopPropagation && e.stopPropagation();
                    goToArtworkDetail(artworkImage.artwork_id);
                  }}
                  onKeyUp={() => {}}
                >
                  <Image
                    preview={false}
                    src={getImgUrl(artworkImage.cover_img_url) || ""}
                    height="126px"
                    width={"100%"}
                    className={`w-full object-cover ${
                      comState === "follow" ? "rounded-sm" : "rounded-xl"
                    }`}
                  />
                </span>
              ))
            : authorItem.artworks?.map((artworkImage) => (
                <span
                  key={artworkImage.artwork_id} // Using index as key here, ideally should use unique identifier
                  onClick={(e) => {
                    stopPropagation && e.stopPropagation();
                    goToArtworkDetail(artworkImage.artwork_id);
                  }}
                  onKeyUp={() => {}}
                >
                  <Image
                    preview={false}
                    src={getImgUrl(artworkImage.cover_img_url) || ""}
                    height="126px"
                    width={"100%"}
                    className={`w-full object-cover ${
                      comState === "follow" ? "rounded-sm" : "rounded-xl"
                    }`}
                  />
                </span>
              ))}
          {Array.from({ length: 3 - authorItem.artworks?.length }).map(() => {
            const uniqueKey = `${new Date().getTime()}-${Math.random()}`;
            return (
              <Space
                key={uniqueKey}
                className={`bg-[#D9D9D9] h-[126px] flex justify-center items-center ${
                  comState === "follow" ? "rounded-sm" : "rounded-xl"
                }`}
              >
                <Icon component={LeyLineIcon} />
              </Space>
            );
          })}
        </div>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default AuthorItem;
