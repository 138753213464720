import { GetJobDetailResponse } from "@/pages/jobs/type";
import { formatToMiddleStyle } from "@/utils/date";
import { Divider, Typography } from "antd";

const { Text } = Typography;

interface Props {
  job: GetJobDetailResponse;
}

const JobProperties = ({ job }: Props) => {
  return (
    <div className="text-center flex justify-start items-center mt-3">
      <div>
        <div className="text-[#999]">Location</div>
        <Text>{job?.location || "--"}</Text>
      </div>
      <Divider type="vertical" />
      <div>
        <div className="text-[#999]">Work Model</div>
        <Text>{job?.remote_option}</Text>
      </div>
      <Divider type="vertical" />
      <div>
        <div className="text-[#999]">Job Type</div>
        <Text>{job?.employment_type}</Text>
      </div>
      <Divider type="vertical" />
      <div>
        <div className="text-[#999]">Create Date</div>
        <Text>
          {formatToMiddleStyle(job?.created_at || new Date().toDateString())}
        </Text>
      </div>
    </div>
  );
};

export default JobProperties;
