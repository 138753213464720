import {
  SettlementDataRecord,
  SettlementDetails,
  SettlementStatus,
  TaskDetail,
} from "@/interfaces";
import { axiosInstance } from "@/utils/http";
import { useResource } from "@refinedev/core";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface SettlementContextType {
  status: string;
  isInProgress: boolean;
  settlementDetails: SettlementDetails | undefined;
  updateTaskDetails: (tasks: TaskDetail[]) => void;
  updateCost: (newCost: number) => void;
  refreshDetail: () => void;
  refreshStatus: () => void;
}

const SettlementContext = createContext<SettlementContextType | null>(null);

export const useSettlementContext = () => useContext(SettlementContext);

interface SettlementProviderProps {
  children: ReactNode;
  record: SettlementDataRecord | null;
  role: string;
  visible: boolean;
}

export const SettlementProvider: React.FC<SettlementProviderProps> = ({
  children,
  record,
  role,
  visible,
}) => {
  const apiUrl = import.meta.env.VITE_PRODUCER_TOOL_API_BASE_URL;
  const TOKEN_KEY = import.meta.env.VITE_AUTH_TOKEN_KEY;
  const token = localStorage.getItem(TOKEN_KEY);

  const { id } = useResource();
  const projectId = id as string;
  const [settlementDetails, setSettlementDetails] = useState<
    SettlementDetails | undefined
  >();

  const [status, setStatus] = useState<string>("");
  const [isInProgress, setIsInProgress] = useState<boolean>(false);

  const fetchSettlementDetails = async () => {
    if (record) {
      try {
        const response = await axiosInstance.get<SettlementDetails>(
          `/settlements/${record.id}/details?role=${
            role === "producer" ? "producer" : "contractor"
          }&method=${record.payment_method}`,
          {
            headers: { Authorization: `Bearer ${token}` },
            baseURL: apiUrl,
          },
        );
        setSettlementDetails(response.data);
      } catch (error) {
        console.error("Error fetching settlement details:", error);
      }
    }
  };

  const fetchStatusDetails = async () => {
    if (record) {
      try {
        const response = await axiosInstance.get<SettlementStatus>(
          `${apiUrl}/settlements/${record?.id}/status`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        const statusData = response.data;
        setStatus(statusData.status);
        setIsInProgress(statusData.is_in_progress);
      } catch (error) {
        console.error("Error fetching status details:", error);
      }
    }
  };

  const refreshDetail = async () => {
    await fetchSettlementDetails();
  };

  const refreshStatus = async () => {
    await fetchStatusDetails();
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (record) {
      fetchSettlementDetails();
      fetchStatusDetails();
    }
  }, [record, visible]);

  const updateTaskDetails = (tasks: TaskDetail[]) => {
    if (settlementDetails) {
      const totalPendingCost = tasks.reduce(
        (sum, task) => sum + task.pending_cost,
        0,
      );
      const totalPendingHours = tasks.reduce(
        (sum, task) => sum + task.pending_hour,
        0,
      );

      const roundedTotalPendingCost = Math.round(totalPendingCost * 100) / 100;
      const roundedTotalPendingHours =
        Math.round(totalPendingHours * 100) / 100;

      setSettlementDetails({
        ...settlementDetails,
        task_details: tasks,
        pending_cost: roundedTotalPendingCost,
        pending_duration: roundedTotalPendingHours,
      });
    }
  };

  const updateCost = (newCost: number) => {
    if (settlementDetails) {
      setSettlementDetails({
        ...settlementDetails,
        pending_cost: newCost,
      });
    }
  };

  return (
    <SettlementContext.Provider
      value={{
        settlementDetails,
        updateTaskDetails,
        updateCost,
        status,
        isInProgress,
        refreshDetail,
        refreshStatus,
      }}
    >
      {children}
    </SettlementContext.Provider>
  );
};
