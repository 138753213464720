import { UserType } from "@/__generated__/graphql";
import { CompanyIcon, DefaultUserIcon } from "@/components/customComponents";
import { Avatar, Space } from "antd";

interface IUserCompanyAvatarProps {
  user_role: string;
  avatar_src: string | null;
  avatar_size: number;
  border_size: string;
  can_jump?: boolean;
}

export const UserCompanyAvatar: React.FC<IUserCompanyAvatarProps> = ({
  user_role,
  avatar_src,
  avatar_size,
  border_size,
  can_jump,
}) => {
  return (
    <Avatar
      size={avatar_size}
      className={`object-cover ${
        user_role === UserType.Company && "border-solid border-[#03B3B4]"
      }`}
      style={{
        borderWidth: user_role === UserType.Company ? border_size : "0",
        cursor: can_jump ? "pointer" : "default",
      }}
      src={
        avatar_src || (
          <DefaultUserIcon width={avatar_size} height={avatar_size} />
        )
      }
    />
  );
};
