import { formatToLongStyleUTC } from "@/utils/date";
import { Alert, Col, Row, Typography } from "antd";
import React from "react";
// import EditableCell from "@/components/editableCell";
import { useSettlementContext } from "../../../contexts/settlement";
const { Text, Title } = Typography;

interface SettlementInformationProps {
  status: string;
}

export const SettlementInformation: React.FC<SettlementInformationProps> = ({
  status,
}) => {
  const { settlementDetails, updateCost } = useSettlementContext() ?? {};
  console.log("settlementDetails of task details: ", settlementDetails);
  const currency = `${settlementDetails?.currency || "USD"} `;

  const editableStatuses = [
    "Pending settlement",
    "Pending confirmation",
    "Pending processing",
  ];
  const isEditable =
    editableStatuses.includes(status) &&
    ["day", "week", "month"].includes(settlementDetails?.unit ?? "");

  const handleCostChange = (val: number) => {
    if (updateCost) {
      updateCost(val);
      console.log(
        `Updated pending_cost to ${val} for record: `,
        settlementDetails,
      );
    }
  };

  React.useEffect(() => {
    console.log("status: ", status);
  }, [status]);

  return (
    <div className="ml-5">
      <Title level={4}>Settlement Information</Title>
      {(status === "Pending processing" || status === "Pending confirmation") &&
        settlementDetails?.remark && (
          <Alert
            className="mb-4"
            message={`Objection: ${settlementDetails?.remark}`}
            type="warning"
          />
        )}
      <Row gutter={24} align="top">
        <Col flex="auto">
          <Row className="mb-2">
            <Col span={8}>
              <Text type="secondary">Total Cost: </Text>
              <Text className="font-semibold">
                {currency}
                {settlementDetails?.total_cost
                  ? settlementDetails.total_cost.toFixed(2)
                  : (0).toFixed(2)}
              </Text>
            </Col>
            <Col span={8}>
              <Text type="secondary">Settled Cost: </Text>
              <Text className="font-semibold">
                {currency}
                {settlementDetails?.settled_cost
                  ? settlementDetails.settled_cost.toFixed(2)
                  : (0).toFixed(2)}
              </Text>
            </Col>
            <Col span={8}>
              <Text type="secondary">Remaining Cost: </Text>
              <Text className="font-semibold">
                {currency}
                {settlementDetails?.remain_cost
                  ? settlementDetails.remain_cost.toFixed(2)
                  : (0).toFixed(2)}
              </Text>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col span={8}>
              <Text type="secondary">Payment Method: </Text>
              <Text className="font-semibold">
                {settlementDetails?.unit_price === null &&
                settlementDetails?.unit === null
                  ? "Negotiable"
                  : `${settlementDetails?.unit_price} / Person / ${settlementDetails?.unit}`}
              </Text>
            </Col>
            <Col span={8}>
              {status === "Paid" ? (
                <>
                  <Text type="secondary">Settlement Duration: </Text>
                  <Text className="font-semibold">
                    {settlementDetails?.pending_duration}
                  </Text>
                </>
              ) : (
                <>
                  <Text type="secondary">Pending Settlement Duration: </Text>
                  <Text className="font-semibold">
                    {settlementDetails?.pending_duration}
                  </Text>
                </>
              )}
            </Col>
            <Col span={8}>
              {status === "Paid" ? (
                <>
                  <Text type="secondary">Payment: </Text>
                  <Text className="font-semibold">
                    {currency}
                    {settlementDetails?.pending_cost?.toFixed(2)}
                  </Text>
                </>
              ) : (
                <>
                  <Text type="secondary">Pending Settlement Cost: </Text>
                  {settlementDetails ? (
                    <Text className="font-semibold">
                      {currency}
                      {settlementDetails.pending_cost?.toFixed(2)}
                    </Text>
                  ) : (
                    <Text className="font-semibold">N/A</Text>
                  )}
                </>
              )}
            </Col>
          </Row>
          <Row className="mb-2">
            <Col span={12}>
              <Text type="secondary">Create Time: </Text>
              <Text className="font-semibold">
                {settlementDetails?.end_date
                  ? formatToLongStyleUTC(settlementDetails.end_date)
                  : "N/A"}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
