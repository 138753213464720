import useShowEmailNotice from "@/pages/enterprises/hooks/useShowEmailNotice";
import { SettingOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const AlertNotice: FC = () => {
  const { showFlag, closeNotice } = useShowEmailNotice();
  const navigate = useNavigate();
  const goSetting = () => {
    navigate("/setting");
    closeNotice();
  };

  return showFlag ? (
    <Alert
      className="mb-3"
      message="To avoid missing any messages, we will send you an email notification when you receive a new message. You can enable or disable this email notification feature through the settings page."
      type="warning"
      closable
      action={
        <Button
          icon={<SettingOutlined />}
          size="small"
          type="primary"
          ghost
          onClick={goSetting}
        >
          Settings
        </Button>
      }
      onClose={closeNotice}
    />
  ) : null;
};

export default AlertNotice;
