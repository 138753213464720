import { CommentDrawerItem } from "@/components/users/components/notificationDrawer/items/CommentDrawerItem";
import { getNotficicationItemsAPI } from "@/pages/discover/api";
import {
  ICommentNotificationData,
  IRequestNotificationData,
} from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import { useInfiniteScroll } from "ahooks";
import { Col, Divider, Row, Skeleton } from "antd";
import { stubFalse } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

interface ICommentDrawerProps {
  setNotifData: (data: string) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const CommentDrawer: React.FC<ICommentDrawerProps> = ({
  setNotifData,
  containerRef,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const [requestNotificationData, setRequestNotificationData] =
    useState<IRequestNotificationData>({
      page: 1,
      size: 10,
      type: "COMMENT",
    });

  const getReadCommentList = async (page: number) => {
    if (!currentRole) return { list: [], nextPage: undefined };
    const response = await getNotficicationItemsAPI(currentRole, {
      ...requestNotificationData,
      page,
    });
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll((d) => getReadCommentList(d ? d.nextPage : 1), {
    target: containerRef,
    isNoMore: (d) => !d?.nextPage,
    reloadDeps: [currentRole],
  });

  return (
    <Row>
      <Col className="w-full">
        {infiniteData === undefined ? (
          <div className="p-[15px]">
            <Skeleton active paragraph={{ rows: 10 }} />
          </div>
        ) : (
          <>
            {/* Unread Notifications */}
            {infiniteData.list.filter((item) => item.is_read === false).length >
              0 && (
              <div className="mb-[15px]">
                <div className="bg-[#2A2D30] text-[#999] text-base p-[15px] py-1 w-full mb-5">
                  Unread
                </div>
                {infiniteData.list
                  .filter((item) => item.is_read === false)
                  .map((item, index) => (
                    <div key={item.id}>
                      {index !== 0 && (
                        <Divider className="my-4 opacity-10 bg-white ml-[65px] w-[450px] min-w-[450px]" />
                      )}
                      <CommentDrawerItem
                        setNotifData={setNotifData}
                        commentItem={item}
                      />
                    </div>
                  ))}
              </div>
            )}

            {/* Read Notifications */}
            {infiniteData.list.filter((item) => item.is_read === true).length >
              0 && (
              <div className="mb-[15px]">
                <div className="bg-[#2A2D30] text-[#999] text-base p-[15px] py-1 w-full mb-5">
                  Read
                </div>
                {infiniteData.list
                  .filter((item) => item.is_read === true)
                  .map((item, index) => (
                    <div key={item.id}>
                      {index !== 0 && (
                        <Divider className="my-4 opacity-10 bg-white ml-[65px] w-[450px] min-w-[450px]" />
                      )}
                      <CommentDrawerItem
                        setNotifData={setNotifData}
                        commentItem={item}
                      />
                    </div>
                  ))}
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};
