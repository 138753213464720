import { PRIVACY_POLICY_URL, USER_AGREEMENT_URL } from "@/constants";
import {
  datePickerFormat,
  generateTimezoneOptions,
  guessUserTimezone,
} from "@/utils/date";
import { axiosInstance } from "@/utils/http";
import { PlusOutlined } from "@ant-design/icons";
import { Create } from "@refinedev/antd";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import type { UploadFile, UploadProps } from "antd";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import { Moment } from "moment";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

interface AllFormData {
  projectName?: string;
  projectDescription?: string;
  timezone?: string;
  projectPeriod?: [Moment, Moment];
  projectCover?: UploadFile[];
}

const { Dragger } = Upload;
const { Option } = Select;
const { RangePicker } = DatePicker;

export const CreateProject: React.FC<IResourceComponentsProps> = () => {
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const apiUrl = useApiUrl();
  const navigate = useNavigate();
  const [projectFileList, setProjectFileList] = useState<UploadFile[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const projectUploadProps: UploadProps = {
    name: "file",
    multiple: false,
    fileList: projectFileList,
    listType: "picture",
    maxCount: 1,
    beforeUpload: (file) => {
      const isLt5M = file.size / 1024 / 1024 <= 5;
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB.");
        return Upload.LIST_IGNORE;
      }
      setProjectFileList([file]);
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
      return false;
    },
    onChange: (info) => {
      setProjectFileList(info.fileList);
    },
    // onPreview: async (file) => {
    //   let src = file.url;
    //   if (!src && file.originFileObj) {
    //     src = await new Promise<string>((resolve) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(file.originFileObj as Blob);
    //       reader.onload = () => resolve(reader.result as string);
    //     });
    //   }
    //   if (src) {
    //     const image = new Image();
    //     image.src = src;
    //     const imgWindow = window.open(src);
    //     imgWindow?.document.write(image.outerHTML);
    //   }
    // },
  };

  const handleSubmit = async () => {
    const loadingMessage = message.loading(
      "Submitting project, please wait...",
      0,
    );
    console.log("All Form Data:", allFormData);
    // Upload project cover and get the URL
    const thumbnailUrl =
      projectFileList.length > 0 ? await uploadFile(projectFileList[0]) : "";

    const [startDate, endDate] = allFormData.projectPeriod || [];

    // Construct the request body
    const projectData = {
      name: allFormData.projectName,
      description: allFormData.projectDescription,
      start_date: startDate ? startDate.format("YYYY-MM-DD") : "",
      end_date: endDate ? endDate.format("YYYY-MM-DD") : "",
      timezone: allFormData.timezone,
      thumbnail_url: thumbnailUrl,
    };
    console.log("project data: ", projectData);

    try {
      const response = await axiosInstance.post("/projects", projectData);
      const data = response.data;
      if (response.status === 201) {
        sessionStorage.setItem("currentProjectId", data.id);
        setIsSubmitted(true);
        console.log("Project created:", data);
        message.success("Project created successfully!");
        navigate("/projects/project-details");
      } else {
        message.error(`Error: ${data.detail || "Failed to create projects"}`);
        setIsSubmitted(false);
      }
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      const errorMessage =
        error.errors?.detail ||
        "Failed to create projects. Please try again later.";
      message.error(`Error creating project: ${errorMessage}`);
      setIsSubmitted(false);
    } finally {
      loadingMessage(); // Close loading message
    }
  };

  // Helper function to upload a file and get the URL
  const uploadFile = async (file: UploadFile) => {
    const formData = new FormData();
    formData.append("file", file.originFileObj as Blob);

    try {
      const response = await fetch(`${apiUrl}/projects/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();
      return `${data.id}`;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const uploadProjectCoverButton = (
    <div className="cursor-pointer border-0 bg-transparent flex flex-col items-center justify-center">
      <PlusOutlined />
      <div className="mt-2">Upload</div>
    </div>
  );

  const [allFormData, setAllFormData] = useState<AllFormData>({});
  const formRef = useRef<FormInstance>(null);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onFormValuesChange = (_: any, allValues: any) => {
    setAllFormData((prevData) => ({ ...prevData, ...allValues }));
    console.log("Form data:", allValues);
    console.log("All Form Data:", allFormData);
  };

  const timezoneOptions = generateTimezoneOptions();

  return (
    <Row gutter={24} className="min-h-screen">
      <Col span={24} className="overflow-auto">
        <Card bordered className="rounded-lg">
          <Create
            title="Create Projects"
            headerButtons={() => <></>}
            footerButtons={() => (
              <>
                <Button
                  type="primary"
                  onClick={() => formRef.current?.submit()}
                >
                  Submit
                </Button>
              </>
            )}
          >
            <Row justify="center">
              <Col span={12}>
                <Form
                  ref={formRef}
                  layout="horizontal"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    isActive: true,
                    ...formRef.current?.getFieldsValue(),
                  }}
                  onFinish={handleSubmit}
                  onValuesChange={onFormValuesChange}
                >
                  <Row gutter={20}>
                    <Col span={24}>
                      <Form.Item
                        label="Project Name"
                        name="projectName"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please enter project name"
                          showCount
                          maxLength={100}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Project Description"
                        name="projectDescription"
                      >
                        <Input.TextArea
                          placeholder="Please enter project description"
                          showCount
                          maxLength={1000}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Timezone"
                        name="timezone"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                          },
                        ]}
                        initialValue={
                          localStorage.getItem("userTimezone") ||
                          guessUserTimezone()
                        }
                      >
                        <Select
                          placeholder="Please select a timezone"
                          showSearch
                          filterOption={(input, option) =>
                            option?.children
                              ? (option.children as unknown as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              : false
                          }
                        >
                          {timezoneOptions.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Project Period"
                        name="projectPeriod"
                        rules={[
                          {
                            type: "array" as const,
                            required: true,
                            message: "Please select the project period",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value[0].isBefore(value[1])) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Start date cannot be later than end date",
                                ),
                              );
                            },
                          }),
                        ]}
                      >
                        <RangePicker
                          format={datePickerFormat}
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Project Cover"
                        name="projectCover"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e?.fileList}
                        rules={
                          previewImage
                            ? []
                            : [
                                {
                                  required: true,
                                  message:
                                    "Please upload the image of the project cover",
                                },
                              ]
                        }
                      >
                        <Row justify="center">
                          <Col span={12}>
                            <Upload
                              {...projectUploadProps}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              accept="image/*"
                            >
                              {previewImage ? (
                                <img
                                  src={previewImage}
                                  alt="Project Cover"
                                  className="w-full h-full object-contain"
                                />
                              ) : (
                                uploadProjectCoverButton
                              )}
                            </Upload>
                          </Col>
                          <Col span={12}>
                            <p>Recommended aspect ratio is 16:9.</p>
                            <p>The file size should not exceed 5MB.</p>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("You must accept the agreement"),
                                  ),
                          },
                        ]}
                        wrapperCol={{ offset: 8, span: 16 }}
                      >
                        <Checkbox
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          I agree to{" "}
                          <a
                            className="!text-[#1677ff] hover:underline"
                            href={USER_AGREEMENT_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            User Agreement
                          </a>{" "}
                          and{" "}
                          <a
                            className="!text-[#1677ff] hover:underline"
                            href={PRIVACY_POLICY_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Create>
        </Card>
      </Col>
    </Row>
  );
};
