import { CustomButton, CustomModalV2 } from "@/components/customComponents";
import { useModalConfirm } from "@/hooks";
import { submitContractInfo } from "@/pages/jobs/services";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Form, message } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { SignContractModalProps } from "./type";
import { handleSubmitData } from "./utils";

const modalTitleConf = {
  1: {
    title: "Please fill in the following details for your contract",
    subTitle: "Please ensure the accuracy of the information",
  },
  2: {
    title: "Quotation Sheet",
    subTitle: "Please ensure the accuracy of the information",
  },
  3: {
    title: "Additional Documents",
    subTitle:
      "Once submitted, the contract cannot be changed, please review carefully before submission",
  },
  4: {
    title: "Please read the following content in detail and agree",
    subTitle: "",
  },
};

type CurrentStepType = keyof typeof modalTitleConf;

const SignContractModal: FC<SignContractModalProps> = ({
  open,
  setOpen,
  refreshList,
  applicant,
}) => {
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [currentStep, setCurrentStep] = useState<CurrentStepType>(4);
  const [formData, setFormData] = useState({});
  const [modalConfirm, modalContextHolder] = useModalConfirm();

  useEffect(() => {
    if (!open) {
      setFormData({});
      setCurrentStep(1);
      form1.resetFields();
      form2.resetFields();
      form3.resetFields();
      form4.resetFields();
    }
  }, [open, form1, form2, form3, form4]);

  const forms = useMemo(
    () => ({ form1, form2, form3, form4 }),
    [form1, form2, form3, form4],
  );

  const titleConf = useMemo(
    () => modalTitleConf[currentStep],
    [modalTitleConf[currentStep]],
  );

  const isLastStep = currentStep >= 4;
  // console.log("formData", formData);

  return open ? (
    <>
      {modalContextHolder}
      <CustomModalV2
        {...titleConf}
        open={open}
        classNames={{
          body: "max-h-[50vh] overflow-auto",
        }}
        width={1100}
        destroyOnClose
        footer={
          <div>
            {currentStep > 1 && (
              <CustomButton
                className="mr-5"
                onClick={() => {
                  setCurrentStep((currentStep - 1) as CurrentStepType);
                }}
              >
                Previous
              </CustomButton>
            )}
            <CustomButton
              type="primary"
              icon={isLastStep ? null : <ArrowRightOutlined />}
              onClick={async () => {
                const value =
                  await forms[`form${currentStep}`].validateFields();
                if (isLastStep) {
                  // @ts-ignore
                  modalConfirm({
                    title: "Notice",
                    content:
                      "After submission, we will send you an email with the online contract. Please check and respond promptly.",
                    okText: "Confirm",
                    async onOk() {
                      try {
                        const params = handleSubmitData(formData);
                        await submitContractInfo(applicant.id, params);
                        message.success("Your contract has been submitted.");
                        setOpen(false);
                        refreshList();
                      } catch (error) {
                        message.error(
                          // @ts-ignore
                          error?.errors?.detail || "Failed to submit contract",
                        );
                      }
                    },
                  });
                } else {
                  setFormData((prevData) => ({
                    ...prevData,
                    ...value,
                  }));
                  setCurrentStep((currentStep + 1) as CurrentStepType);
                }
              }}
            >
              {isLastStep ? "Submit" : "Continue"}
            </CustomButton>
          </div>
        }
        onCancel={() => setOpen(false)}
      >
        <div className={`${currentStep === 1 ? "block" : "hidden"} px-[186px]`}>
          <Step1 form={form1} />
        </div>
        <div className={`${currentStep === 2 ? "block" : "hidden"} px-[10px]`}>
          <Step2 form={form2} />
        </div>
        <div className={`${currentStep === 3 ? "block" : "hidden"} px-5`}>
          <Step3 form={form3} />
        </div>
        <div className={`${currentStep === 4 ? "block" : "hidden"} px-5`}>
          <Step4 form={form4} />
        </div>
      </CustomModalV2>
    </>
  ) : null;
};

export default SignContractModal;
