import { CustomInput, CustomSelect } from "@/components/customComponents";
import { useHasCompanyRole } from "@/hooks";
import { generateTimezoneOptions } from "@/utils/date";
import { DatePicker, Form, FormInstance } from "antd";
import { FC, useEffect } from "react";

interface Props {
  form: FormInstance;
}
const timezoneOptions = generateTimezoneOptions();

const Step1: FC<Props> = ({ form }) => {
  const { companyInfo } = useHasCompanyRole();
  useEffect(() => {
    form.setFieldsValue({
      party_a_name: companyInfo?.extended_info?.name,
      party_a_email: companyInfo?.applicant_email,
    });
  }, [form, companyInfo?.extended_info?.name, companyInfo?.applicant_email]);

  useEffect(() => {
    const res = timezoneOptions.find(
      (item) => item.label === "GMT +00:00 - UTC",
    );
    res &&
      form.setFieldsValue({
        timezone: res.value,
      });
  }, [form]);

  return (
    <Form form={form} labelCol={{ span: 6 }}>
      <Form.Item
        label="Party A"
        name="party_a_name"
        rules={[
          {
            required: true,
            message: "Please enter the company name",
          },
          {
            whitespace: true,
            message: "The company name cannot be a blank character",
          },
        ]}
      >
        <CustomInput
          placeholder="Please enter the company name"
          maxLength={100}
        />
      </Form.Item>
      <Form.Item
        label="Party A’s Email"
        name="party_a_email"
        extra="We will send the online contract to this email, so please fill it in accurately."
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "Please enter the company email",
          },
          {
            type: "email",
            message: "Invalid email address",
          },
        ]}
      >
        <CustomInput placeholder="Please enter the company email" />
      </Form.Item>
      <Form.Item
        label="Party B"
        name="party_b_name"
        rules={[
          {
            required: true,
            message: "Please enter the recipient’s real name",
          },
          {
            whitespace: true,
            message: "The recipient’s real name cannot be a blank character",
          },
        ]}
      >
        <CustomInput
          placeholder="Please enter the recipient’s real name"
          maxLength={100}
        />
      </Form.Item>
      <Form.Item
        label="Party B’s Email"
        name="party_b_email"
        extra="A version of the contract will be sent to this email."
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: "Please enter the recipient’s email",
          },
          {
            type: "email",
            message: "Invalid email address",
          },
        ]}
      >
        <CustomInput placeholder="Please enter the recipient’s email." />
      </Form.Item>
      <Form.Item
        label="Timezone"
        name="timezone"
        rules={[
          {
            required: true,
            message: "Please select the timezone",
          },
        ]}
      >
        <CustomSelect
          showSearch
          filterOption={(input, option) =>
            (option?.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={timezoneOptions}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: "Please enter the work start date",
          },
        ]}
        label="Work Time Period"
        name="workDateRange"
      >
        <DatePicker.RangePicker
          className="w-full"
          // showTime
          format={"MMM DD, YYYY HH:mm:ss"}
        />
      </Form.Item>
    </Form>
  );
};

export default Step1;
