import { UserType } from "@/__generated__/graphql";
import {
  CustomInput,
  CustomSegmented,
  CustomTabs,
} from "@/components/customComponents";
import { CustomEmpty } from "@/components/customComponents";
import CardItem from "@/pages/Applications/components/CardItem";
// import {
//   createCompanySpriteAccount,
//   createIndividualSpriteAccount,
//   getIndividualSpriteAccount,
// } from "@/pages/Applications/services";
import {
  ApplGroupEnum,
  AppliedDataType,
  renderAppliedStageOptions,
  renderWaitingFilters,
} from "@/pages/Applications/utils";
import JobDetail from "@/pages/enterprises/components/JobDetail";
import { getPublishedApplications } from "@/pages/users/services";
import { useGlobalStore } from "@/stores";
import { SearchOutlined } from "@ant-design/icons";
import { useInfiniteScroll, useRequest } from "ahooks";
import { Button, Col, Drawer, Result, Row, Spin, message, theme } from "antd";
import { debounce } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const { useToken } = theme;
const PAGE_SIZE = 10;

const ApplicationsApplied: FC = () => {
  const navigate = useNavigate();
  const { currentRole } = useGlobalStore((state) => state);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [applicationsData, setApplicationsData] =
    useState<AppliedDataType | null>(null);
  const [stageVal, setStageVal] = useState(ApplGroupEnum.PENDING);
  const [filterVal, setFilterVal] = useState("");
  const [selectedJobId, setSelectedJobId] = useState("");
  const ref = useRef<HTMLDivElement>(null);
  const curActionInPageRef = useRef(1);
  const { token } = useToken();

  useEffect(() => {
    if (stageVal === ApplGroupEnum.WAITING) setFilterVal("W0");
    else if (stageVal === ApplGroupEnum.SIGNED) setFilterVal("S0");
  }, [stageVal]);

  // useEffect(() => {
  //   getIndividualSpriteAccount().then((res) => {
  //     console.log(100, res);
  //   });
  //   createIndividualSpriteAccount({
  //     refresh_url: `${location.origin}/mktp/third-party-result?businessType=account`,
  //     return_url: `${location.origin}/mktp/third-party-result?businessType=account`,
  //   }).then((res) => {
  //     console.log(111, res);
  //   });
  //   createCompanySpriteAccount({
  //     refresh_url: `${location.origin}/mktp/third-party-result?businessType=account`,
  //     return_url: `${location.origin}/mktp/third-party-result?businessType=account`,
  //   }).then((res) => {
  //     console.log(222, res);
  //   });
  // }, []);

  const {
    loading,
    run: fetchItems,
    params,
  } = useRequest(
    () =>
      getPublishedApplications({
        role: currentRole === UserType.Company ? "Company" : "Individual",
        publised_list_status: stageVal,
        sub_status: filterVal ? parseFloat(filterVal.replace(/[A-Z]/, "")) : 0,
        search: searchKeyword,
      }),
    {
      manual: false,
      refreshDeps: [stageVal, filterVal, searchKeyword],
      onSuccess(res) {
        setApplicationsData(res.data);
      },
      onError(e) {
        message.error(
          // @ts-ignore
          e?.errors?.detail ||
            "An unknown error occurred. Please try again later.",
        );
      },
    },
  );

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  // const {
  //   data: applicationsData,
  //   loading,
  //   loadingMore,
  //   noMore,
  //   mutate,
  //   error: isErrorApplica,
  // } = useInfiniteScroll((d) => (d ? fetchItems(d?.nextPage) : fetchItems(1)), {
  //   target: ref,
  //   isNoMore: (d) => !d?.nextPage,
  //   reloadDeps: [fetchItems],
  // });

  // const fnRefresh = useCallback(async () => {
  //   const curPageNewData = await fetchItems(curActionInPageRef.current);
  //   mutate((previousData) => {
  //     const updatedList = [...(previousData?.list ?? [])];
  //     const curPageStartIndex = (curActionInPageRef.current - 1) * PAGE_SIZE;
  //     updatedList.splice(curPageStartIndex, PAGE_SIZE, ...curPageNewData.list);
  //     return {
  //       ...previousData,
  //       list: updatedList,
  //     };
  //   });
  // }, [fetchItems, mutate]);

  const hasTabs = useMemo(
    () => [ApplGroupEnum.WAITING, ApplGroupEnum.SIGNED].includes(stageVal),
    [stageVal],
  );

  // const handleCurActionInPage = (index: number) => {
  //   const curPage = Math.ceil((index + 1) / PAGE_SIZE);
  //   curActionInPageRef.current = curPage;
  // };
  const onClickCard = (jobId: string) => {
    setSelectedJobId(jobId);
  };

  const onChangeStage = (val: string | number) => {
    setStageVal(val as ApplGroupEnum);
  };

  const onChangeFilters = (val: string) => {
    setFilterVal(val);
  };

  return (
    <Spin spinning={loading}>
      <div className="p-[30px] pr-6 min-h-[calc(100vh-80px)]">
        <div className={`flex ${hasTabs ? "mb-4" : "mb-7"}`}>
          <CustomInput
            roundRadius
            allowClear
            size="large"
            placeholder="Please enter project name、job title"
            prefix={
              <SearchOutlined style={{ color: token.colorTextPlaceholder }} />
            }
            className="w-96 mr-5"
            // value={searchKeyword}
            onChange={debounce((e) => setSearchKeyword(e.target.value), 500)}
          />
          <CustomSegmented
            size="large"
            value={stageVal}
            onChange={onChangeStage}
            options={renderAppliedStageOptions(
              applicationsData as AppliedDataType,
            )}
          />
        </div>
        {hasTabs && (
          <CustomTabs
            items={renderWaitingFilters(
              stageVal,
              applicationsData as AppliedDataType,
            )}
            activeKey={filterVal}
            onChange={onChangeFilters}
          />
        )}
        <div className="relative">
          <Row gutter={[24, 24]}>
            {/* @ts-ignore */}
            {applicationsData?.selected_applications.length > 0 ? (
              applicationsData?.selected_applications.map((x, i) => (
                <Col span={8} key={x.id}>
                  <CardItem
                    type="applied"
                    infoData={x}
                    onClickContainer={onClickCard}
                    refresh={fetchItems}
                  />
                </Col>
              ))
            ) : (
              <CustomEmpty className="mx-auto mt-[100px]" />
            )}
          </Row>
        </div>
        <Drawer
          width={"75%"}
          open={!!selectedJobId}
          onClose={() => setSelectedJobId("")}
        >
          <JobDetail
            id={selectedJobId as string}
            onClose={() => setSelectedJobId("")}
          />
        </Drawer>
      </div>
    </Spin>
  );
};

export default ApplicationsApplied;
