import { PaymentMethodValueType } from "@/components/businessComponents";
import { useJobInfoContext } from "@/contexts/jobInfo";
import { FC } from "react";

interface Props {
  value?: string;
  onSelect: (val: PaymentMethodValueType) => void;
}

const paymentMethodDescs = [
  {
    title: "By Unit",
    value: PaymentMethodValueType.Paperwork,
    desc: "By Deliverables: Your quote is based on final work delivered, for example, $100/second of animation",
  },
  {
    title: "Wage Rate",
    value: PaymentMethodValueType.Time,
    desc: "Quote by hourly /daily /weekly /monthly rates",
  },
  {
    title: "Entire Project",
    value: PaymentMethodValueType.Negotiable,
    desc: "Quote price for the whole project from start to finish",
  },
];

const Step1: FC<Props> = ({ value, onSelect }) => {
  const jobInfo = useJobInfoContext();

  return (
    <div className="flex justify-stretch">
      {paymentMethodDescs.map((item, index) => (
        // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
        <div
          key={item.value}
          className={`relative py-[30px] px-5 overflow-hidden rounded-xl border-[2px] border-solid ${
            value === item.value ? "border-[#00ADAE]" : "border-transparent"
          } ${
            index === paymentMethodDescs.length - 1 ? "" : "mr-4"
          } flex-1 bg-white/[.04] hover:bg-white/[.1] cursor-pointer`}
          onClick={() => onSelect(item.value)}
        >
          <div className="text-2xl mb-5 text-center">{item.title}</div>
          <div className="text-[#999] leading-[18px] mb-9">{item.desc}</div>
          {item.value ===
            (jobInfo?.job_payment_method ?? jobInfo?.payment_method) &&
            jobInfo?.is_preferred_quotation_method && (
              <div
                className={`absolute bottom-0 left-0 w-full ${
                  value === item.value ? "bg-[#00ADAE]" : "bg-white/[.04]"
                } text-base text-center h-12 leading-[48px]`}
              >
                Preferred by the employer
              </div>
            )}
        </div>
      ))}
    </div>
  );
};

export default Step1;
