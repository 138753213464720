import { formatToMiddleStyle } from "@/utils/date";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Image, Row, Space, Typography, Upload } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IProjectExperience } from "../../../interfaces";
import { padLeftImgUrl } from "../../../utils/common";

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const { Text } = Typography;

interface ProjectPreviewCardProps {
  project: IProjectExperience;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

export const ProjectPreviewCard: React.FC<ProjectPreviewCardProps> = ({
  project,
  onDelete,
  onEdit,
}) => {
  return (
    <Card
      className="mb-3"
      bodyStyle={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "8px",
        borderRadius: "8px",
        backgroundColor: "#0000ff30",
      }}
    >
      <Image
        alt="project cover"
        src={padLeftImgUrl(project.cover_url)}
        width={100}
        height={100}
        className="object-cover mr-5"
      />
      <div className="flex flex-col items-start flex-1 pl-4">
        <div className="flex justify-between items-start w-full">
          <Text strong>{project.name}</Text>
          <Space size={12}>
            {onDelete && (
              <DeleteOutlined onClick={() => onDelete(project.id)} />
            )}
            {onEdit && <EditOutlined onClick={() => onEdit(project.id)} />}
          </Space>
        </div>
        <Text className="mb-2">
          {formatToMiddleStyle(project.start_date)} -{" "}
          {project.end_date ? formatToMiddleStyle(project.end_date) : "Present"}
        </Text>
        <Text className="mb-2">{project.role}</Text>
        <Text type="secondary">{project.description}</Text>
      </div>
    </Card>
  );
};
