import {
  CheckOutlined,
  CopyOutlined,
  SmileFilled,
  SmileOutlined,
} from "@ant-design/icons";
import { FilterDropdown, useTable } from "@refinedev/antd";
import { Input, Space, Table, Tag } from "antd";
import { Typography } from "antd";
import React, { useMemo } from "react";

const { Paragraph, Text } = Typography;

import { handleSumaryTotal } from "@/pages/jobs/utils";
import type { ColumnsType } from "antd/es/table";

interface BudgetProps {
  id: number;
  title: string;
  paymentMethod: string;
  pplNum: number;
  quantity: number;
  duration: number;
  unit: string;
  unitPrice: number;
  status: string[];
  total: number;
  currency: string;
}

interface IJobBudgetItem {
  id: number;
  title: string;
  paymentMethod: string;
  pplNum: number;
  quantity: number;
  duration: number;
  unit: string;
  unitPrice: number;
  status: string[];
  total: number | string;
  currency: string;
}

export const BudgetSummaryTable: React.FC<BudgetProps> = (props) => {
  // const { tableProps } = useTable<IJobBudgetItem>({
  //   resource: "budget",
  //   filters: {
  //     initial: [
  //       {
  //         field: "name",
  //         operator: "contains",
  //         value: "",
  //       },
  //     ],
  //   },
  // });

  const columns: ColumnsType<IJobBudgetItem> = useMemo(
    () => [
      {
        title: "Job Title",
        dataIndex: "title",
        key: "title",
        render: (text) => {
          return text === "Summary" ? text : <a>{text}</a>;
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (_, { status }) => (
          <>
            {status.map((tag) => {
              let color = tag.length > 5 ? "geekblue" : "green";
              if (tag === "loser") {
                color = "volcano";
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "pplNum",
        dataIndex: "pplNum",
        key: "pplNum",
        render: (val) => {
          return val !== 0 ? val : "-";
        },
      },
      {
        title: "Payment Method",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        render: (val) => {
          return val !== 0 ? val : "-";
        },
      },
      {
        title: "Duration (Per Person)",
        dataIndex: "duration",
        key: "duration",
        render: (val) => {
          return val !== 0 ? val : "-";
        },
      },
      // {
      //   title: "Unit",
      //   dataIndex: "unit",
      //   key: "unit",
      // },
      // {
      //   title: "Unit Price",
      //   dataIndex: "unitPrice",
      //   key: "unitPrice",
      //   render: (val, row) => {
      //     return val !== 0 ? `${row.currency} ${val}` : "-";
      //   },
      // },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (val, row) => {
          return val !== 0
            ? typeof val === "string"
              ? val
              : `${row.currency} ${val}`
            : "-";
        },
      },
    ],
    [],
  );

  // @ts-ignore
  const existingJobs = (props.jobInDepartment || []) // @ts-ignore
    .map((element, idx) => {
      return {
        id: idx,
        title: element.title,
        paymentMethod:
          element?.payment_method === "Piece-rate"
            ? "Piecework"
            : element?.payment_method === "Time-based"
              ? "Time"
              : "Negotiable",
        pplNum: element?.headcount ?? 0,
        quantity: element?.payment_method === "Piece-rate" ? element?.count : 0,
        duration: element?.payment_method === "Time-based" ? element?.count : 0,
        unit: element?.count_unit,
        unitPrice: element?.rate,
        status: [element?.is_enabled ? "open" : "closed"],
        total:
          element?.payment_method !== "Negotiable"
            ? (element?.count ?? 0) * (element?.rate ?? 0)
            : element.total_cost ?? 0,
        currency: element?.currency,
      };
    });

  const rowsData = useMemo(() => {
    return [
      {
        id: existingJobs.length,
        // title: "Junior Modeler",
        // @ts-ignore
        title: props.editingJobTitle, // @ts-ignore
        paymentMethod: props.paymentMethod, // @ts-ignore
        pplNum: props.pplNum, // @ts-ignore
        quantity: props.quantity, // @ts-ignore
        duration: props.duration, // @ts-ignore
        unit: props.unit, // @ts-ignore
        unitPrice: props.unitPrice, // @ts-ignore
        status: ["Editing"], // @ts-ignore
        total: props.total,
        currency: props.currency,
      },
      ...existingJobs,
    ];
  }, [props, existingJobs]);

  const data: IJobBudgetItem[] = [
    ...rowsData,
    {
      id: existingJobs.length + 1,
      title: "Summary",
      paymentMethod: "",
      pplNum:
        // @ts-ignore
        existingJobs.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.pplNum;
          // @ts-ignore
        }, 0) + parseFloat(props.pplNum),
      quantity: 0,
      duration: 0,
      unit: "",
      unitPrice: 0,
      status: [],
      total: handleSumaryTotal(rowsData),
    },
  ];

  const tableProps = {
    columns: columns,
    dataSource: data,
    scroll: { x: "max-content" },
  };

  return (
    <div style={{ padding: "4px" }}>
      <div className="flex justify-between">
        <h2>Summary</h2>

        <Paragraph
          copyable={{
            icon: [
              <CopyOutlined key="copy-icon" />,
              <CheckOutlined key="copied-icon" />,
            ],
            tooltips: ["* click to copy summary *", "copied!"],
          }}
        >
          Project Total Headcount:{" "}
          {
            // @ts-ignore
            existingJobs.reduce((accumulator, currentValue) => {
              // @ts-ignore
              return accumulator + currentValue.pplNum ?? 0;
              // @ts-ignore
            }, 0) + parseFloat(props.pplNum)
          }
          <span className="mr-10" />
          Project Total Amount:{" "}
          {
            // @ts-ignore
            existingJobs.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.total;
              // @ts-ignore
            }, 0) + parseFloat(props.total)
          }
        </Paragraph>
      </div>

      {/* <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" sorter={{ multiple: 2 }} />
        <Table.Column
          dataIndex="name"
          title="Name"
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Input placeholder="Search by Job Title" />
            </FilterDropdown>
          )}
        />
        <Table.Column
          dataIndex="total"
          title="Total"
          sorter={{ multiple: 1 }}
        />
      </Table> */}

      <Table {...tableProps} />
    </div>
  );
};
