import { IUserInfo, IWorkExperience } from "@/interfaces";
import { formatToMiddleStyle } from "@/utils/date";
import { Col, Row, Typography } from "antd";

interface IArtistResumeProps {
  workExperience: IWorkExperience;
}

export const ResumeWorkExperienceItem: React.FC<IArtistResumeProps> = ({
  workExperience,
}) => {
  return (
    <Row className="flex flex-col">
      <Col className="flex flex-row gap-28">
        <Typography.Text className="text-white">
          {workExperience.title}
        </Typography.Text>
        <Typography.Text className="text-[#686868]">
          {workExperience.department_name
            ? workExperience.city
              ? workExperience.country
                ? `${workExperience.department_name} | ${workExperience.city} | ${workExperience.country}`
                : `${workExperience.department_name} | ${workExperience.city}`
              : workExperience.department_name
            : workExperience.city
              ? workExperience.country
                ? `${workExperience.city} | ${workExperience.country}`
                : workExperience.city
              : workExperience.country}
        </Typography.Text>
      </Col>
      <Typography.Text className="mt-3 text-white">
        {workExperience.company_name}
      </Typography.Text>
      <Typography.Text className="mt-1 text-[#686868]">
        {formatToMiddleStyle(workExperience.start_date)} ~{" "}
        {workExperience.is_current
          ? "Current"
          : formatToMiddleStyle(workExperience.end_date)}
      </Typography.Text>
      <Typography.Text className="mt-4 text-white">
        {workExperience.description}
      </Typography.Text>
    </Row>
  );
};
