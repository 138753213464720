import { useConfigStorage } from "@/hooks/useConfigStorage";
import {
  ExclamationCircleOutlined,
  LeftOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Divider,
  Drawer,
  FloatButton,
  Modal,
  Row,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { Button, Space } from "antd";
import { useRef, useState } from "react";

const { Title } = Typography;

type guideDataType = {
  title: string;
  videoUrl: string;
};
type guideElementType = {
  label: string;
  key: string;
  data: guideDataType[];
};

const configURL =
  "https://leylinedevelop.blob.core.windows.net/instructions/user_guide.json";

const surveryURL = "https://survey.zohopublic.com.cn/zs/mDBF2X";

export const OffLayoutArea = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { data, isError, fetchConfig } = useConfigStorage(configURL);
  const confData = data || [];
  const [openSurvey, setOpenSurvey] = useState(false);
  const [openGuider, setOpenGuider] = useState(false);
  const videoRefs = useRef<HTMLVideoElement[]>([]);

  const closConfirm = () => {
    return modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to leave? You have unsaved changes.",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        setOpenSurvey(false);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showSurvey = () => {
    setOpenSurvey(true);
  };
  const onCloseSurvey = () => {
    closConfirm();
  };
  const showGuider = () => {
    setOpenGuider(true);
    fetchConfig();
  };
  const onCloseGuider = () => {
    for (const v of videoRefs.current) {
      v.pause();
    }
    setOpenGuider(false);
  };

  const onChangeTab = (key: string) => {
    console.log(key);
    for (const v of videoRefs.current) {
      v.pause();
    }
  };

  const tabContent = (itemList: guideDataType[]) => {
    return (
      <div>
        {itemList?.map((item) => (
          <Row key={item.title} className="mt-4">
            <Divider orientation="left" orientationMargin="0">
              {item.title}
            </Divider>
            <video
              ref={(video) => {
                if (video) {
                  videoRefs.current.push(video);
                }
              }}
              muted
              width={"100%"}
              controls
            >
              <source src={item.videoUrl} />
            </video>
          </Row>
        ))}
      </div>
    );
  };
  const getContent = (confData: guideElementType[]): TabsProps["items"] => {
    if (confData.length === 0) return [];
    const itemList = confData?.map((item) => {
      return {
        key: item.key,
        label: item.label,
        children: tabContent(item.data),
      };
    });
    return itemList;
  };

  return (
    <>
      <FloatButton.Group shape="square" style={{ bottom: "70px" }}>
        <FloatButton onClick={showSurvey} />
        <FloatButton icon={<QuestionCircleOutlined />} onClick={showGuider} />
      </FloatButton.Group>
      <>
        <Drawer
          title="Survey"
          placement={"right"}
          width={550}
          onClose={onCloseSurvey}
          open={openSurvey}
          extra={
            <Space>
              <Button onClick={onCloseSurvey}>Cancel</Button>
            </Space>
          }
          destroyOnClose={true}
        >
          <iframe
            src={surveryURL}
            style={{
              height: "100%",
              width: "100%",
              border: 0,
              overflow: "hidden",
            }}
            allow="geolocation"
            title="survey"
            loading="eager"
          />
        </Drawer>
        <Drawer
          title="User's Guide"
          placement={"right"}
          width={550}
          onClose={onCloseGuider}
          open={openGuider}
          extra={
            <Space>
              <Button onClick={onCloseGuider}>Cancel</Button>
            </Space>
          }
          destroyOnClose={true}
        >
          {isError && <div>Something is wrong...</div>}
          <Tabs
            defaultActiveKey="1"
            items={getContent(confData)}
            onChange={onChangeTab}
          />
        </Drawer>
        {contextHolder}
      </>
    </>
  );
};
