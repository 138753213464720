import {
  CompanyAvatar,
  paymentMethodMap,
} from "@/components/businessComponents";
import {
  CustomButton,
  CustomLink,
  CustomTag,
  LocationIcon,
} from "@/components/customComponents";
import { useApplicantButtons } from "@/components/users/hooks/useApplicantButtons";
import { useAppliedButtons } from "@/components/users/hooks/useAppliedButtons";
import {
  ApplicationStatEnum,
  ApplicationStatType,
} from "@/pages/Applications/enum";
import { applicationStatusTagMap } from "@/pages/Applications/utils";
import { ApplicantCardProps, PaymentStatusType } from "@/pages/jobs/type";
import { useGlobalStore } from "@/stores";
import { padLeftImgUrl } from "@/utils/common";
import { formatToLongStyle, formatToMiddleStyle } from "@/utils/date";
import { formatJobDetails } from "@/utils/helper";
import { EditOutlined, MessageOutlined } from "@ant-design/icons";
import { Divider, Image, Space, Typography } from "antd";
import { FC, useMemo } from "react";
import defaultAvatarImg from "/images/defaultAvatar.png";
import projVerifiedImg from "/images/projVerified.png";
import styles from "./index.module.css";

interface Props {
  type: "applied" | "received";
  infoData?: ApplicantCardProps;
  onClickContainer: (id: string) => void;
  refresh: () => void;
}

const { Text, Paragraph } = Typography;
const baseConfigOption = {
  hideTip: false,
  hideTalkBtn: false,
};

const CardItem: FC<Props> = ({ type, infoData, onClickContainer, refresh }) => {
  const { serviceFeeRate } = useGlobalStore((state) => state);
  const { showTipsAndActions, showRemark, viewResume, fnCommunicate } =
    useApplicantButtons(
      infoData,
      refresh,
      baseConfigOption,
      type === "received",
    );
  const [tipElA, buttonsElA, tipButtonsContextA] = showTipsAndActions || [];
  const [tipElB, buttonsElB, tipButtonsContextB] =
    useAppliedButtons(
      infoData,
      refresh,
      baseConfigOption,
      type === "applied",
    ) || [];
  const tagConf = useMemo(() => {
    return infoData?.status
      ? applicationStatusTagMap[infoData.status as ApplicationStatType]
      : {
          text: "",
          textColor: "",
          bgColor: "",
        };
  }, [infoData?.status]);

  const isUrgent = useMemo(() => {
    return (
      infoData?.status === ApplicationStatEnum.CONTRACTING ||
      (type === "applied"
        ? infoData?.status === ApplicationStatEnum.A_SIGNED
        : infoData?.status === ApplicationStatEnum.B_SIGNED) ||
      (infoData?.status === ApplicationStatEnum.COMPLETED &&
        type === "received" &&
        infoData?.contract?.payment_status === PaymentStatusType.UNPAID)
    );
  }, [type, infoData]);
  const serviceFee = useMemo(
    () => ((infoData?.total_cost || 0) * serviceFeeRate).toFixed(2),
    [infoData?.total_cost, serviceFeeRate],
  );
  const realIncome = useMemo(
    () => ((infoData?.total_cost || 0) - parseFloat(serviceFee)).toFixed(2),
    [infoData?.total_cost, serviceFee],
  );
  const isIndividual = infoData?.role === "Individual";

  return infoData ? (
    <div
      className={`${
        isUrgent ? `bg-[#FF9900]/[.06] ${styles.cardItem}` : "bg-white/[0.1]"
      }  cursor-pointer p-5 rounded-xl overflow-hidden relative transition-transform ease-out hover:scale-[1.05]`}
      onClick={() =>
        type === "applied" ? onClickContainer(infoData?.job_id) : viewResume()
      }
      onKeyUp={() => {}}
    >
      <div className="flex justify-between">
        {!!tagConf && (
          <CustomTag
            text={tagConf.text}
            color={tagConf.textColor}
            bgColor={tagConf.bgColor}
          />
        )}
        <span className="text-[#999]">
          {formatToLongStyle(infoData?.created_at)}
        </span>
      </div>
      {type === "applied" ? (
        <>
          <div className="flex items-center pt-[15px] pb-5">
            <Image
              className="rounded-sm object-cover"
              rootClassName="shrink-0"
              preview={false}
              width={94}
              height={70}
              src={padLeftImgUrl(infoData?.project_thumbnail_url)}
            />
            <div className="ml-2.5 w-[calc(100%-100px)]">
              <Text
                className="text-white text-2xl w-full leading-6 mb-3"
                ellipsis
              >
                {infoData?.job_title}
              </Text>
              <div className="flex w-full items-center mb-3">
                {!!infoData?.is_project_verified && (
                  <Image
                    rootClassName="mr-[6px] shrink-0"
                    preview={false}
                    width={24}
                    height={24}
                    src={projVerifiedImg}
                  />
                )}
                <Text
                  className="text-white/[.8] text-sm w-full leading-4"
                  ellipsis
                >
                  {infoData?.project_name}
                </Text>
              </div>
              <div className=" text-[#606264] leading-4">
                {formatJobDetails(
                  infoData?.location,
                  infoData?.remote_option,
                  infoData?.employment_type,
                  "leading-4",
                )}
              </div>
            </div>
          </div>
          <div className="bg-white/[.03] rounded-xl p-5 text-[#999] mb-[10px]">
            <div className="text-white font-medium text-base mb-2">
              Application Details
            </div>
            <div className="mb-2">
              Payment Method: {paymentMethodMap[infoData?.payment_method]}
            </div>
            <div className="mb-2">Headcount: {infoData?.headcount}</div>
            {infoData?.payment_method === "Negotiable" ? (
              <>
                <div className="flex justify-between mb-2">
                  <span>
                    Total: {infoData?.currency || "USD"}&nbsp;
                    {infoData?.total_cost?.toFixed(2)}
                  </span>
                  <span>
                    Service Fee: {infoData?.currency || "USD"}&nbsp;-
                    {serviceFee}
                  </span>
                </div>
                <div className="h-[30px]" />
                <div className="h-[30px]" />
              </>
            ) : (
              <>
                <div className="mb-2">
                  Unit Price: {infoData?.currency || "USD"}&nbsp;
                  {infoData?.rate?.toFixed(2)}
                </div>
                <div className="mb-2">
                  {infoData?.payment_method === "Time-based"
                    ? "Duration (Per Person)"
                    : "Quantity (Total)"}
                  :&nbsp;{infoData?.count} {infoData?.count_unit}
                </div>
                <div className="flex justify-between mb-2">
                  <span>
                    Total: {infoData?.currency || "USD"}&nbsp;
                    {infoData?.total_cost?.toFixed(2)}
                  </span>
                  <span>
                    Service Fee: {infoData?.currency || "USD"}&nbsp;-
                    {serviceFee}
                  </span>
                </div>
              </>
            )}
            <div className="flex justify-between items-end">
              <span>
                {infoData?.answer_url ? (
                  <CustomLink href={padLeftImgUrl(infoData?.answer_url)}>
                    View test
                  </CustomLink>
                ) : null}
              </span>
              <span>
                You’ll Receive:{" "}
                <span className="text-[#00ADAE] text-xl font-bold">
                  {infoData?.currency || "USD"}&nbsp;{realIncome}
                </span>
              </span>
            </div>
          </div>
          <div className="bg-white/[.03] rounded-xl py-3 px-6 mb-[30px]">
            {tipElB}
          </div>
          {buttonsElB || <div className="h-[32px]" />}
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div onClick={(e) => e.stopPropagation()}>{tipButtonsContextB}</div>
        </>
      ) : (
        <>
          <div className="flex items-center pt-[15px] pb-5">
            {isIndividual ? (
              <Image
                className="rounded-full object-cover"
                rootClassName="shrink-0"
                preview={false}
                width={70}
                height={70}
                src={padLeftImgUrl(infoData?.avatar_url) || defaultAvatarImg}
              />
            ) : (
              <CompanyAvatar
                size="large"
                is_verified={infoData?.is_company_verified}
                name={infoData?.company_name}
                shortname={infoData?.company_shortname as string}
                logo_url={infoData?.company_logo_url}
                className="mr-4"
              />
            )}

            <div className="ml-2.5 w-[calc(100%-80px)]">
              <div className="flex justify-between">
                <Text
                  className="text-white text-2xl w-full leading-6 mb-3"
                  ellipsis
                >
                  {isIndividual
                    ? infoData?.user_name
                    : infoData?.company_shortname}
                </Text>
                <CustomButton
                  size="small"
                  className="shrink-0 !px-4"
                  icon={<MessageOutlined />}
                  type="primary"
                  onClick={fnCommunicate}
                >
                  Message
                </CustomButton>
              </div>
              <Text
                className="text-white/[.8] text-sm w-full leading-4 mb-3"
                ellipsis
              >
                {isIndividual
                  ? infoData?.introduction || "No Introduction"
                  : `${infoData?.company_industry || "--"} | ${
                      infoData?.company_scale
                    } Employees`}
              </Text>
              <div className="text-[#606264] leading-4 flex items-center">
                <LocationIcon />
                {infoData?.city || "No City"}
              </div>
            </div>
          </div>
          <div className="bg-white/[.03] rounded-xl p-5 text-[#999] mb-[10px]">
            <div className="text-white font-medium text-base mb-2">
              Application Details
            </div>
            <div className="mb-2">
              Payment Method: {paymentMethodMap[infoData?.payment_method]}
            </div>
            <div className="mb-2">Headcount: {infoData?.headcount}</div>
            {infoData?.payment_method === "Negotiable" ? (
              <>
                <div className="h-[30px]" />
                <div className="h-[30px]" />
              </>
            ) : (
              <>
                <div className="mb-2">
                  Unit Price: {infoData?.currency || "USD"}&nbsp;
                  {infoData?.rate?.toFixed(2)}
                </div>
                <div className="mb-2">
                  {infoData?.payment_method === "Time-based"
                    ? "Duration (Per Person)"
                    : "Quantity (Total)"}
                  :&nbsp;{infoData?.count} {infoData?.count_unit}
                </div>
              </>
            )}
            <div className="flex justify-between items-end">
              <span>
                {infoData?.answer_url ? (
                  <CustomLink href={padLeftImgUrl(infoData?.answer_url)}>
                    View test
                  </CustomLink>
                ) : (
                  "No answer submitted"
                )}
              </span>
              <span>
                Total:{" "}
                <span className="text-[#00ADAE] text-xl font-bold">
                  {infoData?.currency || "USD"}&nbsp;
                  {infoData?.total_cost?.toFixed(2)}
                </span>
              </span>
            </div>
          </div>
          <div className="bg-white/[.03] rounded-xl py-3 px-6 text-[#999] mb-[10px]">
            <div className="flex justify-between mb-4">
              <span className="text-white text-base">Remark</span>
              <EditOutlined
                className="cursor-pointer hover:text-white/[.8]"
                onClick={(e) => {
                  e.stopPropagation();
                  showRemark();
                }}
              />
            </div>
            {infoData?.memo_remark ? (
              <Paragraph
                className="leading-5 !mb-0 text-center text-[#999] min-h-10"
                ellipsis={{
                  rows: 2,
                  tooltip: infoData?.memo_remark,
                }}
              >
                {infoData?.memo_remark}
              </Paragraph>
            ) : (
              <div className="h-10 text-center">No remark</div>
            )}
          </div>
          <div className="bg-white/[.03] rounded-xl py-3 px-6 mb-[30px]">
            {tipElA}
          </div>
          {buttonsElA || <div className="h-[32px]" />}
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div onClick={(e) => e.stopPropagation()}>{tipButtonsContextA}</div>
        </>
      )}
    </div>
  ) : null;
};

export default CardItem;
