import React from "react";

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

interface CoverProps {
  useXL: boolean;
  thumbnail: string;
}

// @ts-ignore
export const JobCoverInList: React.FC = (props: CoverProps) => {
  return props.useXL ? (
    <img
      alt="job cover"
      style={{
        maxHeight: "11rem",
        minWidth: "22rem",
        maxWidth: "22rem",
        objectFit: "cover",
      }}
      className="h-full !w-auto !rounded-l-lg !rounded-r-none"
      src={IMG_PREFIX + props.thumbnail}
    />
  ) : (
    <img
      alt="job cover"
      className="w-full"
      src={IMG_PREFIX + props.thumbnail}
    />
  );
};
