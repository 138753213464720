import { NoDataIcon } from "@/components/customComponents/CustomIcons";
import AuthorItem from "@/components/discover/homepage/AuthorItem";
import { IIdentity } from "@/interfaces";
import { getAuthorsListAPI, getAuthorsListAuthAPI } from "@/pages/discover/api";
import { ISearchAuthorLIst } from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import Icon from "@ant-design/icons";
import { useGetIdentity, useOne } from "@refinedev/core";
import { useInfiniteScroll } from "ahooks";
import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface IAuthorProps {
  authorFilter: string;
  containerRef: React.RefObject<HTMLDivElement>;
}

const Author: React.FC<IAuthorProps> = ({ authorFilter, containerRef }) => {
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const { data: user } = useGetIdentity<IIdentity>();
  const { data, isLoading } = useOne({
    resource: "users",
    id: "profile",
    queryOptions: {
      enabled: !!user?.id,
    },
  });
  const [searchAuthorData, setSearchAuthorData] = useState<ISearchAuthorLIst>({
    size: 10,
    page: 1,
  });

  useEffect(() => {
    setSearchAuthorData((prevData) => ({
      ...prevData,
      filterBy:
        authorFilter === "Artist"
          ? "INDIVIDUAL"
          : authorFilter === "Studio"
            ? "COMPANY"
            : undefined,
    }));
  }, [authorFilter]);

  const getAuthorList = async (page: number) => {
    if (!currentRole) return { list: [], nextPage: undefined };
    const response = !data
      ? await getAuthorsListAPI(updateLoading, { ...searchAuthorData, page })
      : await getAuthorsListAuthAPI(updateLoading, currentRole, {
          ...searchAuthorData,
          page,
        });

    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll((d) => getAuthorList(d ? d.nextPage : 1), {
    target: containerRef,
    isNoMore: (d) => !d?.nextPage,
    reloadDeps: [currentRole, searchAuthorData],
  });

  return (
    <div>
      {infiniteData?.list.length === 0 ? (
        <div className="flex flex-col justify-center items-center w-full h-full pt-16">
          <Icon className="text-[200px]" component={NoDataIcon} />
          <Typography.Text className="text-[#686868] text-base">
            No Data
          </Typography.Text>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {infiniteData?.list.map((author) => (
            <AuthorItem
              key={author.id + author.role}
              authorItem={author}
              comState="author"
              stopPropagation
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Author;
