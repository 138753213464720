import { IArtworkItem } from "@/pages/discover/types";
import { formatToLongNoSecondStyle } from "@/utils/date";
import { CloseCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";

interface IArtworkViolationPromptProps {
  artwork: IArtworkItem;
}

export const ArtworkViolationPrompt: React.FC<IArtworkViolationPromptProps> = ({
  artwork,
}) => {
  return (
    <Row
      className={`border-solid border-[1px] rounded-sm gap-2.5 px-4 py-3 mb-10 ${
        artwork.violation_status === "DENIED"
          ? "border-[#F55F4E] bg-[#FEEFEE]"
          : "border-[#F5894E] bg-[#FEF4EE]"
      }`}
    >
      <Col className="pt-0.5">
        {artwork.violation_status === "DENIED" ? (
          <CloseCircleFilled className="text-[#F55F4E] text-lg" />
        ) : (
          <ExclamationCircleFilled className="text-[#F5894E] text-lg" />
        )}
      </Col>
      <Col className="flex flex-col">
        <Typography.Text
          className={`text-base font-bold ${
            artwork.violation_status === "DENIED"
              ? "text-[#F55F4E]"
              : "text-[#F5894E]"
          }`}
        >
          {artwork?.violation_status === "DENIED"
            ? "Violated"
            : "You have resubmitted, please wait for platform approval"}
        </Typography.Text>
        <Typography.Text className="text-black text-base">
          Platform feedback: {artwork?.violation?.comments}
        </Typography.Text>
        <Typography.Text className="text-black text-base">
          {formatToLongNoSecondStyle(artwork?.violation?.reviewed_at)}
        </Typography.Text>
        <Typography.Text className="text-black text-base">
          Tip: Violated artworks can not be published, please modify in time
        </Typography.Text>
      </Col>
    </Row>
  );
};
