import * as AllIcons from "@/components/customComponents/CustomIcons";
import { Space, message } from "antd";

export default function () {
  const arr = [];
  for (const compName in AllIcons) {
    // @ts-ignore
    const CompContent = AllIcons[compName];
    arr.push({ name: compName, el: <CompContent /> });
  }
  return (
    <div className="min-h-screen bg-[#333]">
      <Space size={20} wrap>
        {arr.map((item) => (
          <div
            key={item.name}
            className="text-center cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(item.name);
              message.success(
                `The component name <${item.name}> has been copied to the clipboard！`,
              );
            }}
            onKeyUp={() => {}}
          >
            <div className="text-white">{item.name}</div>
            {item.el}
          </div>
        ))}
      </Space>
    </div>
  );
}
