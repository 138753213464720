import { getPublishedJobs } from "@/pages/jobs/services";
import { useEffect, useState } from "react";

interface Option {
  label: string;
  value: string;
  children?: Option[];
}

const useJobsOptions = () => {
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    getPublishedJobs({
      page: 1,
      size: 10000,
      name: "",
    }).then((res) => {
      const arr: Option[] = [];
      const arr2: string[] = [];
      // biome-ignore lint/complexity/noForEach: <explanation>
      res.data.items.forEach(
        (item: {
          project_name: string;
          project_id: string;
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          id: any;
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          title: any;
        }) => {
          const index = arr2.findIndex((x) => x === item.project_name);
          if (index === -1) {
            arr2.push(item.project_name);
            arr.push({
              value: item.project_id,
              label: item.project_name,
              children: [
                {
                  value: item.id,
                  label: item.title,
                },
              ],
            });
          } else {
            arr[index].children?.push({
              value: item.id,
              label: item.title,
            });
          }
        },
      );
      setOptions(arr);
    });
  }, []);

  return options;
};

export default useJobsOptions;
