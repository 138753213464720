import { IIdentity } from "@/interfaces";
import { useGlobalStore } from "@/stores";
import { CheckCircleOutlined, RightOutlined } from "@ant-design/icons";
import { useGetIdentity } from "@refinedev/core";
import { Col, Row } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IPortfolioActionAlarmProps {
  actionType: string;
  setBookmarkState: (value: boolean) => void;
  bookmarkTitle: string;
}

export const PortfolioActionAlarm: React.FC<IPortfolioActionAlarmProps> = ({
  actionType,
  setBookmarkState,
  bookmarkTitle,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const navigate = useNavigate();
  const { data: user } = useGetIdentity<IIdentity>();
  const user_id = user?.id || "";
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const getMessage = (actionType: string) => {
    switch (actionType) {
      case "like":
        return "Liked successfully";
      case "collection":
        return "Successful collection";
      case "link":
        return "Link copied successfully";
      case "bookmark":
        return `Added to ${bookmarkTitle}`;
      default:
        return "";
    }
  };

  return (
    <Row className="px-4 py-3 rounded-full bg-[#343638] shadow-md mt-2 flex flex-row justify-between">
      <Col className="flex flex-row gap-[5px] justify-center text-white text-sm">
        <CheckCircleOutlined />
        <span>{getMessage(actionType)}</span>
      </Col>
      {actionType === "collection" && (
        <Col
          className="flex flex-row gap-1 text-opacity-50 text-white"
          onClick={() => setBookmarkState(true)}
        >
          <span>Added to bookmarks</span>
          <RightOutlined />
        </Col>
      )}
      {actionType === "bookmark" && (
        <Col
          className="flex flex-row gap-1 text-opacity-50 text-white cursor-pointer"
          onClick={() =>
            navigate(`/discover/author/${user_id}?role=${currentRole}`)
          }
        >
          <span>View</span>
          <RightOutlined />
        </Col>
      )}
    </Row>
  );
};
