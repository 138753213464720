import type { Location } from "@remix-run/router";
import { useEffect, useRef } from "react";

export function usePrevious(value: Location) {
  const ref = useRef<Location>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
