import { AuthPageProps } from "@refinedev/core";
import { CardProps, FormProps, LayoutProps } from "antd";
import React from "react";

import {
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  UpdatePasswordPage,
} from "../../authCore/components";
import { CompleteUserInfo } from "./CompleteUserInfo";

export type AuthProps = AuthPageProps<LayoutProps, CardProps, FormProps> & {
  renderContent?: (
    content: React.ReactNode,
    title: React.ReactNode,
  ) => React.ReactNode;
  title?: React.ReactNode;
};

export const CompleteAccountPage: React.FC<AuthProps> = (props) => {
  const authWrapperProps = {
    className: "auth-wrapper", // Add custom class and Tailwind utilities
  };

  const renderAuthContent = (content: React.ReactNode) => {
    return <div className="mx-auto">{content}</div>;
  };

  return (
    <CompleteUserInfo
      {...props}
      wrapperProps={authWrapperProps}
      renderContent={renderAuthContent}
    />
  );
};
