import UploadOrInput from "@/pages/enterprises/components/UploadOrInput";
import { Divider, Form, FormInstance, Image, InputNumber, Switch } from "antd";
import { FC, ReactNode, useEffect, useState } from "react";

interface Props {
  form: FormInstance;
}

const Step3: FC<Props> = ({ form }) => {
  useEffect(() => {
    form.setFieldsValue({
      testType: "upload",
    });
  }, [form]);

  return (
    <div>
      <Form
        form={form}
        initialValues={{ is_visible_to_public: true }}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
      >
        <UploadOrInput
          typeFieldName="testType"
          typeFieldLabel="Test"
          uploadProps={{
            // accept: "image/*,video/*",
            limitSize: 10,
            limitDesc: "The file should not exceed 10MB.",
          }}
          fileFieldName="testFile"
          linkFieldName="testLink"
        />
        <Form.Item
          label="Application visible to Public"
          name="is_visible_to_public"
          required
          extra="Your application is set to be publicly visible by default. If you do not wish to display your application to the public, please turn off this option."
        >
          <Switch />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step3;
