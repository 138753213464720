import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const CHAT_GQL_URL = import.meta.env.VITE_CHAT_GQL_URL;

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("leyline-auth");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const httpLink = new HttpLink({ uri: CHAT_GQL_URL });
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;
