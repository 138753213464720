// fileTypeUtil.ts
export const imageExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "webp",
  "tif",
  "tiff",
  "jfif",
];

export const videoExtensions = [
  "mp4",
  "webm",
  "ogg",
  "mov",
  "avi",
  "mkv",
  "flv",
  "wmv",
  "m4v",
  "mpeg",
  "mpg",
];

export const getFileExtension = (filename: string): string | undefined => {
  const parts = filename.split(".");
  return parts.length > 1 ? parts.pop()?.toLowerCase() : undefined;
};

export const isImageFiles = (filename: string): boolean => {
  const extension = getFileExtension(filename);
  return extension ? imageExtensions.includes(extension) : false;
};

export const isVideoFile = (filename: string): boolean => {
  const extension = getFileExtension(filename);
  return extension ? videoExtensions.includes(extension) : false;
};
