import { LeylineArtists } from "@/components/ai/LeylineArtists";
import { Subscribe } from "@/components/ai/Subscribe";
import { ProducerBanner } from "@/components/producerTool/ProducerBanner";
import { ProducerCards } from "@/components/producerTool/ProducerCards";
import { ProducerMask } from "@/components/producerTool/ProducerMask";
import React from "react";

export const ProducerTool: React.FC = () => {
  return (
    <div>
      <ProducerBanner />
      <ProducerCards />
      <ProducerMask />
      <LeylineArtists />
      <Subscribe />
    </div>
  );
};
