import { UserType } from "@/__generated__/graphql";
import { CompanyIcon, DefaultUserIcon } from "@/components/customComponents";
import { ICompany, ILikeNotificationData, IUser } from "@/pages/discover/types";
import { useArtworkStore } from "@/stores";
import { getImgUrl } from "@/utils/common";
import { formatToLongStyle } from "@/utils/date";
import { Avatar, Badge, Col, Image, Row, Space, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

interface ILikeCollectionDrawerItemProps {
  commentItem: ILikeNotificationData;
}
// LikeCollectionDrawerItem component using mock data
export const LikeCollectionDrawerItem: React.FC<
  ILikeCollectionDrawerItemProps
> = ({ commentItem }) => {
  const { updateArtworkDetailArtworkId, updateArtworkDetailModalVisible } =
    useArtworkStore((state) => state);

  const goToArtworkDetail = (id: string) => {
    updateArtworkDetailArtworkId(id);
    updateArtworkDetailModalVisible(true);
  };
  return (
    <Row className="flex flex-1 w-full justify-between gap-2 px-[15px]">
      <Row className="flex flex-row gap-2.5 flex-1">
        <Link
          to={`/discover/author/${commentItem.target.user_id}?role=${commentItem.target.role}`}
        >
          <div className="relative">
            <Avatar
              className={`object-cover ${
                commentItem.target.role === UserType.Company &&
                "border-2 border-solid border-[#03B3B4]"
              }`}
              size={40}
              src={
                commentItem.target.role === UserType.Company ? (
                  (commentItem.author_info as ICompany).logo_url ? (
                    getImgUrl((commentItem.author_info as ICompany).logo_url)
                  ) : (
                    <DefaultUserIcon />
                  )
                ) : (commentItem.author_info as IUser).avatar_url ? (
                  getImgUrl((commentItem.author_info as IUser).avatar_url)
                ) : (
                  <DefaultUserIcon />
                )
              }
            />
            {commentItem.target.role === UserType.Company && (
              <Space className="absolute z-10 left-1/2 top-[-18%] transform -translate-x-1/2">
                <CompanyIcon width="16px" height="16px" />
              </Space>
            )}
          </div>
        </Link>
        <Row className="flex flex-col flex-1">
          <Col className="flex flex-row justify-between">
            <Link
              to={`/discover/author/${commentItem.target.user_id}?role=${commentItem.target.role}`}
            >
              <Typography.Text className="text-white text-opacity-85 text-base">
                {commentItem.target.role === UserType.Company
                  ? (commentItem.author_info as ICompany).shortname
                  : (commentItem.author_info as IUser).user_name}
              </Typography.Text>
            </Link>
          </Col>
          <Typography.Paragraph
            className="text-[#606264] text-sm w-full !mb-0.5"
            ellipsis={{ rows: 1 }}
          >
            {commentItem.message} {formatToLongStyle(commentItem.created_at)}
          </Typography.Paragraph>
          {(commentItem.message === "Liked your comment" ||
            commentItem.message === "Liked your reply") && (
            <Typography.Paragraph
              className="text-[#606264] text-sm w-full !mb-0.5 flex gap-2"
              ellipsis={{ rows: 1 }}
            >
              <Badge color="white" />
              <span>{commentItem.target.comment}</span>
            </Typography.Paragraph>
          )}
        </Row>
      </Row>
      <Row className="pl-16">
        <Col onClick={() => goToArtworkDetail(commentItem.artwork.id || "")}>
          <Image
            preview={false}
            width="48px"
            height="42px"
            className="rounded-sm"
            src={
              getImgUrl(commentItem.artwork.cover_picture) ||
              "https://placehold.co/64x64"
            }
          />
        </Col>
      </Row>
    </Row>
  );
};
