import { Sider } from "@/components/sider";
import { RefineThemedLayoutV2Props } from "@refinedev/antd";
import { Col, Row } from "antd";
import React from "react";

export const PersonalSider: React.FC<RefineThemedLayoutV2Props> = ({
  children,
}) => {
  return (
    <Row className="flex flex-row flex-1">
      <Col className="flex w-[323px] bg-[#141618] overflow-auto h-[calc(100vh-80px)]">
        <Sider />
      </Col>
      <Col className="flex-1 h-[calc(100vh-80px)] overflow-auto">
        {children}
      </Col>
    </Row>
  );
};
