import { ConfigProvider, Select, SelectProps } from "antd";
import styles from "./styles/select.module.css";

interface Props extends SelectProps {
  roundRadius?: boolean;
  lightBg?: boolean;
}

export const CustomSelect = ({
  roundRadius = false,
  lightBg = false,
  ...props
}: Props) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            borderRadiusSM: roundRadius ? 12 : 0,
            borderRadius: roundRadius ? 18 : 2,
            borderRadiusLG: roundRadius ? 20 : 4,
            ...(lightBg ? { selectorBg: "rgba(255,255,255,.1)" } : {}),
            // colorText: "#FFFFFF",
            // multipleItemBg: "#333333",
            // controlItemBgHover: "#666666",
            // controlItemBgActive: "#333333",
            // colorIcon: "#606264",
            // optionSelectedColor: "#fff",
          },
        },
      }}
    >
      <Select
        rootClassName={styles.selectWrapper}
        style={
          {
            // background: "#292D31",
            // border: 0,
            // borderRadius: "2px",
            // width: "400px",
          }
        }
        popupClassName={`${props.popupClassName} ${
          roundRadius ? "roundRadius" : ""
        }`}
        // dropdownStyle={{ background: "#292D31" }}
        {...props}
      />
    </ConfigProvider>
  );
};
