import {
  CustomInput,
  CustomModalV2,
  CustomTag,
} from "@/components/customComponents";
import { useModalConfirm } from "@/hooks";
import { IResourceComponentsProps } from "@refinedev/core";
import { Input, Modal, Space, Tag } from "antd";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useConfigProvider } from "../../contexts";

export const JobUnmatchModal: React.FC<IResourceComponentsProps> = ({
  // @ts-ignore
  type, // @ts-ignore
  open, // @ts-ignore
  onOk, // @ts-ignore
  onCancel,
}) => {
  const { mode, setMode } = useConfigProvider();
  const [modalConfirm, modalContextHolder] = useModalConfirm();
  const isRefuseType = type === "refuse";

  const unmatchReasons = isRefuseType
    ? [
        "Fee mismatch",
        "Job description mismatch",
        "Job requirements mismatch",
        // "Contract content mismatch",
        "Other reasons",
      ]
    : [
        "Fee mismatch",
        "Industry mismatch",
        "Skill mismatch",
        "Software mismatch",
        "Work model mismatch",
        "Job type mismatch",
        "Information not accurate",
        "Other reasons",
      ];

  const [tagSelected, setTagSelected] = useState<string>("");
  const [remark, setRemark] = useState<string>("");

  const tagClasses = "hover: cursor-pointer mt-1 mb-1";
  // @ts-ignore
  const ReasonTag = ({ children, clicked, setClicked }) => {
    return (
      <CustomTag
        text={children}
        color={clicked ? undefined : "#999"}
        bgColor={clicked ? undefined : "rgba(153, 153, 153, 0.1)"}
        className={tagClasses}
        onClick={() => {
          setClicked(!clicked);
        }}
      />
    );
  };

  const warn = (text: string) => {
    if (mode === "light") {
      toast(text, {
        icon: "⚠️",
      });
    } else {
      toast(text, {
        icon: "⚠️",
        style: {
          background: "#333",
          color: "#fff",
        },
      });
    }
  };

  // @ts-ignore
  const tags = [];
  unmatchReasons.forEach((reason, idx) => {
    tags.push(
      // biome-ignore lint:
      <React.Fragment key={idx}>
        <ReasonTag
          clicked={tagSelected === reason} // @ts-ignore
          setClicked={(val) => {
            if (val) {
              setTagSelected(reason);
            } else {
              setTagSelected("");
            }
          }}
        >
          {reason}
        </ReasonTag>
      </React.Fragment>,
    );
  });

  const modalTitle = isRefuseType
    ? "Do you want to reject the contract?"
    : "Do you want to reject the application?";
  const modalSubTitle = isRefuseType ? (
    <span>
      If you see any discrepancies from your previous negotiations, we suggest
      you go back to <br />
      communicate with the other party.
      <br />
      Once you reject, you will not be able to apply for this job again.
    </span>
  ) : (
    "We can better target our candidates for your projects."
  );
  const contentDesc = isRefuseType
    ? "Please select the reason for rejection."
    : "Please select the reason for rejection.";
  return (
    <>
      <CustomModalV2
        title={modalTitle}
        subTitle={modalSubTitle}
        open={open}
        width={900}
        okButtonProps={{
          disabled:
            !tagSelected ||
            (tagSelected === "Other reasons" && !remark.replaceAll(" ", "")),
        }}
        okText={isRefuseType ? "Reject this contract" : "Confirm"}
        onOk={() => {
          if (isRefuseType) {
            // @ts-ignore
            modalConfirm({
              title: "Are you sure you want to reject the contract? ",
              content: (
                <span>
                  If you see any discrepancies from your previous negotiations,
                  we suggest you go back to communicate with the other party.
                  <br />
                  <br />
                  Once you reject, you will not be able to apply for this job
                  again.
                </span>
              ),
              okText: "Confirm",
              onOk: () => onOk(tagSelected, remark || undefined),
            });
          } else {
            onOk(tagSelected, remark || undefined);
          }
        }}
        onCancel={onCancel}
      >
        <p className="text-center">{contentDesc}</p>
        <Space wrap size={20}>
          {
            // @ts-ignore
            tags
          }
        </Space>
        {tagSelected === "Other reasons" && (
          <CustomInput.TextArea
            className="mt-7"
            value={remark}
            maxLength={1000}
            placeholder="Please enter the reason for rejection"
            autoSize={{ minRows: 4, maxRows: 10 }}
            onChange={(e) => setRemark(e.target.value)}
          />
        )}
      </CustomModalV2>
      {modalContextHolder}
    </>
  );
};
