import { Typography, Upload, message } from "antd";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import React from "react";

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL || "";
const STATIC_ASSETS_BASE_URL =
  import.meta.env.VITE_STATIC_ASSETS_BASE_URL || "";
const TOKEN_KEY = import.meta.env.VITE_AUTH_TOKEN_KEY;
const VITE_PT_WEB_BASE = import.meta.env.VITE_PT_WEB_BASE;
const { Text } = Typography;
export function fileObjectToResouceId(
  fileObject: UploadFile<{ id: string }>,
): string | undefined {
  if (!fileObject) {
    return undefined;
  }

  if (fileObject.url && typeof fileObject.url === "string") {
    const id = fileObject?.url?.replace(IMG_PREFIX, "");
    return id;
  }

  if (fileObject?.response?.id) {
    return fileObject.response.id;
  }

  return undefined;
}

export function handleFileObjectArray(
  fileObjects: UploadFile<{ id: string }>[],
): (string | undefined)[] {
  return fileObjects.map((fileObject) => fileObjectToResouceId(fileObject));
}

export const fileSizeLessThan = (sizeMB = 5) => {
  return (file: RcFile) => {
    const fileSizeLimit = sizeMB;
    if (file.size / 1024 / 1024 > fileSizeLimit) {
      message.error(`Image must be smaller than ${fileSizeLimit}MB.`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };
};

export const ptLinkWithState = (tk = localStorage.getItem(TOKEN_KEY)) => {
  if (!tk) return ptLink("projects");
  const url = import.meta.env.DEV
    ? `http://localhost:3100/pt/landing?tk=${tk}&redirect=/projects`
    : `${VITE_PT_WEB_BASE}landing?tk=${tk}&redirect=/projects`;
  return url;
};

export const ptLink = (path: string) => {
  const url = import.meta.env.DEV
    ? `http://localhost:3100/pt/${path}`
    : `${VITE_PT_WEB_BASE}${path}`;
  return url;
};

export const formatJobDetails = (
  location: string,
  remoteOption: string,
  employmentType: string,
  className?: string,
) => {
  const details = [];
  if (location)
    details.push(
      <Text
        ellipsis
        className={`!max-w-[calc(100%-160px)] text-[#606264] ${
          className ?? ""
        }`}
      >
        {location}
      </Text>,
    );
  if (remoteOption) details.push(remoteOption);
  if (employmentType) details.push(employmentType);

  return (
    <>
      {details[0]} / {`${details[1] ?? "-"} / ${details[2] ?? "-"}`}
    </>
  );
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getLogoUrl = (): string => {
  return `${STATIC_ASSETS_BASE_URL}media/leylinepro_logo.jpeg`;
};
