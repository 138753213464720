import { ConfigProvider, Pagination, PaginationProps } from "antd";
import styles from "./styles/pagination.module.css";

export const CustomPagination = ({ ...props }: PaginationProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 2,
          colorBgTextHover: "rgba(255, 255, 255, 0.20)",
        },
      }}
    >
      <Pagination rootClassName={styles.paginationWrap} {...props} />
    </ConfigProvider>
  );
};
