import { SettleStatusType, SettlementDataRecord } from "@/interfaces";
import { axiosInstance } from "@/utils/http";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useInvalidate, useUpdate } from "@refinedev/core";
import { Button, Modal, Typography, message } from "antd";
import React, { useState } from "react";
// import EditableCell from "@/components/editableCell";
import { useSettlementContext } from "../../../contexts/settlement";
import { SettlementWarningModal } from "./SettlementWarningModal";

const { Text } = Typography;

enum RoleType {
  PRODUCER = "producer",
  CONTRACTOR = "contractor",
}

interface DrawerFooterProps {
  role: RoleType;
  option: string;
  record: SettlementDataRecord | null;
  onClose: () => void;
}

interface ButtonProps {
  key: string;
  onClick: () => void;
  className: string;
  type?: "primary";
}

export const DrawerFooter: React.FC<DrawerFooterProps> = ({
  role,
  record,
  onClose,
}) => {
  const { mutate } = useUpdate();
  const apiUrl = import.meta.env.VITE_PRODUCER_TOOL_API_BASE_URL;
  const TOKEN_KEY = import.meta.env.VITE_AUTH_TOKEN_KEY;
  const token = localStorage.getItem(TOKEN_KEY);

  const buttonConfig = {
    // status: actionLabelList
    producer: {
      settlement: ["Submit"],
      confirmation: ["Update"],
      processing: ["Update"],
      submission: ["Submit payment"],
      payment: ["Payment"],
    },
    contractor: {
      confirmation: ["Disagree", "Confirm"],
    },
  };
  const statusConfig: Record<
    RoleType,
    Record<string, Record<string, SettleStatusType>>
  > = {
    producer: {
      settlement: {
        Submit: "confirmation",
      },
      confirmation: {
        Update: "confirmation",
      },
      processing: {
        Update: "confirmation",
      },
      submission: {
        "Submit payment": "payment",
      },
      payment: {
        Payment: "settled",
      },
    },
    contractor: {
      confirmation: {
        Disagree: "confirmation",
        Confirm: "submission",
      },
    },
  };

  const fetchStatus = async () => {
    try {
      const response = await axiosInstance.get(
        `${apiUrl}/settlements/${record?.id}/status`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching status:", error);
      return null;
    }
  };

  const getButtons = (role: string, status: string) => {
    const roleConfig =
      role === "producer" ? buttonConfig.producer : buttonConfig.contractor;
    const actions = (roleConfig[status as keyof typeof roleConfig] ||
      []) as string[];
    return actions.map((action: string) => {
      const buttonProps: Partial<ButtonProps> = {
        key: action,
        onClick: () => {
          confirmPayment(action);
        },
        className: action === "Disagree" ? "mr-4" : "",
      };

      if (action !== "Disagree") {
        buttonProps.type = "primary";
      }

      return <Button {...buttonProps}>{action}</Button>;
    });
  };

  const { settlementDetails, status } = useSettlementContext() ?? {};

  const dataToSend = {
    tasks: settlementDetails?.task_details.map((task) => ({
      pending_hour: task.pending_hour,
      pending_cost: task.pending_cost,
      act_workhour: task.act_workhour,
      dept_id: task.dept_id,
      ...(task.id && { id: task.id }),
    })),
    pending_cost: settlementDetails?.pending_cost ?? 0,
    pending_duration: settlementDetails?.pending_duration ?? 0,
    status: status,
  };

  console.log(dataToSend);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    role: "",
    status: { status: "", is_in_progress: false },
    action: "",
  });

  const [showObjection, setShowObjection] = useState(false);
  const [modal, contextHolder] = Modal.useModal();

  const confirmPayment = (action: string) => {
    return modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure the payment has been completed?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        handleSubmit(action);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const submitProducerSettlementUpdate = (dataToSend: any) => {
    if (record?.id) {
      mutate(
        {
          resource: "settlements",
          id: record.id,
          values: dataToSend,
          dataProviderName: "PT",
        },
        {
          onSuccess: () => {
            console.log("Settlement updated successfully.");
            onClose();
          },
          onError: (error) => {
            console.error("Error updating settlement:", error);
          },
        },
      );
    }
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const submitContractorSettlementUpdate = (dataToSend: any) => {
    if (record?.id) {
      mutate(
        {
          resource: "settlements",
          id: `${record.id}/contractor`,
          values: dataToSend,
          dataProviderName: "PT",
        },
        {
          onSuccess: () => {
            console.log("Settlement updated successfully.");
            onClose();
          },
          onError: (error) => {
            console.error("Error updating settlement:", error);
          },
        },
      );
    }
  };

  const handleObjectionSubmit = (value: { remark: string }) => {
    if (record?.id) {
      mutate(
        {
          resource: "/settlements",
          id: `${record?.id}/contractor`,
          values: {
            status: "processing",
            remark: value.remark,
          },
          dataProviderName: "PT",
        },
        {
          onSuccess: () => {
            message.success("Objection submitted successfully.");
            setShowObjection(false);
            onClose();
          },
          onError: (error) => {
            console.error("Error submitting objection:", error);
          },
        },
      );
    }
  };

  const handleSubmit = async (action: string) => {
    const actionStatus = statusConfig[role]?.[status as string]?.[action];
    if (!actionStatus) {
      console.error(
        "Action status configuration missing for:",
        role,
        status,
        action,
      );
      return;
    }

    const updatedDataToSend = {
      ...dataToSend,
      status: actionStatus,
    };

    const fetchAndSubmit = async (expectedStatus: string) => {
      const status = await fetchStatus();
      if (status?.status === expectedStatus) {
        submitProducerSettlementUpdate(updatedDataToSend);
      } else {
        setModalContent({ role, status, action });
        setShowModal(true);
      }
    };

    if (role === "producer") {
      if (status === "settlement" && action === "Submit") {
        const status = await fetchStatus();
        if (status?.is_in_progress) {
          setModalContent({ role, status, action });
          setShowModal(true);
        } else {
          submitProducerSettlementUpdate(updatedDataToSend);
        }
      } else if (status === "confirmation" && action === "Update") {
        fetchAndSubmit("confirmation");
      } else if (status === "processing" && action === "Update") {
        fetchAndSubmit("processing");
      } else if (status === "submission" && action === "Submit payment") {
        submitProducerSettlementUpdate({ status: actionStatus });
      } else if (status === "payment" && action === "Payment") {
        submitProducerSettlementUpdate({ status: actionStatus });
      }
    } else if (role === "contractor") {
      if (status === "confirmation" && action === "Disagree") {
        setShowObjection(true);
      } else if (status === "confirmation" && action === "Confirm") {
        submitContractorSettlementUpdate({ status: actionStatus });
      }
    }
  };

  const costLabel =
    status === "settled" ? "Payment" : "Pending Settlement Cost";
  const currency = `${settlementDetails?.currency || "USD"} `;

  return (
    <div>
      {contextHolder}
      {showObjection ? (
        <></>
        // <EditableCell
        //   componentType="Objection"
        //   onChange={handleObjectionSubmit}
        //   editable={true}
        //   forceEditing={true}
        //   componentProps={{}}
        //   onCancelEdit={() => setShowObjection(false)}
        // />
      ) : (
        <div className="flex justify-end items-center px-4 py-2 space-x-4">
          <div className="flex items-center mr-8">
            <Text className="text-base leading-6 font-semibold mr-2">
              {costLabel}:
            </Text>
            <Text className="text-red-500 font-bold text-base">
              {currency}
              {settlementDetails?.pending_cost?.toFixed(2)}
            </Text>
          </div>
          {status && getButtons(role, status)}
          <SettlementWarningModal
            isVisible={showModal}
            onClose={() => setShowModal(false)}
            role={modalContent.role}
            status={modalContent.status}
            action={modalContent.action}
          />
        </div>
      )}
    </div>
  );
};
