import { useIsExistAuthentication, useLink, useParsed } from "@refinedev/core";
import { Col, Image, Row, theme } from "antd";

import { LeyLineIcon } from "../../components";
import { Logo } from "./styled";

const { useToken } = theme;

type TitleProps = {
  collapsed: boolean;
};

const VITE_LANDING_WEB_BASE = import.meta.env.VITE_LANDING_WEB_BASE;
const leylineIndexPage = `${VITE_LANDING_WEB_BASE}`;

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { token } = useToken();
  const Link = useLink();
  const isExistAuthentication = useIsExistAuthentication();
  // const isExistAuthentication = false;

  const { resource, action, id, pathname } = useParsed();
  const isIndexPage =
    pathname === "/" || pathname === "/enterprises/public-jobs";

  const logoLinkTo = isExistAuthentication
    ? isIndexPage
      ? "/users/home"
      : "/"
    : leylineIndexPage;

  return (
    <Logo>
      <Link to={logoLinkTo}>
        {collapsed && !isIndexPage ? (
          // <BikeWhiteIcon
          //   style={{
          //     fontSize: "32px",
          //     color: token.colorTextHeading,
          //   }}
          // />
          <Image
            className="ml-4"
            src="images/L-logo-1.png"
            onError={(e) => {
              e.currentTarget.src = "images/L-logo-1.png";
            }}
            preview={false}
            width={"60%"}
          />
        ) : (
          <Row>
            <Col span={24}>
              <LeyLineIcon
                style={{
                  color: token.colorTextHeading,
                  width: "100%",
                }}
              />
            </Col>
          </Row>
        )}
      </Link>
    </Logo>
  );
};
