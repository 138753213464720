import { httpGet, httpPost, httpPut } from "@/utils/http";

export const getPublishedJobs = (params: {
  page: number;
  size: number;
  name: string;
  is_enabled?: number;
}) => {
  return httpGet("/jobs/published", {
    params,
  });
};

export const submitContractInfo = (applicantId: string, params: unknown) => {
  return httpPost(`/applications/company/${applicantId}/confirm`, params);
};

export const createApplicationInfo = (params: unknown) => {
  return httpPost("/applications", params);
};

export const updateApplicationInfo = (id: string, params: unknown) => {
  return httpPut(`/applications/applicant/${id}`, params);
};

export const getApplyConst = (constant: string) => {
  return httpGet(`/jobs/constants/${constant}`);
};
