import { Button, Modal } from "antd";
import React from "react";

interface VerificationRequiredModalProps {
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const CompanyVerificationModal: React.FC<VerificationRequiredModalProps> = ({
  isVisible,
  onConfirm,
  onCancel,
}) => {
  return (
    <Modal
      title="Company Verification Required"
      visible={isVisible}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onConfirm}>
          Create and Verify Company
        </Button>,
      ]}
    >
      <p>
        You need to go through the company verification process to create a
        project. Click cancel to redirect to the homepage.
      </p>
    </Modal>
  );
};

export default CompanyVerificationModal;
