import { getStaticImgUrl } from "@/utils/common";
import { MotionValue, motion, useTransform } from "framer-motion";
import React from "react";

interface IProducerCard {
  id: number;
  range: [number, number];
  progress: MotionValue<number>;
  targetScale: number;
}

export const ProducerCard: React.FC<IProducerCard> = ({
  id,
  range,
  progress,
  targetScale,
}) => {
  const scale = useTransform(progress, range, [1, targetScale]);

  return (
    <div
      id={`card-${id}`}
      className="sticky top-0 h-screen flex items-center justify-center"
    >
      <motion.div
        style={{
          scale,
          top: `calc(-5vh + ${(id - 1) * 25}px)`,
        }}
        className="max-w-xl aspect-ratio relative flex flex-col origin-top"
      >
        <div className="flex h-full mt-12 gap-12">
          <div className="relative w-full h-full rounded-[2.5rem] overflow-hidden">
            <motion.div>
              <img
                src={getStaticImgUrl(`card${id}.png`) || ""}
                className="object-cover"
                width="100%"
                height="100%"
                alt="card-image"
              />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};
