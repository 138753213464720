import { CustomDropDown } from "@/components/customComponents/CustomDropDown";
import { CustomPopconfirm } from "@/components/customComponents/CustomPopconfirm";
import { IFolder, ISearchArtworkLIst } from "@/pages/discover/types";
import {
  DeleteOutlined,
  EditOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Button, Col, Popconfirm, Row, Space, Typography } from "antd";
import React, { useState } from "react";

interface IFolderItemProps {
  setCurrentPage: (page: number) => void;
  setEditFolderName: (name: string) => void;
  folder: IFolder;
  onEditFolderModal: (id: string) => void;
  onDeleteFolder: (id: string) => void;
  setArtworkFilter: (data: ISearchArtworkLIst) => void;
  searchData: ISearchArtworkLIst;
}

export const FolderItem: React.FC<IFolderItemProps> = ({
  setCurrentPage,
  setEditFolderName,
  folder,
  onEditFolderModal,
  onDeleteFolder,
  setArtworkFilter,
  searchData,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const onEditFolderFunc = (id: string) => {
    onEditFolderModal(id);
    setEditFolderName(folder.folder_name);
  };

  const onSelectFolderFunc = (id: string) => {
    setArtworkFilter({ ...searchData, folder_id: id });
    setCurrentPage(1);
  };

  return (
    <Row
      className={`flex items-center gap-1 justify-between px-4 py-3.5 cursor-pointer flex-nowrap hover:bg-white hover:bg-opacity-5 ${
        searchData.folder_id === folder.id ? "bg-black bg-opacity-20" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Col
        onClick={() => onSelectFolderFunc(folder.id)}
        className="flex flex-row flex-1 cursor-pointer overflow-hidden gap-1.5"
        key={folder.id}
      >
        {searchData.folder_id === folder.id ? (
          <FolderOpenOutlined className="text-base text-[#00ADAE]" />
        ) : (
          <FolderOutlined className="text-base text-white" />
        )}
        <Typography.Text
          className={`text-base overflow-hidden overflow-ellipsis whitespace-nowrap ${
            searchData.folder_id === folder.id ? "text-[#00ADAE]" : "text-white"
          }`}
        >
          {folder.folder_name}
        </Typography.Text>
      </Col>
      <Col className="flex flex-row w-auto justify-end whitespace-nowrap">
        {isHovered ? (
          <div className="text-base text-[#686868] flex flex-row gap-5">
            <EditOutlined
              className="hover:text-white"
              onClick={() => onEditFolderFunc(folder.id)}
            />
            <CustomPopconfirm
              title="Delete folder. Contents will not be deleted. Continue?"
              placement="bottom"
              onConfirm={() => onDeleteFolder(folder.id)}
              className="text-[#686868]"
            >
              <DeleteOutlined className="hover:text-white" />
            </CustomPopconfirm>
          </div>
        ) : (
          <Typography.Text className="text-[#686868] text-sm">
            {folder.artwork_count}{" "}
            {folder.artwork_count > 1 ? "artworks" : "artwork"}
          </Typography.Text>
        )}
      </Col>
    </Row>
  );
};
