import { UserType } from "@/__generated__/graphql";
import {
  PaymentMethodValueType,
  paymentMethodMap,
} from "@/components/businessComponents";
import { CustomButton, CustomModalV2 } from "@/components/customComponents";
import { useJobInfoContext } from "@/contexts/jobInfo";
import { useModalConfirm } from "@/hooks";
import {
  createApplicationInfo,
  updateApplicationInfo,
} from "@/pages/jobs/services";
import { useGlobalStore } from "@/stores";
import { delFileUrlPrefix, isAllowClose } from "@/utils/common";
import { ArrowRightOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Drawer, Form, Modal, Tooltip, message } from "antd";
import {
  ReactNode,
  Ref,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ApplyInfoType } from "../../../pages/enterprises/utils";
import Step1 from "./applyJobSteps/Step1";
import Step2 from "./applyJobSteps/Step2";
import Step3 from "./applyJobSteps/Step3";
import Step4 from "./applyJobSteps/Step4";

interface Props {
  refresh: () => void;
}
const ApplyJobDrawer = (props: Props, ref: Ref<unknown> | undefined) => {
  const [visible, setVisible] = useState(false);
  const [applyInfo, setApplyInfo] = useState<ApplyInfoType>();
  const applyJobRef = useRef<{ checkFormChanged: () => boolean }>(null);
  const [modal, contextHolder] = Modal.useModal();
  const [currentStep, setCurrentStep] = useState(1);
  const { currentRole } = useGlobalStore((state) => state);
  const [formData, setFormData] = useState<{
    paymentMethod?: PaymentMethodValueType;
    [key: string]: string | number | undefined;
  }>({});
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [modalConfirm, modalContextHolder] = useModalConfirm();
  const jobInfo = useJobInfoContext();

  const forms = useMemo(() => ({ form2, form3, form4 }), [form2, form3, form4]);

  useImperativeHandle(ref, () => ({
    open: (info: ApplyInfoType) => {
      form2.resetFields();
      form3.resetFields();
      form4.resetFields();
      setCurrentStep(1);
      setVisible(true);
      setApplyInfo(info);
      setFormData({});
    },
  }));

  const renderTitleAndSubTitle = (): { title: string; subTitle: ReactNode } => {
    switch (currentStep) {
      case 1:
        return {
          title: "How will you quote?",
          subTitle: (
            <span>
              A reliable quote can increase your chances of a successful
              application.
              <br />
              You can only apply for one job ONCE. So please make sure your
              quote is accurate.
            </span>
          ),
        };
      case 2:
        return {
          title: `${
            paymentMethodMap[formData.paymentMethod as PaymentMethodValueType]
          }: Please give a breakdown of your quote`,
          subTitle: (
            <span>
              A reliable quote can increase your chances of a successful
              application.
              <br />
              You can only apply for one job ONCE. So please make sure your
              quote is accurate.
            </span>
          ),
        };
      case 3:
        return {
          title: "Submit a test to increase your chance of getting the job!",
          subTitle: "",
        };
      default:
        return {
          title: "Please read the following content in detail and agree.",
          subTitle: "",
        };
    }
  };

  const onSuccess = () => {
    setVisible(false);
    props.refresh();
  };

  const onClose = async () => {
    const hasChanged = applyJobRef.current?.checkFormChanged();
    const isAllow = await isAllowClose(hasChanged, modal);
    isAllow && setVisible(false);
  };

  const isLastStep = currentStep >= 4;

  return (
    <>
      <CustomModalV2
        centered
        open={visible}
        {...renderTitleAndSubTitle()}
        // rootClassName="top-20"
        onCancel={onClose}
        closable
        width={1100}
        maskClosable={true}
        destroyOnClose
        footer={
          currentStep === 1 ? (
            false
          ) : (
            <div>
              {currentStep > 1 && (
                <CustomButton
                  className="mr-5"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Previous
                </CustomButton>
              )}
              <CustomButton
                type="primary"
                icon={isLastStep ? null : <ArrowRightOutlined />}
                onClick={async () => {
                  const value =
                    // @ts-ignore
                    await forms[`form${currentStep}`].validateFields();
                  if (isLastStep) {
                    // @ts-ignore
                    modalConfirm({
                      title: "Notice",
                      content:
                        "You can only apply for one job ONCE. So please review everything carefully before submiting",
                      okText: "Confirm",
                      async onOk() {
                        try {
                          const {
                            paymentMethod,
                            testType,
                            testFile,
                            testLink,
                            unit,
                            quantity,
                            duration,
                            totalAmountEx,
                            totalCost,
                            unit_price,
                            ...restData
                          } = formData;
                          const isPersonal = [
                            UserType.Individual,
                            UserType.Applicantindividual,
                          ].includes(currentRole as UserType);

                          const requestBody = {
                            ...restData,
                            payment_method: paymentMethod,
                            project_id: jobInfo?.project_id,
                            job_id: jobInfo?.job_id,
                            role: isPersonal ? "Individual" : "Company",
                            count:
                              paymentMethod === PaymentMethodValueType.Paperwork
                                ? quantity
                                : paymentMethod === PaymentMethodValueType.Time
                                  ? duration
                                  : undefined,
                            count_unit:
                              paymentMethod !==
                              PaymentMethodValueType.Negotiable
                                ? unit
                                : undefined,
                            rate:
                              paymentMethod !==
                              PaymentMethodValueType.Negotiable
                                ? unit_price
                                : undefined,
                            rate_unit:
                              paymentMethod === PaymentMethodValueType.Time
                                ? `person/${unit}`
                                : undefined,
                            total_cost:
                              paymentMethod ===
                              PaymentMethodValueType.Negotiable
                                ? totalCost
                                : parseFloat(totalAmountEx as string),
                            // @ts-ignore
                            ...(testType === "upload"
                              ? {
                                  answer_url:
                                    // @ts-ignore
                                    testFile?.length > 0
                                      ? delFileUrlPrefix(
                                          // @ts-ignore
                                          testFile[0].response?.id ||
                                            // @ts-ignore
                                            testFile[0].url,
                                        )
                                      : "",
                                }
                              : null),
                            ...(testType === "link"
                              ? { answer_link: testLink }
                              : null),
                          };
                          console.log(777, requestBody);
                          await (applyInfo?.id
                            ? updateApplicationInfo(
                                applyInfo?.id as string,
                                requestBody,
                              )
                            : createApplicationInfo(requestBody));
                          message.success(
                            "Your application has been submitted.",
                          );
                          onSuccess();
                        } catch (error) {
                          message.error(
                            // @ts-ignore
                            error?.errors?.detail ||
                              "Failed to submit application",
                          );
                        }
                      },
                    });
                  } else {
                    setFormData((prevData) => ({
                      ...prevData,
                      ...value,
                    }));
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                {isLastStep ? "Submit" : "Continue"}
              </CustomButton>
            </div>
          )
        }
      >
        <div className={currentStep === 1 ? "block px-5" : "hidden"}>
          <Step1
            value={formData.paymentMethod as string | undefined}
            onSelect={(e) => {
              if (e !== formData.paymentMethod) form2.resetFields();
              setFormData((fData) => ({ ...fData, paymentMethod: e }));
              // Slightly delay the jump.
              setTimeout(() => setCurrentStep(currentStep + 1), 200);
            }}
          />
        </div>
        <div className={currentStep === 2 ? "block px-5" : "hidden"}>
          <Step2
            form={form2}
            paymentMethod={formData.paymentMethod as PaymentMethodValueType}
          />
        </div>
        <div className={currentStep === 3 ? "block px-5" : "hidden"}>
          <Step3 form={form3} />
        </div>
        <div className={currentStep === 4 ? "block px-5" : "hidden"}>
          <Step4 form={form4} />
        </div>
        {contextHolder}
      </CustomModalV2>
      {modalContextHolder}
    </>
  );
};

export default forwardRef(ApplyJobDrawer);
