import { removeBookmarkAPI } from "@/pages/discover/api";
import { Modal, Row } from "antd";

interface IBookmarkDeleteConfirmModalProps {
  bookmarkId: string;
  modalState: boolean;
  setModalState: (value: boolean) => void;
  setHandleChangeState: (value: string) => void;
}

export const BookmarkDeleteConfirmModal: React.FC<
  IBookmarkDeleteConfirmModalProps
> = ({ bookmarkId, modalState, setModalState, setHandleChangeState }) => {
  const handleRemoveBookmark = async (bookmark_id: string) => {
    const response = await removeBookmarkAPI(bookmark_id);
    if (response === "success") {
      setHandleChangeState(`delete_${bookmark_id}`);
      setModalState(false);
    }
  };
  return (
    <Row>
      <Modal
        title={"Confirm"}
        open={modalState}
        onCancel={() => setModalState(false)}
        onOk={() => handleRemoveBookmark(bookmarkId)}
        styles={{ header: { background: "none" } }}
      >
        <p>
          Delete bookmark. Works in the bookmark will not be unfavorited. Do you
          want to continue deleting?
        </p>
      </Modal>
    </Row>
  );
};
