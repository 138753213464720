export const HEADERS_PRODUCER = {
  settlement: [
    "settlement",
    "staff",
    "headcount",
    "tasks",
    "paymentMethod",
    "pendingSettlementDuration",
    "totalCost",
    "settledCost",
    "remainingCost",
    "pendingSettlementCost",
    "createTime",
  ],
  confirmation: [
    "settlement",
    "staff",
    "headcount",
    "tasks",
    "paymentMethod",
    "pendingSettlementDuration",
    "totalCost",
    "settledCost",
    "remainingCost",
    "updateTime",
    "createTime",
  ],
  processing: [
    "settlement",
    "staff",
    "headcount",
    "tasks",
    "paymentMethod",
    "pendingSettlementDuration",
    "totalCost",
    "settledCost",
    "remainingCost",
    "pendingSettlementCost",
    "updateTime",
    "createTime",
  ],
  submission: [
    "settlement",
    "staff",
    "headcount",
    "tasks",
    "paymentMethod",
    "pendingSettlementDuration",
    "totalCost",
    "settledCost",
    "remainingCost",
    "pendingSettlementCost",
    "updateTime",
    "createTime",
    // "accountType",
    // "collectionAccount",
    // "mobile",
  ],
  payment: [
    "settlement",
    "staff",
    "projectName",
    "pendingSettlementCost",
    // "accountType",
    // "collectionAccount",
    // "mobile",
    "updateTime", // submission time
  ],
  settled: [
    "settlement",
    "staff",
    "projectName",
    "payment", // -> pendingSettlementCost
    // "accountType",
    // "collectionAccount",
    // "mobile",
    "payTime", // -> payTime
  ],
};

export const HEADERS_CONTRACTOR = {
  settlement: [
    "settlement",
    "tasks",
    "pendingSettlementDuration",
    "pendingSettlementCost",
    "createTime",
  ],
  confirmation: [
    "settlement",
    "tasks",
    "pendingSettlementDuration",
    "pendingSettlementCost",
    "updateTime",
    "createTime",
  ],
  processing: [
    "settlement",
    "tasks",
    "pendingSettlementDuration",
    "pendingSettlementCost",
    "updateTime",
    "createTime",
  ],
  payment: [
    "settlement",
    "projectName",
    "pendingSettlementCost",
    "updateTime", // "Submission Time"
    // "accountType",
    // "collectionAccount",
    // "mobile",
  ],
  collection: [
    "settlement",
    "tasks",
    "pendingSettlementDuration",
    "pendingSettlementCost",
    "updateTime",
    "createTime",
    // "accountType",
    // "collectionAccount",
    // "mobile",
  ],
  settled: [
    "settlement",
    "projectName",
    "payment", // -> pendingSettlementCost
    // "paymentId", // pay no.
    "payTime", // "payTime",
    // "accountType",
    // "collectionAccount",
    // "mobile",
  ],
};
