import { isImageFile } from "@/utils/upload";
import {
  FileDoneOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Create, useStepsForm } from "@refinedev/antd";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Tabs,
  Upload,
  message,
} from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { RcFile } from "antd/lib/upload/interface";
import { UploadRequestOption } from "rc-upload/lib/interface";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IUserProfile } from "../../interfaces";
import { preventAutoUpload } from "../../utils/common";

const { TabPane } = Tabs;

interface VerificationMethod {
  contract_url: string;
  license_number: string;
  license_pic_urls: string[];
  license_type: string;
  manager_license_pic_url: string;
  manager_name: string;
}

interface UploadButtonProps {
  loading: boolean;
  label: string;
}

export const EditProjectVerification: React.FC = () => {
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const navigate = useNavigate();

  const [activeTopTab, setActiveTopTab] = useState("contractVerification");
  const { queryResult } = useStepsForm<IUserProfile>();

  const [verificationMethod, setVerificationMethod] =
    useState<VerificationMethod | null>(null);

  useEffect(() => {
    const projectId = sessionStorage.getItem("currentProjectId");
    if (projectId) {
      fetchProjectDetails(projectId);
    }
  }, []);

  const fetchProjectDetails = async (projectId: string) => {
    try {
      const response = await fetch(`${apiUrl}/projects/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        console.log("Project Details:", data);
        setVerificationMethod(data.verification_method);
      } else {
        console.error("Cannot fetch project details:", response.status, data);
      }
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  // biome-ignore lint:
  useEffect(() => {
    if (verificationMethod) {
      formRef.current?.setFieldsValue({
        projectRepresentative: verificationMethod.manager_name,
        documentType: verificationMethod.license_type,
        documentNumber: verificationMethod.license_number,
      });

      if (verificationMethod.license_pic_urls.length > 0) {
        const frontImageId = verificationMethod.license_pic_urls[0];
        const frontFullUrl = `${marketplaceUploadUrl}${frontImageId}`;
        setImageUrl(frontImageId);
        setFullImageUrl(frontFullUrl);
        formRef.current?.setFieldsValue({ documentFront: frontImageId });
        formRef.current?.setFields([
          {
            name: "documentFront",
            errors: [],
            value: verificationMethod.license_pic_urls[0],
          },
        ]);

        if (verificationMethod.license_pic_urls.length > 1) {
          const backImageId = verificationMethod.license_pic_urls[1];
          const backFullUrl = `${marketplaceUploadUrl}${backImageId}`;
          setImageBackUrl(backImageId);
          setFullImageBackUrl(backFullUrl);
          formRef.current?.setFieldsValue({ documentBack: backImageId });
        }
      }

      if (verificationMethod.manager_license_pic_url) {
        const handHeldImageId = verificationMethod.manager_license_pic_url;
        const handHeldFullUrl = `${marketplaceUploadUrl}${handHeldImageId}`;
        setHandHeldImageUrl(handHeldImageId);
        setFullHandHeldImageUrl(handHeldFullUrl);
        formRef.current?.setFieldsValue({
          handHeldDocumentPhoto: handHeldImageId,
        });
        formRef.current?.setFields([
          {
            name: "handHeldDocumentPhoto",
            errors: [],
            value: verificationMethod.manager_license_pic_url,
          },
        ]);
      }

      if (verificationMethod.contract_url) {
        const contractImageId = verificationMethod.contract_url;
        const contractFullUrl = `${marketplaceUploadUrl}${contractImageId}`;
        setContractImageUrl(contractImageId);
        setFullContractFileUrl(contractFullUrl);
        formRef.current?.setFieldsValue({
          projectContractFile: contractImageId,
        });
        formRef.current?.setFields([
          {
            name: "projectContractFile",
            errors: [],
            value: contractImageId,
          },
        ]);
      }
    }
  }, [verificationMethod]);

  interface FormValues {
    projectRepresentative: string;
    documentType: string;
    documentNumber: string;
  }

  const [frontLoading, setFrontLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [fullImageUrl, setFullImageUrl] = useState<string | null>(null);

  const [backLoading, setBackLoading] = useState(false);
  const [imageBackUrl, setImageBackUrl] = useState<string | null>(null);
  const [fullImageBackUrl, setFullImageBackUrl] = useState<string | null>(null);

  const [handHeldLoading, setHandHeldLoading] = useState(false);
  const [handHeldImageUrl, setHandHeldImageUrl] = useState<string | null>(null);
  const [fullHandHeldImageUrl, setFullHandHeldImageUrl] = useState<
    string | null
  >(null);

  const [contractLoading, setContractLoading] = useState(false);
  const [contractImageUrl, setContractImageUrl] = useState<string | null>(null);
  const [fullContractFileUrl, setFullContractFileUrl] = useState<string | null>(
    null,
  );

  const [isUploading, setIsUploading] = useState({
    front: false,
    back: false,
    handHeld: false,
    contract: false,
  });

  const beforeImageUpload = (file: RcFile) => {
    const isImage = isImageFile(file);
    const imageSizeLimit = 5;

    if (isImage) {
      if (file.size / 1024 / 1024 > imageSizeLimit) {
        message.error(`Image must be smaller than ${imageSizeLimit}MB.`);
        return Upload.LIST_IGNORE;
      }
    } else {
      message.error("You can only upload images.");
      return Upload.LIST_IGNORE;
    }
  };

  const beforeFileUpload = (file: RcFile) => {
    const fileSizeLimit = 10;
    if (file.size / 1024 / 1024 > fileSizeLimit) {
      message.error(`Image must be smaller than ${fileSizeLimit}MB.`);
      return Upload.LIST_IGNORE;
    }
  };

  const UploadButton: React.FC<UploadButtonProps> = ({ loading, label }) => (
    <button className="cursor-pointer border-0 bg-none" type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="mt-2">{label}</div>
    </button>
  );

  const handleUpload = async (
    info: UploadChangeParam<UploadFile>,
    type: "front" | "back" | "handHeld" | "contract",
  ) => {
    if (info.file.status === "uploading" && !isUploading[type]) {
      setIsUploading({ ...isUploading, [type]: true });
      const setLoadingMap = {
        front: setFrontLoading,
        back: setBackLoading,
        handHeld: setHandHeldLoading,
        contract: setContractLoading,
      };

      const setImageMap = {
        front: setImageUrl,
        back: setImageBackUrl,
        handHeld: setHandHeldImageUrl,
        contract: setContractImageUrl,
      };

      const setFullImageMap = {
        front: setFullImageUrl,
        back: setFullImageBackUrl,
        handHeld: setFullHandHeldImageUrl,
        contract: setFullContractFileUrl,
      };

      const formFieldMap = {
        front: "documentFront",
        back: "documentBack",
        handHeld: "handHeldDocumentPhoto",
        contract: "projectContractFile",
      };

      const setLoading = setLoadingMap[type];
      setLoading(true);

      try {
        const fileId = await uploadFile(info.file);
        const fullUrl = `${marketplaceUploadUrl}${fileId}`;

        const setImage = setImageMap[type];
        setImage(fileId);

        const setFullImage = setFullImageMap[type];
        setFullImage(fullUrl);

        formRef.current?.setFieldsValue({ [formFieldMap[type]]: fileId });
      } catch (error) {
        console.error("Error uploading file:", error);
        message.error("Upload failed.");
      } finally {
        setIsUploading({ ...isUploading, [type]: false });
        setLoading(false);
      }
    }
  };

  const [form] = Form.useForm();

  const formRef = useRef<FormInstance>(null);

  const uploadFile = async (file: UploadFile) => {
    const formData = new FormData();
    formData.append("file", file.originFileObj as Blob);

    try {
      const response = await fetch(`${apiUrl}/projects/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();
      return data.id;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const onFinish = async (values: FormValues) => {
    try {
      const documentFront = formRef.current?.getFieldValue("documentFront");
      const documentBack = formRef.current?.getFieldValue("documentBack");
      const handHeldDocumentPhoto = formRef.current?.getFieldValue(
        "handHeldDocumentPhoto",
      );
      const projectContractFile = formRef.current?.getFieldValue(
        "projectContractFile",
      );

      const licensePicUrls = [];
      if (documentFront) {
        licensePicUrls.push(imageUrl);
      }
      if (documentBack) {
        licensePicUrls.push(imageBackUrl);
      }

      const verificationMethod = {
        manager_name: values.projectRepresentative,
        license_type: values.documentType,
        license_number: values.documentNumber,
        license_pic_urls: licensePicUrls,
        manager_license_pic_url: handHeldImageUrl,
        contract_url: contractImageUrl,
      };

      const projectId = sessionStorage.getItem("currentProjectId");
      const response = await fetch(
        `${apiUrl}/projects/${projectId}/verification`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(verificationMethod),
        },
      );
      console.log("response: ", response);
      if (!response.ok) {
        throw new Error("Failed to submit verification");
      }

      message.success(
        "Your information has been submitted and is waiting for verification. Redirecting to project details ...",
        () => {
          navigate("/projects/project-details");
        },
      );
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to update a project");
    }
  };

  const documentType = Form.useWatch("documentType", form);

  return (
    <Row gutter={24} className="min-h-screen">
      <Col span={24} className="overflow-auto">
        <Card bordered className="rounded-lg">
          <Tabs
            defaultActiveKey="contractVerification"
            onChange={(key) => setActiveTopTab(key)}
          >
            <TabPane tab="Contract Verification" key="contractVerification" />
            {/* <TabPane tab="Deposit Verification" key="depositVerification" /> */}
          </Tabs>

          {/* Conditional Rendering Based on Active Tab */}
          {activeTopTab === "contractVerification" && (
            <div>
              <Create
                title="Edit Project Verification"
                isLoading={queryResult?.isFetching}
                headerButtons={() => <></>}
                footerButtons={() => (
                  <>
                    <Button
                      type="primary"
                      onClick={() => formRef.current?.submit()}
                    >
                      Update
                    </Button>
                  </>
                )}
              >
                <Row justify="center">
                  <Col span={12}>
                    <Form
                      form={form}
                      ref={formRef}
                      name="contractVerificationForm"
                      onFinish={onFinish}
                      layout="vertical"
                    >
                      <Row key="Contract Verification" gutter={20}>
                        <Col span={24}>
                          <Form.Item
                            label="Project Representative"
                            name="projectRepresentative"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter the project representative",
                              },
                            ]}
                          >
                            <Input showCount maxLength={60} />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Document Type"
                            name="documentType"
                            rules={[
                              {
                                required: true,
                                message: "Please select the document type",
                              },
                            ]}
                          >
                            <Select>
                              <Select.Option value="ID Card">
                                ID Card
                              </Select.Option>
                              <Select.Option value="Passport">
                                Passport
                              </Select.Option>
                              <Select.Option value="Other">Other</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            label="Document Number"
                            name="documentNumber"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the document number",
                              },
                            ]}
                          >
                            <Input showCount maxLength={60} />
                          </Form.Item>
                        </Col>

                        {/* Document Photos */}
                        <Col span={24}>
                          <Form.Item label="Document Photos">
                            <Row justify="center">
                              <Col span={12}>
                                <Form.Item
                                  name="documentFront"
                                  rules={
                                    imageUrl
                                      ? []
                                      : [
                                          {
                                            required: true,
                                            message:
                                              "Please upload the front of the document",
                                          },
                                        ]
                                  }
                                >
                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeImageUpload}
                                    onChange={(info) =>
                                      handleUpload(info, "front")
                                    }
                                    accept="image/*"
                                    customRequest={preventAutoUpload}
                                  >
                                    {fullImageUrl ? (
                                      <img
                                        src={fullImageUrl}
                                        alt="front"
                                        className="w-full h-full object-contain"
                                      />
                                    ) : (
                                      <UploadButton
                                        loading={frontLoading}
                                        label="Front"
                                      />
                                    )}
                                  </Upload>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <div>
                                  <p>
                                    The document photo should be a front-facing
                                    photo.
                                  </p>
                                  <p>
                                    Please provide valid documents within the
                                    expiration date, ensuring that the text is
                                    clear and legible, and the document itself
                                    is intact.
                                  </p>
                                  <p>The file size should not exceed 5MB.</p>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-5">
                              <Col span={12}>
                                <Form.Item
                                  name="documentBack"
                                  rules={[
                                    {
                                      required: documentType === "ID Card",
                                      message:
                                        "Please upload the back of the document",
                                    },
                                  ]}
                                >
                                  <Upload
                                    name="backAvatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={beforeImageUpload}
                                    onChange={(info) =>
                                      handleUpload(info, "back")
                                    }
                                    accept="image/*"
                                    customRequest={preventAutoUpload}
                                  >
                                    {fullImageBackUrl ? (
                                      <img
                                        src={fullImageBackUrl}
                                        alt="back"
                                        className="w-full h-full object-contain"
                                      />
                                    ) : (
                                      <UploadButton
                                        loading={backLoading}
                                        label="Back"
                                      />
                                    )}
                                  </Upload>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <div>
                                  <p>
                                    If the document type is an ID card of
                                    People's Republic of China (Mainland),
                                    please also provide a back-facing photo.
                                  </p>
                                  <p>
                                    Please provide valid documents within the
                                    expiration date, ensuring that the text is
                                    clear and legible, and the document itself
                                    is intact.
                                  </p>
                                  <p>The file size should not exceed 5MB.</p>
                                </div>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>

                        {/* Hand-held Photos */}
                        <Col span={24}>
                          <Form.Item
                            label="Hand-held Document Photos"
                            name="handHeldDocumentPhotos"
                            rules={
                              handHeldImageUrl
                                ? []
                                : [
                                    {
                                      required: true,
                                      message:
                                        "Please upload a photo of yourself holding the document.",
                                    },
                                  ]
                            }
                          >
                            <Row justify="center">
                              <Col span={12}>
                                <Upload
                                  name="handHeldDocumentPhotos"
                                  listType="picture-card"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  beforeUpload={beforeImageUpload}
                                  onChange={(info) =>
                                    handleUpload(info, "handHeld")
                                  }
                                  accept="image/*"
                                  customRequest={preventAutoUpload}
                                >
                                  {fullHandHeldImageUrl ? (
                                    <img
                                      src={fullHandHeldImageUrl}
                                      alt="hand-held"
                                      className="w-full h-full object-contain"
                                    />
                                  ) : (
                                    <UploadButton
                                      loading={handHeldLoading}
                                      label="Photo"
                                    />
                                  )}
                                </Upload>
                              </Col>
                              <Col span={12}>
                                <div>
                                  <p>
                                    Please upload a photo of yourself holding
                                    the front side of your document.
                                  </p>
                                  <p>
                                    Please provide valid documents within the
                                    expiration date, ensuring that the text is
                                    clear and legible, and the document itself
                                    is intact.
                                  </p>
                                  <p>The file size should not exceed 5MB.</p>
                                </div>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>

                        {/* Project Contract */}
                        <Col span={24}>
                          <Form.Item
                            label="Project Contract"
                            name="projectContract"
                            rules={
                              contractImageUrl
                                ? []
                                : [
                                    {
                                      required: true,
                                      message:
                                        "Please upload the project contract.",
                                    },
                                  ]
                            }
                          >
                            <Row justify="center">
                              <Col span={12}>
                                <Upload
                                  name="projectContract"
                                  listType="picture-card"
                                  showUploadList={false}
                                  beforeUpload={beforeFileUpload}
                                  onChange={(info) =>
                                    handleUpload(info, "contract")
                                  }
                                  customRequest={preventAutoUpload}
                                >
                                  {contractImageUrl && !contractLoading ? (
                                    /\.(jpeg|jpg|png)$/.test(
                                      contractImageUrl,
                                    ) ? (
                                      <img
                                        src={fullContractFileUrl || ""}
                                        alt="contract"
                                        className="w-full h-full object-contain"
                                      />
                                    ) : (
                                      <div>
                                        <FileDoneOutlined className="text-4xl" />
                                      </div>
                                    )
                                  ) : (
                                    <UploadButton
                                      loading={contractLoading}
                                      label="Project Contract"
                                    />
                                  )}
                                </Upload>
                              </Col>
                              <Col span={12}>
                                <div>
                                  <p>The file size should not exceed 10MB.</p>
                                </div>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Create>
            </div>
          )}

          {activeTopTab === "depositVerification" && <div />}
        </Card>
      </Col>
    </Row>
  );
};
