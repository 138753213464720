import { datePickerFormat } from "@/utils/date";
import { useApiUrl } from "@refinedev/core";
import { Col, DatePicker, Form, FormProps, Input, Row, UploadFile } from "antd";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React from "react";
import { TOKEN_KEY } from "../../utils/constants";
import { fileSizeLessThan } from "../../utils/helper";
import UploadCrop from "./components/UploadCrop";
const { TextArea } = Input;
dayjs.extend(isSameOrBefore);

type ProjectExperienceFormProps = {
  formProps: FormProps;
  onAvatarPreview: (file: UploadFile) => void;
};

const { RangePicker } = DatePicker;
export const ProjectExperienceForm: React.FC<ProjectExperienceFormProps> = ({
  formProps,
  onAvatarPreview,
}) => {
  const apiUrl = useApiUrl();

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Project Name"
            name={"name"}
            rules={[
              {
                required: true,
                message: "Project Name is required",
              },
              {
                validator: (_, value) => {
                  if (value?.length > 0 && value?.trim() === "")
                    return Promise.reject(
                      new Error("Project Name cannot be only spaces"),
                    );
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input maxLength={100} />
          </Form.Item>

          <Form.Item
            label="Role"
            name={["role"]}
            rules={[
              {
                required: true,
                message: "Role is required",
              },
              {
                validator: (_, value) => {
                  if (value?.length > 0 && value?.trim() === "")
                    return Promise.reject(
                      new Error("Role cannot be only spaces"),
                    );
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input maxLength={60} />
          </Form.Item>

          <Form.Item
            label="Project Duration"
            name="project_duration"
            rules={[
              {
                required: true,
                message: "Project duration is required",
              },
            ]}
          >
            <RangePicker format={datePickerFormat} />
          </Form.Item>

          <Form.Item label="Project Description" name={"description"}>
            <TextArea rows={4} maxLength={1000} showCount />
          </Form.Item>
          <Form.Item
            label="Page Cover"
            name={"cover_url"}
            rules={[
              {
                required: true,
                message: "Please upload a page cover",
              },
            ]}
          >
            {/* @ts-ignore */}
            <UploadCrop
              listType="picture"
              uploadProps={{
                action: `${apiUrl}/users/upload`,
                headers: {
                  authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
                },
                onPreview: onAvatarPreview,
                beforeUpload: fileSizeLessThan(5),
              }}
              aspect={1 / 1}
              modalTitle={"Page Cover"}
            />
          </Form.Item>
        </Col>
        <Col span={12} />
      </Row>
    </>
  );
};
