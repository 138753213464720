import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { ReactNode, useCallback } from "react";

interface ConfirmProps {
  title: string;
  width?: number;
  content?: ReactNode;
  okText?: string;
  cancelText?: string;
  onOk: () => void;
}

export const useModalConfirm = () => {
  const [modal, modalContextHolder] = Modal.useModal();

  const modalConfirm = useCallback(
    ({ title, content, ...conf }: ConfirmProps) => {
      modal.confirm({
        width: 520,
        closable: true,
        ...conf,
        title: "",
        icon: null,
        content: (
          <div>
            <div className="py-5 text-base">
              <span className="bg-white rounded-full inline-block w-6 h-6 mr-2">
                <ExclamationCircleFilled className="text-[#FF9900] text-2xl align-[-6px]" />
              </span>
              {title}
            </div>
            {!!content && (
              <div className="text-[#999] pl-8 pb-4">{content}</div>
            )}
          </div>
        ),
      });
    },
    [modal],
  );

  return [modalConfirm, modalContextHolder];
};
