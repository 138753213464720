import { Checkbox, DatePicker, Form, FormInstance } from "antd";
import { FC } from "react";

interface Props {
  form: FormInstance;
}

const Step4: FC<Props> = ({ form }) => {
  return (
    <div className="text-[#999]">
      <div>
        <div>你正在提交签约内容</div>
        <div>
          1.甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项。
        </div>
        <div>
          1.甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项。
        </div>
        <div>
          1.甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项。
        </div>
        <div>
          1.甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项。
        </div>
        <div>
          1.甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项。
        </div>
        <div>
          1.甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项甲方提交签约的注意事项。
        </div>
      </div>
      <Form form={form}>
        <Form.Item
          label=""
          name="isAgree"
          valuePropName="checked"
          className="text-center !mb-0"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Please carefully read the above content and agree.",
                      ),
                    ),
            },
          ]}
        >
          <Checkbox className="text-[#999]">Yes, I understand.</Checkbox>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Step4;
