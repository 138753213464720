import { CompanyAvatar } from "@/components/businessComponents";
import { formatToMiddleStyle } from "@/utils/date";
import { formatJobDetails } from "@/utils/helper";
import { QuestionOutlined } from "@ant-design/icons";
import { Image, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import compVerifiedImg from "/images/compVerified.png";
import projVerifiedImg from "/images/projVerified.png";

const { Text } = Typography;
interface JobItem {
  company_name: string;
  id: string;
  title: string;
  project_thumbnail_url: string;
  project_name: string;
  project_id: string;
  company_id: string;
  company_shortname: string;
  company_logo_url: string;
  department_name: string;
  location: string;
  remote_option: string;
  employment_type: string;
  created_at: string;
  is_company_verified: number | boolean;
  is_project_verified: number | boolean;
  is_enabled: number;
  is_recommended: number;
  user_id: string;
}

interface Props {
  job: JobItem;
  mode: "list" | "detail";
  isSelected?: boolean;
  onSelect: (item: JobItem) => void;
}

const JobItemCard = ({ job, mode, isSelected, onSelect }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const urlPrefix = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);

  useEffect(() => {
    isSelected &&
      containerRef.current?.scrollIntoView?.({
        behavior: "smooth",
        block: "center",
      });
  }, [isSelected]);

  const goToDetail = (job: JobItem) => {
    // if (token) {
    onSelect(job);
    // } else {
    //   navigate("/login");
    // }
  };

  return (
    <div
      ref={containerRef}
      onClick={(e) => {
        goToDetail(job);
      }}
      onKeyUp={() => {}}
      className="bg-white/[0.1] rounded-xl cursor-pointer"
    >
      <div
        className={`overflow-hidden rounded-xl hover:bg-white/[0.1] ${
          isSelected ? "bg-black/[.2]" : ""
        } ${mode === "detail" ? "mb-6" : ""}`}
      >
        <div className="p-5 pr-0 pb-0">
          <Text className="text-white text-2xl w-full" ellipsis>
            {job.title}
          </Text>
          <div className=" text-[#606264] border-b-[1px] border-solid border-b-white/[0.1]">
            {formatJobDetails(
              job.location,
              job.remote_option,
              job.employment_type,
            )}
          </div>
          <div className="flex pt-[15px] pb-5">
            <Image
              className="rounded-sm object-cover"
              rootClassName="shrink-0"
              preview={false}
              width={80}
              height={60}
              src={`${urlPrefix}${job.project_thumbnail_url}`}
            />
            <div className="ml-2.5 w-[calc(100%-100px)]">
              <div className="flex w-full items-center mb-3">
                {!!job.is_project_verified && (
                  <Image
                    rootClassName="mr-[6px] shrink-0"
                    preview={false}
                    width={24}
                    height={24}
                    src={projVerifiedImg}
                  />
                )}
                {/* <span className="text-white text-sm">{job.project_name}</span> */}
                <Text className="text-white text-sm w-full leading-6" ellipsis>
                  {job.project_name}
                </Text>
              </div>
              <div className=" text-[#606264] text-sm">
                {formatToMiddleStyle(job.created_at)}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center py-2 px-5 bg-white/[0.03]">
          <CompanyAvatar
            size="small"
            is_verified={job?.is_company_verified}
            name={job?.company_name}
            shortname={job?.company_shortname}
            logo_url={job?.company_logo_url}
          />
          <span className="text-[#999] text-base ml-4">
            {job.company_shortname ? job.company_shortname : job.company_name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default JobItemCard;
