import { checkIsInPersonalCenter } from "@/utils/common";
import { AuthBindings, useParsed } from "@refinedev/core";
import { notification } from "antd";
import { disableAutoLogin, enableAutoLogin } from "./hooks";
import { IIdentity, IProfile } from "./interfaces";
import { userIdentityBody } from "./interfaces";
import { jwtDecode } from "./packages/jwt-decode";
import { ptLinkWithState } from "./utils/helper";
import { axiosInstance } from "./utils/http";
const TOKEN_KEY = "leyline-auth";

interface Error {
  message: string;
  name: string;
  stack?: string;
}

const VITE_RECON_WEB_BASE = import.meta.env.VITE_RECON_WEB_BASE;

export const authProvider: AuthBindings = {
  login: async ({ email, password, redirectPath }) => {
    try {
      // disableAutoLogin();
      // enableAutoLogin()
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = new URLSearchParams();
      data.append("username", email);
      data.append("password", password);
      const response = await axiosInstance.post("/auth/login", data, config);
      console.log(response.data);
      const {
        data: { access_token, token_type },
      } = response;

      const decoded = jwtDecode(access_token) as userIdentityBody;
      if (!access_token || !decoded?.user_id) {
        throw new Error("Invalid User Credentials");
      }

      localStorage.setItem("username", `${email}`);
      localStorage.setItem("token_type", `${token_type}`);
      localStorage.setItem(TOKEN_KEY, `${access_token}`);
      localStorage.setItem("showPromotions", "true");

      try {
        const settingsResponse = await axiosInstance.get("/users/settings", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
        const timezone = settingsResponse.data.timezone;
        if (timezone) {
          localStorage.setItem("userTimezone", timezone);
        } else {
          return {
            success: true,
            redirectTo: "/novice-guide",
          };
        }
      } catch (settingsError) {
        console.error("Failed to fetch user settings", settingsError);
      }

      if (redirectPath?.startsWith("/pt")) {
        window.location.assign(ptLinkWithState(access_token));
        return {
          success: true,
        };
      }
      if (redirectPath?.startsWith("/recon-web")) {
        window.location.assign(
          `${VITE_RECON_WEB_BASE}landing?tk=${access_token}`,
        );
        return {
          success: true,
        };
      }

      let next = redirectPath || "/";
      if (decoded?.profile?.user_name === null) {
        next = `/complete-account?redirectPath=${encodeURIComponent(
          redirectPath || "/",
        )}`;
      }

      return {
        success: true,
        redirectTo: next,
      };
      // biome-ignore lint: error TS1196 - Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    } catch (error: any) {
      error.name = "";
      return {
        success: false,
        error,
      };
    }
  },
  // @ts-ignore
  register: async ({
    user_name,
    email,
    password,
    invitation_code,
    step,
    user_id,
    otp,
    redirectPath,
  }) => {
    try {
      if (step === "register") {
        console.log(
          "arguments ",
          email,
          password,
          invitation_code,
          step,
          user_id,
          otp,
        );
        const { data } = await axiosInstance.post("/auth/register", {
          user_name,
          email,
          password,
          invitation_code: invitation_code
            ? invitation_code.toLowerCase?.()
            : undefined,
        });
        console.log("register", data);
        if (data.id) {
          return {
            success: true,
            redirectTo: undefined,
            user_id: data.id,
          };
        }
        throw new Error("Register failed");
      }

      if (step === "otp") {
        const { data } = await axiosInstance.post(
          `/auth/${user_id}/verify_code?otp=${otp}`,
        );
        console.log(data);
        notification.success({
          message: "Email Verified",
          description: "Email verified successfully",
        });
        return {
          success: true,
          redirectTo: `/login${location.search}`,
          // redirectTo: redirectPath
          //   ? `/login?redirectPath=${encodeURIComponent(redirectPath)}`
          //   : "/login",
        };
      }
      // biome-ignore lint: error TS1196 - Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    } catch (error: any) {
      return {
        success: false,
        error: {
          message: "Register failed",
          name: error?.errors?.detail || "Register failed",
        },
      };
    }
  },
  updatePassword: async (params) => {
    const { password, token } = params;
    try {
      if (password && token) {
        const { data } = await axiosInstance.put(
          "/auth/forgot-password/reset",
          {
            token,
            new_password: password,
          },
        );
        console.log(data); // response data is null, for success
        notification.success({
          message: "Updated Password",
          description: "Password updated successfully",
        });
        return {
          success: true,
          redirectTo: `/login${location.search}`,
        };
      }
      throw new Error("Reset password failed");
      // biome-ignore lint: error TS1196 - Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    } catch (error: any) {
      return {
        success: false,
        error: {
          message: "Reset password failed",
          name: error?.errors?.detail || "Reset password failed",
        },
      };
    }
  },
  forgotPassword: async ({ email, otp }) => {
    try {
      if (email) {
        const { data } = await axiosInstance.post(
          "/auth/forgot-password/verify",
          {
            email,
            otp,
          },
        );
        if (data.access_token) {
          notification.success({
            message: "Reset Password",
            description: "Verification succeeded.",
          });
          return {
            success: true,
            access_token: data.access_token,
          };
        }
      }
      throw new Error("Verification failed");
      // biome-ignore lint: error TS1196 - Catch clause variable type annotation must be 'any' or 'unknown' if specified.
    } catch (error: any) {
      return {
        success: false,
        error: {
          message: "Reset password failed",
          name: error?.errors?.detail || "Reset password failed",
        },
      };
    }
  },
  logout: async () => {
    disableAutoLogin();
    localStorage.removeItem(TOKEN_KEY);
    const { useGlobalStore } = await import("@/stores");
    const { menuItems } = useGlobalStore.getState();
    const handledPathname = location.pathname.replace("/mktp", "");
    const isInPersonalCenter = checkIsInPersonalCenter(
      handledPathname,
      menuItems,
    );
    return {
      success: true,
      redirectTo: `/login?to=${
        isInPersonalCenter ? "/homepage" : handledPathname + location.search
      }`,
    };
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  check: async (e) => {
    const access_token = localStorage.getItem(TOKEN_KEY) || "";
    if (!access_token) {
      return {
        authenticated: false,
        error: {
          message: "Auth Check",
          name: "Invalid User Credentials",
        },
        logout: true,
        redirectTo: "/login",
      };
    }
    try {
      const decoded = jwtDecode(access_token) as userIdentityBody;
      if (!decoded?.user_id) {
        throw new Error("Invalid User Credentials");
      }
      if (!decoded?.profile?.user_name) {
        return {
          authenticated: true,
          redirectTo: "/complete-account",
        };
      }
      return {
        authenticated: true,
      };
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      return {
        authenticated: false,
        error: {
          message: error.message || "An error occurred",
          name: error.name,
        },
        logout: true,
        redirectTo: "/login",
      };
    }
  },
  getPermissions: async () => {
    try {
      const { data } = await axiosInstance.post("/probe/health", {});
      console.log("health check", data);
      if (data) {
        return {
          success: true,
        };
      }
      throw new Error("check failed");
    } catch (error) {
      return {
        success: false,
        error: {
          message: "backend health check failed",
        },
      };
    }
  },
  // @ts-ignore
  getIdentity: () => {
    const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return null;
    }

    const decoded = jwtDecode(token as string) as userIdentityBody;
    if (token && decoded?.user_id && decoded?.profile) {
      return {
        id: decoded?.user_id,
        name: decoded?.profile?.user_name,
        avatar: decoded?.profile?.avatar_url,
        is_company_verified: decoded?.is_company_verified,
      };
    }
    return null;
  },
};
