import { authProvider } from "../../authProvider";

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
export interface ApplyInfoType {
  id: string;
  role: string;
  headcount: number;
  count: number;
  rate: number;
  payment_method: string;
  count_unit: string;
  total_cost: number;
  answer_url: string;
  answer_link?: string;
  currency: string;
}

export const transformToFormData = (data: ApplyInfoType) => {
  return {
    role: data.role,
    headcount: data.headcount,
    payment_method: data.payment_method,
    budget: {
      quantity: data.count,
      duration_per_ppl: data.count,
      unit_price: data.rate,
      unit: data.count_unit,
    },
    totalAmount: data.total_cost,
    totalAmountEx: data.total_cost,
    testType: data.answer_url
      ? "upload"
      : data.answer_link
        ? "link"
        : undefined,
    testFile: data.answer_url
      ? [
          {
            uid: "1",
            name: "test file",
            status: "done",
            url: IMG_PREFIX + data.answer_url,
          },
        ]
      : [],
    testLink: data.answer_link,
  };
};

export const paymentMethodOptions = [
  // { label: "Piece-rate", value: "Piece-rate" },
  { label: "Time-based", value: "Time-based" },
  { label: "Negotiable", value: "Negotiable" },
];

export const roleOptions = [
  { label: "Individual", value: "Individual" },
  { label: "Company", value: "Company" },
];

export const judgeIsMyself = (user_id: string) => {
  // @ts-ignore
  return authProvider?.getIdentity()?.id === user_id;
};
