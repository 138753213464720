import dayjs from "dayjs";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import "./i18n";

import * as Sentry from "@sentry/react";
import LocaleData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import WeekDay from "dayjs/plugin/weekday";
import ErrorBoundaryWrapper from "./ErrorBoundaryWrapper";

dayjs.extend(WeekDay);
dayjs.extend(LocaleData);
dayjs.extend(LocalizedFormat);
const isDEV = import.meta.env.DEV;
const isNotPROD = import.meta.env.MODE === "staging" || import.meta.env.DEV;

Sentry.init({
  enabled: !isDEV,
  dsn: "https://c20bd6c2d94e7ad4244e0027cb328b0c@o4506898354077696.ingest.us.sentry.io/4506944114262016",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/dev-api\.leylinepro\.com\/mktp\//,
    /^https:\/\/api\.leylinepro\.com\/mktp\//,
  ],
});

if (!isNotPROD) {
  // Reddit Pixel
  const redditScript = document.createElement("script");
  redditScript.innerHTML = `
    !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
    rdt('init','a2_flz9jgmin6d2');
    rdt('track', 'PageVisit');
  `;
  document.head.appendChild(redditScript);

  // Google Tag
  const googleTagScript = document.createElement("script");
  googleTagScript.src =
    "https://www.googletagmanager.com/gtag/js?id=AW-16695797569";
  googleTagScript.async = true;
  document.head.appendChild(googleTagScript);

  const googleTagConfig = document.createElement("script");
  googleTagConfig.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-16695797569');
  `;
  document.head.appendChild(googleTagConfig);
}

const container = document.getElementById("root");
// biome-ignore lint:
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <ErrorBoundaryWrapper>
        <App />
      </ErrorBoundaryWrapper>
    </React.Suspense>
  </React.StrictMode>,
);
