import { ConfigProvider, Dropdown, DropdownProps } from "antd";
import styles from "./styles/dropdown.module.css";

export const CustomDropDown = ({ ...props }: DropdownProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // colorBgElevated: "#232529",
          // borderRadiusLG: 2,
        },
        components: {
          Dropdown: {
            controlItemBgHover: "#666",
            colorBgBase: "#333",
            colorText: "#fff",
            colorBgElevated: "#333",
            colorTextPlaceholder: "#606264",
          },
          // Button: {
          //   defaultBg: "#333",
          //   defaultBorderColor: "#333",
          //   defaultHoverBg: "#666",
          //   defaultHoverBorderColor: "#666",
          //   defaultHoverColor: "#fff",
          //   colorText: "#fff",
          // },
        },
      }}
    >
      <Dropdown {...props} rootClassName={styles.dropdownWrap} />
    </ConfigProvider>
  );
};
