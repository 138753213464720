import { ConfigProvider, Input, InputProps } from "antd";
import { TextAreaProps } from "antd/es/input";

export const CustomTextArea = ({ ...props }: TextAreaProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: "#686868",
          colorTextDescription: "#686868",
        },
      }}
    >
      <Input.TextArea
        {...props}
        style={{
          background: "#292D31",
          border: 0,
          borderRadius: "2px",
          // width: "400px",
          color: "white",
        }}
      />
    </ConfigProvider>
  );
};
