export enum ApplicationStatEnum {
  PENDING = "PENDING",
  A_DENIED = "A_DENIED",
  B_DENIED = "B_DENIED",
  WITHDRAWN = "WITHDRAWN",
  A_SIGNED = "A_SIGNED",
  B_SIGNED = "B_SIGNED",
  A_CANCELLED = "A_CANCELLED",
  B_CANCELLED = "B_CANCELLED",
  A_TIMEOUT = "A_TIMEOUT",
  B_TIMEOUT = "B_TIMEOUT", // At present, it should not exist. Be compatible with old data.
  CONTRACTING = "CONTRACTING",
  COMPLETED = "COMPLETED",
}
export type ApplicationStatType = keyof typeof ApplicationStatEnum;
