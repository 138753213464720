import SelectBox from "@/components/discover/homepage/SelectBox";
import { useGlobalStore } from "@/stores";
import { Row } from "antd";
import React, { useState } from "react";

interface IFilterBoxProps {
  portfolioFilter: string;
  setPortfolioFilter: (filter: string) => void;
  authorFilter: string;
  setAuthorFilter: (filter: string) => void;
}

export const FilterBox: React.FC<IFilterBoxProps> = ({
  portfolioFilter,
  setPortfolioFilter,
  authorFilter,
  setAuthorFilter,
}) => {
  const portfolioSelectTypes: string[] = ["Default", "Latest", "Following"];
  const artistSelectTypes: string[] = ["All", "Artist", "Studio"];
  const { activeCol, updateActiveCol } = useGlobalStore((state) => ({
    activeCol: state.activeCol,
    updateActiveCol: state.updateActiveCol,
  }));

  // const [selectedPortfolio, setSelectedPortfolio] = useState<string>("Default");
  const [showPortfolioMenu, setShowPortfolioMenu] = useState<boolean>(false);
  const [showArtistMenu, setShowArtistMenu] = useState<boolean>(false);

  return (
    <>
      <div className="fixed bottom-[57px] w-auto left-[translate(-50%, 50%)] flex justify-center opacity-60 hover:opacity-100">
        <Row className="relative rounded-full bg-white h-14 p-1 z-20 text-base text-[#999]">
          <SelectBox
            label="Portfolio"
            options={portfolioSelectTypes}
            selected={portfolioFilter}
            setSelected={setPortfolioFilter}
            showMenu={showPortfolioMenu}
            setShowMenu={setShowPortfolioMenu}
            updateActiveCol={updateActiveCol}
            activeCol={activeCol}
            colKey="portfolio"
          />
          <SelectBox
            label="Author"
            options={artistSelectTypes}
            selected={authorFilter}
            setSelected={setAuthorFilter}
            showMenu={showArtistMenu}
            setShowMenu={setShowArtistMenu}
            updateActiveCol={updateActiveCol}
            activeCol={activeCol}
            colKey="artist"
          />
        </Row>
      </div>
    </>
  );
};
