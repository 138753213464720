import { SettlementDetails } from "@/interfaces";
import { formatToMiddleStyleUTC } from "@/utils/date";
import { Tag } from "antd";
import React from "react";
import { useSettlementContext } from "../../../contexts/settlement";

type DrawerTitleProps = {
  role: string;
};

const getTagColor = (status: string) => {
  switch (status) {
    case "Pending processing":
      return "orange";
    case "Settled":
      return "green";
    default:
      return "blue";
  }
};

const mapStatusToDisplay = (
  role: "producer" | "contractor",
  status: string,
) => {
  const statusMapping: {
    [key in "producer" | "contractor"]: { [key: string]: string };
  } = {
    producer: {
      settlement: "Pending settlement",
      confirmation: "Pending confirmation",
      processing: "Pending processing",
      submission: "Pending submission",
      payment: "Pending payment",
      settled: "Settled",
    },
    contractor: {
      settlement: "Pending settlement",
      confirmation: "Pending confirmation",
      processing: "Pending processing",
      submission: "Pending collection",
      payment: "Pending collection",
      settled: "Settled",
    },
  };

  return statusMapping[role][status] || "Unknown";
};

export const DrawerTitle: React.FC<DrawerTitleProps> = ({ role }) => {
  const { settlementDetails, status } = useSettlementContext() ?? {};
  //@ts-ignore
  const confirmationStatus = mapStatusToDisplay(role, status);
  return (
    <div className="flex justify-between items-center w-full">
      <span>
        Settlement Confirmation –{" "}
        {formatToMiddleStyleUTC(settlementDetails?.start_date) || "N/A"} to{" "}
        {formatToMiddleStyleUTC(settlementDetails?.end_date) || "N/A"}
      </span>
      <Tag color={getTagColor(confirmationStatus)}>{confirmationStatus}</Tag>
    </div>
  );
};
