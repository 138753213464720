import {
  IResourceComponentsProps,
  useGo,
  useParsed,
  useShow,
} from "@refinedev/core";
import React, { useEffect, useMemo, useState } from "react";

import {
  DeleteButton,
  EditButton,
  List,
  SaveButton,
  ShowButton,
  useForm,
  useModalForm,
  useTable,
} from "@refinedev/antd";

import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";

import { convertFileListToUrlList, urlToFileList } from "@/utils/upload";
import { Navigate, useNavigate } from "react-router-dom";
import ProfileForm from "../../../components/users/ProfileForm";
import { useRefreshToken } from "../../../hooks";
import { IProfile } from "../../../interfaces";
import { makeUploadFileObject } from "../../../utils/common";
import { onAvatarPreview } from "../../../utils/dev";

const { Title, Text } = Typography;

export const NewUserProfile: React.FC = () => {
  const navigate = useNavigate();
  const { params } = useParsed();
  const { formProps, onFinish } = useForm<IProfile>({
    resource: "users/profile",
    action: "create",
    meta: {
      method: "put",
    },
  });

  const { queryResult, setShowId } = useShow<IProfile>();

  const { data: showQueryResult } = queryResult;
  const record = showQueryResult?.data;
  const { refreshToken } = useRefreshToken();

  // biome-ignore lint:
  const handleOnFinish = (values: any) => {
    console.log("onFinish:", values);
    try {
      if (values.avatar_url && Array.isArray(values.avatar_url)) {
        values.avatar_url = convertFileListToUrlList(values.avatar_url)?.[0];
      }
      if (values.page_cover_url && Array.isArray(values.page_cover_url)) {
        values.page_cover_url = convertFileListToUrlList(
          values.page_cover_url,
        )?.[0];
      }
      onFinish(values)
        .then(() => refreshToken())
        .then(() => {
          const redirectPath = params?.redirectPath || "/users/home";
          navigate(redirectPath);
        });
    } catch (error) {
      console.error("onFinish error:", error);
    }
  };

  return (
    <>
      <Form {...formProps} layout="vertical" onFinish={handleOnFinish}>
        <ProfileForm
          formProps={formProps}
          onAvatarPreview={onAvatarPreview}
          showSaveButton={false}
          hidePageCover={true}
        />
        <Row justify="center">
          <Col>
            <SaveButton type="primary" htmlType="submit" />
          </Col>
        </Row>
      </Form>
    </>
  );
};
