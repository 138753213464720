import { SettlementStatus } from "@/interfaces";
import { Modal } from "antd";
import React from "react";
import { useSettlementContext } from "../../../contexts/settlement";

interface SettlementWarningModalProps {
  isVisible: boolean;
  onClose: () => void;
  role: string;
  status: SettlementStatus;
  action: string;
}

export const SettlementWarningModal: React.FC<SettlementWarningModalProps> = ({
  isVisible,
  onClose,
  role,
  status,
  action,
}) => {
  const { refreshDetail, refreshStatus } = useSettlementContext() ?? {};

  const getMessage = () => {
    if (
      role === "producer" &&
      status.status === "settlement" &&
      action === "Submit"
    ) {
      return "There is an ongoing settlement confirmation for this staff. Please finish it before submitting a new settlement.";
    }
    if (
      role === "producer" &&
      status.status === "confirmation" &&
      action === "Update"
    ) {
      return 'The current settlement confirmation status has changed. Please click on "OK" to refresh the data.';
    }
    if (
      role === "producer" &&
      status.status === "processing" &&
      action === "Update"
    ) {
      return 'The current settlement confirmation status has changed. Please click on "OK" to refresh the data.';
    }
    return "Please confirm your action.";
  };

  const handleOk = () => {
    if (
      role === "producer" &&
      status.status === "confirmation" &&
      action === "Update"
    ) {
      refreshDetail?.();
      refreshStatus?.();
    }
    if (
      role === "producer" &&
      status.status === "processing" &&
      action === "Update"
    ) {
      refreshDetail?.();
      refreshStatus?.();
    }
    onClose();
  };

  return (
    <Modal
      title="Submission Warning"
      visible={isVisible}
      onOk={handleOk}
      onCancel={onClose}
      okText="OK"
    >
      <p>{getMessage()}</p>
    </Modal>
  );
};
