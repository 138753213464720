import { useRefineContext } from "@refinedev/core";
import { keys } from "./helper";

export const useKeys = () => {
  const {
    options: { useNewQueryKeys },
  } = useRefineContext();

  return {
    keys,
    preferLegacyKeys: !useNewQueryKeys,
  };
};
