import { useCallback, useState } from "react";

const KEY = "showedEmailNotice";
export default function () {
  const [showFlag, setShowFlag] = useState(!localStorage.getItem(KEY));
  const closeNotice = useCallback(() => {
    setShowFlag(false);
    localStorage.setItem(KEY, "true");
  }, []);

  return { showFlag, closeNotice };
}
