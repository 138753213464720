import ArtistPortfolioCore from "@/pages/discover/ArtistPortfolioCore";
import { useGlobalStore } from "@/stores";
import { FC } from "react";

const HomePersonalCenter: FC = () => {
  const { currentRole } = useGlobalStore((state) => state);
  return currentRole ? (
    <ArtistPortfolioCore hideBack userRole={currentRole} userType="myself" />
  ) : null;
};

export default HomePersonalCenter;
