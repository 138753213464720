import { CompanyAvatar } from "@/components/businessComponents";
import { CustomTag } from "@/components/customComponents";
import { Divider, Image, Space, Tag, Tooltip, Typography } from "antd";
import { FC } from "react";

const { Title, Text, Link } = Typography;

interface Props {
  companyInfo: {
    company_business_fields: string[];
    company_country: string;
    company_industry: string;
    company_logo_url: string;
    company_scale: string;
    company_name: string;
    company_shortname: string;
    is_company_verified: boolean;
  };
}

const CompanyInfoCard: FC<Props> = ({ companyInfo }) => {
  return (
    <div>
      <div className="flex items-center">
        <Tooltip title="Click to view enterprise homepage">
          <CompanyAvatar
            size="large"
            is_verified={companyInfo?.is_company_verified}
            name={companyInfo?.company_name}
            shortname={companyInfo?.company_shortname}
            logo_url={companyInfo?.company_logo_url}
            className="mr-4"
          />
        </Tooltip>
        <div>
          <Title level={4} className="!text-white !font-normal !mb-[2px]">
            {companyInfo?.company_shortname}
          </Title>
          <Space
            size={4}
            split={
              <Divider
                type="vertical"
                style={{ borderColor: "rgba(255, 255, 255, .1)" }}
              />
            }
          >
            <Text className="text-white !mb-0">
              {companyInfo?.company_industry || "--"}
            </Text>
            <Text className="text-white !mb-0">
              {companyInfo?.company_scale} Employees
            </Text>
          </Space>
          <div className="text-[#999]">{companyInfo?.company_country}</div>
        </div>
      </div>
      <p className="mt-4">
        {companyInfo?.company_business_fields?.map((v) => {
          return <CustomTag className="mr-2 mb-2" key={v} text={v} />;
        })}
      </p>
    </div>
  );
};

export default CompanyInfoCard;
