import { ConfigProvider, Segmented } from "antd";
import { FC, ReactNode } from "react";
import styles from "./styles/segmented.module.css";

interface SegmentedItemType {
  label: ReactNode;
  value: string | number;
  icon?: ReactNode;
  disabled?: boolean;
  className?: string;
}

interface Props {
  block?: boolean;
  options: string[] | number[] | SegmentedItemType[];
  defaultValue?: string | number;
  disabled?: boolean;
  size?: "large" | "middle" | "small";
  vertical?: boolean;
  value?: string | number;
  className?: string;
  onChange?: (value: string | number) => void;
}

export const CustomSegmented: FC<Props> = (props) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadiusXS: 8,
          borderRadiusSM: 12,
          borderRadius: 16,
          borderRadiusLG: 20,
        },
        components: {
          Segmented: {
            trackBg: "rgba(255, 255, 255, 0.1)",
            itemSelectedBg: "#fff",
            itemSelectedColor: "#333538",
            itemHoverColor: "rgba(255, 255, 255, 0.88)",
            itemHoverBg: "rgba(255, 255, 255, 0.1)",
            itemColor: "rgba(255, 255, 255, 1)",
            itemActiveBg: "rgba(0, 0, 0, 0.15)",
          },
        },
      }}
    >
      <div className={styles.segmentedWrap}>
        <Segmented {...props} />
      </div>
    </ConfigProvider>
  );
};
