import { CustomInput, CustomRadio } from "@/components/customComponents";
import { CustomUpload } from "@/components/customComponents/CustomUpload";
import { Form, Input, Radio } from "antd";
import { UploadProps } from "antd/es/upload";
import { FC } from "react";
type OmitUploadProps = Omit<UploadProps, "onChange" | "fileList">;

interface LimitSize {
  video: number;
  image: number;
}
interface CustomUploadProps extends OmitUploadProps {
  limitSize?: number | LimitSize;
  limitDesc?: string;
}

const typeOptions = [
  { label: "Upload", value: "upload" },
  { label: "Link", value: "link" },
];

interface UploadOrInputProps {
  typeFieldName: string | string[];
  typeFieldLabel: string;
  typeFieldRequired?: boolean;
  uploadProps?: CustomUploadProps;
  fileFieldName: string | string[];
  linkFieldName: string | string[];
}

const UploadOrInput: FC<UploadOrInputProps> = ({
  typeFieldName,
  typeFieldLabel,
  typeFieldRequired = false,
  uploadProps,
  fileFieldName,
  linkFieldName,
}) => {
  const form = Form.useFormInstance();
  const typeVal = Form.useWatch(typeFieldName, form);

  return (
    <>
      <Form.Item
        name={typeFieldName}
        label={typeFieldLabel}
        className="!mb-3"
        rules={[
          {
            required: typeFieldRequired,
            message: `Please select ${typeFieldLabel.toLowerCase?.()}`,
          },
        ]}
      >
        <CustomRadio.Group options={typeOptions} />
      </Form.Item>
      {typeVal === "upload" && (
        <Form.Item
          name={fileFieldName}
          label={" "}
          colon={false}
          valuePropName="fileList"
          extra={
            uploadProps?.limitDesc ||
            "The image/video should not exceed 10/20MB."
          }
          className="!mb-3"
          required={false}
          rules={[
            {
              required: typeFieldRequired,
              message: "Please upload file",
            },
          ]}
        >
          <CustomUpload listType="picture" {...uploadProps} />
        </Form.Item>
      )}
      {typeVal === "link" && (
        <Form.Item
          name={linkFieldName}
          label={" "}
          colon={false}
          className="!mb-3"
          required={false}
          rules={[
            {
              required: typeFieldRequired,
              message: "Please enter link",
            },
            {
              type: "url",
            },
          ]}
        >
          <CustomInput />
        </Form.Item>
      )}
    </>
  );
};

export default UploadOrInput;
