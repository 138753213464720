import { ICompanyInfo } from "@/pages/companies/type";
import { httpGet } from "@/utils/http";
import { useOne } from "@refinedev/core";
import { useRequest } from "ahooks";
import { useMemo } from "react";

const TOKEN_KEY = "leyline-auth";
const api = () => httpGet("/companies/my-company");

export const useHasCompanyRole = (isReady?: boolean) => {
  const token = localStorage.getItem(TOKEN_KEY);

  // const { data: companyResp, isLoading } = useOne({
  //   resource: "companies",
  //   id: "my-company",
  //   queryOptions: {
  //     enabled: !!token,
  //   },
  // });

  const { data: companyResp, loading: isLoading } = useRequest(api, {
    manual: false,
    ready: !!token,
    refreshDeps: [],
    cacheKey: "cache-company-info",
    debounceWait: 500,
  });

  const companyInfo = useMemo(
    () => companyResp?.data as ICompanyInfo,
    [companyResp?.data],
  );
  const hasEmployerView = useMemo(() => {
    return (
      // companyInfo?.verification_status === "APPROVED" ||
      // companyInfo?.verification_status === "DENIED" ||
      // companyInfo?.verification_status === "PENDING"
      !!companyInfo?.is_verified
    );
  }, [companyInfo?.is_verified]);

  return { companyInfo, hasEmployerView, isLoading };
};
