import { useState } from "react";
import { axiosInstance } from "../../utils/http";

const TOKEN_KEY = "leyline-auth";

export const useRefreshToken = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const saveToken = async (newToken: string) => {
    try {
      localStorage.setItem(TOKEN_KEY, newToken);
    } catch (error) {
      console.error("Error saving token:", error);
      setError("Failed to save token. Please try again later.");
    }
  };

  const refreshToken = async () => {
    setIsLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      const response = await axiosInstance.get("/auth/refresh-token");
      const data = response.data;

      if (data?.access_token) {
        await saveToken(data.access_token);
        setError(null); // Clear errors on success
      } else {
        console.error("Invalid token data:", data);
        setError("Failed to refresh token. Please contact support.");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      setError("Failed to refresh token. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return { refreshToken, isLoading, error };
};
