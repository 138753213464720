import { CustomCard } from "@/components/customComponents/CustomCard";
import { IPublicCompanyInfo } from "@/pages/companies/type";
import { videoExtensions } from "@/utils/identifyFiles";
import { Col, Divider, Image, Row, Space } from "antd";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { getImgUrl } from "../../../utils/common";
const videoExtensionsRegex = videoExtensions.join("|");
const PublicCompanyIntroduction = ({
  companyInfo,
}: { companyInfo: IPublicCompanyInfo | undefined }) => {
  const renderAddresses = () => {
    return (
      <div>
        {companyInfo?.addresses?.map((address, index) => (
          <div key={address}>
            {index !== 0 && <Divider className="bg-white opacity-10 my-5" />}
            {address}
          </div>
        ))}
      </div>
    );
  };

  const renderMediaGallery = () => {
    return (
      <Row gutter={16}>
        {companyInfo?.pic_urls?.map(
          (url) =>
            url && (
              <Col key={url} span={6} className="mb-4">
                {url.match(
                  new RegExp(`\\.(?:${videoExtensionsRegex})$`, "i"),
                ) ? (
                  <div
                    className="w-full aspect-[16/9]"
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <Player
                      fluid={true}
                      width={100}
                      height={100}
                      playsInline
                      src={getImgUrl(url) as string}
                    />
                  </div>
                ) : (
                  <Image
                    className="aspect-[16/9] object-cover"
                    width={"100%"}
                    height={"100%"}
                    preview={true}
                    src={getImgUrl(url) || ""}
                  />
                )}
              </Col>
            ),
        )}
      </Row>
    );
  };

  return (
    <div className="gap-4 flex flex-col">
      <CustomCard title="Company Description">
        <p>{companyInfo?.description || "No profile information available."}</p>
      </CustomCard>
      <CustomCard title="Business Field">
        <div className="flex flex-row gap-2 flex-wrap">
          {companyInfo?.business_fields &&
          companyInfo.business_fields.length > 0 ? (
            companyInfo.business_fields.map((area) => (
              <Space
                key={area}
                className="bg-[#404444] text-white rounded-full text-sm py-0.5 px-2 hover:bg-[#606464]"
              >
                {area}
              </Space>
            ))
          ) : (
            <p>No business field specified.</p>
          )}
        </div>
      </CustomCard>
      <CustomCard title="Company Photo Album">
        {renderMediaGallery()}
      </CustomCard>

      <CustomCard title="Address">{renderAddresses()}</CustomCard>
    </div>
  );
};

export default PublicCompanyIntroduction;
