import { ConfigProvider, Modal, ModalProps } from "antd";

export const CustomModal = ({ ...props }: ModalProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            titleColor: "#fff",
            titleFontSize: 24,
          },
        },
      }}
    >
      <Modal
        {...props}
        styles={{
          header: {
            background: "#2A2D30",
            padding: "15px 24px 13px 24px",
          },
          content: { padding: "0px", background: "#232529" },
          footer: {
            padding: "16px 22px",
            borderTop: "1px solid rgba(255, 255, 255, 0.10)",
          },
        }}
      />
    </ConfigProvider>
  );
};
