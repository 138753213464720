const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { FileType } from "../interfaces";

export type FileListType = FileType[];

// biome-ignore lint:
export const isString = (value: any) => {
  return typeof value === "string" || value instanceof String;
};

export const padImageUrl = (url: string) => {
  if (!isString(url)) return url;
  if (url.length === 0) return url;
  if (url.includes(IMG_PREFIX)) return url;
  return `${IMG_PREFIX}${url}`;
};

export const removePrefix = (url: string | undefined) => {
  return url?.replace(IMG_PREFIX, "");
};

export const urlToFileList = (url: string | undefined | null) => {
  if (!url) return url;
  return [
    {
      uid: "-1",
      name: "Cover.png",
      status: "done",
      url: padImageUrl(url),
    },
  ];
};

export const fileListToUrlList = (uploadState: FileListType) => {
  if (uploadState?.length > 0) {
    return (
      uploadState
        // biome-ignore lint:
        ?.map((file: any) => file?.response.id)
        ?.map((url: string) => url?.replace(IMG_PREFIX, ""))
    );
  }
};

export const normfileListToUrlList = (uploadState: FileListType) => {
  if (uploadState?.length > 0) {
    return (
      uploadState
        // biome-ignore lint:
        ?.map((file: any) => file?.url)
        ?.map((url: string) => url?.replace(IMG_PREFIX, ""))
    );
  }
};
export const convertFileListToUrlList = (uploadState: FileListType) => {
  if (uploadState.length > 0) {
    const file = uploadState[0];
    // @ts-ignore
    if (file?.response) {
      return fileListToUrlList(uploadState);
    }
    return normfileListToUrlList(uploadState);
  }
  return uploadState;
};

export const isImageFile = (file: UploadFile | null | undefined): boolean =>
  file?.type ? file.type.startsWith("image/") : false;
export const isVideoFile = (file: UploadFile | null | undefined): boolean =>
  file?.type ? file.type.startsWith("video/") : false;
export const isPdfFile = (file: UploadFile | null | undefined): boolean =>
  file?.type ? file.type.startsWith("application/pdf") : false;

export const isFileSizeLargerThan = (sizeMB = 5) => {
  return (file: RcFile) => {
    const fileSizeLimit = sizeMB;
    return file.size / 1024 / 1024 > fileSizeLimit;
  };
};
