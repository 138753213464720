import {
  Avatar,
  Image,
  Layout as AntdLayout,
  Space,
  Typography,
  theme,
} from "antd";
import React from "react";
import { CustomizedTitleV2 } from "./title";

export const CustomizedThemedHeader: React.FC<{
  sticky?: boolean;
}> = ({ sticky }) => {
  const { token } = theme.useToken();

  const LeyLineLogo = (
    <Image className="ml-1" src="images/L-logo-3.png" preview={false} />
  );

  return (
    <AntdLayout.Header
      className={`flex items-center p-0 px-6 h-16 ${
        sticky ? "sticky top-0 z-10" : ""
      }`}
      style={{
        backgroundColor: token.colorBgElevated,
      }}
    >
      <Space>
        <CustomizedTitleV2 icon={LeyLineLogo} />
      </Space>
    </AntdLayout.Header>
  );
};
