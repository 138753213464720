import { CustomButton } from "@/components/customComponents";
import { handleDefaultRole } from "@/components/header/utils";
import ContainerContent from "@/pages/authCore/components/containerContent";
import AuthLayout from "@/pages/authCore/layout";
import { useGlobalStore } from "@/stores";
import { setDefaultTimezone } from "@/utils/date";
import { Image } from "antd";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const descClass = "text-white/[.5] mb-7";
const dotClass =
  "before:inline-block before:w-[5px] before:h-[5px] before:bg-[#00ADAE] before:rounded-full before:content-[''] before:align-middle before:mr-[10px]";

const NoviceGuide: FC = () => {
  const navigate = useNavigate();
  const { updateCurrentRole } = useGlobalStore((state) => state);

  useEffect(() => {
    setDefaultTimezone();
  }, []);

  useEffect(() => {
    const defaultRole = handleDefaultRole();
    // @ts-ignore
    updateCurrentRole(defaultRole);
  }, [updateCurrentRole]);

  const clickBtn = (type: string) => {
    let nextPage = "";
    let state: { fromPage: string } | null = { fromPage: "noviceGuide" };
    switch (type) {
      case "edit":
        nextPage = "/users/edit";
        break;
      case "publish":
        nextPage = "/portfolio/create";
        break;
      case "verify":
        nextPage = "/companies/create";
        break;
      default:
        nextPage = "/";
        state = null;
        break;
    }
    navigate(nextPage, {
      replace: true,
      state,
    });
  };

  return (
    <AuthLayout>
      <div className=" max-w-[1000px] m-auto">
        <ContainerContent
          hideLogo
          title="Congratulations!"
          description="Become part of the community to learn and collaborate with your peers."
        >
          <div className="flex">
            <div className="pt-10 mr-5 px-5 pb-7 text-center bg-white/[.1] rounded-xl w-[426px]">
              <Image
                className="!w-20 !h-20 mb-5"
                preview={false}
                src="images/artist.png"
              />
              <div className="text-white text-xl mb-7">I am an Artist</div>
              <div className="w-[208px] whitespace-nowrap mx-auto text-left">
                <div className={`${descClass} ${dotClass}`}>
                  Showcase my Artwork
                </div>
                <div className={`${descClass} ${dotClass}`}>Apply Jobs</div>
                <div className={`${descClass} ${dotClass}`}>
                  Seek Inspiration
                </div>
              </div>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("edit")}
              >
                Edit Your Profile
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                onClick={() => clickBtn("publish")}
              >
                Publish Your Artwork
              </CustomButton>
            </div>
            <div className="pt-10 px-5 pb-7 text-center bg-white/[.1] rounded-xl w-[426px]">
              <Image
                className="!w-20 !h-20 mb-5"
                preview={false}
                src="images/studio.png"
              />
              <div className="text-white text-xl mb-7">I am a Studio</div>
              <div className="w-[208px] whitespace-nowrap mx-auto text-left">
                <div className={`${descClass} ${dotClass}`}>
                  Recruit Artists
                </div>
                <div className={`${descClass} ${dotClass}`}>
                  Outsource Projects
                </div>
                <div className={`${descClass} ${dotClass}`}>
                  Manage Productions
                </div>
              </div>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                onClick={() => clickBtn("verify")}
              >
                Verify your Company now!
              </CustomButton>
            </div>
          </div>
          <div className="text-center mt-[60px]">
            <CustomButton
              shape="round"
              ghost
              type="primary"
              onClick={() => clickBtn("browsing")}
            >
              Exploring
            </CustomButton>
          </div>
        </ContainerContent>
      </div>
    </AuthLayout>
  );
};

export default NoviceGuide;
