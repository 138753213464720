import { getStaticImgUrl } from "@/utils/common";
import { useParsed } from "@refinedev/core";
import { Image } from "antd";
import { useEffect } from "react";

const ThirdPartyResult = () => {
  const { params } = useParsed();
  const isDeposit = params?.businessType === "deposit";

  useEffect(() => {
    const channel = new BroadcastChannel("refreshReceivedList");
    channel.postMessage("");
  }, []);

  return (
    <div className="w-full h-screen bg-[#1d1f22] relative overflow-hidden">
      <Image
        wrapperClassName="absolute -left-10 top-10 w-[540px] h-[280x]"
        src={getStaticImgUrl("logo/L-logo-4.png?a=1") as string}
        preview={false}
      />
      <div className="absolute left-1/2 top-1/2 w-[460px] h-[400px] -ml-[230px] -mt-[200px] text-center">
        <Image
          wrapperClassName="absolute left-0 top-0 w-full h-full"
          src={getStaticImgUrl("successBg.png") as string}
          preview={false}
        />
        <Image
          className="w-[128px] h-[120px] object-fill mb-7 mt-[110px]"
          src={getStaticImgUrl("successIcon.png") as string}
          preview={false}
        />
        <h3 className="text-white text-2xl">Payment successful</h3>
        <p className="text-[#999]">You can close this window</p>
      </div>
      <span className="text-[240px] font-bold text-white/[.02] absolute -right-8 bottom-0">
        LeyLine
      </span>
    </div>
  );
};

export default ThirdPartyResult;
