import { datePickerFormat, generateTimezoneOptions } from "@/utils/date";
import { axiosInstance } from "@/utils/http";
import { isImageFile, isVideoFile } from "@/utils/upload";
import { PlusOutlined } from "@ant-design/icons";
import { Create } from "@refinedev/antd";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import type { UploadFile, UploadProps } from "antd";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

interface GenericObject {
  [key: string]: unknown;
}

interface AllFormData {
  projectName?: string;
  projectDescription?: string;
  timezone?: string;
  projectPeriod?: [Dayjs, Dayjs];
  projectCover?: UploadFile[];
}

const { Dragger } = Upload;
const { Option } = Select;
const { RangePicker } = DatePicker;

import { PRIVACY_POLICY_URL, USER_AGREEMENT_URL } from "@/constants";
import { RcFile } from "antd/lib/upload/interface";

export const EditProject: React.FC<IResourceComponentsProps> = () => {
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const apiUrl = useApiUrl();
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
  const navigate = useNavigate();
  const projectId = sessionStorage.getItem("currentProjectId");

  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [isVerified, setIsVerified] = useState<number | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>("");

  const fileToUploadFormat = useCallback((url: string, type: string) => {
    if (!url) return [];
    return [
      {
        uid: "-1",
        type: type,
        name: "Current file (click to download)",
        status: "done" as const,
        originalurl: url,
        url: `${marketplaceUploadUrl}${url}`,
      },
    ];
  }, []);

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const response = await axiosInstance.get(`/projects/${projectId}`);
        const data = response.data;
        if (response.status === 200) {
          console.log(data);
          setIsVerified(data.verification?.is_verified);
          setThumbnailUrl(data.thumbnail_url || "");
          setProjectFileList(fileToUploadFormat(data.thumbnail_url, "img"));

          const formData: AllFormData = {
            projectName: data.name,
            projectDescription: data.description,
            projectPeriod: [dayjs(data.start_date), dayjs(data.end_date)],
            projectCover: fileToUploadFormat(data.thumbnail_url, "img"),
            timezone: data.timezone,
          };

          formRef.current?.setFieldsValue(formData);
          setAllFormData(formData);
          if (data.thumbnail_url) {
            setPreviewImage(`${marketplaceUploadUrl}${data.thumbnail_url}`);
          }
          setInitialDataLoaded(true);
        } else {
          console.error("Error fetching project data:", data.detail);
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    if (!initialDataLoaded) {
      fetchProjectData();
    }
  }, [projectId, initialDataLoaded, fileToUploadFormat]);

  const [projectFileList, setProjectFileList] = useState<UploadFile[]>([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const projectUploadProps: UploadProps = {
    name: "file",
    multiple: false,
    fileList: projectFileList,
    listType: "picture",
    maxCount: 1,
    beforeUpload: (file) => {
      const isImage = isImageFile(file);
      const imageSizeLimit = 5;

      if (isImage) {
        if (file.size / 1024 / 1024 > imageSizeLimit) {
          message.error(`Image size exceeded ${imageSizeLimit}MB.`);
          return Upload.LIST_IGNORE;
        }
      } else {
        message.error("You can only upload images.");
        return Upload.LIST_IGNORE;
      }
      setProjectFileList([file]);
      const previewUrl = URL.createObjectURL(file);
      setPreviewImage(previewUrl);
      return false;
    },
    onChange: (info) => {
      setProjectFileList(info.fileList);
    },
    // onPreview: async (file) => {
    //   let src = file.url;
    //   if (!src && file.originFileObj) {
    //     src = await new Promise<string>((resolve) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(file.originFileObj as Blob);
    //       reader.onload = () => resolve(reader.result as string);
    //     });
    //   }
    //   if (src) {
    //     const image = new Image();
    //     image.src = src;
    //     const imgWindow = window.open(src);
    //     imgWindow?.document.write(image.outerHTML);
    //   }
    // },
  };

  const previewFile = (file: UploadFile, isImage: boolean) => {
    if (!file || !file.originFileObj) return;
    const src = file.url || URL.createObjectURL(file.originFileObj as Blob);
    if (isImage) {
      const imgWindow = window.open(src);
      if (imgWindow) {
        imgWindow.document.write(`<img src="${src}" className="w-full">`);
      }
    } else {
      const videoWindow = window.open(src);
      if (videoWindow) {
        videoWindow.document.write(
          `<video src="${src}" controls className="w-full"></video>`,
        );
      }
    }
  };

  const isPreviewableFile = (file: UploadFile): boolean => {
    return isImageFile(file) || isVideoFile(file);
  };

  const downloadFile = (file: UploadFile) => {
    const url =
      file.url ||
      (file.originFileObj
        ? URL.createObjectURL(file.originFileObj as Blob)
        : null);
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name || "download";
      link.click();
    }
  };

  const beforeUploadForFiles = (file: RcFile) => {
    const isImage = isImageFile(file);
    const isVideo = isVideoFile(file);
    const isImageOrVideo = isImage || isVideo;
    if (!isImageOrVideo) {
      message.error("You can only upload image or video files.");
      return Upload.LIST_IGNORE;
    }
    const imageSizeLimit = 5;
    const videoSizeLimit = 50;

    if (isImage) {
      if (file.size / 1024 / 1024 > imageSizeLimit) {
        message.error(`Image size exceeded ${imageSizeLimit}MB.`);
        return Upload.LIST_IGNORE;
      }
    } else if (isVideo) {
      if (file.size / 1024 / 1024 > videoSizeLimit) {
        message.error(`Video size exceeded ${videoSizeLimit}MB.`);
        return Upload.LIST_IGNORE;
      }
    } else {
      message.error("You can only upload image or video files.");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const cleanObject = (obj: GenericObject): GenericObject => {
    const cleanedObj: GenericObject = {};
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (
        value !== undefined &&
        value !== "" &&
        value !== null &&
        value !== "[]" &&
        value !== "undefined"
      ) {
        cleanedObj[key] = value;
      }
    }
    return cleanedObj;
  };

  const handleSubmit = async () => {
    const loadingMessage = message.loading(
      "Updating project, please wait...",
      0,
    );

    try {
      console.log("All Form Data:", allFormData);
      const submittedThumbnailUrl =
        projectFileList.length > 0 && projectFileList[0].originFileObj
          ? await uploadFile(projectFileList[0])
          : thumbnailUrl;

      const [startDate, endDate] = allFormData.projectPeriod || [];

      const projectData = {
        name: allFormData.projectName,
        description: allFormData.projectDescription,
        start_date: startDate ? startDate.format("YYYY-MM-DD") : "",
        end_date: endDate ? endDate.format("YYYY-MM-DD") : "",
        timezone: allFormData.timezone,
        thumbnail_url: submittedThumbnailUrl,
      };
      console.log("project data: ", projectData);

      const cleanedProjectData = cleanObject(projectData);

      console.log("cleaned project data: ", cleanedProjectData);

      const response = await axiosInstance.put(
        `/projects/${projectId}`,
        projectData,
      );
      const data = response.data;
      if (response.status === 200) {
        setIsSubmitted(true);
        navigate("/projects/project-details");
      } else {
        message.error(`Error: ${data?.detail || "Failed to edit project"}`);
        setIsSubmitted(false);
      }
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      const errorMessage =
        error.errors?.detail ||
        "Failed to edit project. Please try again later.";
      message.error(`Error editing project: ${errorMessage}`);
      setIsSubmitted(false);
    } finally {
      loadingMessage(); // Close loading message
    }
  };

  const uploadFile = async (file: UploadFile) => {
    const formData = new FormData();
    formData.append("file", file.originFileObj as Blob);

    try {
      const response = await fetch(`${apiUrl}/projects/upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();
      return `${data.id}`;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const uploadProjectCoverButton = (
    <div className="cursor-pointer border-0 bg-transparent flex flex-col items-center justify-center">
      <PlusOutlined />
      <div className="mt-2">Upload</div>
    </div>
  );

  const [allFormData, setAllFormData] = useState<AllFormData>({});
  const formRef = useRef<FormInstance>(null);

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onFormValuesChange = (_: any, allValues: any) => {
    setAllFormData((prevData) => ({ ...prevData, ...allValues }));
    console.log("Form data:", allValues);
    console.log("All Form Data:", allFormData);
  };

  const timezoneOptions = generateTimezoneOptions();

  return (
    <Row gutter={24} className="min-h-screen">
      <Col span={24} className="overflow-auto">
        <Card bordered className="rounded-lg">
          <Create
            title="Edit Project"
            headerButtons={() => <></>}
            footerButtons={() => (
              <>
                <Button
                  type="primary"
                  onClick={() => formRef.current?.submit()}
                >
                  Submit
                </Button>
              </>
            )}
          >
            <Row justify="center">
              <Col span={12}>
                <Form
                  ref={formRef}
                  layout="horizontal"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{
                    isActive: true,
                    ...initialFormValues,
                  }}
                  onFinish={handleSubmit}
                  onValuesChange={onFormValuesChange}
                >
                  <Row gutter={20}>
                    <Col span={24}>
                      <Form.Item
                        label="Project Name"
                        name="projectName"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please enter project name"
                          showCount
                          maxLength={100}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Project Description"
                        name="projectDescription"
                      >
                        <Input.TextArea
                          placeholder="Please enter project description"
                          showCount
                          maxLength={1000}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Timezone"
                        name="timezone"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Please select the timezone",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Please select a timezone"
                          showSearch
                          filterOption={(input, option) =>
                            option?.children
                              ? (option.children as unknown as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              : false
                          }
                        >
                          {timezoneOptions.map((option) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Project Period"
                        name="projectPeriod"
                        rules={[
                          {
                            type: "array" as const,
                            required: true,
                            message: "Please select the project period",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || value[0].isBefore(value[1])) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Start date cannot be later than end date",
                                ),
                              );
                            },
                          }),
                        ]}
                      >
                        <RangePicker
                          format={datePickerFormat}
                          className="w-full"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label="Project Cover"
                        name="projectCover"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e?.fileList}
                        rules={
                          previewImage
                            ? []
                            : [
                                {
                                  required: true,
                                  message:
                                    "Please upload the image of the project cover",
                                },
                              ]
                        }
                      >
                        <Row justify="center">
                          <Col span={12}>
                            <Upload
                              {...projectUploadProps}
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              accept="image/*"
                            >
                              {previewImage ? (
                                <img
                                  src={previewImage}
                                  alt="Project Cover"
                                  className="w-full h-full object-contain"
                                />
                              ) : (
                                uploadProjectCoverButton
                              )}
                            </Upload>
                          </Col>
                          <Col span={12}>
                            <p>Recommended aspect ratio is 16:9.</p>
                            <p>The file size should not exceed 5MB.</p>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                          {
                            validator: (_, value) =>
                              value
                                ? Promise.resolve()
                                : Promise.reject(
                                    new Error("You must accept the agreement"),
                                  ),
                          },
                        ]}
                        wrapperCol={{ offset: 8, span: 16 }}
                      >
                        <Checkbox
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          I agree to{" "}
                          <a
                            className="!text-[#1677ff] hover:underline"
                            href={USER_AGREEMENT_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            User Agreement
                          </a>{" "}
                          and{" "}
                          <a
                            className="!text-[#1677ff] hover:underline"
                            href={PRIVACY_POLICY_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                          .
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Create>
        </Card>
      </Col>
    </Row>
  );
};
