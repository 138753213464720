import { ConfigProvider, Radio, RadioProps } from "antd";
import { RadioGroupProps } from "antd/lib";
import styles from "./styles/radio.module.css";

export const CustomRadio = ({ children, ...props }: RadioProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {},
      }}
    >
      <Radio {...props}>{children}</Radio>
    </ConfigProvider>
  );
};

CustomRadio.Group = (props: RadioGroupProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {},
        components: {},
      }}
    >
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        {...props}
        className={styles.radioWrap}
        rootClassName={styles.radioWrap}
      />
    </ConfigProvider>
  );
};

export default CustomRadio;
