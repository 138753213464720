import { accountTypeMapping } from "@/utils/common";
import { axiosInstance } from "@/utils/http";
import { padImageUrl } from "@/utils/upload";
import {
  DollarOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useTable } from "@refinedev/antd";
import { HttpError, useInvalidate, useUpdate } from "@refinedev/core";
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Modal,
  Row,
  Segmented,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { SearchProps } from "antd/es/input";
import { ColumnProps } from "antd/lib/table";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { SettleStatusType, SettlementDataRecord } from "../../interfaces/index";
import {
  datePickerFormat,
  formatToLongStyleUTC,
  formatToMiddleStyleUTC,
} from "../../utils/date";
import { SettlementConfirmationDrawer } from "./SettlementConfirmationDrawer";
import {
  HEADERS_CONTRACTOR,
  HEADERS_PRODUCER,
} from "./settlement/tableHeaders";
const { Text } = Typography;

// only for Segmented options
const optionsOfSettlementStatus = [
  // "Pending settlement",
  // "Pending confirmation",
  // "Pending processing",
  // "Pending submission",
  "Pending payment",
  "Paid",
];

const optionsOfSettlementStatusForContractorRole = ["Pending payment", "Paid"];

// Segmented options to settleStatus
export const optionToSettleStatus = {
  "Pending settlement": "settlement",
  "Pending confirmation": "confirmation",
  "Pending processing": "processing",
  "Pending submission": "submission",
  "Pending payment": "payment",
  Paid: "settled",
};

const optionToHeaders: Record<string, string[]> = {
  "Pending settlement": HEADERS_PRODUCER.settlement,
  "Pending confirmation": HEADERS_PRODUCER.confirmation,
  "Pending processing": HEADERS_PRODUCER.processing,
  "Pending submission": HEADERS_PRODUCER.submission,
  "Pending payment": HEADERS_PRODUCER.payment,
  Paid: HEADERS_PRODUCER.settled,
};

const optionToHeadersForContractorRole: Record<string, string[]> = {
  "Pending settlement": HEADERS_CONTRACTOR.settlement,
  "Pending confirmation": HEADERS_CONTRACTOR.confirmation,
  "Pending processing": HEADERS_CONTRACTOR.processing,
  "Pending payment": HEADERS_CONTRACTOR.payment,
  Paid: HEADERS_CONTRACTOR.settled,
};

type StatsType = {
  remain_cost: number;
  settled_cost: number;
  total_cost: number;
  unit: string;
  unit_price: number;
};

interface CostViewBodyProps {
  role: string;
  projectId: string;
}

const ROLE = {
  PRODUCER: "producer",
  CONTRACTOR: "contractor",
};

enum RoleType {
  PRODUCER = "producer",
  CONTRACTOR = "contractor",
}
interface SettlementListProps {
  role?: RoleType;
  projectId?: string;
}

const basePTUrl = import.meta.env.VITE_PRODUCER_TOOL_API_BASE_URL;

const SettlementList: React.FC<SettlementListProps> = () => {
  const [selectedRole, setSelectedRole] = useState<RoleType>(RoleType.PRODUCER);
  const [settleStatus, setSettleStatus] = useState<SettleStatusType>("payment");
  const [selectedOption, setSelectedOption] =
    useState<string>("Pending payment");

  const [selectedDate, setSelectedDate] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(2, "month").startOf("month"),
    dayjs().endOf("month"),
  ]);
  const [searchProjectName, setSearchProjectName] = useState<string | null>();
  const [searchStaffName, setSearchStaffName] = useState<string | null>();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [modal, contextHolder] = Modal.useModal();
  const { mutate } = useUpdate();
  const hasSelected = selectedRowKeys.length > 0;

  const isProducer = selectedRole === ROLE.PRODUCER;

  const { tableProps } = useTable<SettlementDataRecord, HttpError>({
    resource: "settlements/wallet",
    meta: {
      query: {
        start_date: selectedDate[0]?.toISOString(),
        end_date: selectedDate[1]?.toISOString(),
        // end_date: "2024-08-01T00:00:00Z",
        project_name: searchProjectName,
        staff_name: searchStaffName,
        role: selectedRole,
        settle_status: settleStatus,
      },
    },
    dataProviderName: "PT",
  });

  // @ts-ignore
  // const dataSource = (tableProps?.dataSource?.items ||
  //   dummyData) as SettlementDataRecord[];
  const dataSource = tableProps?.dataSource?.items as SettlementDataRecord[];

  // const dataSource = dummyData as SettlementDataRecord[];

  console.log("dataSource", dataSource);

  const handleOptionChange = (option: string) => {
    setSelectedRowKeys([]);
    setSelectedOption(option);
    // @ts-ignore
    setSettleStatus(optionToSettleStatus[option]);
  };

  const handleRoleChange = (option: RoleType) => {
    setSelectedRowKeys([]);
    setSelectedRole(option);
  };

  const onSearchName: SearchProps["onSearch"] = (value) => {
    setSearchProjectName(value);
  };

  const onSearchStaffName: SearchProps["onSearch"] = (value) => {
    setSearchStaffName(value);
  };

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] =
    useState<SettlementDataRecord | null>(null);

  const handleSettlementPeriodClick = (record: SettlementDataRecord) => {
    setSelectedRecord(record);
    setDrawerVisible(true);
  };

  const onClickExport = async (ids: string[]) => {
    let settleIds = [];
    if (ids?.length > 0) {
      settleIds = ids;
    } else {
      settleIds = dataSource.map((record) => record.id);
    }
    const walletExport = "settlements/wallet/export";
    try {
      const { data } = await axiosInstance.post(
        walletExport,
        {
          settle_ids: settleIds,
          project_name: searchProjectName,
          staff_name: searchStaffName,
          settle_status: settleStatus,
          start_date: selectedDate[0]?.toISOString(),
          end_date: selectedDate[1]?.toISOString(),
        },
        {
          responseType: "blob",
          baseURL: basePTUrl,
        },
      );
      if (!data) return;
      const url = URL.createObjectURL(data);
      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = "settlements_exported";
        link.click();
      }
    } catch (error) {
      console.error("Error exporting settlements: ", error);
      message.error("Error exporting settlements");
    }
  };

  const invalidate = useInvalidate();

  const refreshList = () => {
    invalidate({
      resource: "settlements/wallet",
      invalidates: ["list"],
      dataProviderName: "PT",
    });
  };

  const viewOptions = [
    { label: "Time", value: "Time", disabled: false },
    { label: "Task", value: "Task", disabled: true },
    { label: "Negotiable", value: "Negotiable", disabled: true },
  ];

  const columnsStore: Record<string, ColumnProps<SettlementDataRecord>> = {
    settlement: {
      title: "Settlement Period",
      dataIndex: "settlement",
      key: "settlement",
      render: (text: string, record: SettlementDataRecord) => (
        <a
          onClick={() => handleSettlementPeriodClick(record)}
          className="text-nowrap"
          style={{ color: "#1677ff" }}
        >
          {`${formatToMiddleStyleUTC(
            record.start_date,
          )} to ${formatToMiddleStyleUTC(record.end_date)}`}
        </a>
      ),
    },
    staff: {
      title: "Staff",
      dataIndex: "staff",
      key: "staff",
      render: (text: string, record: SettlementDataRecord) => (
        <div>
          <Avatar
            src={padImageUrl(record.avatar_url)}
            className="mr-2"
            alt={record.name}
          />
          <Text className="whitespace-nowrap">{record.name}</Text>
        </div>
      ),
    },
    headcount: { title: "Headcount", dataIndex: "headcount", key: "headcount" },
    tasks: { title: "Tasks", dataIndex: "tasks", key: "tasks" },
    paymentMethod: {
      title: (
        <>
          <Text>Payment Method</Text>
          <br />
          <Text>(Unit Price / Person / Unit)</Text>
        </>
      ),
      dataIndex: "unit_price",
      key: "paymentMethod",
      render: (text: string, record: SettlementDataRecord) => (
        <Text>{`${record.unit_price} / Person / ${record.unit}`}</Text>
      ),
    },
    pendingSettlementDuration: {
      title: "Pending Settlement Duration",
      dataIndex: "pending_duration",
      key: "pendingSettlementDuration",
    },
    totalCost: {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "totalCost",
    },
    settledCost: {
      title: "Settled Cost",
      dataIndex: "settled_cost",
      key: "settledCost",
    },
    remainingCost: {
      title: "Remaining Cost",
      dataIndex: "remain_cost",
      key: "remainingCost",
    },
    pendingSettlementCost: {
      title: "Pending Settlement Cost",
      dataIndex: "pending_cost",
      key: "pendingSettlementCost",
    },
    // accountType: {
    //   title: "Account Type",
    //   dataIndex: "account_type",
    //   key: "accountType",
    //   render: (text: string) => accountTypeMapping[text] || text,
    // },
    // collectionAccount: {
    //   title: "Collection Account",
    //   dataIndex: "account",
    //   key: "collectionAccount",
    // },
    // mobile: {
    //   title: "Mobile",
    //   dataIndex: "contact",
    //   key: "mobile",
    // },
    projectName: {
      title: "Project Name",
      dataIndex: "project_name",
      key: "projectName",
    },
    updateTime: {
      title: "Submission Time",
      dataIndex: "updated_at",
      key: "updateTime",
      render: (text: string, record: SettlementDataRecord) => (
        <Text>{formatToLongStyleUTC(record.updated_at)}</Text>
      ),
    },
    createTime: {
      title: "Create Time",
      dataIndex: "end_date",
      key: "createTime",
      render: (text: string, record: SettlementDataRecord) => (
        <Text>{formatToLongStyleUTC(record.end_date)}</Text>
      ),
    },
    settlementDuration: {
      title: "Settlement Duration",
      dataIndex: "duration",
      key: "settlementDuration",
    },
    payment: {
      title: "Payment",
      dataIndex: "pending_cost",
      key: "payment",
    },
    paymentId: {
      title: "paymentId",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    payTime: {
      title: "Pay Time",
      dataIndex: "updated_at",
      key: "payTime",
      render: (text: string, record: SettlementDataRecord) => (
        <Text>{formatToLongStyleUTC(record.updated_at)}</Text>
      ),
    },
  };

  const getColumnsByOption = () => {
    if (!selectedOption) return [];
    const options = isProducer
      ? optionToHeaders[selectedOption]
      : optionToHeadersForContractorRole[selectedOption];
    return options.map((header) => columnsStore[header]);
  };

  const getOptionsByRole = () => {
    return isProducer
      ? optionsOfSettlementStatus
      : optionsOfSettlementStatusForContractorRole;
  };

  const calculateSelectedPendingCost = () => {
    return selectedRowKeys.reduce((acc, key) => {
      const item = dataSource.find((item) => item.id === key.toString());
      // @ts-ignore
      return acc + (item?.pending_cost || 0);
    }, 0) as number;
  };

  const confirmPayment = () => {
    return modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure the payments has been completed?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        settlementBulkPayment();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const settlementBulkPayment = () => {
    if (hasSelected) {
      mutate(
        {
          resource: "settlements",
          id: "update_status",
          values: {
            ids: selectedRowKeys,
            status: "settled",
          },
          dataProviderName: "PT",
        },
        {
          onSuccess: () => {
            console.log("Settlement updated successfully.");
            setSelectedRowKeys([]);
            refreshList();
          },
          onError: (error) => {
            console.error("Error updating settlement:", error);
          },
        },
      );
    }
  };

  return (
    <div className="container mx-auto px-4 mt-4">
      <Row gutter={[16, 16]} justify="start" className="mb-4">
        <Col>
          <Segmented
            options={[
              {
                label: "Producer",
                value: RoleType.PRODUCER,
              },
              {
                label: "Artist",
                value: RoleType.CONTRACTOR,
              },
            ]}
            value={selectedRole}
            //@ts-ignore
            onChange={handleRoleChange}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="start" className="mb-4">
        <Col>
          <Segmented
            options={getOptionsByRole()}
            value={selectedOption}
            //@ts-ignore
            onChange={handleOptionChange}
          />
        </Col>
      </Row>
      <Text type="secondary">
        {/* id: project_cb4aab40-2c1a-41c7-9229-2d2db69089a2 */}
      </Text>
      <Row gutter={[16, 16]} justify="start" className="mb-4">
        {/* <Col>
          <Segmented options={viewOptions} />
        </Col> */}
        <Col>
          <Space.Compact>
            <Input.Search placeholder="Project name" onSearch={onSearchName} />
          </Space.Compact>
        </Col>
        {isProducer && (
          <Col>
            <Space.Compact>
              <Input.Search
                placeholder="Staff name"
                onSearch={onSearchStaffName}
              />
            </Space.Compact>
          </Col>
        )}
        <Col>
          <DatePicker.RangePicker
            value={selectedDate}
            onChange={(dates, dateStrings) => {
              setSelectedDate([dayjs(dates?.[0]), dayjs(dates?.[1])]);
            }}
            format={datePickerFormat}
          />
        </Col>
        {isProducer && (
          <Col>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={() => onClickExport([])}
            >
              Export All{" "}
            </Button>
          </Col>
        )}
      </Row>
      {hasSelected && (
        <Row
          className="absolute w-full"
          style={{
            zIndex: 100,
            marginTop: "-16px",
            marginLeft: "100px",
          }}
        >
          <Col>
            <div className="text-left p-2 bg-white shadow-md rounded-md relative">
              <Space size={4} split={<Divider type="vertical" />}>
                <span style={{ color: "#1677ff" }}>
                  {selectedRowKeys.length} Selected
                </span>
                {settleStatus === "payment" && (
                  <span className="cursor-pointer">
                    {dataSource[0]?.currency === "USD" ? "$" : "¥"}
                    {calculateSelectedPendingCost()}{" "}
                    <WalletOutlined onClick={confirmPayment} />
                  </span>
                )}
                <span className="cursor-pointer">
                  <DownloadOutlined
                    onClick={() => onClickExport(selectedRowKeys as string[])}
                  />
                </span>
              </Space>
            </div>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} justify="start" className="mb-4">
        <Col span={24}>
          <Table
            {...tableProps}
            rowSelection={rowSelection}
            columns={getColumnsByOption()}
            dataSource={dataSource}
            rowKey="id"
          />
          <SettlementConfirmationDrawer
            record={selectedRecord}
            option={selectedOption}
            role={selectedRole}
            visible={drawerVisible}
            onClose={() => {
              refreshList();
              setDrawerVisible(false);
            }}
          />
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
};

export default SettlementList;
