import { useCallback, useEffect, useState } from "react";

export type TConfigResponse = {
  status: number;
  statusText: string;
  // biome-ignore lint:
  data: any;
  // biome-ignore lint:
  isError: any;
  isLoading: boolean;
  fetchConfig: () => void;
};

export const useConfigStorage = (url: string): TConfigResponse => {
  const [status, setStatus] = useState<number>(0);
  const [statusText, setStatusText] = useState<string>("");
  // biome-ignore lint:
  const [data, setData] = useState<any>();
  // biome-ignore lint:
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchConfig = useCallback(async () => {
    setLoading(true);
    try {
      const apiResponse = await fetch(url);
      if (!apiResponse.ok) {
        throw new Error(`Error: ${apiResponse.statusText}`);
      }
      const json = await apiResponse.json();
      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      setData(json);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [url]);

  return {
    status,
    statusText,
    data,
    isError: error,
    isLoading: loading,
    fetchConfig,
  };
};
