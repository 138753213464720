import { SelectBoxProps } from "@/interfaces";
import { DownOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import React, { useRef, useEffect, useCallback } from "react";

const SelectBox: React.FC<SelectBoxProps> = ({
  label,
  options,
  selected,
  setSelected,
  showMenu,
  setShowMenu,
  updateActiveCol,
  activeCol,
  colKey,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (type: string) => {
    setSelected(type);
    updateActiveCol(colKey);
    setShowMenu(false);
  };
  // biome-ignore lint:
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    },
    [ref],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClickFunc = () => {
    updateActiveCol(colKey);
    setShowMenu(!showMenu);
  };

  return (
    <Col
      ref={ref}
      className={`relative py-3 px-6 gap-4 rounded-full cursor-pointer flex items-center justify-between z-20 ${
        activeCol === colKey
          ? "bg-[#1D1F22] text-[#999]"
          : "bg-white text-[#1D1F22]"
      }`}
      onClick={() => {
        updateActiveCol(colKey);
      }}
    >
      <span>{label}</span>
      <Button
        className={`flex items-center border-0 p-0 h-auto bg-transparent hover:!bg-transparent ${
          activeCol === colKey
            ? "text-[#999] hover:!text-[#999]"
            : "text-black hover:!text-black"
        }`}
        onClick={handleClickFunc}
      >
        <span>{selected}</span> <DownOutlined />
      </Button>
      {showMenu && (
        <div className="absolute bottom-1 right-6 rounded-xl w-auto bg-white text-[#999] text-[13px] py-[7px] px-[5px] flex flex-col">
          {options.map((type) => (
            <Button
              key={type}
              className="pl-[7px] py-[2px] text-left border-0 hover:!bg-[#E7E7E7] hover:!rounded-[6px] hover:!text-[#578A8C] cursor-pointer pr-4"
              onClick={() => handleClick(type)}
            >
              {type}
            </Button>
          ))}
        </div>
      )}
    </Col>
  );
};

export default SelectBox;
