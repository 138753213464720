import { AccordionIcon } from "@/components/customComponents/CustomIcons";
import { getStaticImgUrl } from "@/utils/common";
import { Card, Col, Collapse, Row } from "antd";
import type { CollapseProps } from "antd";
import React, { useState } from "react";

const items: CollapseProps["items"] = [
  {
    key: "1",
    label: "AI AV script",
    children: (
      <div>
        <span className="block mb-4 md:mb-6 lg:mb-10 text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-white">
          Let's hear it play out
        </span>
        <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/40 font-medium leading-normal">
          Quickly generate AV scripts with our voice APIs & directly sync with
          our director review system to get to the next step in production.
        </span>
      </div>
    ),
  },
  {
    key: "2",
    label: "AI Text to storyboard",
    children: (
      <div>
        <span className="block mb-4 md:mb-6 lg:mb-10 text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-white">
          Let's hear it play out
        </span>
        <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/40 font-medium leading-normal">
          Quickly generate AV scripts with our voice APIs & directly sync with
          our director review system to get to the next step in production.
        </span>
      </div>
    ),
  },
];

export const AIScriptBreakdown: React.FC = () => {
  const [activeAccordion, setActiveAccordion] = useState<string>("1");

  const handleChangeAccordion = (key: string | string[]) => {
    setActiveAccordion(key[0]);
  };

  return (
    <div className="relative bg-grey1 bg-linearGradient2">
      <div className="max-w-7xl px-6 mx-auto py-10 sm:py-20 md:py-32 lg:py-40">
        <div className="flex flex-col gap-4 sm:gap-6 md:gap-8 lg:gap-10">
          <span className="text-2xl sm:text-3xl md:text-5xl lg:text-7xl font-extrabold text-white">
            AI Script breakdown
          </span>
          <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium text-white">
            1-click to generate accurate script breakdowns with tag elements
          </span>
          <span className="text-sm sm:text-base md:text-lg lg:text-xl text-white/60">
            Get your script ready for production faster
          </span>
        </div>
        <Row
          className="bg-black rounded-[2.5rem] pl-6 sm:pl-8 md:pl-10 lg:pl-16 py-12 sm:py-16 md:py-24 lg:py-32 mt-20"
          gutter={[16, 16]}
        >
          <Col xs={24} md={12}>
            <Collapse
              accordion
              items={items}
              onChange={handleChangeAccordion}
              defaultActiveKey={["1"]}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <AccordionIcon expanded={isActive} />
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <div className="relative w-full h-full aspect-[1.3/1] max-h-[30rem]">
              <img
                src={getStaticImgUrl("accordion1.png") || ""}
                className={`absolute top-1/2 -translate-y-1/2 right-0 2xl:-right-1/4 w-full max-w-3xl h-auto !duration-[400ms] transition-opacity delay-0 ease-in-out ${
                  activeAccordion === "1" ? "opacity-100" : "opacity-0"
                }`}
                alt="test"
              />
              <img
                src={getStaticImgUrl("accordion2.png") || ""}
                className={`absolute top-1/2 -translate-y-1/2 right-0 2xl:-right-1/4 w-full max-w-3xl h-auto !duration-[400ms] transition-opacity delay-0 ease-in-out ${
                  activeAccordion === "2" ? "opacity-100" : "opacity-0"
                }`}
                alt="test"
              />
            </div>
          </Col>
        </Row>
        <div className="rounded-[2.5rem] overflow-hidden mt-10 sm:mt-20 md:mt-40 lg:mt-72">
          <img
            src={getStaticImgUrl("banner_ai.png") || ""}
            className="w-full aspect-[1.75/1]"
            alt="test"
          />
          <Card className="rounded-t-none rounded-b-[2.5rem] py-6 sm:py-8 md:py-10 lg:py-14">
            <div className="flex flex-col gap-4 sm:gap-6 md:gap-8 lg:gap-10 items-center">
              <span className="text-2xl sm:text-3xl md:text-5xl lg:text-7xl text-black font-extrabold">
                AI Concept
              </span>
              <span className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-black font-medium">
                Let's sketch some ideas out
              </span>
              <span className="text-sm sm:text-base md:text-lg lg:text-xl text-black/60 text-center">
                Quickly generate concept images with your text prompts and
                reference image inputs
              </span>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
