import { Card, CardProps, ConfigProvider } from "antd";

export const CustomCard = ({ ...props }: CardProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: "#232529",
          borderRadiusLG: 2,
        },
        components: {
          Card: {
            headerBg: "#2A2D30",
          },
        },
      }}
    >
      <Card
        bordered={false}
        styles={{
          header: {
            background: "#2A2D30",
            color: "white",
            fontWeight: "bold",
            padding: "16px auto 13px 20px",
          },
          body: {
            background: "#232529",
            color: "white",
            opacity: 0.85,
            padding: "20px 20px 29px 20px",
          },
        }}
        {...props}
      />
    </ConfigProvider>
  );
};
