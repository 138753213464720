import { ShortUserType } from "@/utils/imUtils";
import { UserType } from "../../__generated__/graphql";

// const ALL_ROLES = [UserType.Individual, UserType.Company];

export const handleDefaultRole = () => {
  const localEmail = window.localStorage.getItem("username");
  const localRole = window.localStorage.getItem(`${localEmail}_curRole`);
  if (!localRole)
    window.localStorage.setItem(`${localEmail}_curRole`, UserType.Individual);
  // @ts-ignore
  return localRole && ShortUserType[localRole] === "Company"
    ? UserType.Company
    : UserType.Individual;
};

export const updateLocalRole = (role: UserType) => {
  const localEmail = window.localStorage.getItem("username");
  window.localStorage.setItem(`${localEmail}_curRole`, role);
};
