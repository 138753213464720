import { CustomMenu } from "@/components/customComponents/CustomMenu";
import { LogoutOutlined, UnorderedListOutlined } from "@ant-design/icons";
import {
  CanAccess,
  ITreeMenu,
  pickNotDeprecated,
  useActiveAuthProvider,
  useIsExistAuthentication,
  useLink,
  useLogout,
  useTranslate,
  useWarnAboutChange,
} from "@refinedev/core";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";

export const renderMenuTreeView = (tree: ITreeMenu[], selectedKey?: string) => {
  const Link = useLink();
  return tree.map((item: ITreeMenu) => {
    const {
      icon,
      label,
      route,
      key,
      name,
      children,
      parentName,
      meta,
      options,
    } = item;

    if (children.length > 0) {
      return (
        <CanAccess
          key={item.key}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <CustomMenu
            key={item.key}
            icon={icon ?? <UnorderedListOutlined />}
            title={label}
          >
            {renderMenuTreeView(children, selectedKey)}
          </CustomMenu>
        </CanAccess>
      );
    }
    const isSelected = key === selectedKey;
    const isRoute = !(
      pickNotDeprecated(meta?.parent, options?.parent, parentName) !==
        undefined && children.length === 0
    );

    return (
      <CanAccess
        key={item.key}
        resource={name.toLowerCase()}
        action="list"
        params={{
          resource: item,
        }}
      >
        <CustomMenu
          key={item.key}
          icon={icon ?? (isRoute && <UnorderedListOutlined />)}
        >
          <Link to={route ?? ""}>{label}</Link>
          {isSelected && <div className="ant-menu-tree-arrow" />}
        </CustomMenu>
      </CanAccess>
    );
  });
};

export const useMyLogout = () => {
  const isExistAuthentication = useIsExistAuthentication();
  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const translate = useTranslate();
  const authProvider = useActiveAuthProvider();
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const queryClient = useQueryClient();

  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        translate(
          "warnWhenUnsavedChanges",
          "Are you sure you want to leave? You have unsaved changes.",
        ),
      );

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
        queryClient.removeQueries();
      }
    } else {
      mutateLogout();
      queryClient.removeQueries();
    }
  };

  return handleLogout;

  // return (
  //   isExistAuthentication && (
  //     <CustomMenu
  //       key="logout"
  //       onClick={() => handleLogout()}
  //       icon={<LogoutOutlined />}
  //     >
  //       {translate("buttons.logout", "Logout")}
  //     </CustomMenu>
  //   )
  // );
};
