import { useOne, useResource } from "@refinedev/core";
import { Divider, Drawer } from "antd";
import { SettlementProvider } from "../../contexts/settlement";
import {
  SettleStatusType,
  SettlementDataRecord,
  SettlementDetails,
} from "../../interfaces";
import {
  CollectionInformation,
  DrawerFooter,
  DrawerTitle,
  SettlementInformation,
  TaskDetails,
  UserProfile,
} from "./settlement";

enum RoleType {
  PRODUCER = "producer",
  CONTRACTOR = "contractor",
}
type SettlementDrawerProps = {
  record: SettlementDataRecord | null;
  option: string;
  role: RoleType;
  visible: boolean;
  onClose: () => void;
};

export const SettlementConfirmationDrawer: React.FC<SettlementDrawerProps> = ({
  record,
  option,
  role,
  visible,
  onClose,
}) => {
  const confirmationStatus = option || "Unknown";
  const collectionInformationOptions = ["Pending payment", "Paid"].includes(
    option,
  );

  return (
    <SettlementProvider record={record} role={role} visible={visible}>
      <Drawer
        title={<DrawerTitle role={role} />}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={800}
        styles={{
          header: {
            height: "62px",
            padding: "20px",
          },
          body: {
            padding: "15px 20px",
          },
          content: {
            position: "absolute",
            height: "calc(100vh - 80px)",
            bottom: 0,
          },
        }}
        footer={
          <DrawerFooter
            role={role}
            option={option}
            record={record}
            onClose={onClose}
          />
        }
      >
        <div>
          {role === RoleType.PRODUCER && (
            <>
              <UserProfile status={confirmationStatus} />
              <Divider />
            </>
          )}
          {/* {collectionInformationOptions && (
            <>
              <CollectionInformation />
              <Divider />
            </>
          )} */}
          <SettlementInformation status={confirmationStatus} />
          <Divider />
          <TaskDetails role={role} status={confirmationStatus} />
        </div>
      </Drawer>
    </SettlementProvider>
  );
};
