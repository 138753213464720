import { ConfigProvider, Menu, MenuProps } from "antd";

export const CustomMenus = ({ ...props }: MenuProps) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemColor: "rgba(255, 255, 255, 0.50)",
            itemSelectedBg: "#191B1D",
            itemSelectedColor: "white",
            itemHoverColor: "white",
          },
        },
      }}
    >
      <Menu {...props} />
    </ConfigProvider>
  );
};
