import { PaymentMethodValueType } from "@/components/businessComponents";
import React, { ReactNode, createContext, useContext } from "react";

export interface JobInfoContextType {
  project_name: string;
  project_id: string;
  company_id: string;
  job_title: string;
  job_id: string;
  payment_method?: PaymentMethodValueType;
  job_payment_method?: PaymentMethodValueType;
  is_preferred_quotation_method?: boolean;
}

interface JobInfoProviderType {
  children?: ReactNode;
  jobData: {
    project_name: string;
    project_id: string;
    company_id: string;
    job_title: string;
    job_id: string;
  };
}

const JobInfoContext = createContext<JobInfoContextType | null>(null);

export const useJobInfoContext = () => useContext(JobInfoContext);

export const JobInfoProvider: React.FC<JobInfoProviderType> = ({
  children,
  ...rest
}) => {
  return (
    <JobInfoContext.Provider value={rest.jobData}>
      {children}
    </JobInfoContext.Provider>
  );
};
