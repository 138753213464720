// import errorCodeMsg from "@/constants/errorCodeMsg";
import { HttpError } from "@refinedev/core";
import { message } from "antd";
import axios, { AxiosError } from "axios";
import { authProvider } from "../../authProvider";

const TOKEN_KEY = "leyline-auth";
const BASE_URL = import.meta.env.VITE_MARKETPLACE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 15 * 1000,
});

// @ts-ignore
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  // special handling for reset password
  if (config.url === "/auth/forgot-password/reset") return config;
  if (config?.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return {
    ...config,
    paramsSerializer: {
      indexes: null,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      ...config.headers,
    },
  };
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  // (error) => {
  //   const customError: HttpError = {
  //     ...error,
  //     message: error.response?.data?.message,
  //     statusCode: error.response?.status,
  //   };

  //   return Promise.reject(customError);
  // },
  async (error: AxiosError) => {
    if (error.response) {
      const { status, data, headers } = error.response;
      const customErrorCode = headers["x-error"];
      if (customErrorCode) {
        // @ts-ignore
        const message = data?.detail || "An error occurred";
        const httpError: HttpError = {
          ...error,
          statusCode: status,
          message,
          // @ts-ignore
          errors: message ? { detail: message } : data,
        };
        if (
          error?.config?.url !== "/auth/reset-password" &&
          customErrorCode === "20004" &&
          !window.location.pathname.includes("/login")
        ) {
          // @ts-ignore
          const res = await authProvider.logout();
          const { origin, pathname, search } = window.location;
          res.success &&
            // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
            (window.location.href = `${origin}/mktp/login?to=${
              pathname.replace("/mktp", "") + search
            }`);
        }
        return Promise.reject(httpError);
      }
      // Handle standard HTTP errors
      const httpError: HttpError = {
        ...error,
        statusCode: status,
        // @ts-ignore
        errors: data,
      };
      // @ts-ignore
      // data.detail && message.error(data.detail);
      return Promise.reject(httpError);
    }
  },
);

export { axiosInstance };
