import { UserType } from "@/__generated__/graphql";
import {
  updateCompanyOrUserInfoAPI,
  uploadCoverImageAPI,
} from "@/pages/discover/api";
import { httpPost, httpPut } from "@/utils/http";
import { EditOutlined } from "@ant-design/icons";
import { Button, Upload, message } from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/es/upload";
import { FC } from "react";
import styles from "./editCover.module.css";

interface Props {
  userRole: string;
  companyId?: string;
  refresh: () => void;
}

const EditCover: FC<Props> = ({ companyId, userRole, refresh }) => {
  const handleCoverUpload = (file: RcFile) => {
    const isOver10M = file.size / 1024 / 1024 > 10;
    if (isOver10M) {
      message.warning("File must smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }

    const uploadApiPath =
      userRole === UserType.Company
        ? `/companies/${companyId}/upload`
        : "/users/upload";
    uploadCoverImageAPI(file, uploadApiPath)
      .then(async (fileId) => {
        if (fileId) {
          const params = {
            [userRole === UserType.Company ? "cover_url" : "page_cover_url"]:
              fileId,
          };
          const updateApiPath =
            userRole === UserType.Company
              ? `/companies/${companyId}/cover`
              : "/users/profile";
          const res = await updateCompanyOrUserInfoAPI(params, updateApiPath);
          res && refresh?.();
          return res;
        }
        return Promise.reject("Upload failed");
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  return (
    <ImgCrop
      aspect={23 / 3}
      modalWidth={700}
      modalClassName={styles.editCovermodal}
    >
      <Upload
        name="cover"
        showUploadList={false}
        beforeUpload={handleCoverUpload}
        customRequest={() => {}}
        accept="image/*"
      >
        <Button type="primary" icon={<EditOutlined />}>
          Edit Cover
        </Button>
      </Upload>
    </ImgCrop>
  );
};

export default EditCover;
