import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import zh from "./locales/en.json";

const loadPath = "/mktp/locales/{{lng}}.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    lng: "en",
    supportedLngs: ["en", "zh"],
    backend: {
      loadPath: loadPath,
    },
    fallbackLng: ["en", "zh"],
    resources: {
      en: {
        translation: en,
      },
      zh: {
        translation: zh,
      },
    },
  });

export default i18n;
