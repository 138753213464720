import {
  CustomInput,
  CustomSelect,
  CustomUpload,
} from "@/components/customComponents";
import { generateTimezoneOptions } from "@/utils/date";
import { Checkbox, Col, DatePicker, Form, FormInstance, Row } from "antd";
import { FC } from "react";

interface Props {
  form: FormInstance;
}

// const uploadProps = {
//   listType: "picture",
// };

const Step3: FC<Props> = ({ form }) => {
  return (
    <Form form={form} layout="vertical" colon>
      <Row gutter={20} align="stretch" className="mb-5">
        <Col span={8}>
          <Form.Item
            className="h-full bg-white/[.1] p-5 rounded-xl"
            label="Standard Project Files"
            name="project_file_urls"
            extra={
              <span>
                Please upload quality reference. Detailed standards will give
                your team better reference.
                <br />
                This will also serve as the standard for LeyLine to handle any
                dispute should they arise during the production process.
              </span>
            }
          >
            <CustomUpload limitSize={20} listType="picture" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="h-full bg-white/[.1] p-5 rounded-xl"
            label="Delivery and Payment Schedule"
            name="delivery_and_payment_schedule_file_urls"
            extra="Breakdown of Delivery and Payment Schedule."
          >
            <CustomUpload limitSize={20} listType="picture" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            className="h-full bg-white/[.1] p-5 rounded-xl"
            label="Other Supplementary Contracts"
            name="contract_file_urls"
            extra="Any other custom agreements and contracts for the project."
          >
            <CustomUpload limitSize={20} listType="picture" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label=""
        name="isAgree"
        valuePropName="checked"
        className="text-center"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("You must accept the agreement")),
          },
        ]}
      >
        <Checkbox>
          双方为自己的合同合法性负责，leyline不负责【language to be given】
        </Checkbox>
      </Form.Item>
    </Form>
  );
};

export default Step3;
