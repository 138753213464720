import { CommentItem } from "@/components/discover/workDetail/CommentItem";
import { ICommentItem, ISetReplyCommentInfo } from "@/pages/discover/types";
import { DownOutlined } from "@ant-design/icons";
import { Col, Divider, Space, Typography } from "antd";
import React, { useState } from "react";

interface ICommentDetailProps {
  modal?: boolean;
  setReloadState: (state: string) => void;
  getCommentCount: () => void;
  commentCount: number;
  artworkUserId: string;
  user_id: string;
  commentListData: ICommentItem[] | undefined;
  setIsCommentInputVisible: (state: boolean) => void;
  setCommentReplyState: (state: boolean) => void;
  setReplyCommentInfo: (info: ISetReplyCommentInfo) => void;
}

export const CommentDetail: React.FC<ICommentDetailProps> = ({
  modal,
  setReloadState,
  getCommentCount,
  commentCount,
  artworkUserId,
  user_id,
  commentListData,
  setIsCommentInputVisible,
  setCommentReplyState,
  setReplyCommentInfo,
}) => {
  const [expandedReplies, setExpandedReplies] = useState<{
    [key: string]: number;
  }>({});

  const handleExpandReplies = (commentId: string) => {
    setExpandedReplies((prevState) => ({
      ...prevState,
      [commentId]: (prevState[commentId] || 1) + 10,
    }));
  };

  return (
    <Col className="flex flex-col flex-1 gap-[22px]">
      <Typography.Text className="text-[#999] text-base">
        {commentCount > 1 ? "Comments" : "Comment"} ({commentCount})
      </Typography.Text>
      <Col className="flex flex-col gap-2">
        {commentListData?.map((commentItem) => (
          <div key={commentItem.id}>
            <CommentItem
              modal={modal}
              setReloadState={setReloadState}
              getCommentCount={getCommentCount}
              artworkUserId={artworkUserId}
              user_id={user_id}
              commentItem={commentItem}
              rootId={commentItem.id}
              commentListData={commentListData}
              setIsCommentInputVisible={setIsCommentInputVisible}
              setCommentReplyState={setCommentReplyState}
              setReplyCommentInfo={setReplyCommentInfo}
            />
            {commentItem.replies && commentItem.replies.length > 0 && (
              <>
                {commentItem.replies
                  .slice(0, expandedReplies[commentItem.id] || 1)
                  .map((commentReplyItem) => (
                    <div className="pl-12" key={commentReplyItem.id}>
                      <CommentItem
                        modal={modal}
                        setReloadState={setReloadState}
                        getCommentCount={getCommentCount}
                        artworkUserId={artworkUserId}
                        replyState={true}
                        user_id={user_id}
                        commentItem={commentReplyItem}
                        rootId={commentItem.id}
                        commentListData={commentListData}
                        setIsCommentInputVisible={setIsCommentInputVisible}
                        setCommentReplyState={setCommentReplyState}
                        setReplyCommentInfo={setReplyCommentInfo}
                      />
                    </div>
                  ))}
                {commentItem.replies.length >
                  (expandedReplies[commentItem.id] || 1) && (
                  <Divider plain className="flex w-full pl-12 !text-[#515151]">
                    <Space
                      className="cursor-pointer text-[#515151]"
                      onClick={() => handleExpandReplies(commentItem.id)}
                    >
                      {`Expand ${Math.min(
                        10,
                        commentItem.replies.length -
                          (expandedReplies[commentItem.id] || 1),
                      )} replies`}
                      <DownOutlined />
                    </Space>
                  </Divider>
                )}
              </>
            )}
          </div>
        ))}
      </Col>
    </Col>
  );
};
