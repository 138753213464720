import { CustomButton } from "@/components/customComponents";
import { NoDataIcon } from "@/components/customComponents/CustomIcons";
import PortfolioItem from "@/components/discover/homepage/PortfolioItem";
import { getAuthArtworkListAPI } from "@/pages/discover/api";
import { ISearchArtworkLIst } from "@/pages/discover/types";
import { useGlobalStore } from "@/stores";
import { artworkListLocalStorage } from "@/utils/artworkListLocalStorage";
import Icon, { SettingOutlined } from "@ant-design/icons";
import { useInfiniteScroll } from "ahooks";
import { Button, Col, Row, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface IPortFolioComProps {
  containerRef: React.RefObject<HTMLDivElement>;
  author_id: string;
  userId: string;
  user_role: string;
}

export const AuthorPortfolio: React.FC<IPortFolioComProps> = ({
  containerRef,
  author_id,
  userId,
  user_role,
}) => {
  const navigate = useNavigate();
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const [searchArtworkData, setSearchArtworkData] =
    useState<ISearchArtworkLIst>({
      size: 10,
      page: 1,
      sort: "LATEST",
    });

  const getArtistList = useCallback(
    async (page: number) => {
      if (!currentRole || !author_id) return { list: [], nextPage: undefined };
      const response = await getAuthArtworkListAPI(updateLoading, currentRole, {
        ...searchArtworkData,
        user_id: author_id,
        role: user_role,
        page,
      });
      artworkListLocalStorage(response);
      return {
        list: response.list,
        nextPage:
          response.page < response.pages ? response.page + 1 : undefined,
      };
    },
    [currentRole, user_role, author_id, searchArtworkData, updateLoading],
  );

  const { data: infiniteData } = useInfiniteScroll(
    (d) => getArtistList(d ? d.nextPage : 1),
    {
      target: containerRef,
      isNoMore: (d) => !d?.nextPage,
      reloadDeps: [currentRole, getArtistList],
    },
  );

  return (
    <Row className="flex flex-col">
      {author_id === userId && user_role === currentRole && (
        <Col className="-mt-3 mb-[14px] text-right">
          <CustomButton
            shape="round"
            type="primary"
            className="py-2 px-6 h-full text-sm"
            onClick={() => navigate("/portfolio/home")}
          >
            <SettingOutlined />
            <span>Portfolio Management</span>
          </CustomButton>
        </Col>
      )}
      {infiniteData?.list.length === 0 ? (
        <Col className="flex flex-col justify-center items-center w-full h-full">
          <Icon className="text-[200px]" component={NoDataIcon} />
          <Typography.Text className="text-[#686868] text-base">
            No Data
          </Typography.Text>
        </Col>
      ) : (
        <Row className="w-full grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {infiniteData?.list.map((artwork, index) => (
            // biome-ignore lint:
            <PortfolioItem artworkItem={artwork} key={artwork.id + index} />
          ))}
        </Row>
      )}
    </Row>
  );
};
