export default {
  mode: "dark",
  token: {
    colorPrimary: "#00ADAE",
    colorPrimaryHover: "#33BDBE",
    colorPrimaryBorder: "#00ADAE",
    colorLink: "#00ADAE",
    colorTextPlaceholder: "#686868",
    colorFillContent: "rgba(0, 173, 174, 0.10)",
    controlOutline: "#2d2d2d",
    controlTmpOutline: "#2d2d2d",
    colorTextBase: "#ffffff",
    colorBgMask: "rgba(0, 0, 0, 0.65)",
  },
  components: {
    Button: {
      controlHeightLG: 60,
      controlHeight: 40,
      controlHeightSM: 30,
      borderRadius: 20,
      borderRadiusLG: 30,
      borderRadiusSM: 16,
      paddingInline: 30,
      paddingInlineLG: 80,
      paddingInlineSM: 30,
      contentFontSize: 16,
      contentFontSizeLG: 20,
      contentFontSizeSM: 14,
      defaultColor: "#00ADAE",
      defaultBg: "transparent",
      defaultBorderColor: "#00ADAE",
      defaultHoverBg: "rgba(255,255,255,0.1)",
      // defaultHoverBorderColor: "#3EB1FF",
      // defaultHoverColor: "#3EB1FF",
      defaultActiveBg: "rgba(0,0,0,0.2)",
      // colorBgContainerDisabled: "#333538",
      // colorTextDisabled: "#666666",
      borderColorDisabled: "transparent",
      ghostBg: "transparent",
      defaultGhostBorderColor: "#00ADAE",
      defaultGhostColor: "#00ADAE",
      defaultShadow: "none",
      primaryShadow: "none",
    },
    Checkbox: {
      colorText: "#fff",
    },
    Dropdown: {
      controlItemBgHover: "#666",
      colorBgBase: "#333",
      colorText: "#fff",
      colorBgElevated: "#333",
      colorTextPlaceholder: "#606264",
    },
    Drawer: {
      colorText: "#fff",
      colorBgElevated: "#232529",
      colorBgMask: "rgba(0, 0, 0, 0.7)",
      colorFillAlter: "#2a2d30",
    },
    Form: {
      labelColor: "#fff",
    },
    Input: {
      colorBgContainer: "#292D31",
      colorBorder: "transparent",
      controlPaddingHorizontal: 20,
      // hoverBorderColor: "rgba(3, 179, 180, 0.5)",
      // activeBorderColor: "rgba(3, 179, 180, 1)",
    },
    InputNumber: {
      borderRadiusSM: 0,
      borderRadius: 2,
      borderRadiusLG: 4,
      colorBgContainer: "#292D31",
      colorText: "#fff",
      colorBorder: "transparent",
      addonBg: "#292D31",
      // hoverBorderColor: "rgba(3, 179, 180, 0.5)",
      // activeBorderColor: "rgba(3, 179, 180, 1)",
    },
    Menu: {
      itemSelectedBg: "#191B1D",
      itemSelectedColor: "white",
    },
    Modal: {
      contentBg: "#292D31",
    },
    Radio: {
      borderRadius: 2,
      buttonBg: "#292D31",
      buttonColor: "#999999",
      colorBorder: "transparent",
    },
    Segmented: {
      trackBg: "#292D31",
      itemColor: "rgba(96, 98, 100, .65)",
      itemHoverColor: "#606264",
      itemSelectedColor: "#fff",
      itemSelectedBg: "#00ADAE",
      trackPadding: 4,
      controlPaddingHorizontal: 20,
    },
    Select: {
      borderRadiusSM: 0,
      borderRadius: 2,
      borderRadiusLG: 4,
      selectorBg: "#292D31",
      colorText: "#FFFFFF",
      colorBorder: "transparent",
      multipleItemBg: "#333333",
      controlItemBgHover: "rgba(255,255,255,.1)",
      controlItemBgActive: "rgba(0,0,0,.2)",
      optionSelectedColor: "#fff",
      colorBgElevated: "#292D31",
      paddingSM: 20,
    },
    Tooltip: {
      colorBgSpotlight: "#333333",
    },
    Upload: {
      colorBorder: "transparent",
      colorPrimaryBorder: "transparent",
      colorFillAlter: "#2A2D30",
      colorBgMask: "rgba(255,255,255, 0.1)",
    },
    Pagination: {
      itemActiveBg: "rgba(255, 255, 255, 0.10)",
    },
    DatePicker: {
      colorBgContainer: "#292D31",
      colorBgElevated: "#292D31",
      cellActiveWithRangeBg: "rgba(0, 173, 174, 0.1)",
      controlItemBgActive: "rgba(0, 173, 174, 0.1)",
      borderRadiusSM: 0,
      borderRadius: 2,
      borderRadiusLG: 4,
      activeBg: "#292D31",
      colorSplit: "rgba(255,255,255, 0.1)",
      colorBorder: "transparent",
    },
    Divider: {
      colorSplit: "rgba(255,255,255, 0.1)",
      margin: 30,
    },
  },
};
