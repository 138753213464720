import { ConfigProvider, InputNumber, InputNumberProps } from "antd";
import styles from "./styles/inputNumber.module.css";

export const CustomInputNumber = (props: InputNumberProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {},
      }}
    >
      <div className={styles.inputNumberWrap}>
        <InputNumber style={{ width: "calc(100% + 20px)" }} {...props} />
      </div>
    </ConfigProvider>
  );
};

export default CustomInputNumber;
