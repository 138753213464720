import { IJobData } from "@/pages/discover/types";
import { getImgUrl } from "@/utils/common";
import { formatToMiddleStyle } from "@/utils/date";
import { Col, Divider, Image, Row, Typography } from "antd";
import { Link } from "react-router-dom";

interface IJobItemProps {
  job: IJobData;
  authorId: string;
  userId: string;
}

export const JobItem: React.FC<IJobItemProps> = ({ job, authorId, userId }) => {
  return (
    <Row className="p-5 bg-[#232529] rounded-sm">
      <Link
        to={`/enterprises/public-job-details/${job?.id}`}
        className="flex flex-col w-full"
      >
        <Typography.Text className="text-white text-2xl font-bold">
          {job.title}
        </Typography.Text>
        <Typography.Text className="text-sm line-height-1 text-[#686868]">
          {job.location
            ? `${job.location} / ${job.remote_option} / ${job.employment_type}`
            : `${job.remote_option} / ${job.employment_type}`}
        </Typography.Text>
        {authorId === userId && (
          <Row className="flex flex-row gap-4 py-1">
            <Typography.Text className="flex gap-1 text-[#606264] text-sm items-center">
              <span className="text-white text-base">{job.total_views}</span>{" "}
              <span>Viewed</span>
            </Typography.Text>
            <Typography.Text className="flex gap-1 text-[#606264] text-sm items-center">
              <span className="text-white text-base">
                {job.total_communicated}
              </span>{" "}
              <span>Communicated</span>
            </Typography.Text>
            <Typography.Text className="flex gap-1 text-[#606264] text-sm items-center">
              <span className="text-white text-base">
                {job.total_applicants}
              </span>{" "}
              <span>Applied</span>
            </Typography.Text>
          </Row>
        )}
        <Divider className="bg-white opacity-10 mt-1 mb-4" />
        <Row className="flex flex-row gap-2.5">
          <Image
            preview={false}
            width="80px"
            height="60px"
            className="rounded-sm"
            src={
              getImgUrl(job.project_thumbnail_url) ||
              "https://placehold.co/64x64"
            }
          />
          <Col className="flex-1 flex flex-col gap-2.5 justify-center">
            <Typography.Text className="text-white text-opacity-85 font-bold text-sm">
              {job.project_name}
            </Typography.Text>
            <Typography.Text className="text-[#606264] text-sm">
              Local Create Date: {formatToMiddleStyle(job.created_at)}
            </Typography.Text>
          </Col>
        </Row>
      </Link>
    </Row>
  );
};
