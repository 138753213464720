import { CustomLink } from "@/components/customComponents/CustomLink";
import { padLeftImgUrl, showAbsoluteUrl } from "@/utils/common";
import { Image, Typography } from "antd";
import React, { FC } from "react";

const { Title, Text } = Typography;

interface Props {
  link: string;
  pic_url: string;
  video_url: string;
}

const ShowFileOrLink: FC<Props> = ({ link, pic_url, video_url }) => {
  return link || pic_url || video_url ? (
    <span style={{ display: "inline-block" }}>
      {link ? (
        <CustomLink
          href={showAbsoluteUrl(link)}
          className="px-2 !bg-white/[.06] rounded-[4px]"
        >
          {`${showAbsoluteUrl(link)}`}
        </CustomLink>
      ) : (
        <React.Fragment />
      )}
      {!!pic_url && (
        <Image
          style={{ maxWidth: "100%", objectFit: "cover" }}
          src={padLeftImgUrl(pic_url)}
        />
      )}
      {!!video_url && (
        // biome-ignore lint/a11y/useMediaCaption: <explanation>
        <video width={"100%"} controls src={padLeftImgUrl(video_url)} />
      )}
    </span>
  ) : (
    <React.Fragment />
  );
};

export default ShowFileOrLink;
