import { axiosInstance } from "@/utils/http";
import { useApiUrl } from "@refinedev/core";
import { Alert, Card, Col, Empty, Row, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyVerificationModal from "../../components/projects/CompanyVerificationModal";
import { formatToMiddleStyle } from "../../utils/date";
import SearchHeader from "./SearchHeader";

const { Title, Text } = Typography;

interface CardData {
  id: string;
  name: string;
  progress_status: string;
  created_at: string;
  thumbnail_url: string;
  is_verified: boolean;
  is_violated: boolean;
  is_enabled: boolean;
  violation_reason: string;
}

export const ProjectManagement: React.FC = () => {
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const [cards, setCards] = useState<CardData[]>([]);
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [selectedProjectStatus, setSelectedProjectStatus] = useState<string[]>(
    [],
  );
  const [selectedVerificationStatus, setSelectedVerificationStatus] = useState<
    string[]
  >([]);
  const [isViolated, setIsViolated] = useState<boolean>(false);

  const mapProjectStatusToApiValue = (status: string) => {
    switch (status) {
      case "Ongoing":
        return "IN_PROGRESS";
      case "Not Started":
        return "NOT_STARTED";
      case "Finished":
        return "FINISHED";
      case "Terminated":
        return "TERMINATED";
      default:
        return null;
    }
  };

  const getProjectStatusText = (card: CardData): string => {
    if (card.is_enabled === false) {
      return "Terminated";
    }

    switch (card.progress_status) {
      case "IN_PROGRESS":
        return "Ongoing";
      case "NOT_STARTED":
        return "Not Started";
      case "FINISHED":
        return "Finished";
      default:
        return "Undefined";
    }
  };

  const getProjectStatusTagColor = (card: CardData) => {
    if (card.is_enabled === false) {
      return "red";
    }

    switch (card.progress_status) {
      case "IN_PROGRESS":
        return "blue";
      case "NOT_STARTED":
        return "green";
      case "FINISHED":
        return "gray";
      default:
        return "default";
    }
  };

  const fetchProjects = async () => {
    try {
      const isVerifiedFilter =
        selectedVerificationStatus.includes("Verified") &&
        selectedVerificationStatus.includes("Unverified")
          ? undefined
          : selectedVerificationStatus.includes("Verified")
            ? true
            : selectedVerificationStatus.includes("Unverified")
              ? false
              : undefined;

      const params = {
        page: 1,
        size: 100,
        name: searchKeyword || undefined,
        progress_status:
          selectedProjectStatus.length > 0
            ? selectedProjectStatus
                .map(mapProjectStatusToApiValue)
                .filter(Boolean)
            : undefined,
        is_verified: isVerifiedFilter,
        is_violated: isViolated || undefined,
      };

      const response = await axiosInstance.get("/projects", {
        params,
      });
      if (response.data?.items) {
        setCards(response.data.items);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const checkCompanyVerification = async () => {
      try {
        const response = await axiosInstance.get("/companies/my-company");
        if (
          !response.data ||
          Object.keys(response.data).length === 0 ||
          response.data.is_verified !== true
        ) {
          setIsModalVisible(true);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    checkCompanyVerification();
    fetchProjects();
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    fetchProjects();
  }, [
    searchKeyword,
    selectedProjectStatus,
    selectedVerificationStatus,
    isViolated,
  ]);

  const handleConfirm = () => {
    setIsModalVisible(false);
    navigate("/companies/show");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    navigate("/users/home");
  };

  const handleCardClick = (cardId: string) => {
    sessionStorage.setItem("currentProjectId", cardId);
    navigate("/projects/project-details");
  };

  if (isModalVisible) {
    return (
      <CompanyVerificationModal
        isVisible={isModalVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 mt-4">
      <SearchHeader
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        selectedProjectStatus={selectedProjectStatus}
        setSelectedProjectStatus={setSelectedProjectStatus}
        selectedVerificationStatus={selectedVerificationStatus}
        setSelectedVerificationStatus={setSelectedVerificationStatus}
        isViolated={isViolated}
        setIsViolated={setIsViolated}
      />
      <Row gutter={[16, 16]} className="mt-8">
        {cards.length > 0 ? (
          cards.map((card) => (
            <React.Fragment key={card.id}>
              <Col span={24} md={12} lg={6}>
                <Card
                  className="overflow-auto"
                  hoverable
                  onClick={() => handleCardClick(card.id)}
                  cover={
                    <div className="h-96 overflow-hidden">
                      <img
                        alt="Project Cover"
                        src={
                          card.thumbnail_url?.includes("https")
                            ? card.thumbnail_url
                            : card.thumbnail_url
                              ? `${marketplaceUploadUrl}${card.thumbnail_url}`
                              : ""
                        }
                        className="w-full h-full object-cover"
                      />
                    </div>
                  }
                >
                  <Card.Meta
                    title={<Title level={4}>{card.name}</Title>}
                    description={
                      <div className="flex justify-between items-center">
                        <div>
                          <Text type="secondary">
                            Local Create Date:{" "}
                            {formatToMiddleStyle(card.created_at)}
                          </Text>
                        </div>
                        <div>
                          <Tag color={getProjectStatusTagColor(card)}>
                            {getProjectStatusText(card)}
                          </Tag>
                        </div>
                      </div>
                    }
                  />
                </Card>
                {card.is_violated === true && (
                  <Alert
                    message="Violated"
                    description={`The project has violated guidelines: ${card.violation_reason}`}
                    type="error"
                    showIcon
                    banner
                  />
                )}
              </Col>
            </React.Fragment>
          ))
        ) : (
          <Col span={24}>
            <Empty description="No projects found" />
          </Col>
        )}
      </Row>
    </div>
  );
};
