import { GetJobDetailResponse } from "@/pages/jobs/type";
import { padLeftImgUrl } from "@/utils/common";
import { formatToMiddleStyle } from "@/utils/date";
import { EnvironmentFilled, SafetyCertificateFilled } from "@ant-design/icons";
import {
  Col,
  Divider,
  Image,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { BsBuildings } from "react-icons/bs";
import { Link as LinkDom, useNavigate } from "react-router-dom";

const { Text, Title } = Typography;

interface Props {
  job: GetJobDetailResponse;
}

const DefaultImg = () => (
  <div
    style={{
      width: "50px",
      height: "50px",
      backgroundColor: "#f0f0f0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <BsBuildings size={30} color="#aaa" />
  </div>
);

const CompanyInfo = ({ job }: Props) => {
  return (
    <>
      <Row className="w-full">
        <Col>
          {job.company_id ? (
            <Tooltip title="Click to view enterprise homepage">
              <LinkDom to="/enterprises/public-company-homepage">
                <div className="relative">
                  {job.company_logo_url ? (
                    <Image
                      src={`${padLeftImgUrl(job.company_logo_url)}`}
                      alt={job.company_shortname || ""}
                      preview={false}
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <DefaultImg />
                  )}
                  {!!job.is_company_verified && (
                    <SafetyCertificateFilled
                      className="absolute right-0 top-0"
                      style={{ color: "#87d068" }}
                    />
                  )}
                </div>
              </LinkDom>
            </Tooltip>
          ) : (
            <DefaultImg />
          )}
        </Col>
        <Col className="pl-2">
          <Title level={5}>{job.company_shortname || ""}</Title>
          <Space>
            <Text>{job.company_industry}</Text>
            <Text>{job.company_scale}</Text>
          </Space>
          {!!job.company_country && (
            <div>
              <EnvironmentFilled />
              <Text>{job.company_country}</Text>
            </div>
          )}
        </Col>
      </Row>

      <p className="mt-4">
        {job.company_business_fields?.map((v) => {
          return (
            <Tag color="#00ced1" className="mr-1 mb-1">
              {v}
            </Tag>
          );
        })}
      </p>
    </>
  );
};

export default CompanyInfo;
