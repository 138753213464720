import { useUserData } from "@/hooks/useUserData"; // assuming useUserData is already implemented
import { useNavigate } from "react-router-dom";

export const useCheckAuth = () => {
  const navigate = useNavigate();
  const profile = useUserData();

  const checkAuth = async () => {
    return new Promise((resolve, reject) => {
      if (!profile) {
        navigate("/login");
        reject("User not authenticated");
      } else {
        resolve(true);
      }
    });
  };

  return { checkAuth };
};
