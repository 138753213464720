import { CustomInput } from "@/components/customComponents/CustomInput";
import { CustomModal } from "@/components/customComponents/CustomModal";
import { createBookmarkAPI, updateBookmarkAPI } from "@/pages/discover/api";
import { IBookmarkSettingData } from "@/pages/discover/types";
import { Button, Checkbox, CheckboxProps, Row, message } from "antd";
import { useEffect, useState } from "react";

interface IBookmarkSettingModalProps {
  bookmarkSetting: IBookmarkSettingData;
  setBookmarkSetting: (value: IBookmarkSettingData) => void;
  currentRole: string;
  setHandleChangeState: (value: string) => void;
}

export const BookmarkSettingModal: React.FC<IBookmarkSettingModalProps> = ({
  bookmarkSetting,
  setBookmarkSetting,
  currentRole,
  setHandleChangeState,
}) => {
  const [bookmarkName, setBookmarkName] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState(true);
  const onCreateBookmarkName = (value: string) => {
    setBookmarkName(value);
  };
  const handlePrivateChange: CheckboxProps["onChange"] = (e) => {
    setIsPrivate(e.target.checked);
  };
  useEffect(() => {
    if (bookmarkSetting.editState) {
      setBookmarkName(bookmarkSetting.folderName ?? "");
      setIsPrivate(bookmarkSetting.isPrivate ?? true);
    } else {
      setBookmarkName("");
      setIsPrivate(true);
    }
  }, [bookmarkSetting]);

  const handleBookmarkSetting = async () => {
    if (!bookmarkName) {
      message.error("Please enter bookmark title");
      return;
    }
    if (bookmarkSetting.editState) {
      const response = await updateBookmarkAPI(
        bookmarkSetting.bookmarkId ?? "",
        bookmarkName,
        isPrivate,
      );
      if (response === "success") {
        setHandleChangeState(
          `edit_${bookmarkSetting.bookmarkId}_${isPrivate}_${bookmarkName}`,
        );
      } else {
        message.error("Failed to edit bookmark");
      }
    } else {
      const response = await createBookmarkAPI(
        currentRole,
        bookmarkName,
        isPrivate,
      );
      if (response.id) {
        setHandleChangeState(`create_${response.id}`);
      } else {
        message.error("Failed to create bookmark");
      }
    }

    setBookmarkSetting({
      open: false,
    });
  };

  return (
    <Row>
      <CustomModal
        title={`${bookmarkSetting.editState ? "Edit" : "Create"} Bookmark`}
        open={bookmarkSetting.open}
        onCancel={() =>
          setBookmarkSetting({
            open: false,
          })
        }
        footer={
          <Button
            type="primary"
            className="bg-[#00ADAE] px-4 py-1 border-none rounded-full text-white text-base"
            onClick={handleBookmarkSetting}
          >
            Confirm
          </Button>
        }
      >
        <div className="px-6 py-5 flex flex-col gap-2.5">
          <CustomInput
            value={bookmarkName}
            placeholder="Bookmark Title"
            maxLength={30}
            className="!rounded-full !w-full !bg-transparent !border-[1px] !border-solid !border-[#606264]"
            onChange={(e) => onCreateBookmarkName(e.target.value)}
          />
          <Checkbox
            checked={isPrivate}
            className="text-white text-sm"
            onChange={handlePrivateChange}
          >
            <span>Private</span>
          </Checkbox>
        </div>
      </CustomModal>
    </Row>
  );
};
