import { Conversation } from "@tencentcloud/chat";
import { Avatar } from "antd";
import React, { memo } from "react";

interface ChatHeaderProps {
  conversation: Conversation;
}

const AdminChatHeader = (props: ChatHeaderProps) => {
  const { conversation } = props;
  const { userProfile } = conversation;

  return (
    <div className="border-solid border-slate-100 border-b p-3">
      <div className="header-content flex justify-between">
        <div className="flex justify-start items-center">
          {userProfile.avatar ? (
            <Avatar className="mr-2" src={userProfile.avatar} />
          ) : (
            <Avatar className="mr-2">{userProfile.nick}</Avatar>
          )}
          <h3 className="title text-base mb-0">{userProfile.nick}</h3>
        </div>
      </div>
    </div>
  );
};

export default memo(AdminChatHeader);
