import { CustomTag } from "@/components/customComponents";

interface Props {
  status: string;
}
const statusMap = {
  NOT_STARTED: {
    text: "Not Started",
    color: "#999",
    bg: "rgba(255, 255, 255, 0.04)",
  },
  IN_PROGRESS: {
    text: "Ongoing",
    color: "#4EBBF5",
    bg: "rgba(78, 187, 245, 0.10)",
  },
  FINISHED: {
    text: "Finished",
    color: "#46DB7A",
    bg: "rgba(70, 219, 122, 0.10)",
  },
  TERMINATED: {
    text: "Terminated",
    color: "#F55F4E",
    bg: "rgba(245, 95, 78, 0.10)",
  },
};
const ProjectStatusTag = ({ status }: Props) => {
  // @ts-ignore
  const { text, color, bg } = statusMap[status] || {};

  return (
    <CustomTag
      color={color}
      bgColor={bg}
      text={text}
      className="w-[114px]"
      fixedWidth
    />
  );
};

export default ProjectStatusTag;
