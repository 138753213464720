import { IArtworkItem } from "@/pages/discover/types";

export const artworkListLocalStorage = (response: {
  list: IArtworkItem[];
  page: number;
  pages: number;
  total: number;
}) => {
  const infoData = {
    total: response.total,
    page: response.page,
    pages: response.pages,
  };
  localStorage.setItem(
    "artworkList",
    JSON.stringify(response.list.map((item: IArtworkItem) => item.id)),
  );
  localStorage.setItem("artInfoData", JSON.stringify(infoData));
};
